import {Route, Routes} from 'react-router-dom';
import OrderModification from './Order Modification/OrderModification';
import TrackerIncompletion from './Tracker Incompletion/TrackerIncompletion';
import LandingPage from './Landing Page/LandingPage';
import Archivetracker from './Tracker Incompletion/Archive Tracker Incompletion/ArchiveTrackerIncompletion';
import ICRCreationForm from './ICR/ICRCreaionForm';
import RequestTracker from './Request Tracker/OrderProcessingRequestTracker';
import ArchiveRequestTracker from './Request Tracker/Archive Request Tracker/ArchiveRequestTracker';
import AccessDenied from './Common Files/AccessDenied';
import Overflowstatusdashboard from './Dashboard/OverFlowStatusDashboard';
import { RequiredAuth } from "./okta/SecureRoute";
import { LoginCallback } from "@okta/okta-react";
export default [
    <>

<Routes>

<Route path="/trackerincompletion" element={<RequiredAuth />}>
  <Route path="" element={<TrackerIncompletion />}></Route>
</Route>
<Route path="/ordermodification" element={<RequiredAuth />}>
  <Route path="" element={<OrderModification />}></Route>
</Route>
<Route path="/archivedata" element={<RequiredAuth />}>
  <Route path="" element={<Archivetracker />}></Route>
</Route>
<Route path="/icrcreationform" element={<RequiredAuth />}>
  <Route path="" element={<ICRCreationForm />}></Route>
</Route>
<Route path="/requesttracker" element={<RequiredAuth />}>
  <Route path="" element={<RequestTracker />}></Route>
</Route>
<Route path="/archiverequesttracker" element={<RequiredAuth />}>
  <Route path="" element={<ArchiveRequestTracker />}></Route>
</Route>
<Route path="/accessdenied" element={<RequiredAuth />}>
  <Route path="" element={<AccessDenied />}></Route>
</Route>
<Route path="/orderflowstatusdashboard" element={<RequiredAuth />}>
  <Route path="" element={<Overflowstatusdashboard />}></Route>
</Route>



<Route path="" element={<LoginCallback />} />


<Route path="/home" element={<RequiredAuth />}>
  <Route path="" element={<LandingPage />}></Route>
</Route>

</Routes>
</>
];