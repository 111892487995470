import React from 'react';
import Button from 'react-bootstrap/Button';


const ReadOnlyRowsForAdd = ({ material, reasonCodeOptions, handelRemoveClick }) => {

    return (

        <tr>
            <td style={{ backgroundColor: "#e9ecef", verticalAlign: "middle" }}>{material.materialNumber}</td>
            <td style={{ backgroundColor: "#e9ecef", verticalAlign: "middle" }}>{material.upc}</td>
            <td style={{ backgroundColor: "#e9ecef", verticalAlign: "middle" }}>{material.materialDescription}</td>
            <td style={{ backgroundColor: "#e9ecef", verticalAlign: "middle" }}>{material.uom}</td>
            <td style={{ backgroundColor: "#e9ecef", verticalAlign: "middle" }}>{material.orderQuantity}</td>
            <td style={{ backgroundColor: "#ffffff", verticalAlign: "middle" }}>
                <select class="form-select" name="ReasonCode" idx={material.id} data-getrc={material.reasonCode} data-getrcd={material.reasonCodeDesc} onfocus='this.size=10;'  >
                    {(() => { if (material.reasonCode !== "") { return (<option value={material.reasonCode}>{material.reasonCodeDesc}</option>) } })()}
                    {
                    }

                </select>
                <span class="help-block text-danger" name="Invalid_ReasonCode" style={{ display: "None", float: "left" }}>Please select Reason Code</span>
            </td>


            <td>
                <Button variant="outline-light" onClick={(event) => handelRemoveClick(material.id, reasonCodeOptions, event)} style={{ backgroundColor: "#FF0000", color: "white" }} >Remove</Button>
            </td>
        </tr>
    )

}
export default ReadOnlyRowsForAdd;