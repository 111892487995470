import React, { useState, Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


import "../Styles.css";

import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import JQuery, { event } from 'jquery';

import { nanoid } from 'nanoid';

import SublistRead from "./SubList/SublistRead";
import SublistEdit from "./SubList/SublistEdit";


import AllocationListRead from "./AllocationList/AllocationListRead";
import AllocationListEdit from "./AllocationList/AllocationListEdit";


import AddListRead from "./AddList/AddListRead";
import AddListEdit from "./AddList/AddListEdit";


import CutListRead from "./CutList/CutListRead";
import CutListEdit from "./CutList/CutListEdit";

import { EXCEL_FILE_BASE64 } from './ExportICRTemplate';
import FileSaver from 'file-saver';

import UtilBaseUrl from '../ConfigUtility';
import * as Constants from '../Common Files/Constants.js';
import moment from 'moment';
import 'moment-timezone';


const getToken = () => {
    let tokenStr = localStorage.getItem("okta-token-storage");

    if (!tokenStr) {
        return;
    }
    else {
        const tokenObj = JSON.parse(tokenStr);
        return tokenObj?.accessToken?.accessToken;
    }
}


const ICRCreation = () => {


    const hostUrl = UtilBaseUrl.URL;

    const [show, setShow] = useState(false);
    const [isShowConfirmPopUp, setShowConfirmPopUp] = useState(false);

    const handleClose = () => {
        setShowConfirmPopUp(false);

        setShow(false);

        let temp_windowload = sessionStorage.getItem("windowload");


        if (temp_windowload === 'true') {

            window.location.reload(true);

        }

    }
    const handleShow = () => setShow(true);


    const [ICR_AdvancedValidation, SetICR_AdvancedValidation] = useState(false);

    const [tradesegment, SetTradeSegment] = useState();
    const [customerExclude, SetCustomerExclude] = useState();
    const [customerInclude, SetCustomerInclude] = useState();
    const [hierarchy, SetHierarchy] = useState();
    const [soldtoparty, SetSoldToParty] = useState();
    const [orderReporting, SetOrderReporting] = useState();
    const [commenttext, setCommentText] = useState();

    const [destLocationInclude, SetDestLocationInclude] = useState();
    const [destLocationExclude, SetDestLocationExclude] = useState();
    const [PurchaseGroup, SetPurchaseGroup] = useState();
    const [STOnumber, SetSTOnumber] = useState();
    const [STOType, SetSTOType] = useState();
    const [commenttextSTO, setCommentTextSTO] = useState();


    //onchange states for file upload in advanced modal//

    const [excelFile, setExcelFile] = useState(null);
    const [typeError, setTypeError] = useState(null);

    const [validfileshow, setValidfileshow] = useState();
    const [validfileSTOshow, setValidfileSTOshow] = useState();
    const [EncodedFile, SetEncodedFile] = useState();
    const [FileType, SetFileType] = useState();

    let selectedFile, File_Type, uploadFileEncode;

    const handleFileAdvanced = (e) => {

        e.preventDefault();
        let filetypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
        selectedFile = e.target.files[0];


        if (selectedFile) {
            if (selectedFile && filetypes.includes(selectedFile.type)) {

                setTypeError(null);
                JQuery('#Invalid_File').hide();
                JQuery('#display_Valid_File').hide();
                JQuery('#Valid_File').html("<i class='fa fa-check-circle'></i> " + selectedFile.name).show();

                convertBase64(selectedFile);
            }
            else {


                JQuery('#Valid_File').hide();
                JQuery('#display_Valid_File').hide();
                JQuery('#Invalid_File').html("<i class='fa fa-times-circle'></i> Invalid File Type, File types other than .xls are not supported, Please upload a valid file type").show();

                setExcelFile(null);
            }
        }

    }
    // NUMERIC SITE ID INPUT

    const numericSourceLocation = () => {
        JQuery('input[idx= "restrictedSorceLocation"]').keyup(function (e) {
            if (/\D/g.test(this.value)) {

                this.value = this.value.replace(/\D/g, '');
            }
        });

    }

    // BASE 64 CONVERSION //
    const convertBase64 = (file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {

            let uploadFile = reader.result;

            uploadFileEncode = uploadFile.split(",")[1];


        };
        reader.onerror = function (error) {
        };
    };

    const handleUpload = (event) => {

        JQuery(event.target).siblings('input[type="file"]').click();
    }

    //DOWNLOAD FUNCTIONALITY //
    const handleDownload = () => {

        let sliceSize = 1024;
        let byteCharecters = atob(EXCEL_FILE_BASE64);
        let bytesLength = byteCharecters.length;
        let slicesCount = Math.ceil(bytesLength / sliceSize);
        let byteArrays = new Array(slicesCount);

        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            let begin = sliceIndex * sliceSize;
            let end = Math.min(begin + sliceSize, bytesLength);
            let bytes = new Array(end - begin);
            for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharecters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        let blob = new Blob(byteArrays, { type: "application/vnd.ms-excel" });
        FileSaver.saveAs(new Blob([blob], {}), "ICR_Template.xlsx");

    };

    //SO popup//
    const [Advancedshow, SetAdvancedshowShow] = useState(false);

    const handleAdvancedshowClose = () => { SetAdvancedshowShow(false) };
    const handleAdvancedshowShow = () => { SetAdvancedshowShow(true) };



    const ToggleAdvancedSO = () => {

        SetAdvancedshowShow(true);

    }


    //SO Modal//
    const ModalAdvancedcontent = () => {

        return (

            <div>
                <Modal show={Advancedshow} onHide={handleAdvancedshowClose} size="lg"  >
                    <Modal.Header closeButton>
                        <Modal.Title>


                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ height: "auto" }}>
                        <div className='row'>
                            <b style={{ textAlign: "center" }}>
                                Advanced ICR Filters are handled by the MBS CSR team; fill out the form below with notes for optional fields and advanced criteria and a CSR will handle your request:
                            </b>

                        </div>
                        <div className='row' style={{ marginTop: "1.5vw" }}>
                            <div className='col-md-3'>
                                <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Trade Segment:</Form.Label>

                            </div>
                            <div className='col-md-5'>
                                <Form.Control type="text" id="Trade_Segment" Value={tradesegment} />

                            </div>
                        </div>
                        <div className='row' style={{ marginTop: "1vw" }}>
                            <div className='col-md-3'>
                                <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Customer (Exclude):</Form.Label>

                            </div>
                            <div className='col-md-5'>
                                <Form.Control type="text" id="Customer_Exclude" Value={customerExclude} />

                            </div>
                        </div>
                        <div className='row' style={{ marginTop: "1vw" }}>
                            <div className='col-md-3'>
                                <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Customer (Include):</Form.Label>

                            </div>
                            <div className='col-md-5'>
                                <Form.Control type="text" id="Customer_Include" Value={customerInclude} />

                            </div>
                        </div>
                        <div className='row' style={{ marginTop: "1vw" }}>
                            <div className='col-md-3'>
                                <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Hierarchy:</Form.Label>

                            </div>
                            <div className='col-md-5'>
                                <Form.Control type="text" id="Hierarchy" Value={hierarchy} />

                            </div>
                        </div>
                        <div className='row' style={{ marginTop: "1vw" }}>
                            <div className='col-md-3'>
                                <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Sold-to-Party:</Form.Label>

                            </div>
                            <div className='col-md-5'>
                                <Form.Control type="text" id="Sold_To_Party" Value={soldtoparty} />

                            </div>
                        </div>
                        <div className='row' style={{ marginTop: "1vw" }}>
                            <div className='col-md-3'>
                                <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Order Reporting:</Form.Label>

                            </div>
                            <div className='col-md-5'>
                                <Form.Control type="text" id="Order_Reporting" Value={orderReporting} />

                            </div>
                        </div>
                        <div className='row' style={{ marginTop: "2vw" }}>
                            <div className='col-md-3'>
                                <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Comments:</Form.Label>

                            </div>
                            <div className='col-md-5'>

                                <textarea style={{ height: "8rem", width: "19rem" }} maxlength="5000" className="form-control" id="CommentText">{commenttext}</textarea>

                            </div>
                        </div>

                        <div className='row' style={{ marginTop: "2vw" }}>

                            <div className='col-md-12'>

                                <span className="help-block text-success" id="display_Valid_File" style={{ float: 'left', marginLeft: '13rem' }} >
                                    {validfileshow}
                                </span>

                                <span className="help-block text-success" id="Valid_File" style={{ float: 'left', display: 'None', marginLeft: '12rem' }} >

                                </span>

                                <span className="help-block text-danger" id="Invalid_File" style={{ float: 'left', display: 'None', marginLeft: '12rem' }} >

                                </span>
                            </div>
                            <div className='col-md-6 mt-3 '>
                                <input type='button' className='btn-outline-pepsi' value='Download Template&#13;&#10;(For CSR Handling) ' style={{ float: 'left' }} id="" onClick={handleDownload} />
                                <input type='file' name='fileSelector' className=' d-none' onChange={handleFileAdvanced} />
                                <input type='button' className='btn-outline-pepsi' value='Add Attachment&#13;&#10;(.xlsx) ' style={{ marginLeft: '1.4rem', float: 'left' }} onClick={handleUpload} />
                            </div>

                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-light" id="Advanced_modal_Submit" style={{ color: "White", backgroundColor: "#009639" }} onClick={ModalAdvancedSubmit}>Save</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }

    //SO Modal Save//
    const ModalAdvancedSubmit = () => {


        let Trade_Segment = JQuery('#Trade_Segment').val();
        let Customer_Exclude = JQuery('#Customer_Exclude').val();
        let Customer_Include = JQuery('#Customer_Include').val();
        let Hierarchy = JQuery('#Hierarchy').val();
        let Sold_To_Party = JQuery('#Sold_To_Party').val();
        let Order_Reporting = JQuery('#Order_Reporting').val();
        let CommentText = JQuery('#CommentText').val();




        SetTradeSegment(Trade_Segment);
        SetCustomerExclude(Customer_Exclude);
        SetCustomerInclude(Customer_Include);
        SetHierarchy(Hierarchy);
        SetSoldToParty(Sold_To_Party);
        SetOrderReporting(Order_Reporting);
        setCommentText(CommentText);

        let fileUploded = 0;

        let filetypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
        if (selectedFile && filetypes.includes(selectedFile.type)) {
            setValidfileshow(selectedFile.name);
            File_Type = "." + selectedFile.name.split(".")[1];

            SetFileType(File_Type);
            SetEncodedFile(uploadFileEncode);
            fileUploded = 1;

        }
        else {
            setValidfileshow('');
            SetFileType('');
            SetEncodedFile('');
        }

        if (Trade_Segment !== "" || Customer_Exclude !== "" || Customer_Include !== "" || Hierarchy !== ""
            || Sold_To_Party !== "" || Order_Reporting !== "" || CommentText !== "" || fileUploded === 1) {
            SetICR_AdvancedValidation(true);
        }
        else {
            SetICR_AdvancedValidation(false);
        }
        handleAdvancedshowClose();


    }


    //STO popup//
    const [AdvancedSTOshow, SetAdvancedSTOshowShow] = useState(false);

    const handleAdvancedSTOshowClose = () => { SetAdvancedSTOshowShow(false) };
    const handleAdvancedSTOshowShow = () => { SetAdvancedSTOshowShow(true) };

    const ToggleAdvancedSTO = () => {

        SetAdvancedSTOshowShow(true);

    }

    //STO Modal//
    const ModalAdvancedSTOcontent = () => {

        return (

            <div>
                <Modal show={AdvancedSTOshow} onHide={handleAdvancedSTOshowClose} size="lg"  >
                    <Modal.Header closeButton>
                        <Modal.Title>


                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ height: "auto" }}>
                        <div className='row'>
                            <b style={{ textAlign: "center" }}>
                                Advanced ICR Filters are handled by the MBS CSR team; fill out the form below with notes for optional fields and advanced criteria and a CSR will handle your request:
                            </b>
                        </div>
                        <div className='row' style={{ marginTop: "1.5vw" }}>
                            <div className='col-md-3'>
                                <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Dest Location(Include):</Form.Label>

                            </div>
                            <div className='col-md-5'>
                                <Form.Control type="text" id="Dest_Location_Include" Value={destLocationInclude} />

                            </div>
                        </div>
                        <div className='row' style={{ marginTop: "1vw" }}>
                            <div className='col-md-3'>
                                <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Dest Location(Exclude):</Form.Label>

                            </div>
                            <div className='col-md-5'>
                                <Form.Control type="text" id="Dest_Location_Exclude" Value={destLocationExclude} />

                            </div>
                        </div>
                        <div className='row' style={{ marginTop: "1vw" }}>
                            <div className='col-md-3'>
                                <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Purchase Group:</Form.Label>

                            </div>
                            <div className='col-md-5'>
                                <Form.Control type="text" id="Purchase_Group" Value={PurchaseGroup} />

                            </div>
                        </div>
                        <div className='row' style={{ marginTop: "1vw" }}>
                            <div className='col-md-3'>
                                <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>STO #:</Form.Label>

                            </div>
                            <div className='col-md-5'>
                                <Form.Control type="text" id="STOnumber" Value={STOnumber} />

                            </div>
                        </div>
                        <div className='row' style={{ marginTop: "1vw" }}>
                            <div className='col-md-3'>
                                <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>STO Type:</Form.Label>

                            </div>
                            <div className='col-md-5'>
                                <Form.Control type="text" id="STO_Type" Value={STOType} />

                            </div>
                        </div>
                        <div className='row' style={{ marginTop: "2vw" }}>
                            <div className='col-md-3'>
                                <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Comments:</Form.Label>

                            </div>
                            <div className='col-md-5'>

                                <textarea style={{ height: "8rem", width: "19rem" }} className="form-control" id="CommentTextSTO" maxlength="5000">{commenttextSTO}</textarea>

                            </div>
                        </div>

                        <div className='row' style={{ marginTop: "2vw" }}>

                            <div className='col-md-12'>

                                <span className="help-block text-success" id="display_Valid_File" style={{ float: 'left', marginLeft: '13rem' }} >
                                    {validfileSTOshow}
                                </span>

                                <span className="help-block text-success" id="Valid_File" style={{ float: 'left', display: 'None', marginLeft: '12rem' }} >

                                </span>

                                <span className="help-block text-danger" id="Invalid_File" style={{ float: 'left', display: 'None', marginLeft: '12rem' }} >

                                </span>
                            </div>
                            <div className='col-md-6 mt-3 '>
                                <input type='button' className='btn-outline-pepsi' value='Download Template&#13;&#10;(For CSR Handling) ' style={{ float: 'left' }} id="" onClick={handleDownload} />
                                <input type='file' name='fileSelector' className=' d-none' onChange={handleFileAdvanced} />
                                <input type='button' className='btn-outline-pepsi' value='Add Attachment&#13;&#10;(.xlsx) ' style={{ marginLeft: '1.4rem', float: 'left' }} onClick={handleUpload} />
                            </div>

                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-light" id="Advanced_STO_modal_Submit" style={{ color: "White", backgroundColor: "#009639" }} onClick={ModalAdvancedSTOSubmit}>Save</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }

    //STO Modal Save//
    const ModalAdvancedSTOSubmit = () => {


        let Dest_Location_Exclude = JQuery('#Dest_Location_Exclude').val();
        let Dest_Location_Include = JQuery('#Dest_Location_Include').val();
        let Purchase_Group = JQuery('#Purchase_Group').val();
        let STOnumber = JQuery('#STOnumber').val();
        let STO_Type = JQuery('#STO_Type').val();
        let CommentTextSTO = JQuery('#CommentTextSTO').val();




        SetDestLocationInclude(Dest_Location_Include);
        SetDestLocationExclude(Dest_Location_Exclude);
        SetPurchaseGroup(Purchase_Group);
        SetSTOnumber(STOnumber);
        SetSTOType(STO_Type);
        setCommentTextSTO(CommentTextSTO);

        let fileUploded = 0;

        let filetypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
        if (selectedFile && filetypes.includes(selectedFile.type)) {
            setValidfileSTOshow(selectedFile.name);
            File_Type = "." + selectedFile.name.split(".")[1];


            SetFileType(File_Type);
            SetEncodedFile(uploadFileEncode);
            fileUploded = 1;
        }
        else {
            setValidfileSTOshow('');
            SetFileType('');
            SetEncodedFile('');
        }

        if (Dest_Location_Include !== "" || Dest_Location_Exclude !== "" || Purchase_Group !== ""
            || STOnumber !== "" || STO_Type !== "" || CommentTextSTO !== "" || fileUploded === 1) {
            SetICR_AdvancedValidation(true);
        }
        else {
            SetICR_AdvancedValidation(false);
        }
        handleAdvancedSTOshowClose();


    }




    let temp_emailid = sessionStorage.getItem("emailid");
    let temp_gpid = sessionStorage.getItem("gpid");
    let temp_role = sessionStorage.getItem("role");
    let temp_siteaccess = [];
    if (sessionStorage.getItem("siteaccess") && sessionStorage.getItem("siteaccess").length > 0) {
        temp_siteaccess = sessionStorage.getItem("siteaccess")
    }


    let reasonCodeResponse = [], reasonCodeOptions = '';

    // get Reason Code//

    const getReasonCode = () => {

        const token = getToken();
        const headers = {
            "accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }

        JQuery.ajax({
            url: hostUrl + "/ordermodification/reason-code",
            type: 'POST',
            dataType: 'json',
            CORS: true,
            contentType: 'application/json',
            secure: true,
            headers: headers,
            crossDomain: true,
            success: function (response) {
                reasonCodeOptions = '<option value="" name ="" selected>--Select--</option><input type="hidden" value="">\n';
                reasonCodeResponse = response["response"];
                for (let i = 0; i < reasonCodeResponse.length; i++) {
                    reasonCodeOptions += '<option value="' + reasonCodeResponse[i]["description"] + '" name="' + reasonCodeResponse[i]["description"] + '">' + reasonCodeResponse[i]["description"] + '</option>\n';
                }

                JQuery("#ReasonCodeSublist").html(reasonCodeOptions);
                JQuery("#ReasonCodeAlloactionlist").html(reasonCodeOptions);
                JQuery("#ReasonCodeAddlist").html(reasonCodeOptions);
                JQuery("#ReasonCodeCutlist").html(reasonCodeOptions);



            },
            error: function (jqHXR, textStatus, errorThrown) {


            }

        });


    };


    // Magic Number Function//

    JQuery("body").on('mouseup', function (event) {


        let TableMaterialCodeFromSublist = JQuery("#TableMaterialCodeFromSublist").val();
        if (TableMaterialCodeFromSublist?.length === 8) {
            let appliedMagicFormula = (parseInt(TableMaterialCodeFromSublist) + Math.pow(10, 9)) * Math.pow(10, 3);
            JQuery("#TableMaterialCodeFromSublist").val(appliedMagicFormula);

            checkValidationMaterialMagicNumber("TableMaterialCodeFromSublist", appliedMagicFormula.toString());
            ValidMaterialMagicNumber("TableMaterialCodeFromSublist", appliedMagicFormula.toString());
        }

        let TableMaterialCodeToSublist = JQuery("#TableMaterialCodeToSublist").val();
        if (TableMaterialCodeToSublist?.length === 8) {
            let appliedMagicFormula = (parseInt(TableMaterialCodeToSublist) + Math.pow(10, 9)) * Math.pow(10, 3);
            JQuery("#TableMaterialCodeToSublist").val(appliedMagicFormula);

            checkValidationMaterialMagicNumber("TableMaterialCodeToSublist", appliedMagicFormula.toString());
            ValidMaterialMagicNumber("TableMaterialCodeToSublist", appliedMagicFormula.toString());
        }

        let TableMaterialCodeAllocationlist = JQuery("#TableMaterialCodeAllocationlist").val();
        if (TableMaterialCodeAllocationlist?.length === 8) {
            let appliedMagicFormula = (parseInt(TableMaterialCodeAllocationlist) + Math.pow(10, 9)) * Math.pow(10, 3);
            JQuery("#TableMaterialCodeAllocationlist").val(appliedMagicFormula);

            checkValidationMaterialMagicNumber("TableMaterialCodeAllocationlist", appliedMagicFormula.toString());
            ValidMaterialMagicNumber("TableMaterialCodeAllocationlist", appliedMagicFormula.toString());
        }

        let TableMaterialCodeAddlist = JQuery("#TableMaterialCodeAddlist").val();
        if (TableMaterialCodeAddlist?.length === 8) {
            let appliedMagicFormula = (parseInt(TableMaterialCodeAddlist) + Math.pow(10, 9)) * Math.pow(10, 3);
            JQuery("#TableMaterialCodeAddlist").val(appliedMagicFormula);

            checkValidationMaterialMagicNumber("TableMaterialCodeAddlist", appliedMagicFormula.toString());
            ValidMaterialMagicNumber("TableMaterialCodeAddlist", appliedMagicFormula.toString());
        }

        let TableMaterialCodeCutlist = JQuery("#TableMaterialCodeCutlist").val();
        if (TableMaterialCodeCutlist?.length === 8) {
            let appliedMagicFormula = (parseInt(TableMaterialCodeCutlist) + Math.pow(10, 9)) * Math.pow(10, 3);
            JQuery("#TableMaterialCodeCutlist").val(appliedMagicFormula);


            checkValidationMaterialMagicNumber("TableMaterialCodeCutlist", appliedMagicFormula.toString());
            ValidMaterialMagicNumber("TableMaterialCodeCutlist", appliedMagicFormula.toString());


        }




    });

    const [validmaterialCode, setvalidmaterialCode] = useState(false);
    const [EditvalidmaterialCode, setEditvalidmaterialCode] = useState(false);

    // Material Description function//
    const setMaterialDecription = (event) => {


        let materialcode = event.target.value;

        if (materialcode.length === 13) {

            checkValidationMaterial(event);
            ValidMaterial(event);

        }

        else {

            if (event.target.id === "TableMaterialCodeFromSublist") {

                ClearSublistErrorLebels();
                JQuery("#TableMaterialCodeFromSublist").val(materialcode);

                JQuery("#Invalid_Status_MaterialCodeFromSub").hide();
                JQuery("#Inactive_Status_MaterialCodeFromSub").hide();

                JQuery("#TableMaterialCodeFromSublist").removeAttr("style");
                JQuery("#TableMaterialCodeFromSublist").css({ "width": "9rem" });



            }

            if (event.target.id === "TableMaterialCodeToSublist") {

                ClearSublistErrorLebels();

                JQuery("#TableMaterialCodeToSublist").val(materialcode);
                JQuery("#Invalid_Status_MaterialCodeToSub").hide();
                JQuery("#Inactive_Status_MaterialCodeToSub").hide();

                JQuery("#TableMaterialCodeToSublist").removeAttr("style");
                JQuery("#TableMaterialCodeToSublist").css({ "width": "9rem" });


            }

            if (event.target.id === "TableMaterialCodeAllocationlist") {

                ClearAllocationlistErrorLebels();

                JQuery("#TableMaterialCodeAllocationlist").val(materialcode);
                JQuery("#Invalid_Status_MaterialCodeAllocation").hide();
                JQuery("#Inactive_Status_MaterialCodeAllocation").hide();

                JQuery("#TableMaterialCodeAllocationlist").removeAttr("style");
                JQuery("#TableMaterialCodeAllocationlist").css({ "width": "10rem" });

            }

            if (event.target.id === "TableMaterialCodeAddlist") {

                ClearAddlistErrorLebels();

                JQuery("#TableMaterialCodeAddlist").val(materialcode);
                JQuery("#Invalid_Status_MaterialCodeAdd").hide();
                JQuery("#Inactive_Status_MaterialCodeAdd").hide();
                JQuery("#TableMaterialCodeAddlist").removeAttr("style");
                JQuery("#TableMaterialCodeAddlist").css({ "width": "10rem" });


            }

            if (event.target.id === "TableMaterialCodeCutlist") {

                ClearCutlistErrorLebels();

                JQuery("#TableMaterialCodeCutlist").val(materialcode);
                JQuery("#Invalid_Status_MaterialCodeCut").hide();
                JQuery("#Inactive_Status_MaterialCodeCut").hide();
                JQuery("#TableMaterialCodeCutlist").removeAttr("style");
                JQuery("#TableMaterialCodeCutlist").css({ "width": "10rem" });

            }

        }


    }
    // Check Validation//
    const checkValidationMaterial = (event) => {



        const token = getToken();
        const headers = {
            "accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }


        let materialcode = event.target.value;

        JQuery.ajax({
            url: hostUrl + "/orderModification/materialValidation",
            type: 'POST',
            dataType: 'json',
            data: JSON.stringify({
                "materialNumber": materialcode
            }),
            CORS: true,
            contentType: 'application/json',
            secure: true,
            headers: headers,
            crossDomain: true,
            success: (res) => {

                let MaterialCodeMessage = res.errormessage;

                if (MaterialCodeMessage === "Material Exists") {
                    setvalidmaterialCode(true);

                    if (event.target.id === "TableMaterialCodeFromSublist") {

                        JQuery("#Invalid_Status_MaterialCodeFromSub").hide();
                        JQuery("#Inactive_Status_MaterialCodeFromSub").hide();
                        JQuery("#TableMaterialCodeFromSublist").removeAttr("style");
                        JQuery("#TableMaterialCodeFromSublist").css({ "width": "9rem" });


                    }

                    if (event.target.id === "TableMaterialCodeToSublist") {

                        JQuery("#Invalid_Status_MaterialCodeToSub").hide();
                        JQuery("#Inactive_Status_MaterialCodeToSub").hide();

                        JQuery("#TableMaterialCodeToSublist").removeAttr("style");
                        JQuery("#TableMaterialCodeToSublist").css({ "width": "9rem" });

                    }

                    if (event.target.id === "TableMaterialCodeAllocationlist") {

                        JQuery("#Invalid_Status_MaterialCodeAllocation").hide();
                        JQuery("#Inactive_Status_MaterialCodeAllocation").hide();
                        JQuery("#TableMaterialCodeAllocationlist").removeAttr("style");
                        JQuery("#TableMaterialCodeAllocationlist").css({ "width": "10rem" });


                    }

                    if (event.target.id === "TableMaterialCodeAddlist") {

                        JQuery("#Invalid_Status_MaterialCodeAdd").hide();
                        JQuery("#Inactive_Status_MaterialCodeAdd").hide();
                        JQuery("#TableMaterialCodeAddlist").removeAttr("style");
                        JQuery("#TableMaterialCodeAddlist").css({ "width": "10rem" });


                    }

                    if (event.target.id === "TableMaterialCodeCutlist") {

                        JQuery("#Invalid_Status_MaterialCodeCut").hide();
                        JQuery("#Inactive_Status_MaterialCodeCut").hide();
                        JQuery("#TableMaterialCodeCutlist").removeAttr("style");
                        JQuery("#TableMaterialCodeCutlist").css({ "width": "10rem" });


                    }


                }
                else if (MaterialCodeMessage === "Material is not active") {
                    setvalidmaterialCode(false);


                    if (event.target.id === "TableMaterialCodeFromSublist") {

                        ClearSublistErrorLebels();
                        JQuery("#TableMaterialCodeFromSublist").val(materialcode);

                        JQuery("#Invalid_Status_MaterialCodeFromSub").hide();
                        JQuery("#Inactive_Status_MaterialCodeFromSub").show();

                        JQuery("#TableMaterialCodeFromSublist").css("border", "1px solid red");

                        JQuery("#TableMaterialDescriptionFromSublist").val(res.response.materialDesciption);
                        JQuery("#TableUnitOfMeasureFrom").val(res.response.umo);

                    }

                    if (event.target.id === "TableMaterialCodeToSublist") {

                        ClearSublistErrorLebels();

                        JQuery("#TableMaterialCodeToSublist").val(materialcode);
                        JQuery("#Invalid_Status_MaterialCodeToSub").hide();
                        JQuery("#Inactive_Status_MaterialCodeToSub").show();

                        JQuery("#TableMaterialCodeToSublist").css("border", "1px solid red");

                        JQuery("#TableMaterialDescriptionToSublist").val(res.response.materialDesciption);
                        JQuery("#TableUnitOfMeasureTo").val(res.response.umo);

                    }

                    if (event.target.id === "TableMaterialCodeAllocationlist") {

                        ClearAllocationlistErrorLebels();

                        JQuery("#TableMaterialCodeAllocationlist").val(materialcode);
                        JQuery("#Invalid_Status_MaterialCodeAllocation").hide();
                        JQuery("#Inactive_Status_MaterialCodeAllocation").show();

                        JQuery("#TableMaterialCodeAllocationlist").css("border", "1px solid red");

                        JQuery("#TableMaterialDescriptionAllocationlist").val(res.response.materialDesciption);
                        JQuery("#TableUnitOfMeasure").val(res.response.umo);
                    }

                    if (event.target.id === "TableMaterialCodeAddlist") {

                        ClearAddlistErrorLebels();

                        JQuery("#TableMaterialCodeAddlist").val(materialcode);
                        JQuery("#Invalid_Status_MaterialCodeAdd").hide();
                        JQuery("#Inactive_Status_MaterialCodeAdd").show();
                        JQuery("#TableMaterialCodeAddlist").css("border", "1px solid red");

                        JQuery("#TableMaterialDescriptionAddlist").val(res.response.materialDesciption);
                        JQuery("#TableUnitOfMeasureAdd").val(res.response.umo);
                    }

                    if (event.target.id === "TableMaterialCodeCutlist") {

                        ClearCutlistErrorLebels();

                        JQuery("#TableMaterialCodeCutlist").val(materialcode);
                        JQuery("#Invalid_Status_MaterialCodeCut").hide();
                        JQuery("#Inactive_Status_MaterialCodeCut").show();
                        JQuery("#TableMaterialCodeCutlist").css("border", "1px solid red");

                        JQuery("#TableMaterialDescriptionCutlist").val(res.response.materialDesciption);
                        JQuery("#TableUnitOfMeasureCut").val(res.response.umo);
                    }


                }
                else {

                    setvalidmaterialCode(false);

                    if (event.target.id === "TableMaterialCodeFromSublist") {

                        ClearSublistErrorLebels();
                        JQuery("#TableMaterialCodeFromSublist").val(materialcode);

                        JQuery("#Invalid_Status_MaterialCodeFromSub").show();
                        JQuery("#Inactive_Status_MaterialCodeFromSub").hide();

                        JQuery("#TableMaterialCodeFromSublist").css("border", "1px solid red");

                        JQuery("#TableMaterialDescriptionFromSublist").val(res.response.materialDesciption);
                        JQuery("#TableUnitOfMeasureFrom").val(res.response.umo);

                    }

                    if (event.target.id === "TableMaterialCodeToSublist") {

                        ClearSublistErrorLebels();

                        JQuery("#TableMaterialCodeToSublist").val(materialcode);
                        JQuery("#Invalid_Status_MaterialCodeToSub").show();
                        JQuery("#Inactive_Status_MaterialCodeToSub").hide();

                        JQuery("#TableMaterialCodeToSublist").css("border", "1px solid red");

                        JQuery("#TableMaterialDescriptionToSublist").val(res.response.materialDesciption);
                        JQuery("#TableUnitOfMeasureTo").val(res.response.umo);

                    }

                    if (event.target.id === "TableMaterialCodeAllocationlist") {

                        ClearAllocationlistErrorLebels();

                        JQuery("#TableMaterialCodeAllocationlist").val(materialcode);
                        JQuery("#Invalid_Status_MaterialCodeAllocation").show();
                        JQuery("#Inactive_Status_MaterialCodeAllocation").hide();
                        JQuery("#TableMaterialCodeAllocationlist").css("border", "1px solid red");

                        JQuery("#TableMaterialDescriptionAllocationlist").val(res.response.materialDesciption);
                        JQuery("#TableUnitOfMeasure").val(res.response.umo);
                    }

                    if (event.target.id === "TableMaterialCodeAddlist") {

                        ClearAddlistErrorLebels();

                        JQuery("#TableMaterialCodeAddlist").val(materialcode);
                        JQuery("#Invalid_Status_MaterialCodeAdd").show();
                        JQuery("#Inactive_Status_MaterialCodeAdd").hide();

                        JQuery("#TableMaterialCodeAddlist").css("border", "1px solid red");

                        JQuery("#TableMaterialDescriptionAddlist").val(res.response.materialDesciption);
                        JQuery("#TableUnitOfMeasureAdd").val(res.response.umo);
                    }

                    if (event.target.id === "TableMaterialCodeCutlist") {


                        ClearCutlistErrorLebels();

                        JQuery("#TableMaterialCodeCutlist").val(materialcode);
                        JQuery("#Invalid_Status_MaterialCodeCut").show();
                        JQuery("#Inactive_Status_MaterialCodeCut").hide();

                        JQuery("#TableMaterialCodeCutlist").css("border", "1px solid red");

                        JQuery("#TableMaterialDescriptionCutlist").val(res.response.materialDesciption);
                        JQuery("#TableUnitOfMeasureCut").val(res.response.umo);
                    }


                }


            },
            error: function (jqHXR, textStatus, errorThrown) {


            }

        });

    }

    // Valid material function//
    const ValidMaterial = (event) => {

        const token = getToken();
        const headers = {
            "accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }

        let materialcode = event.target.value;;


        JQuery.ajax({
            url: hostUrl + "/ordermodification/materialDescription",
            type: 'POST',
            dataType: 'json',
            data: JSON.stringify({
                "materialNumber": materialcode
            }),
            CORS: true,
            contentType: 'application/json',
            secure: true,
            headers: headers,
            crossDomain: true,
            success: (res) => {


                ResponseSetMaterial(res.response, event.target.id, materialcode);

            },
            error: function (jqHXR, textStatus, errorThrown) {


            }

        });

    }

    const ResponseSetMaterial = (Response, ID, materialcode) => {

        if (ID === "TableMaterialCodeFromSublist") {

            JQuery("#TableMaterialCodeFromSublist").val(materialcode);
            JQuery("#TableMaterialDescriptionFromSublist").val(Response.materialDesciption);
            JQuery("#TableUnitOfMeasureFrom").val(Response.umo);

        }

        if (ID === "TableMaterialCodeToSublist") {

            JQuery("#TableMaterialCodeToSublist").val(materialcode);
            JQuery("#TableMaterialDescriptionToSublist").val(Response.materialDesciption);
            JQuery("#TableUnitOfMeasureTo").val(Response.umo);

        }

        if (ID === "TableMaterialCodeAllocationlist") {

            JQuery("#TableMaterialCodeAllocationlist").val(materialcode);
            JQuery("#TableMaterialDescriptionAllocationlist").val(Response.materialDesciption);
            JQuery("#TableUnitOfMeasure").val(Response.umo);

        }

        if (ID === "TableMaterialCodeAddlist") {

            JQuery("#TableMaterialCodeAddlist").val(materialcode);
            JQuery("#TableMaterialDescriptionAddlist").val(Response.materialDesciption);
            JQuery("#TableUnitOfMeasureAdd").val(Response.umo);

        }

        if (ID === "TableMaterialCodeCutlist") {

            JQuery("#TableMaterialCodeCutlist").val(materialcode);

            JQuery("#TableMaterialDescriptionCutlist").val(Response.materialDesciption);
            JQuery("#TableUnitOfMeasureCut").val(Response.umo);

        }



    }


    // Check Validation for Magic Number//
    const checkValidationMaterialMagicNumber = (id, val) => {



        const token = getToken();
        const headers = {
            "accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }


        let materialcode = val;

        JQuery.ajax({
            url: hostUrl + "/orderModification/materialValidation",
            type: 'POST',
            dataType: 'json',
            data: JSON.stringify({
                "materialNumber": materialcode
            }),
            CORS: true,
            contentType: 'application/json',
            secure: true,
            headers: headers,
            crossDomain: true,
            success: (res) => {


                let MaterialCodeMessage = res.errormessage;

                if (MaterialCodeMessage === "Material Exists") {
                    setvalidmaterialCode(true);

                    if (id === "TableMaterialCodeFromSublist") {

                        JQuery("#Invalid_Status_MaterialCodeFromSub").hide();
                        JQuery("#Inactive_Status_MaterialCodeFromSub").hide();
                        JQuery("#TableMaterialCodeFromSublist").removeAttr("style");
                        JQuery("#TableMaterialCodeFromSublist").css({ "width": "9rem" });


                    }

                    if (id === "TableMaterialCodeToSublist") {

                        JQuery("#Invalid_Status_MaterialCodeToSub").hide();
                        JQuery("#Inactive_Status_MaterialCodeToSub").hide();

                        JQuery("#TableMaterialCodeToSublist").removeAttr("style");
                        JQuery("#TableMaterialCodeToSublist").css({ "width": "9rem" });

                    }

                    if (id === "TableMaterialCodeAllocationlist") {

                        JQuery("#Invalid_Status_MaterialCodeAllocation").hide();
                        JQuery("#Inactive_Status_MaterialCodeAllocation").hide();
                        JQuery("#TableMaterialCodeAllocationlist").removeAttr("style");
                        JQuery("#TableMaterialCodeAllocationlist").css({ "width": "10rem" });


                    }

                    if (id === "TableMaterialCodeAddlist") {

                        JQuery("#Invalid_Status_MaterialCodeAdd").hide();
                        JQuery("#Inactive_Status_MaterialCodeAdd").hide();
                        JQuery("#TableMaterialCodeAddlist").removeAttr("style");
                        JQuery("#TableMaterialCodeAddlist").css({ "width": "10rem" });


                    }

                    if (id === "TableMaterialCodeCutlist") {

                        JQuery("#Invalid_Status_MaterialCodeCut").hide();
                        JQuery("#Inactive_Status_MaterialCodeCut").hide();
                        JQuery("#TableMaterialCodeCutlist").removeAttr("style");
                        JQuery("#TableMaterialCodeCutlist").css({ "width": "10rem" });


                    }


                }
                else if (MaterialCodeMessage === "Material is not active") {
                    setvalidmaterialCode(false);


                    if (id === "TableMaterialCodeFromSublist") {

                        ClearSublistErrorLebels();
                        JQuery("#TableMaterialCodeFromSublist").val(materialcode);

                        JQuery("#Invalid_Status_MaterialCodeFromSub").hide();
                        JQuery("#Inactive_Status_MaterialCodeFromSub").show();

                        JQuery("#TableMaterialCodeFromSublist").css("border", "1px solid red");

                        JQuery("#TableMaterialDescriptionFromSublist").val(res.response.materialDesciption);
                        JQuery("#TableUnitOfMeasureFrom").val(res.response.umo);

                    }

                    if (id === "TableMaterialCodeToSublist") {

                        ClearSublistErrorLebels();

                        JQuery("#TableMaterialCodeToSublist").val(materialcode);
                        JQuery("#Invalid_Status_MaterialCodeToSub").hide();
                        JQuery("#Inactive_Status_MaterialCodeToSub").show();

                        JQuery("#TableMaterialCodeToSublist").css("border", "1px solid red");

                        JQuery("#TableMaterialDescriptionToSublist").val(res.response.materialDesciption);
                        JQuery("#TableUnitOfMeasureTo").val(res.response.umo);

                    }

                    if (id === "TableMaterialCodeAllocationlist") {

                        ClearAllocationlistErrorLebels();

                        JQuery("#TableMaterialCodeAllocationlist").val(materialcode);
                        JQuery("#Invalid_Status_MaterialCodeAllocation").hide();
                        JQuery("#Inactive_Status_MaterialCodeAllocation").show();

                        JQuery("#TableMaterialCodeAllocationlist").css("border", "1px solid red");

                        JQuery("#TableMaterialDescriptionAllocationlist").val(res.response.materialDesciption);
                        JQuery("#TableUnitOfMeasure").val(res.response.umo);
                    }

                    if (id === "TableMaterialCodeAddlist") {

                        ClearAddlistErrorLebels();

                        JQuery("#TableMaterialCodeAddlist").val(materialcode);
                        JQuery("#Invalid_Status_MaterialCodeAdd").hide();
                        JQuery("#Inactive_Status_MaterialCodeAdd").show();
                        JQuery("#TableMaterialCodeAddlist").css("border", "1px solid red");

                        JQuery("#TableMaterialDescriptionAddlist").val(res.response.materialDesciption);
                        JQuery("#TableUnitOfMeasureAdd").val(res.response.umo);
                    }

                    if (id === "TableMaterialCodeCutlist") {

                        ClearCutlistErrorLebels();

                        JQuery("#TableMaterialCodeCutlist").val(materialcode);
                        JQuery("#Invalid_Status_MaterialCodeCut").hide();
                        JQuery("#Inactive_Status_MaterialCodeCut").show();
                        JQuery("#TableMaterialCodeCutlist").css("border", "1px solid red");

                        JQuery("#TableMaterialDescriptionCutlist").val(res.response.materialDesciption);
                        JQuery("#TableUnitOfMeasureCut").val(res.response.umo);
                    }


                }
                else {

                    setvalidmaterialCode(false);

                    if (id === "TableMaterialCodeFromSublist") {

                        ClearSublistErrorLebels();
                        JQuery("#TableMaterialCodeFromSublist").val(materialcode);

                        JQuery("#Invalid_Status_MaterialCodeFromSub").show();
                        JQuery("#Inactive_Status_MaterialCodeFromSub").hide();

                        JQuery("#TableMaterialCodeFromSublist").css("border", "1px solid red");

                        JQuery("#TableMaterialDescriptionFromSublist").val(res.response.materialDesciption);
                        JQuery("#TableUnitOfMeasureFrom").val(res.response.umo);

                    }

                    if (id === "TableMaterialCodeToSublist") {

                        ClearSublistErrorLebels();

                        JQuery("#TableMaterialCodeToSublist").val(materialcode);
                        JQuery("#Invalid_Status_MaterialCodeToSub").show();
                        JQuery("#Inactive_Status_MaterialCodeToSub").hide();

                        JQuery("#TableMaterialCodeToSublist").css("border", "1px solid red");

                        JQuery("#TableMaterialDescriptionToSublist").val(res.response.materialDesciption);
                        JQuery("#TableUnitOfMeasureTo").val(res.response.umo);

                    }

                    if (id === "TableMaterialCodeAllocationlist") {

                        ClearAllocationlistErrorLebels();

                        JQuery("#TableMaterialCodeAllocationlist").val(materialcode);
                        JQuery("#Invalid_Status_MaterialCodeAllocation").show();
                        JQuery("#Inactive_Status_MaterialCodeAllocation").hide();
                        JQuery("#TableMaterialCodeAllocationlist").css("border", "1px solid red");

                        JQuery("#TableMaterialDescriptionAllocationlist").val(res.response.materialDesciption);
                        JQuery("#TableUnitOfMeasure").val(res.response.umo);
                    }

                    if (id === "TableMaterialCodeAddlist") {

                        ClearAddlistErrorLebels();

                        JQuery("#TableMaterialCodeAddlist").val(materialcode);
                        JQuery("#Invalid_Status_MaterialCodeAdd").show();
                        JQuery("#Inactive_Status_MaterialCodeAdd").hide();

                        JQuery("#TableMaterialCodeAddlist").css("border", "1px solid red");

                        JQuery("#TableMaterialDescriptionAddlist").val(res.response.materialDesciption);
                        JQuery("#TableUnitOfMeasureAdd").val(res.response.umo);
                    }

                    if (id === "TableMaterialCodeCutlist") {


                        ClearCutlistErrorLebels();

                        JQuery("#TableMaterialCodeCutlist").val(materialcode);
                        JQuery("#Invalid_Status_MaterialCodeCut").show();
                        JQuery("#Inactive_Status_MaterialCodeCut").hide();

                        JQuery("#TableMaterialCodeCutlist").css("border", "1px solid red");

                        JQuery("#TableMaterialDescriptionCutlist").val(res.response.materialDesciption);
                        JQuery("#TableUnitOfMeasureCut").val(res.response.umo);
                    }


                }


            },
            error: function (jqHXR, textStatus, errorThrown) {


            }

        });

    }
    //  Valid Magic Number//
    const ValidMaterialMagicNumber = (id, val) => {

        const token = getToken();
        const headers = {
            "accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }


        let materialcode = val;


        JQuery.ajax({
            url: hostUrl + "/ordermodification/materialDescription",
            type: 'POST',
            dataType: 'json',
            data: JSON.stringify({
                "materialNumber": materialcode
            }),
            CORS: true,
            contentType: 'application/json',
            secure: true,
            headers: headers,
            crossDomain: true,
            success: (res) => {


                ResponseSetMaterial(res.response, id, materialcode);

            },
            error: function (jqHXR, textStatus, errorThrown) {


            }

        });

    }




    //Datepicker//
    const [startDate, setStartDate] = useState(new Date());
    const [Validfromdate, SetValidFromdate] = useState('');
    const [Validtodate, SetValidTodate] = useState('');
    const [Deliveryfromdate, SetDeliveryFromdate] = useState('');
    const [Deliverytodate, SetDeliveryTodate] = useState('');
    const [LoadDateFromDate, SetLoadDateFromDate] = useState('');
    const [LoadDateToDate, SetLoadDateToDate] = useState('');




    const [Divshowhide, SetDivShowhide] = useState('');

    const [targetOrderTypeValue, SetTargetOrderTypeValue] = useState('');

    // targer order type select function


    const handleAdvancedToggle = (event) => {

        JQuery("#Invalid_TargetOrderType").hide();
        JQuery("#TargetOrderType").removeAttr("style");

        JQuery("#Invalid_Advanced_TargetOrderType").hide();
        SetTargetOrderTypeValue(event.target.value);
    }


    const ToggleAdvanced = () => {

        JQuery("#Invalid_TargetOrderType").hide();
        if (targetOrderTypeValue === "SO") {

            ToggleAdvancedSO();
            JQuery("#Invalid_Advanced_TargetOrderType").hide();

        }
        else if (targetOrderTypeValue === "STO") {

            ToggleAdvancedSTO();
            JQuery("#Invalid_Advanced_TargetOrderType").hide();

        }
        else if (targetOrderTypeValue === "") {

            JQuery("#Invalid_Advanced_TargetOrderType").show();
        }

    }


    //**  Sub List Region **// 

    const ClearSublistErrorLebels = () => {

        JQuery("#Invalid_MaterialCodeFrom").hide();
        JQuery("#Invalid_MaterialCodeTo").hide();

        JQuery("#Invalid_MaterialDescriptionFrom").hide();
        JQuery("#TableMaterialDescriptionFromSublist").removeAttr("style");
        JQuery("#TableMaterialDescriptionFromSublist").css({ "width": "8rem" });

        JQuery("#Invalid_MaterialDescriptionTo").hide();
        JQuery("#TableMaterialDescriptionToSublist").removeAttr("style");
        JQuery("#TableMaterialDescriptionToSublist").css({ "width": "8rem" });


        JQuery("#Invalid_UnitOfMeasureFrom").hide();
        JQuery("#TableUnitOfMeasureFrom").removeAttr("style");
        JQuery("#TableUnitOfMeasureFrom").css({ "width": "7rem" });

        JQuery("#Invalid_UnitOfMeasureTo").hide();
        JQuery("#TableUnitOfMeasureTo").removeAttr("style");
        JQuery("#TableUnitOfMeasureTo").css({ "width": "7rem" });

        JQuery("#Invalid_ReasonCodeSublist").hide();
        JQuery("#ReasonCodeSublist").removeAttr("style");
        JQuery("#ReasonCodeSublist").css({ "width": "8rem" });

    }

    const [Sublistmaterial, SetSublistmaterial] = useState([]);

    //*add*//
    const [showAddSublist, setShowAddSublist] = useState(false);


    const [addSublistmaterialFormData, SetaddSublistmaterialFormData] = useState({

        MaterialCodeFrom: '',
        MaterialDescriptionFrom: '',
        UnitOfMeasureFrom: '',
        MaterialCodeTo: '',
        MaterialDescriptionTo: '',
        UnitOfMeasureTo: '',
        ReasonCode: '',
        ReasonCodeDescription: ''

    });

    const handleAddSublistmaterialFormChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.getAttribute('name');
        const fieldvalue = event.target.value;

        JQuery('input[idx= "restrictedDecimal"]').keyup(function (e) {
            if (/\D/g.test(this.value)) {
                this.value = this.value.replace(/\D/g, '');
            }
        });

        const newFormData = { ...addSublistmaterialFormData };
        newFormData[fieldName] = fieldvalue;

        SetaddSublistmaterialFormData(newFormData);

    }

    const handleAddSublistmaterialFormSubmit = (event) => {

        event.preventDefault();

        ClearSublistErrorLebels();

        const materialcode_regex = /^[0-9]{13}$/;
        const MaterialCodeFromSublist = JQuery("#TableMaterialCodeFromSublist").val();
        const MaterialCodeToSublist = JQuery("#TableMaterialCodeToSublist").val();

        const MaterialDescriptionFromSublist = JQuery("#TableMaterialDescriptionFromSublist").val();
        const MaterialDescriptionToSublist = JQuery("#TableMaterialDescriptionToSublist").val();

        const UnitOfMeasureFrom = JQuery("#TableUnitOfMeasureFrom").find(":selected").val();
        const UnitOfMeasureTo = JQuery("#TableUnitOfMeasureTo").find(":selected").val();

        const ReasonCodeSublist = JQuery("#ReasonCodeSublist").find(":selected").val();

        const ReasonCodeSublistText = JQuery("#ReasonCodeSublist").find(":selected").html();


        if (materialcode_regex.test(MaterialCodeFromSublist) && materialcode_regex.test(MaterialCodeToSublist)
            && MaterialDescriptionFromSublist !== "" && MaterialDescriptionToSublist !== ""
            && UnitOfMeasureFrom !== "" && UnitOfMeasureTo !== "" && ReasonCodeSublist !== "" && validmaterialCode === true) {

            const newObject = {

                id: nanoid(),
                MaterialCodeFrom: MaterialCodeFromSublist,
                MaterialDescriptionFrom: MaterialDescriptionFromSublist,
                UnitOfMeasureFrom: UnitOfMeasureFrom,

                MaterialCodeTo: MaterialCodeToSublist,
                MaterialDescriptionTo: MaterialDescriptionToSublist,
                UnitOfMeasureTo: UnitOfMeasureTo,

                ReasonCode: addSublistmaterialFormData.ReasonCode,
                ReasonCodeDescription: ReasonCodeSublistText
            }

            const newObjects = [...Sublistmaterial, newObject];

            let temp = [];
            for (let i = 0; i < newObjects.length; i++) {
                temp.push(newObjects[i]);

            }


            SetSublistmaterial(temp);

            JQuery('#AddItemsSublist').find('input').val('');


            JQuery("#Invalid_Status_MaterialCodeFromSub").hide();
            JQuery("#Invalid_Status_MaterialCodeToSub").hide();

            JQuery("#Inactive_Status_MaterialCodeFromSub").hide();
            JQuery("#Inactive_Status_MaterialCodeToSub").hide();

            JQuery("#TableMaterialCodeFromSublist").removeAttr("style");
            JQuery("#TableMaterialCodeFromSublist").css({ "width": "9rem" });

            JQuery("#TableMaterialCodeToSublist").removeAttr("style");
            JQuery("#TableMaterialCodeToSublist").css({ "width": "9rem" });

            ClearSublistErrorLebels();


        }
        else {

            if (!materialcode_regex.test(MaterialCodeFromSublist)) {
                JQuery("#Invalid_MaterialCodeFrom").show();

                JQuery("#Invalid_Status_MaterialCodeFromSub").hide();
                JQuery("#Inactive_Status_MaterialCodeFromSub").hide();

                JQuery("#TableMaterialCodeFromSublist").css("border", "1px solid red");

            }

            if (!materialcode_regex.test(MaterialCodeToSublist)) {

                JQuery("#Invalid_MaterialCodeTo").show();
                JQuery("#Invalid_Status_MaterialCodeToSub").hide();
                JQuery("#Inactive_Status_MaterialCodeToSub").hide();

                JQuery("#TableMaterialCodeToSublist").css("border", "1px solid red");

            }
            if (MaterialDescriptionFromSublist === "") {
                JQuery("#Invalid_MaterialDescriptionFrom").show();

                JQuery("#TableMaterialDescriptionFromSublist").css("border", "1px solid red");
            }

            if (MaterialDescriptionToSublist === "") {
                JQuery("#Invalid_MaterialDescriptionTo").show();

                JQuery("#TableMaterialDescriptionToSublist").css("border", "1px solid red");
            }


            if (UnitOfMeasureFrom === "") {

                JQuery("#Invalid_UnitOfMeasureFrom").show();
                JQuery("#TableUnitOfMeasureFrom").css("border", "1px solid red");

            }

            if (UnitOfMeasureTo === "") {

                JQuery("#Invalid_UnitOfMeasureTo").show();
                JQuery("#TableUnitOfMeasureTo").css("border", "1px solid red");

            }



            if (ReasonCodeSublist === "") {

                JQuery("#Invalid_ReasonCodeSublist").show();
                JQuery("#ReasonCodeSublist").css("border", "1px solid red");
            }


        }



    }

    //*edit*//

    const [editSublistmaterialId, setEditSublistmaterialId] = useState(null);

    const [editSublistmaterialFormData, SeteditSublistmaterialFormData] = useState({

        MaterialCodeFrom: '',
        MaterialDescriptionFrom: '',
        UnitOfMeasureFrom: '',
        MaterialCodeTo: '',
        MaterialDescriptionTo: '',
        UnitOfMeasureTo: '',
        ReasonCode: '',
        ReasonCodeDescription: ''

    });

    const handleEditSublistmaterialClick = (event, material) => {
        event.preventDefault();

        setEditSublistmaterialId(material.id);

        const formvalues = {

            MaterialCodeFrom: material.MaterialCodeFrom,
            MaterialDescriptionFrom: material.MaterialDescriptionFrom,
            UnitOfMeasureFrom: material.UnitOfMeasureFrom,
            MaterialCodeTo: material.MaterialCodeTo,
            MaterialDescriptionTo: material.MaterialDescriptionTo,
            UnitOfMeasureTo: material.UnitOfMeasureTo,
            ReasonCode: material.ReasonCode

        }

        SeteditSublistmaterialFormData(formvalues);
    }


    const handleEditSublistmaterialFormChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.getAttribute('name');
        const fieldvalue = event.target.value;

        JQuery('input[idx= "restrictedDecimal"]').keyup(function (e) {
            if (/\D/g.test(this.value)) {

                this.value = this.value.replace(/\D/g, '');
            }
        });

        const newFormData = { ...editSublistmaterialFormData };
        newFormData[fieldName] = fieldvalue;

        SeteditSublistmaterialFormData(newFormData);

    }

    const handleEditSublistmaterialFormSubmit = (event) => {
        event.preventDefault();

        ClearSublistErrorLebels();

        const materialcode_regex = /^[0-9]{13}$/;

        const MaterialCodeFromSublist = JQuery("#TableMaterialCodeFromSublist").val();
        const MaterialCodeToSublist = JQuery("#TableMaterialCodeToSublist").val();

        const MaterialDescriptionFromSublist = JQuery("#TableMaterialDescriptionFromSublist").val();
        const MaterialDescriptionToSublist = JQuery("#TableMaterialDescriptionToSublist").val();

        const UnitOfMeasureFrom = JQuery("#TableUnitOfMeasureFrom").find(":selected").val();
        const UnitOfMeasureTo = JQuery("#TableUnitOfMeasureTo").find(":selected").val();

        const ReasonCodeSublist = JQuery("#ReasonCodeSublist").find(":selected").val();

        const ReasonCodeSublistText = JQuery("#ReasonCodeSublist").find(":selected").html();

        checkEditValidationMaterial(MaterialCodeFromSublist);
        checkEditValidationMaterial(MaterialCodeToSublist);

        if (materialcode_regex.test(MaterialCodeFromSublist) && materialcode_regex.test(MaterialCodeToSublist)
            && MaterialDescriptionFromSublist !== "" && MaterialDescriptionToSublist !== ""
            && UnitOfMeasureFrom !== "" && UnitOfMeasureTo !== "" && ReasonCodeSublist !== "" && EditvalidmaterialCode === true) {

            const EditObject = {

                id: editSublistmaterialId,

                MaterialCodeFrom: editSublistmaterialFormData.MaterialCodeFrom,
                MaterialDescriptionFrom: MaterialDescriptionFromSublist,
                UnitOfMeasureFrom: UnitOfMeasureFrom,

                MaterialCodeTo: editSublistmaterialFormData.MaterialCodeTo,
                MaterialDescriptionTo: MaterialDescriptionToSublist,
                UnitOfMeasureTo: UnitOfMeasureTo,
                ReasonCode: editSublistmaterialFormData.ReasonCode,
                ReasonCodeDescription: ReasonCodeSublistText
            }

            const newObjects = [...Sublistmaterial];

            const index = Sublistmaterial.findIndex((Sublistmaterial) => Sublistmaterial.id === editSublistmaterialId);

            newObjects[index] = EditObject

            SetSublistmaterial(newObjects);
            setEditSublistmaterialId(null);



            JQuery("#Invalid_Status_MaterialCodeFromSub").hide();
            JQuery("#Invalid_Status_MaterialCodeToSub").hide();

            JQuery("#Inactive_Status_MaterialCodeFromSub").hide();
            JQuery("#Inactive_Status_MaterialCodeToSub").hide();

            JQuery("#TableMaterialCodeFromSublist").removeAttr("style");
            JQuery("#TableMaterialCodeFromSublist").css({ "width": "9rem" });

            JQuery("#TableMaterialCodeToSublist").removeAttr("style");
            JQuery("#TableMaterialCodeToSublist").css({ "width": "9rem" });

            ClearSublistErrorLebels();


        }
        else {

            if (!materialcode_regex.test(MaterialCodeFromSublist)) {
                JQuery("#Invalid_MaterialCodeFrom").show();

                JQuery("#Invalid_Status_MaterialCodeFromSub").hide();
                JQuery("#Inactive_Status_MaterialCodeFromSub").hide();

                JQuery("#TableMaterialCodeFromSublist").css("border", "1px solid red");

            }

            if (!materialcode_regex.test(MaterialCodeToSublist)) {

                JQuery("#Invalid_MaterialCodeTo").show();
                JQuery("#Invalid_Status_MaterialCodeToSub").hide();
                JQuery("#Inactive_Status_MaterialCodeToSub").hide();

                JQuery("#TableMaterialCodeToSublist").css("border", "1px solid red");

            }
            if (MaterialDescriptionFromSublist === "") {
                JQuery("#Invalid_MaterialDescriptionFrom").show();

                JQuery("#TableMaterialDescriptionFromSublist").css("border", "1px solid red");
            }

            if (MaterialDescriptionToSublist === "") {
                JQuery("#Invalid_MaterialDescriptionTo").show();

                JQuery("#TableMaterialDescriptionToSublist").css("border", "1px solid red");
            }

            if (UnitOfMeasureFrom === "") {

                JQuery("#Invalid_UnitOfMeasureFrom").show();
                JQuery("#TableUnitOfMeasureFrom").css("border", "1px solid red");

            }

            if (UnitOfMeasureTo === "") {

                JQuery("#Invalid_UnitOfMeasureTo").show();
                JQuery("#TableUnitOfMeasureTo").css("border", "1px solid red");

            }


            if (ReasonCodeSublist === "") {

                JQuery("#Invalid_ReasonCodeSublist").show();
                JQuery("#ReasonCodeSublist").css("border", "1px solid red");
            }


        }



    }

    //*Remove*//
    const handelRemoveSublistmaterialClick = (SublistmaterialId) => {
        const newObjects = [...Sublistmaterial];

        const index = Sublistmaterial.findIndex((sublistmaterial) => sublistmaterial.id === SublistmaterialId);

        newObjects.splice(index, 1);

        SetSublistmaterial(newObjects);

    }

    //*cancel*//

    const handelCancelSublistmaterialClick = () => {

        setEditSublistmaterialId(null);

    }

    //** End Sub List Region  **//



    //--------***********--------//

    //** Allocation List Region **//

    const ClearAllocationlistErrorLebels = () => {

        JQuery("#Invalid_MaterialCode").hide();

        JQuery("#Invalid_MaterialDescriptionAlloacation").hide();
        JQuery("#TableMaterialDescriptionAllocationlist").removeAttr("style");
        JQuery("#TableMaterialDescriptionAllocationlist").css({ "width": "10rem" });

        JQuery("#Invalid_UnitOfMeasure").hide();
        JQuery("#TableUnitOfMeasure").removeAttr("style");
        JQuery("#TableUnitOfMeasure").css({ "width": "6rem" });

        JQuery("#Invalid_MinQtyCap").hide();
        JQuery("#TableMinQtyCap").removeAttr("style");
        JQuery("#TableMinQtyCap").css({ "width": "5rem" });

        JQuery("#Invalid_MaxQtyCap").hide();
        JQuery("#TableMaxQtyCap").removeAttr("style");
        JQuery("#TableMaxQtyCap").css({ "width": "5rem" });

        JQuery("#Invalid_ReasonCodeAlloactionlist").hide();
        JQuery("#ReasonCodeAlloactionlist").removeAttr("style");
        JQuery("#ReasonCodeAlloactionlist").css({ "width": "10rem" });

    }

    const [Allocationlistmaterial, SetAllocationlistmaterial] = useState([]);

    //*add*//
    const [showAddAllocationlist, setShowAddAllocationlist] = useState(false);
    const [addAllocationlistmaterialFormData, SetaddAllocationlistmaterialFormData] = useState({

        MaterialCode: '',
        MaterialDescription: '',
        UnitOfMeasure: '',
        MinQtyCap: '',
        MaxQtyCap: '',
        ReasonCode: '',
        ReasonCodeDescription: ''

    });

    const handleAddAllocationlistmaterialFormChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.getAttribute('name');
        const fieldvalue = event.target.value;

        JQuery('input[idx= "restrictedDecimal"]').keyup(function (e) {
            if (/\D/g.test(this.value)) {
                this.value = this.value.replace(/\D/g, '');
            }
        });

        const newFormData = { ...addAllocationlistmaterialFormData };
        newFormData[fieldName] = fieldvalue;

        SetaddAllocationlistmaterialFormData(newFormData);

    }

    const handleAddAllocationlistmaterialFormSubmit = (event) => {
        event.preventDefault();

        ClearAllocationlistErrorLebels();

        const materialcode_regex = /^[0-9]{13}$/;
        const MaterialCodeAllocationlist = JQuery("#TableMaterialCodeAllocationlist").val();
        const MaterialDescriptionAllocationlist = JQuery("#TableMaterialDescriptionAllocationlist").val();

        const UnitOfMeasure = JQuery("#TableUnitOfMeasure").find(":selected").val();
        const TableMinQtyCap = JQuery("#TableMinQtyCap").val();
        const TableMaxQtyCap = JQuery("#TableMaxQtyCap").val();

        const ReasonCodeAlloactionlist = JQuery("#ReasonCodeAlloactionlist").find(":selected").val();

        const ReasonCodeAlloactionlistText = JQuery("#ReasonCodeAlloactionlist").find(":selected").html();


        if (materialcode_regex.test(MaterialCodeAllocationlist) && MaterialDescriptionAllocationlist !== ""
            && UnitOfMeasure !== ""
            && TableMinQtyCap !== "" && TableMaxQtyCap !== "" && ReasonCodeAlloactionlist !== "" && validmaterialCode === true) {

            const newObject = {

                id: nanoid(),

                MaterialCode: MaterialCodeAllocationlist,
                MaterialDescription: MaterialDescriptionAllocationlist,
                UnitOfMeasure: UnitOfMeasure,
                MinQtyCap: (parseInt(addAllocationlistmaterialFormData.MinQtyCap)).toString(),
                MaxQtyCap: (parseInt(addAllocationlistmaterialFormData.MaxQtyCap)).toString(),
                ReasonCode: addAllocationlistmaterialFormData.ReasonCode,
                ReasonCodeDescription: ReasonCodeAlloactionlistText

            }

            const newObjects = [...Allocationlistmaterial, newObject];

            let temp = [];
            for (let i = 0; i < newObjects.length; i++) {
                temp.push(newObjects[i]);

            }


            SetAllocationlistmaterial(temp);


            JQuery('#AddItemsAllocationlist').find('input').val('');


            JQuery("#Invalid_Status_MaterialCodeAllocation").hide();
            JQuery("#Inactive_Status_MaterialCodeAllocation").hide();

            JQuery("#TableMaterialCodeAllocationlist").removeAttr("style");
            JQuery("#TableMaterialCodeAllocationlist").css({ "width": "10rem" });

            ClearAllocationlistErrorLebels();


        }
        else {

            if (!materialcode_regex.test(MaterialCodeAllocationlist)) {

                JQuery("#Invalid_MaterialCode").show();
                JQuery("#Invalid_Status_MaterialCodeAllocation").hide();
                JQuery("#Inactive_Status_MaterialCodeAllocation").hide();

                JQuery("#TableMaterialCodeAllocationlist").css("border", "1px solid red");
            }
            if (MaterialDescriptionAllocationlist === "") {

                JQuery("#Invalid_MaterialDescriptionAlloacation").show();

                JQuery("#TableMaterialDescriptionAllocationlist").css("border", "1px solid red");
            }

            if (UnitOfMeasure === "") {

                JQuery("#Invalid_UnitOfMeasure").show();
                JQuery("#TableUnitOfMeasure").css("border", "1px solid red");

            }

            if (TableMinQtyCap === "") {

                JQuery("#Invalid_MinQtyCap").show();
                JQuery("#TableMinQtyCap").css("border", "1px solid red");

            }
            if (TableMaxQtyCap === "") {

                JQuery("#Invalid_MaxQtyCap").show();
                JQuery("#TableMaxQtyCap").css("border", "1px solid red");

            }

            if (ReasonCodeAlloactionlist === "") {

                JQuery("#Invalid_ReasonCodeAlloactionlist").show();
                JQuery("#ReasonCodeAlloactionlist").css("border", "1px solid red");

            }

        }

    }
    //*edit*//

    const [editAllocationlistmaterialId, setEditAllocationlistmaterialId] = useState(null);

    const [editAllocationlistmaterialFormData, SeteditAllocationlistmaterialFormData] = useState({

        MaterialCode: '',
        MaterialDescription: '',
        UnitOfMeasure: '',
        MinQtyCap: '',
        MaxQtyCap: '',
        ReasonCode: '',
        ReasonCodeDescription: ''

    });

    const handleEditAllocationlistmaterialClick = (event, material) => {
        event.preventDefault();

        setEditAllocationlistmaterialId(material.id);

        const formvalues = {

            MaterialCode: material.MaterialCode,
            MaterialDescription: material.MaterialDescription,
            UnitOfMeasure: material.UnitOfMeasure,
            MinQtyCap: material.MinQtyCap,
            MaxQtyCap: material.MaxQtyCap,
            ReasonCode: material.ReasonCode

        }

        SeteditAllocationlistmaterialFormData(formvalues);
    }


    const handleEditAllocationlistmaterialFormChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.getAttribute('name');
        const fieldvalue = event.target.value;

        JQuery('input[idx= "restrictedDecimal"]').keyup(function (e) {
            if (/\D/g.test(this.value)) {
                this.value = this.value.replace(/\D/g, '');
            }
        });

        const newFormData = { ...editAllocationlistmaterialFormData };
        newFormData[fieldName] = fieldvalue;

        SeteditAllocationlistmaterialFormData(newFormData);

    }

    const handleEditAllocationlistmaterialFormSubmit = (event) => {
        event.preventDefault();

        ClearAllocationlistErrorLebels();

        const materialcode_regex = /^[0-9]{13}$/;
        const MaterialCodeAllocationlist = JQuery("#TableMaterialCodeAllocationlist").val();
        const MaterialDescriptionAllocationlist = JQuery("#TableMaterialDescriptionAllocationlist").val();

        const UnitOfMeasure = JQuery("#TableUnitOfMeasure").find(":selected").val();
        const TableMinQtyCap = JQuery("#TableMinQtyCap").val();
        const TableMaxQtyCap = JQuery("#TableMaxQtyCap").val();


        const ReasonCodeAlloactionlist = JQuery("#ReasonCodeAlloactionlist").find(":selected").val();

        const ReasonCodeAlloactionlistText = JQuery("#ReasonCodeAlloactionlist").find(":selected").html();

        checkEditValidationMaterial(MaterialCodeAllocationlist);

        if (materialcode_regex.test(MaterialCodeAllocationlist) && MaterialDescriptionAllocationlist !== ""
            && UnitOfMeasure !== ""
            && TableMinQtyCap !== "" && TableMaxQtyCap !== "" && ReasonCodeAlloactionlist !== "" && EditvalidmaterialCode === true) {

            const EditObject = {

                id: editAllocationlistmaterialId,
                MaterialCode: editAllocationlistmaterialFormData.MaterialCode,
                MaterialDescription: MaterialDescriptionAllocationlist,
                UnitOfMeasure: UnitOfMeasure,
                MinQtyCap: (parseInt(editAllocationlistmaterialFormData.MinQtyCap)).toString(),
                MaxQtyCap: (parseInt(editAllocationlistmaterialFormData.MaxQtyCap)).toString(),
                ReasonCode: editAllocationlistmaterialFormData.ReasonCode,
                ReasonCodeDescription: ReasonCodeAlloactionlistText

            }

            const newObjects = [...Allocationlistmaterial];

            const index = Allocationlistmaterial.findIndex((Allocationlistmaterial) => Allocationlistmaterial.id === editAllocationlistmaterialId);

            newObjects[index] = EditObject

            SetAllocationlistmaterial(newObjects);
            setEditAllocationlistmaterialId(null);


            JQuery("#Invalid_Status_MaterialCodeAllocation").hide();
            JQuery("#Inactive_Status_MaterialCodeAllocation").hide();
            JQuery("#TableMaterialCodeAllocationlist").removeAttr("style");
            JQuery("#TableMaterialCodeAllocationlist").css({ "width": "10rem" });

            ClearAllocationlistErrorLebels();
        }
        else {

            if (!materialcode_regex.test(MaterialCodeAllocationlist)) {

                JQuery("#Invalid_MaterialCode").show();
                JQuery("#Invalid_Status_MaterialCodeAllocation").hide();
                JQuery("#Inactive_Status_MaterialCodeAllocation").hide();
                JQuery("#TableMaterialCodeAllocationlist").css("border", "1px solid red");
            }
            if (MaterialDescriptionAllocationlist === "") {

                JQuery("#Invalid_MaterialDescriptionAlloacation").show();

                JQuery("#TableMaterialDescriptionAllocationlist").css("border", "1px solid red");
            }
            if (UnitOfMeasure === "") {

                JQuery("#Invalid_UnitOfMeasure").show();
                JQuery("#TableUnitOfMeasure").css("border", "1px solid red");

            }

            if (TableMinQtyCap === "") {

                JQuery("#Invalid_MinQtyCap").show();
                JQuery("#TableMinQtyCap").css("border", "1px solid red");

            }
            if (TableMaxQtyCap === "") {

                JQuery("#Invalid_MaxQtyCap").show();
                JQuery("#TableMaxQtyCap").css("border", "1px solid red");

            }

            if (ReasonCodeAlloactionlist === "") {

                JQuery("#Invalid_ReasonCodeAlloactionlist").show();
                JQuery("#ReasonCodeAlloactionlist").css("border", "1px solid red");

            }

        }
    }

    //*Remove*//
    const handelRemoveAllocationlistmaterialClick = (AllocationlistId) => {
        const newObjects = [...Allocationlistmaterial];

        const index = Allocationlistmaterial.findIndex((allocationlistmaterial) => allocationlistmaterial.id === AllocationlistId);

        newObjects.splice(index, 1);

        SetAllocationlistmaterial(newObjects);

    }
    //*cancel*//

    const handelCancelAllocationlistmaterialClick = () => {

        setEditAllocationlistmaterialId(null);

    }


    //**  End Allocation List Region **//

    //--------***********--------//

    //* Add List Region **//

    const ClearAddlistErrorLebels = () => {

        JQuery("#Invalid_MaterialCodeAdd").hide();

        JQuery("#Invalid_MaterialDescriptionAdd").hide();
        JQuery("#TableMaterialDescriptionAddlist").removeAttr("style");
        JQuery("#TableMaterialDescriptionAddlist").css({ "width": "10rem" });

        JQuery("#Invalid_AddValue").hide();
        JQuery("#TableAddValue").removeAttr("style");
        JQuery("#TableAddValue").css({ "width": "5rem" });

        JQuery("#Invalid_UnitOfMeasureAdd").hide();
        JQuery("#TableUnitOfMeasureAdd").removeAttr("style");
        JQuery("#TableUnitOfMeasureAdd").css({ "width": "6rem" });

        JQuery("#Invalid_ReasonCodeAddlist").hide();
        JQuery("#ReasonCodeAddlist").removeAttr("style");
        JQuery("#ReasonCodeAddlist").css({ "width": "10rem" });

        JQuery("#Invalid_AddValueQty").hide();
        JQuery("#TableAddValueQty").removeAttr("style");
        JQuery("#TableAddValueQty").css({ "width": "5rem" });
    }

    const [Addlistmaterial, SetAddlistmaterial] = useState([]);

    //*add*//
    const [showAddAddlist, setShowAddAddlist] = useState(false);
    const [addAddlistmaterialFormData, SetaddAddlistmaterialFormData] = useState({

        MaterialCode: '',
        MaterialDescription: '',
        UnitOfMeasure: '',
        AddValue: '',
        AddValueQty: '',
        ReasonCode: '',
        ReasonCodeDescription: '',

    });

    const handleAddAddlistmaterialFormChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.getAttribute('name');
        const fieldvalue = event.target.value;

        JQuery('input[idx= "restrictedDecimal"]').keyup(function (e) {
            if (/\D/g.test(this.value)) {
                this.value = this.value.replace(/\D/g, '');
            }
        });

        const newFormData = { ...addAddlistmaterialFormData };
        newFormData[fieldName] = fieldvalue;

        SetaddAddlistmaterialFormData(newFormData);

    }

    const handleAddAddlistmaterialFormSubmit = (event) => {
        event.preventDefault();

        ClearAddlistErrorLebels();

        const materialcode_regex = /^[0-9]{13}$/;
        const MaterialCodeAddlist = JQuery("#TableMaterialCodeAddlist").val();
        const MaterialDescriptionAddlist = JQuery("#TableMaterialDescriptionAddlist").val();

        const AddValue = JQuery("#TableAddValue").val();

        const UnitOfMeasure = JQuery("#TableUnitOfMeasureAdd").find(":selected").val();
        const AddValueQty = JQuery("#TableAddValueQty").val();
        const ReasonCodeAddlist = JQuery("#ReasonCodeAddlist").find(":selected").val();

        const ReasonCodeAddlistText = JQuery("#ReasonCodeAddlist").find(":selected").html();


        if (materialcode_regex.test(MaterialCodeAddlist) && MaterialDescriptionAddlist !== "" && UnitOfMeasure !== ""
            && AddValue !== "" && AddValueQty !== "" && ReasonCodeAddlist !== "" && validmaterialCode === true) {

            const newObject = {

                id: nanoid(),

                MaterialCode: MaterialCodeAddlist,
                MaterialDescription: MaterialDescriptionAddlist,
                UnitOfMeasure: UnitOfMeasure,
                AddValue: addAddlistmaterialFormData.AddValue,
                AddValueQty: addAddlistmaterialFormData.AddValueQty,
                ReasonCode: addAddlistmaterialFormData.ReasonCode,
                ReasonCodeDescription: ReasonCodeAddlistText

            }

            const newObjects = [...Addlistmaterial, newObject];

            let temp = [];
            for (let i = 0; i < newObjects.length; i++) {
                temp.push(newObjects[i]);

            }

            SetAddlistmaterial(temp);

            JQuery('#AddItemsAddlist').find('input').val('');

            JQuery("#Invalid_Status_MaterialCodeAdd").hide();
            JQuery("#Inactive_Status_MaterialCodeAdd").hide();
            JQuery("#TableMaterialCodeAddlist").removeAttr("style");
            JQuery("#TableMaterialCodeAddlist").css({ "width": "10rem" });

            ClearAddlistErrorLebels();

        }

        else {

            if (!materialcode_regex.test(MaterialCodeAddlist)) {
                JQuery("#Invalid_MaterialCodeAdd").show();
                JQuery("#Invalid_Status_MaterialCodeAdd").hide();
                JQuery("#Inactive_Status_MaterialCodeAdd").hide();
                JQuery("#TableMaterialCodeAddlist").css("border", "1px solid red");
            }

            if (MaterialDescriptionAddlist === "") {
                JQuery("#Invalid_MaterialDescriptionAdd").show();
                JQuery("#TableMaterialDescriptionAddlist").css("border", "1px solid red");
            }

            if (AddValue === "") {
                JQuery("#Invalid_AddValue").show();
                JQuery("#TableAddValue").css("border", "1px solid red");
            }

            if (UnitOfMeasure === "") {

                JQuery("#Invalid_UnitOfMeasureAdd").show();
                JQuery("#TableUnitOfMeasureAdd").css("border", "1px solid red");

            }

            if (AddValueQty === "") {

                JQuery("#Invalid_AddValueQty").show();
                JQuery("#TableAddValueQty").css("border", "1px solid red");
            }

            if (ReasonCodeAddlist === "") {

                JQuery("#Invalid_ReasonCodeAddlist").show();
                JQuery("#ReasonCodeAddlist").css("border", "1px solid red");

            }


        }
    }
    //*edit*//

    const [editAddlistmaterialId, setEditAddlistmaterialId] = useState(null);

    const [editAddlistmaterialFormData, SeteditAddlistmaterialFormData] = useState({

        MaterialCode: '',
        MaterialDescription: '',
        UnitOfMeasure: '',
        AddValue: '',
        AddValueQty: '',
        ReasonCode: '',
        ReasonCodeDescription: ''

    });

    const handleEditAddlistmaterialClick = (event, material) => {
        event.preventDefault();

        setEditAddlistmaterialId(material.id);

        const formvalues = {

            MaterialCode: material.MaterialCode,
            MaterialDescription: material.MaterialDescription,
            UnitOfMeasure: material.UnitOfMeasure,
            AddValue: material.AddValue,
            AddValueQty: material.AddValueQty,
            ReasonCode: material.ReasonCode

        }

        SeteditAddlistmaterialFormData(formvalues);
    }


    const handleEditAddlistmaterialFormChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.getAttribute('name');
        const fieldvalue = event.target.value;

        JQuery('input[idx= "restrictedDecimal"]').keyup(function (e) {
            if (/\D/g.test(this.value)) {
                this.value = this.value.replace(/\D/g, '');
            }
        });

        const newFormData = { ...editAddlistmaterialFormData };
        newFormData[fieldName] = fieldvalue;

        SeteditAddlistmaterialFormData(newFormData);

    }

    const handleEditAddlistmaterialFormSubmit = (event) => {
        event.preventDefault();

        ClearAddlistErrorLebels();

        const materialcode_regex = /^[0-9]{13}$/;
        const MaterialCodeAddlist = JQuery("#TableMaterialCodeAddlist").val();
        const MaterialDescriptionAddlist = JQuery("#TableMaterialDescriptionAddlist").val();

        const AddValue = JQuery("#TableAddValue").val();

        const UnitOfMeasure = JQuery("#TableUnitOfMeasureAdd").find(":selected").val();
        const AddValueQty = JQuery("#TableAddValueQty").val();
        const ReasonCodeAddlist = JQuery("#ReasonCodeAddlist").find(":selected").val();

        const ReasonCodeAddlistText = JQuery("#ReasonCodeAddlist").find(":selected").html();

        checkEditValidationMaterial(MaterialCodeAddlist);

        if (materialcode_regex.test(MaterialCodeAddlist) && MaterialDescriptionAddlist !== "" && UnitOfMeasure !== ""
            && AddValue !== "" && AddValueQty !== "" && ReasonCodeAddlist !== "" && EditvalidmaterialCode === true) {


            const EditObject = {

                id: editAddlistmaterialId,

                MaterialCode: editAddlistmaterialFormData.MaterialCode,
                MaterialDescription: MaterialDescriptionAddlist,
                UnitOfMeasure: UnitOfMeasure,
                AddValue: editAddlistmaterialFormData.AddValue,
                AddValueQty: editAddlistmaterialFormData.AddValueQty,
                ReasonCode: editAddlistmaterialFormData.ReasonCode,
                ReasonCodeDescription: ReasonCodeAddlistText

            }

            const newObjects = [...Addlistmaterial];

            const index = Addlistmaterial.findIndex((addlistmaterial) => addlistmaterial.id === editAddlistmaterialId);

            newObjects[index] = EditObject

            SetAddlistmaterial(newObjects);
            setEditAddlistmaterialId(null);

            JQuery("#Invalid_Status_MaterialCodeAdd").hide();
            JQuery("#Inactive_Status_MaterialCodeAdd").hide();
            JQuery("#TableMaterialCodeAddlist").removeAttr("style");
            JQuery("#TableMaterialCodeAddlist").css({ "width": "10rem" });

            ClearAddlistErrorLebels();

        }
        else {

            if (!materialcode_regex.test(MaterialCodeAddlist)) {
                JQuery("#Invalid_MaterialCodeAdd").show();
                JQuery("#Invalid_Status_MaterialCodeAdd").hide();
                JQuery("#Inactive_Status_MaterialCodeAdd").hide();

                JQuery("#TableMaterialCodeAddlist").css("border", "1px solid red");
            }
            if (MaterialDescriptionAddlist === "") {
                JQuery("#Invalid_MaterialDescriptionAdd").show();
                JQuery("#TableMaterialDescriptionAddlist").css("border", "1px solid red");
            }
            if (AddValue === "") {
                JQuery("#Invalid_AddValue").show();
                JQuery("#TableAddValue").css("border", "1px solid red");
            }

            if (UnitOfMeasure === "") {

                JQuery("#Invalid_UnitOfMeasureAdd").show();
                JQuery("#TableUnitOfMeasureAdd").css("border", "1px solid red");

            }

            if (AddValueQty === "") {

                JQuery("#Invalid_AddValueQty").show();
                JQuery("#TableAddValueQty").css("border", "1px solid red");
            }

            if (ReasonCodeAddlist === "") {

                JQuery("#Invalid_ReasonCodeAddlist").show();
                JQuery("#ReasonCodeAddlist").css("border", "1px solid red");

            }



        }
    }

    //*Remove*//
    const handelRemoveAddlistmaterialClick = (AddlistId) => {
        const newObjects = [...Addlistmaterial];

        const index = Addlistmaterial.findIndex((addlistmaterial) => addlistmaterial.id === AddlistId);

        newObjects.splice(index, 1);

        SetAddlistmaterial(newObjects);

    }
    //*cancel*//

    const handelCancelAddlistmaterialClick = () => {

        setEditAddlistmaterialId(null);

    }

    //** End Add List Region **//

    /*  --------***********--------*/

    /*  Cut List Region*/
    const ClearCutlistErrorLebels = () => {

        JQuery("#Invalid_MaterialCodeCut").hide();

        JQuery("#Invalid_MaterialDescriptionCut").hide();
        JQuery("#TableMaterialDescriptionCutlist").removeAttr("style");
        JQuery("#TableMaterialDescriptionCutlist").css({ "width": "10rem" });

        JQuery("#Invalid_UnitOfMeasureCut").hide();
        JQuery("#TableUnitOfMeasureCut").removeAttr("style");
        JQuery("#TableUnitOfMeasureCut").css({ "width": "6rem" });

        JQuery("#Invalid_CutValue").hide();
        JQuery("#TableCutValue").removeAttr("style");
        JQuery("#TableCutValue").css({ "width": "5rem" });

        JQuery("#Invalid_CutValueQty").hide();
        JQuery("#TableCutValueQty").removeAttr("style");
        JQuery("#TableCutValueQty").css({ "width": "5rem" });

        JQuery("#Invalid_ReasonCodeCutlist").hide();
        JQuery("#ReasonCodeCutlist").removeAttr("style");
        JQuery("#ReasonCodeCutlist").css({ "width": "10rem" });

    }

    const [Cutlistmaterial, SetCutlistmaterial] = useState([]);


    //* add*//
    const [showAddCutlist, setShowAddCutlist] = useState(false);
    const [addCutlistmaterialFormData, SetaddCutlistmaterialFormData] = useState({

        MaterialCode: '',
        MaterialDescription: '',
        UnitOfMeasure: '',
        CutValue: '',
        CutValueQty: '',
        ReasonCode: '',
        ReasonCodeDescription: '',


    });

    const handleAddCutlistmaterialFormChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.getAttribute('name');
        const fieldvalue = event.target.value;

        JQuery('input[idx= "restrictedDecimal"]').keyup(function (e) {
            if (/\D/g.test(this.value)) {
                this.value = this.value.replace(/\D/g, '');
            }
        });
        const newFormData = { ...addCutlistmaterialFormData };
        newFormData[fieldName] = fieldvalue;

        SetaddCutlistmaterialFormData(newFormData);

    }

    const handleAddCutlistmaterialFormSubmit = (event) => {
        event.preventDefault();

        ClearCutlistErrorLebels();
        const materialcode_regex = /^[0-9]{13}$/;
        const MaterialCodeCutlist = JQuery("#TableMaterialCodeCutlist").val();
        const MaterialDescriptionCutlist = JQuery("#TableMaterialDescriptionCutlist").val();

        const CutValue = JQuery("#TableCutValue").val();

        const UnitOfMeasure = JQuery("#TableUnitOfMeasureCut").find(":selected").val();
        const CutValueQty = JQuery("#TableCutValueQty").val();
        const ReasonCodeCutlist = JQuery("#ReasonCodeCutlist").find(":selected").val();

        const ReasonCodeCutlistText = JQuery("#ReasonCodeCutlist").find(":selected").html();


        if (materialcode_regex.test(MaterialCodeCutlist) && MaterialDescriptionCutlist !== "" && UnitOfMeasure !== ""

            && CutValue !== "" && CutValueQty !== "" && ReasonCodeCutlist !== "" && validmaterialCode === true) {


            const newObject = {

                id: nanoid(),
                MaterialCode: MaterialCodeCutlist,
                MaterialDescription: MaterialDescriptionCutlist,
                UnitOfMeasure: UnitOfMeasure,

                CutValue: addCutlistmaterialFormData.CutValue,
                CutValueQty: addCutlistmaterialFormData.CutValueQty,
                ReasonCode: addCutlistmaterialFormData.ReasonCode,
                ReasonCodeDescription: ReasonCodeCutlistText,

            }

            const newObjects = [...Cutlistmaterial, newObject];

            let temp = [];
            for (let i = 0; i < newObjects.length; i++) {
                temp.push(newObjects[i]);

            }

            SetCutlistmaterial(temp);

            JQuery('#AddItemsCutlist').find('input').val('');


            JQuery("#Invalid_Status_MaterialCodeCut").hide();
            JQuery("#Inactive_Status_MaterialCodeCut").hide();
            JQuery("#TableMaterialCodeCutlist").removeAttr("style");
            JQuery("#TableMaterialCodeCutlist").css({ "width": "10rem" });

            ClearCutlistErrorLebels();

        }
        else {

            if (!materialcode_regex.test(MaterialCodeCutlist)) {

                JQuery("#Invalid_MaterialCodeCut").show();
                JQuery("#Invalid_Status_MaterialCodeCut").hide();
                JQuery("#Inactive_Status_MaterialCodeCut").hide();

                JQuery("#TableMaterialCodeCutlist").css("border", "1px solid red");
            }

            if (MaterialDescriptionCutlist === "") {
                JQuery("#Invalid_MaterialDescriptionCut").show();
                JQuery("#TableMaterialDescriptionCutlist").css("border", "1px solid red");
            }

            if (CutValue === "") {

                JQuery("#Invalid_CutValue").show();
                JQuery("#TableCutValue").css("border", "1px solid red");
            }

            if (UnitOfMeasure === "") {
                JQuery("#Invalid_UnitOfMeasureCut").show();
                JQuery("#TableUnitOfMeasureCut").css("border", "1px solid red");
            }

            if (CutValueQty === "") {

                JQuery("#Invalid_CutValueQty").show();
                JQuery("#TableCutValueQty").css("border", "1px solid red");

            }
            if (ReasonCodeCutlist === "") {
                JQuery("#Invalid_ReasonCodeCutlist").show();
                JQuery("#ReasonCodeCutlist").css("border", "1px solid red");
            }


        }

    }

    //* edit*//


    const [editCutlistmaterialId, setEditCutlistmaterialId] = useState(null);

    const [editCutlistmaterialFormData, SeteditCutlistmaterialFormData] = useState({

        MaterialCode: '',
        MaterialDescription: '',
        UnitOfMeasure: '',
        CutValue: '',
        CutValueQty: '',
        ReasonCodeDescription: '',
        ReasonCode: ''


    });

    const handleEditCutlistmaterialClick = (event, material) => {
        event.preventDefault();

        setEditCutlistmaterialId(material.id);

        const formvalues = {

            MaterialCode: material.MaterialCode,
            MaterialDescription: material.MaterialDescription,
            UnitOfMeasure: material.UnitOfMeasure,
            CutValue: material.CutValue,
            CutValueQty: material.CutValueQty,
            ReasonCode: material.ReasonCode

        }

        SeteditCutlistmaterialFormData(formvalues);
    }


    const handleEditCutlistmaterialFormChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.getAttribute('name');
        const fieldvalue = event.target.value;

        JQuery('input[idx= "restrictedDecimal"]').keyup(function (e) {
            if (/\D/g.test(this.value)) {
                this.value = this.value.replace(/\D/g, '');
            }
        });

        const newFormData = { ...editCutlistmaterialFormData };
        newFormData[fieldName] = fieldvalue;

        SeteditCutlistmaterialFormData(newFormData);

    }

    const handleEditCutlistmaterialFormSubmit = (event) => {
        event.preventDefault();

        ClearCutlistErrorLebels();

        const materialcode_regex = /^[0-9]{13}$/;
        const MaterialCodeCutlist = JQuery("#TableMaterialCodeCutlist").val();
        const MaterialDescriptionCutlist = JQuery("#TableMaterialDescriptionCutlist").val();

        const CutValue = JQuery("#TableCutValue").val();

        const UnitOfMeasure = JQuery("#TableUnitOfMeasureCut").find(":selected").val();
        const CutValueQty = JQuery("#TableCutValueQty").val();
        const ReasonCodeCutlist = JQuery("#ReasonCodeCutlist").find(":selected").val();

        const ReasonCodeCutlistText = JQuery("#ReasonCodeCutlist").find(":selected").html();

        checkEditValidationMaterial(MaterialCodeCutlist);


        if (materialcode_regex.test(MaterialCodeCutlist) && MaterialDescriptionCutlist !== "" && UnitOfMeasure !== ""

            && CutValue !== "" && CutValueQty !== "" && ReasonCodeCutlist !== "" && EditvalidmaterialCode === true) {

            const EditObject = {

                id: editCutlistmaterialId,

                MaterialCode: editCutlistmaterialFormData.MaterialCode,
                MaterialDescription: MaterialDescriptionCutlist,
                UnitOfMeasure: UnitOfMeasure,
                CutValue: editCutlistmaterialFormData.CutValue,
                CutValueQty: editCutlistmaterialFormData.CutValueQty,
                ReasonCode: editCutlistmaterialFormData.ReasonCode,
                ReasonCodeDescription: ReasonCodeCutlistText,

            }

            const newObjects = [...Cutlistmaterial];

            const index = Cutlistmaterial.findIndex((cutlistmaterial) => cutlistmaterial.id === editCutlistmaterialId);

            newObjects[index] = EditObject

            SetCutlistmaterial(newObjects);
            setEditCutlistmaterialId(null);

            JQuery("#Invalid_Status_MaterialCodeCut").hide();
            JQuery("#Inactive_Status_MaterialCodeCut").hide();
            JQuery("#TableMaterialCodeCutlist").removeAttr("style");
            JQuery("#TableMaterialCodeCutlist").css({ "width": "10rem" });

            ClearCutlistErrorLebels();
        }
        else {

            if (!materialcode_regex.test(MaterialCodeCutlist)) {

                JQuery("#Invalid_MaterialCodeCut").show();
                JQuery("#Invalid_Status_MaterialCodeCut").hide();
                JQuery("#Inactive_Status_MaterialCodeCut").hide();

                JQuery("#TableMaterialCodeCutlist").css("border", "1px solid red");
            }

            if (MaterialDescriptionCutlist === "") {
                JQuery("#Invalid_MaterialDescriptionCut").show();
                JQuery("#TableMaterialDescriptionCutlist").css("border", "1px solid red");
            }

            if (CutValue === "") {

                JQuery("#Invalid_CutValue").show();
                JQuery("#TableCutValue").css("border", "1px solid red");
            }

            if (UnitOfMeasure === "") {
                JQuery("#Invalid_UnitOfMeasureCut").show();
                JQuery("#TableUnitOfMeasureCut").css("border", "1px solid red");
            }

            if (CutValueQty === "") {

                JQuery("#Invalid_CutValueQty").show();
                JQuery("#TableCutValueQty").css("border", "1px solid red");

            }
            if (ReasonCodeCutlist === "") {
                JQuery("#Invalid_ReasonCodeCutlist").show();
                JQuery("#ReasonCodeCutlist").css("border", "1px solid red");
            }


        }
    }

    /* Remove*/

    const handelRemoveCutlistmaterialClick = (CutlistId) => {
        const newObjects = [...Cutlistmaterial];

        const index = Cutlistmaterial.findIndex((cutlistmaterial) => cutlistmaterial.id === CutlistId);

        newObjects.splice(index, 1);

        SetCutlistmaterial(newObjects);

    }

    /* cancel*/


    const handelCancelCutlistmaterialClick = () => {

        setEditCutlistmaterialId(null);

    }

    const ClearErrorLebels = () => {

        JQuery("#Invalid_SalesChannel").hide();
        JQuery("#SalesChannel").removeAttr("style");


        JQuery("#Invalid_SourceLocation").hide();
        JQuery("#Invalid_SourceLocationSite").hide();
        JQuery("#SourceLocation").removeAttr("style");

        JQuery("#Invalid_Validfromdate").hide();
        JQuery("#Validfromdate").removeAttr("style");

        JQuery("#Invalid_Validtodate").hide();
        JQuery("#Invalid_Validtodate_from").hide();
        JQuery("#Validtodate").removeAttr("style");

        JQuery("#Invalid_Deliveryfromdate").hide();
        JQuery("#Invalid_EitherDelivery").hide();
        JQuery("#Deliveryfromdate").removeAttr("style");

        JQuery("#Invalid_Deliverytodate").hide();
        JQuery("#Invalid_Deliverytodate_from").hide();
        JQuery("#Deliverytodate").removeAttr("style");

        JQuery("#Invalid_LoadDateFromDate").hide();
        JQuery("#Invalid_LoadDateFromDate").hide();
        JQuery("#LoadDateFromDate").removeAttr("style");

        JQuery("#Invalid_LoadDateToDate").hide();
        JQuery("#Invalid_EitherLoadDate").hide();
        JQuery("#LoadDateToDate").removeAttr("style");


        JQuery("#Invalid_ProtectPOET_Events").hide();
        JQuery("#ProtectPOET_Events").removeAttr("style");

        JQuery("#Invalid_TargetOrderType").hide();
        JQuery("#TargetOrderType").removeAttr("style");

    }



    // submit ICR form//

    const handleSubmitICRForm = () => {

        ClearErrorLebels();


        let Description = JQuery('#Description').val();

        let SalesChannel = JQuery('#SalesChannel').find(":selected").val();

        let SourceLocation = JQuery('#SourceLocation').val();


        let Validfromdate = JQuery('#Validfromdate').val();
        let Validtodate = JQuery('#Validtodate').val();

        let Deliveryfromdate = JQuery('#Deliveryfromdate').val();
        let Deliverytodate = JQuery('#Deliverytodate').val();

        let LoadDateFromDate = JQuery('#LoadDateFromDate').val();
        let LoadDateToDate = JQuery('#LoadDateToDate').val();

        let ProtectPOET_Events = JQuery('#ProtectPOET_Events').find(":selected").val();

        let TargetOrderType = JQuery('#TargetOrderType').find(":selected").val();
        let siteAccessValue = JQuery("#SourceLocation").val();

        let icrSite = temp_siteaccess.includes(siteAccessValue);




        //Sublist

        let sublistdatatype = [];

        for (let i = 0; i < Sublistmaterial.length; i++) {

            let temp_sublistdatatypes = {
                "materialCodeForm": Sublistmaterial[i].MaterialCodeFrom,
                "materialCodeFromDesc": Sublistmaterial[i].MaterialDescriptionFrom,
                "unitOfMeasureForm": Sublistmaterial[i].UnitOfMeasureFrom,
                "materialCodeTo": Sublistmaterial[i].MaterialCodeTo,
                "materialCodeToDesc": Sublistmaterial[i].MaterialDescriptionTo,
                "unitOfMeasureTo": Sublistmaterial[i].UnitOfMeasureTo,
                "reasonCode": Sublistmaterial[i].ReasonCode
            }

            sublistdatatype.push(temp_sublistdatatypes);

        }

        let temp1_sublistdatatypes = [];

        for (let i = 0; i < Sublistmaterial.length; i++) {

            temp1_sublistdatatypes = sublistdatatype.reduce((values, value) => {

                values.push({
                    materialCodeForm: value.materialCodeForm,
                    materialCodeFromDesc: value.materialCodeFromDesc,
                    unitOfMeasureForm: value.unitOfMeasureForm,
                    materialCodeTo: value.materialCodeTo,
                    materialCodeToDesc: value.materialCodeToDesc,
                    unitOfMeasureTo: value.unitOfMeasureTo,
                    reasonCode: value.reasonCode
                });

                return values;
            }, []);

        }

        //Alloactionlist
        let alloatDetailDataTypes = []

        for (let i = 0; i < Allocationlistmaterial.length; i++) {

            let temp_alloatDetailDataTypes = {
                "materialCodeAlloat": Allocationlistmaterial[i].MaterialCode,
                "materialCodeDescAlloat": Allocationlistmaterial[i].MaterialDescription,
                "unitOdMeasureAlloat": Allocationlistmaterial[i].UnitOfMeasure,
                "minQtyCap": Allocationlistmaterial[i].MinQtyCap,
                "maxQtyCap": Allocationlistmaterial[i].MaxQtyCap,
                "reasonCodeAlloat": Allocationlistmaterial[i].ReasonCode
            }

            alloatDetailDataTypes.push(temp_alloatDetailDataTypes);

        }

        let temp1_alloatDetailDataTypes = []
        for (let i = 0; i < Allocationlistmaterial.length; i++) {
            temp1_alloatDetailDataTypes = alloatDetailDataTypes.reduce((values, value) => {

                values.push({
                    materialCodeAlloat: value.materialCodeAlloat,
                    materialCodeDescAlloat: value.materialCodeDescAlloat,
                    unitOdMeasureAlloat: value.unitOdMeasureAlloat,
                    minQtyCap: value.minQtyCap,
                    maxQtyCap: value.maxQtyCap,
                    reasonCodeAlloat: value.reasonCodeAlloat
                });

                return values;
            }, []);
        }


        //Cutlist
        let ICRcutdataDetails = []


        for (let i = 0; i < Cutlistmaterial.length; i++) {

            let temp_ICRcutdataDetails = {
                "materialCodeICR": Cutlistmaterial[i].MaterialCode,
                "materialCodeDescICR": Cutlistmaterial[i].MaterialDescription,
                "unitOfMeasureICR": Cutlistmaterial[i].UnitOfMeasure,
                "cutValue": Cutlistmaterial[i].CutValue,
                "cutValueQorP": Cutlistmaterial[i].CutValueQty,
                "reasonCodeICR": Cutlistmaterial[i].ReasonCode

            }

            ICRcutdataDetails.push(temp_ICRcutdataDetails);

        }


        let temp1_ICRcutdataDetails = []
        for (let i = 0; i < Cutlistmaterial.length; i++) {
            temp1_ICRcutdataDetails = ICRcutdataDetails.reduce((values, value) => {

                values.push({
                    materialCodeICR: value.materialCodeICR,
                    materialCodeDescICR: value.materialCodeDescICR,
                    unitOfMeasureICR: value.unitOfMeasureICR,
                    cutValue: value.cutValue,
                    cutValueQorP: value.cutValueQorP,
                    reasonCodeICR: value.reasonCodeICR
                });

                return values;
            }, []);
        }



        //Addlist
        let ICRAdddataDetails = [];

        for (let i = 0; i < Addlistmaterial.length; i++) {

            let temp_ICRAdddataDetails = {

                "materialCodeICRAdd": Addlistmaterial[i].MaterialCode,
                "materialCodeDescAdd": Addlistmaterial[i].MaterialDescription,
                "unitOfMeasureICRAdd": Addlistmaterial[i].UnitOfMeasure,
                "addValue": Addlistmaterial[i].AddValue,
                "addValueQorP": Addlistmaterial[i].AddValueQty,
                "reasonCodeICRAdd": Addlistmaterial[i].ReasonCode

            }

            ICRAdddataDetails.push(temp_ICRAdddataDetails);

        }

        let temp1_ICRAdddataDetails = []
        for (let i = 0; i < Addlistmaterial.length; i++) {
            temp1_ICRAdddataDetails = ICRAdddataDetails.reduce((values, value) => {

                values.push({
                    materialCodeICRAdd: value.materialCodeICRAdd,
                    materialCodeDescAdd: value.materialCodeDescAdd,
                    unitOfMeasureICRAdd: value.unitOfMeasureICRAdd,
                    addValue: value.addValue,
                    addValueQorP: value.addValueQorP,
                    reasonCodeICRAdd: value.reasonCodeICRAdd
                });

                return values;
            }, []);
        }

        const token = getToken();
        const headers = {
            "accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }


        if (ICR_AdvancedValidation === false) {


            let ICRSubmitData = {


                "requestorGpid": temp_gpid,
                "requestorEmailId": temp_emailid,


                "requestType": "ICR",
                "component": "Webapp",


                "description": Description,
                "sourceLocation": SourceLocation,
                "validDateFrom": Validfromdate,
                "validDateTo": Validtodate,
                "deliveryDateFrom": Deliveryfromdate,
                "deliveryDateTo": Deliverytodate,
                "loadDateFrom": LoadDateFromDate,
                "loadDateTo": LoadDateToDate,
                "protectPOETEvents": ProtectPOET_Events,
                "targetOrderType": TargetOrderType,
                "channel": SalesChannel,


                "subListTypes": temp1_sublistdatatypes,
                "alloatDetailTypes": temp1_alloatDetailDataTypes,
                "icRcutDetails": temp1_ICRcutdataDetails,
                "icrAddDetails": temp1_ICRAdddataDetails

            }

            if (temp_role === Constants.roleSite) {
                icrSite = temp_siteaccess.includes(siteAccessValue);
            }
            else {
                icrSite = true;
            }

            if (SalesChannel !== "" && SourceLocation.length === 4 && icrSite === true && Validfromdate !== "" && Validtodate !== "" &&


                ((Deliveryfromdate !== "" && Deliverytodate !== "") || (LoadDateFromDate !== "" && LoadDateToDate !== "")) &&

                ProtectPOET_Events !== "" && TargetOrderType !== "" &&


                (Validtodate >= Validfromdate) &&
                ((Deliverytodate >= Deliveryfromdate) || (LoadDateToDate >= LoadDateFromDate)) &&

                (Sublistmaterial.length > 0 || Allocationlistmaterial.length > 0 ||
                    Cutlistmaterial.length > 0 || Addlistmaterial.length > 0
                )


            ) {

                setShow(true);

                JQuery.ajax({

                    url: hostUrl + "/icrForm/sendDetails",

                    type: 'POST',

                    dataType: 'json',

                    CORS: true,

                    data: JSON.stringify(ICRSubmitData),

                    contentType: 'application/json',

                    headers: headers,

                    crossDomain: true,

                    success: function (response) {

                        JQuery('.modal-title').html("<strong class='text-success'><i class='fa fa-check-circle'></i> Successfully Submitted </strong>");


                        JQuery(".modal-body").html("<p>Request has been submitted successfully. SAP updates in progress. Notification will be sent on completion.</p> \n <p>Your Request ID is #" + response.response[1] + "</p>");

                        setInterval(setShow(true), 1500);

                        sessionStorage.setItem("windowload", true);

                    },

                    error: function (xhr, textStatus) {

                        JQuery('.modal-title').html("<strong class='text-danger'><i class='fa fa-exclamation-circle'></i> Error !!! </strong>");

                        JQuery(".modal-body").html("<p>The data is not submitted<p>");

                        setInterval(setShow(true), 1500);

                    }

                });

                ClearErrorLebels();

            }

            else {

                if (SalesChannel === "") {
                    JQuery("#Invalid_SalesChannel").show();
                    JQuery("#SalesChannel").css("border", "1px solid red");

                }
                else {

                    JQuery("#Invalid_SalesChannel").hide();
                    JQuery("#SalesChannel").removeAttr("style");

                }

                if (icrSite === false) {
                    JQuery("#Invalid_SourceLocationSite").show();
                    JQuery("#SourceLocation").css("border", "1px solid red");
                }
                else {
                    JQuery("#Invalid_SourceLocationSite").hide();
                    JQuery("#SourceLocation").removeAttr("style");
                }

                if (SourceLocation.length !== 4) {
                    JQuery("#Invalid_SourceLocation").show();
                    JQuery("#SourceLocation").css("border", "1px solid red");
                    JQuery("#Invalid_SourceLocationSite").hide();

                }
                else {
                    JQuery("#Invalid_SourceLocation").hide();
                    JQuery("#SourceLocation").removeAttr("style");
                }


                if (Validfromdate === "") {
                    JQuery("#Invalid_Validfromdate").show();
                    JQuery("#Validfromdate").css("border", "1px solid red");

                }
                else {

                    JQuery("#Invalid_Validfromdate").hide();
                    JQuery("#Validfromdate").removeAttr("style");

                }

                if (Validtodate === "") {
                    JQuery("#Invalid_Validtodate").show();
                    JQuery("#Validtodate").css("border", "1px solid red");
                }

                else {

                    JQuery("#Invalid_Validtodate").hide();
                    JQuery("#Validtodate").removeAttr("style");

                }


                if (Validfromdate !== "" && Validtodate !== "") {

                    if (Validtodate < Validfromdate) {

                        JQuery("#Invalid_Validtodate_from").show();
                        JQuery("#Validtodate").css("border", "1px solid red");

                    }
                    else {

                        JQuery("#Invalid_Validtodate_from").hide();
                        JQuery("#Validtodate").removeAttr("style");

                    }
                }


                if (Deliveryfromdate === "" && Deliverytodate === "" && LoadDateFromDate === "" && LoadDateToDate === "") {

                    JQuery("#Invalid_EitherDelivery").show();
                    JQuery("#Deliveryfromdate").css("border", "1px solid red");

                    JQuery("#Invalid_EitherLoadDate").show();
                    JQuery("#LoadDateFromDate").css("border", "1px solid red");
                }
                else {
                    JQuery("#Invalid_EitherDelivery").hide();
                    JQuery("#Deliveryfromdate").removeAttr("style");

                    JQuery("#Invalid_EitherLoadDate").hide();
                    JQuery("#LoadDateFromDate").removeAttr("style");

                    if (Deliveryfromdate !== "" || Deliverytodate !== "") {

                        if (Deliveryfromdate === "") {
                            JQuery("#Invalid_Deliveryfromdate").show();
                            JQuery("#Deliveryfromdate").css("border", "1px solid red");
                        }

                        else {

                            JQuery("#Invalid_Deliveryfromdate").hide();
                            JQuery("#Deliveryfromdate").removeAttr("style");

                        }

                        if (Deliverytodate === "") {
                            JQuery("#Invalid_Deliverytodate").show();
                            JQuery("#Deliverytodate").css("border", "1px solid red");

                        }
                        else {

                            JQuery("#Invalid_Deliverytodate").hide();
                            JQuery("#Deliverytodate").removeAttr("style");
                        }



                        if (Deliveryfromdate !== "" && Deliverytodate !== "") {

                            if (Deliverytodate < Deliveryfromdate) {

                                JQuery("#Invalid_Deliverytodate_from").show();
                                JQuery("#Deliverytodate").css("border", "1px solid red");

                            }
                            else {

                                JQuery("#Invalid_Deliverytodate_from").hide();
                                JQuery("#Deliverytodate").removeAttr("style");
                            }
                        }


                    }

                    if (LoadDateFromDate !== "" || LoadDateToDate !== "") {

                        if (LoadDateFromDate === "") {
                            JQuery("#Invalid_LoadDateFromDate").show();
                            JQuery("#LoadDateFromDate").css("border", "1px solid red");
                        }

                        else {

                            JQuery("#Invalid_LoadDateFromDate").hide();
                            JQuery("#LoadDateFromDate").removeAttr("style");

                        }

                        if (LoadDateToDate === "") {
                            JQuery("#Invalid_LoadDateToDate").show();
                            JQuery("#LoadDateToDate").css("border", "1px solid red");

                        }
                        else {

                            JQuery("#Invalid_LoadDateToDate").hide();
                            JQuery("#LoadDateToDate").removeAttr("style");
                        }



                        if (LoadDateFromDate !== "" && LoadDateToDate !== "") {

                            if (LoadDateToDate < LoadDateFromDate) {

                                JQuery("#Invalid_LoadDateToDate_from").show();
                                JQuery("#LoadDateToDate").css("border", "1px solid red");

                            }
                            else {

                                JQuery("#Invalid_LoadDateToDate_from").hide();
                                JQuery("#LoadDateToDate").removeAttr("style");
                            }
                        }


                    }



                }



                if (ProtectPOET_Events === "") {
                    JQuery("#Invalid_ProtectPOET_Events").show();
                    JQuery("#ProtectPOET_Events").css("border", "1px solid red");

                }
                else {

                    JQuery("#Invalid_ProtectPOET_Events").hide();
                    JQuery("#ProtectPOET_Events").removeAttr("style");

                }


                if (TargetOrderType === "") {
                    JQuery("#Invalid_TargetOrderType").show();
                    JQuery("#TargetOrderType").css("border", "1px solid red");

                }
                else {
                    JQuery("#Invalid_TargetOrderType").hide();
                    JQuery("#TargetOrderType").removeAttr("style");


                }



                if (Sublistmaterial.length === 0 && Allocationlistmaterial.length === 0 &&
                    Cutlistmaterial.length === 0 && Addlistmaterial.length === 0) {


                    setShow(true);
                    setTimeout(function () {
                        JQuery('.modal-title').html("<strong class='text-danger'><i class='fa fa-exclamation-circle'></i> Error !!! </strong>");
                        JQuery(".modal-body").html("<p>Please perform at least one operation.</p>");
                    }, 0);

                }

                sessionStorage.setItem("windowload", false);


            }

        }

        else if (ICR_AdvancedValidation === true) {


            let TradeSegment = '';
            let CustomersExclude = '';
            let CustomersInclude = '';
            let Hierarchy = '';
            let SoldToParty = '';
            let OrderReporting = '';

            let DestLocationExclude = ''
            let DestLocationInclude = ''

            let purchaseGroup = '';
            let STO = '';
            let stoType = '';

            let Advanced_comment = '';

            let Encoded_file = '';
            let file_type = '';



            if (TargetOrderType === "SO") {

                TradeSegment = tradesegment;
                CustomersExclude = customerExclude;
                CustomersInclude = customerInclude;
                Hierarchy = hierarchy;
                SoldToParty = soldtoparty;
                OrderReporting = orderReporting;

                Advanced_comment = commenttext;
                Encoded_file = EncodedFile;
                file_type = FileType;
            }
            else if (TargetOrderType === "STO") {

                DestLocationExclude = destLocationExclude;
                DestLocationInclude = destLocationInclude;
                purchaseGroup = PurchaseGroup;
                STO = STOnumber;
                stoType = STOType;

                Advanced_comment = commenttextSTO;
                Encoded_file = EncodedFile;
                file_type = FileType;
            }



            let ICR_AdvancedSubmitData =
            {
                "requestorGpidAdv": temp_gpid,

                "requestTypeAdv": "ICR-Advanced Functionality",
                "componentAdv": "Webapp",

                "descriptionAdv": Description,
                "sourceLocationAdv": SourceLocation,

                "validDateFromAdv": Validfromdate,
                "validDateToAdv": Validtodate,
                "deliveryDateFromIcrAdv": Deliveryfromdate,
                "deliveryDateToIrcAdv": Deliverytodate,
                "loadDateFromIcrAdv": LoadDateFromDate,
                "loadDateToIcrAdv": LoadDateToDate,

                "protectPoetEvents": ProtectPOET_Events,
                "targetOrderType": TargetOrderType,
                "channelIcrAdv": SalesChannel,

                "requestorEmailId": temp_emailid,

                "tradeSegment": TradeSegment,
                "customersExclude": CustomersExclude,
                "customersInclude": CustomersInclude,
                "hierarchy": Hierarchy,
                "soldToParty": SoldToParty,
                "orderReporting": OrderReporting,

                "destLocationExclude": DestLocationExclude,
                "destLocationInclude": DestLocationInclude,
                "purchaseGroup": purchaseGroup,
                "sto": STO,
                "stoType": stoType,

                "comments": Advanced_comment,

                "encodedFile": Encoded_file,
                "encodedFileType": file_type,
                "siteAccessListAdv": temp_role === Constants.roleSite ? temp_siteaccess : "",
                "subListTypesIcrAdv": temp1_sublistdatatypes,
                "alloatDetailTypesIcrAdv": temp1_alloatDetailDataTypes,
                "icrcutDetails": temp1_ICRcutdataDetails,
                "icrAddDetails": temp1_ICRAdddataDetails,

            }
            let SubmitAdvancedReq = 1;
            if (temp_role === Constants.roleSite) {
                icrSite = temp_siteaccess.includes(siteAccessValue);
            }
            else {
                icrSite = true;
            }
            if (SalesChannel !== "" && SourceLocation.length === 4 && icrSite === true
                && TargetOrderType !== "" && (Validfromdate !== "" || Deliveryfromdate !== "" || LoadDateFromDate !== ""
                    || Validtodate !== "" || Deliverytodate !== "" || LoadDateToDate !== "")

            ) {
                if (Validfromdate !== "" && Validtodate === "") {

                    JQuery("#Invalid_Validtodate").show();
                    JQuery("#Validtodate").css("border", "1px solid red");

                    SubmitAdvancedReq = 0;
                }

                if (Validfromdate === "" && Validtodate !== "") {

                    JQuery("#Invalid_Validfromdate").show();
                    JQuery("#Validfromdate").css("border", "1px solid red");

                    SubmitAdvancedReq = 0;
                }



                if (Deliveryfromdate !== "" && Deliverytodate === "") {

                    JQuery("#Invalid_Deliverytodate").show();
                    JQuery("#Deliverytodate").css("border", "1px solid red");
                    SubmitAdvancedReq = 0;
                }

                if (Deliveryfromdate === "" && Deliverytodate !== "") {

                    JQuery("#Invalid_Deliveryfromdate").show();
                    JQuery("#Deliveryfromdate").css("border", "1px solid red");
                    SubmitAdvancedReq = 0;
                }



                if (LoadDateFromDate !== "" && LoadDateToDate === "") {

                    JQuery("#Invalid_LoadDateToDate").show();
                    JQuery("#LoadDateToDate").css("border", "1px solid red");
                    SubmitAdvancedReq = 0;
                }
                if (LoadDateFromDate === "" && LoadDateToDate !== "") {

                    JQuery("#Invalid_LoadDateFromDate").show();
                    JQuery("#LoadDateFromDate").css("border", "1px solid red");
                    SubmitAdvancedReq = 0;
                }


            }
            if (SalesChannel !== "" && SourceLocation.length === 4
                && TargetOrderType !== "" && SubmitAdvancedReq === 1 && icrSite === true

            ) {


                setShow(true);

                JQuery.ajax({

                    url: hostUrl + "/icrForm/sendIcrAdvDetails",

                    type: 'POST',

                    dataType: 'json',

                    CORS: true,

                    data: JSON.stringify(ICR_AdvancedSubmitData),

                    contentType: 'application/json',

                    headers: headers,

                    crossDomain: true,

                    success: function (response) {
                        JQuery('.modal-title').html("<strong class='text-success'><i class='fa fa-check-circle'></i> Successfully Submitted </strong>");
                        JQuery(".modal-body").html("<p>Request has been submitted successfully and sent to CSR for manual handling.</p> \n <p>Your Request ID is #" + response.response[1] + "</p>");


                        setInterval(setShow(true), 1500);

                        SetTradeSegment();
                        SetCustomerExclude();
                        SetCustomerInclude();
                        SetHierarchy();
                        SetSoldToParty();
                        SetOrderReporting();
                        setCommentText();

                        SetDestLocationInclude();
                        SetDestLocationExclude();
                        SetPurchaseGroup();
                        SetSTOnumber();
                        SetSTOType();
                        setCommentTextSTO();

                        SetEncodedFile();
                        SetFileType();

                        SetICR_AdvancedValidation(false);

                        sessionStorage.setItem("windowload", true);




                    },

                    error: function (xhr, textStatus) {

                        JQuery('.modal-title').html("<strong class='text-danger'><i class='fa fa-exclamation-circle'></i> Error !!! </strong>");

                        JQuery(".modal-body").html("<p>The data is not submitted<p>");

                        setInterval(setShow(true), 1500);

                    }

                });

                ClearErrorLebels();

            }


            else {

                if (SalesChannel === "") {
                    JQuery("#Invalid_SalesChannel").show();
                    JQuery("#SalesChannel").css("border", "1px solid red");

                }
                else {

                    JQuery("#Invalid_SalesChannel").hide();
                    JQuery("#SalesChannel").removeAttr("style");

                }
                if (icrSite === false) {
                    JQuery("#Invalid_SourceLocationSite").show();
                    JQuery("#SourceLocation").css("border", "1px solid red");
                }
                else {
                    JQuery("#Invalid_SourceLocationSite").hide();
                    JQuery("#SourceLocation").removeAttr("style");
                }
                if (SourceLocation.length !== 4) {
                    JQuery("#Invalid_SourceLocation").show();
                    JQuery("#SourceLocation").css("border", "1px solid red");
                    JQuery("#Invalid_SourceLocationSite").hide();

                }
                else {
                    JQuery("#Invalid_SourceLocation").hide();
                    JQuery("#SourceLocation").removeAttr("style");
                }

                if (Validfromdate !== "" && Validtodate !== "") {

                    if (Validtodate < Validfromdate) {

                        JQuery("#Invalid_Validtodate_from").show();
                        JQuery("#Validtodate").css("border", "1px solid red");

                    }
                    else {

                        JQuery("#Invalid_Validtodate_from").hide();
                        JQuery("#Validtodate").removeAttr("style");

                    }
                }

                if (Deliveryfromdate !== "" && Deliverytodate !== "") {

                    if (Deliverytodate < Deliveryfromdate) {

                        JQuery("#Invalid_Deliverytodate_from").show();
                        JQuery("#Deliverytodate").css("border", "1px solid red");

                    }
                    else {

                        JQuery("#Invalid_Deliverytodate_from").hide();
                        JQuery("#Deliverytodate").removeAttr("style");
                    }
                }

                if (LoadDateFromDate !== "" && LoadDateToDate !== "") {

                    if (LoadDateToDate < LoadDateFromDate) {

                        JQuery("#Invalid_LoadDateToDate_from").show();
                        JQuery("#LoadDateToDate").css("border", "1px solid red");

                    }
                    else {

                        JQuery("#Invalid_LoadDateToDate_from").hide();
                        JQuery("#LoadDateToDate").removeAttr("style");
                    }
                }


                if (TargetOrderType === "") {
                    JQuery("#Invalid_TargetOrderType").show();
                    JQuery("#TargetOrderType").css("border", "1px solid red");

                }
                else {
                    JQuery("#Invalid_TargetOrderType").hide();
                    JQuery("#TargetOrderType").removeAttr("style");


                }

                sessionStorage.setItem("windowload", false);


            }

        }

    }
    const handleOk = () => {
        setShowConfirmPopUp(false);
        setShow(false);
        handleSubmitICRForm();

    }
    //CHECK Confirmation validation
    const handleConfirmation = () => {
        //Check Date deliveryDate-1,2
        let deliveryDate = JQuery('#Deliveryfromdate').val();
        const TimeZone = 'America/Chicago';
        let twoDaysBeforeDeliveryDate = moment(deliveryDate).subtract(2, 'day').format('DD/MM/YYYY')
        var compareDate = moment(moment.tz(moment(), TimeZone).format('DD/MM/YYYY'), "DD/MM/YYYY");
        var startDate = moment(twoDaysBeforeDeliveryDate, "DD/MM/YYYY");
        var endDate = moment(deliveryDate, "DD/MM/YYYY");
        const isValidTime = !compareDate.isBetween(startDate, endDate);
        //Get CST Time in hours
        const CurrentTime = parseInt(moment.tz(moment(), TimeZone).format('HH'));
        console.log("Current CST Time:::", moment.tz(moment(), TimeZone).format('DD/MM/YYYY HH:mm'))
        console.log("isValidTime:::", isValidTime)
        //Check Final validation 
        // if (isValidTime) {
        //     handleSubmitICRForm();
        // } else {
        //     if (CurrentTime < 15) {
        //         handleSubmitICRForm();
        //     } else {
                setShowConfirmPopUp(true);
                setTimeout(setShow(true), 1500);
                setTimeout(function () {
                    // if (CurrentTime === 15) {
                        JQuery('.modal-title').html("<strong class='text-warning'><i class='fas fa-exclamation-triangle' style='color:#ffc107;'></i> Warning</strong>");
                        JQuery(".modal-body").html("<p> Please consider DCs release time & Tcomm to make sure all the requests are processed in time.</p>");
                    // } else if (CurrentTime >= 16) {
                    //     JQuery('.modal-title').html("<strong class='text-warning'><i class='fas fa-exclamation-triangle' style='color:#ffc107;'></i> Warning</strong>");
                    //     JQuery(".modal-body").html("<p> Please consider DCs release time & Tcomm to make sure all the requests are processed in time.</p>");
                    // } else {
                        // setShowConfirmPopUp(false);
                        // setShow(false);
                        // handleSubmitICRForm();
                    // }
                }, 0);
        //     }
        // }
    }
    // Check Edit validation Material //
    const checkEditValidationMaterial = (val) => {
        let materialcode = val

        const token = getToken();
        const headers = {
            "accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }

        JQuery.ajax({
            url: hostUrl + "/orderModification/materialValidation",
            type: 'POST',
            dataType: 'json',
            data: JSON.stringify({
                "materialNumber": materialcode
            }),
            CORS: true,
            contentType: 'application/json',
            secure: true,
            headers: headers,
            crossDomain: true,
            success: (res) => {


                let MaterialCodeMessage = res.errormessage;

                if (MaterialCodeMessage === "Material Exists") {

                    setEditvalidmaterialCode(true);

                }
                else if (MaterialCodeMessage === "Material is not active") {

                    setEditvalidmaterialCode(false);
                }
                else {

                    setEditvalidmaterialCode(false);

                }

            },
            error: function (jqHXR, textStatus, errorThrown) {


            }

        });

    }


    // access nagiavtion//
    const navigate = useNavigate();
    useEffect(() => {


        if (temp_role === Constants.roleundefined || temp_role === Constants.roleCommercial) {
            navigate('/accessdenied');
        }

    }, []);



    return (
        <div className="order-font container-fluid">


            <Card.Header style={{ backgroundColor: "#1C1B83" }}>

                <div className='row'>

                    <div className='col-md-2' style={{ backgroundColor: "white" }}>

                        <img src={process.env.PUBLIC_URL + "/PepsicoLogo.png"} width="140" alt="logo" style={{}} />

                    </div>

                    <div className='col-md-6 ' style={{ color: "white", backgroundColor: "#1C1B83" }}>

                        <h5 style={{ marginTop: ".5rem", textAlign: "left" }}><b>ICR Creation Form</b></h5>

                    </div>

                    <div className='col-md-2'>

                        <a href='/orders/home'>

                            <i class="fa fa-home" aria-hidden="true" style={{ color: 'white', marginLeft: '9vw', fontSize: '2.3rem', marginTop: ".3rem" }}></i>

                        </a>

                    </div>

                    <div className='col-md-2 ' style={{ color: "white", backgroundColor: "white" }}>

                        <img src={process.env.PUBLIC_URL + "/LogoImages.png"} width="140" alt="logo" style={{ width: "11rem" }} />

                    </div>




                </div>




            </Card.Header>




            <Card.Body >
                <div className='col-md-12 mt-2' style={{ color: "Black", textAlign: 'center' }}>
                    <h4 style={{ fontWeight: "bold" }}>ICR Parameters</h4>
                </div>

                <div className="row">
                    <div className='col-md-1' style={{ background: "#1C1B83", height: "2.5rem", marginLeft: "0.75rem" }}>
                        <Form.Label style={{ color: "white", marginTop: ".5rem", fontWeight: "bold", fontSize: 14 }}>Description</Form.Label>

                    </div>
                    <div className='col-md-3'>
                        <Form.Control type="text" id='Description' maxLength="30" />

                    </div>
                </div>

                <div className="row" style={{ marginTop: "1vw" }}>



                    <Table bordered responsive hover className="square border border-dark">
                        <thead>
                            <th style={{ backgroundColor: "#1C1B83", textAlign: 'center', width: "10rem", fontSize: 14, verticalAlign: "middle" }}>
                                <Form.Label style={{ color: "White", fontWeight: "bold" }}>Sales Channel<img src={process.env.PUBLIC_URL + "/redasterisk1.png"} style={{ width: "0.6rem" }} /> </Form.Label>
                            </th>


                            <th style={{ backgroundColor: "#1C1B83", textAlign: 'center', width: "10rem", fontSize: 14, verticalAlign: "middle" }}>
                                <Form.Label style={{ color: "White", fontWeight: "bold" }}>Source Location<img src={process.env.PUBLIC_URL + "/redasterisk1.png"} style={{ width: "0.6rem" }} /></Form.Label>
                            </th>


                            <th style={{ backgroundColor: "#1C1B83", textAlign: 'center', width: "10rem", fontSize: 14, verticalAlign: "middle" }}>
                                <Form.Label style={{ color: "White", fontWeight: "bold" }}>Valid Date (From)<img src={process.env.PUBLIC_URL + "/redasterisk1.png"} style={{ width: "0.6rem" }} /></Form.Label>
                            </th>
                            <th style={{ backgroundColor: "#1C1B83", textAlign: 'center', width: "10rem", fontSize: 14, verticalAlign: "middle" }}>
                                <Form.Label style={{ color: "White", fontWeight: "bold" }}>Valid Date (To) <img src={process.env.PUBLIC_URL + "/redasterisk1.png"} style={{ width: "0.6rem" }} /></Form.Label>
                            </th>
                            <th style={{ backgroundColor: "#1C1B83", textAlign: 'center', width: "9rem", fontSize: 14, verticalAlign: "middle" }}>
                                <Form.Label style={{ color: "White", fontWeight: "bold" }}>Delivery Date (From) <img src={process.env.PUBLIC_URL + "/redasterisk1.png"} style={{ width: "0.6rem" }} /></Form.Label>
                            </th>
                            <th style={{ backgroundColor: "#1C1B83", textAlign: 'center', width: "9rem", fontSize: 14, verticalAlign: "middle" }}>
                                <Form.Label style={{ color: "White", fontWeight: "bold" }}>Delivery Date (To) </Form.Label>
                            </th>

                            <th style={{ backgroundColor: "#1C1B83", textAlign: 'center', width: "9rem", fontSize: 14, verticalAlign: "middle" }}>
                                <Form.Label style={{ color: "White", fontWeight: "bold" }}>Load Date (From) <img src={process.env.PUBLIC_URL + "/redasterisk1.png"} style={{ width: "0.6rem" }} /></Form.Label>
                            </th>

                            <th style={{ backgroundColor: "#1C1B83", textAlign: 'center', width: "9rem", fontSize: 14, verticalAlign: "middle" }}>
                                <Form.Label style={{ color: "White", fontWeight: "bold" }}>Load Date (To) </Form.Label>
                            </th>

                            <th style={{ backgroundColor: "#1C1B83", textAlign: 'center', width: "9rem", fontSize: 14, verticalAlign: "middle" }}>
                                <Form.Label style={{ color: "White", fontWeight: "bold" }}>Protect POET Events<img src={process.env.PUBLIC_URL + "/redasterisk1.png"} style={{ width: "0.6rem" }} /></Form.Label>
                            </th>

                            <th style={{ backgroundColor: "#1C1B83", textAlign: 'center', width: "10rem", fontSize: 14, verticalAlign: "middle" }}>

                                <Form.Label style={{ color: "White", fontWeight: "bold" }}>Target Order Type<img src={process.env.PUBLIC_URL + "/redasterisk1.png"} style={{ width: "0.6rem" }} /> </Form.Label>
                            </th>
                        </thead>
                        <tbody>
                            <tr>

                                <td >
                                    <Form.Select aria-label="Default select example" id='SalesChannel'>
                                        <option selected value="" >--Select--</option>
                                        <option value="GES">GES</option>
                                        <option value="IMO">IMO</option>
                                        <option value="Retail">Retail</option>
                                    </Form.Select>

                                    <span className="help-block text-danger" id="Invalid_SalesChannel" style={{ display: "None", float: "left" }}> Please select Sales Channel</span>

                                </td>

                                <td >

                                    <Form.Control type="text" id='SourceLocation' idx="restrictedSorceLocation" onChange={numericSourceLocation} />

                                    <span className="help-block text-danger" id="Invalid_SourceLocation" style={{ display: "None", float: "left" }}>Please enter 4 digit Source Location</span>
                                    <span className="help-block text-danger" id="Invalid_SourceLocationSite" style={{ display: "None", float: "left" }}>User is not authorized to submit request for this Source Location</span>
                                </td>


                                <td >

                                    <DatePicker className='form-control' id='Validfromdate' dateFormat="MM/dd/yyyy" onChange={(date) => { SetValidFromdate(date); }} selected={Validfromdate} minDate={startDate} placeholderText="MM/DD/YYYY" />
                                    <span className="help-block text-danger" id="Invalid_Validfromdate" style={{ display: "None", float: "left" }}>Please select Valid Date (From) </span>

                                </td>



                                <td >
                                    <DatePicker className='form-control' id='Validtodate' dateFormat="MM/dd/yyyy" onChange={(date) => { SetValidTodate(date); }} selected={Validtodate} minDate={Validfromdate} placeholderText="MM/DD/YYYY" />

                                    <span className="help-block text-danger" id="Invalid_Validtodate" style={{ display: "None", float: "left" }}>Please select Valid Date (To) </span>
                                    <span className="help-block text-danger" id="Invalid_Validtodate_from" style={{ display: "None", float: "left" }}>Valid Date (To) should be greater than or equal to Valid Date (From)  </span>
                                </td>



                                <td >

                                    <DatePicker className='form-control' id='Deliveryfromdate' dateFormat="MM/dd/yyyy" onChange={(date) => { SetDeliveryFromdate(date); }} selected={Deliveryfromdate} minDate={startDate} placeholderText="MM/DD/YYYY" />

                                    <span className="help-block text-danger" id="Invalid_Deliveryfromdate" style={{ display: "None", float: "left" }}>Please select Delivery Date (From)  </span>
                                    <span className="help-block text-danger" id="Invalid_EitherDelivery" style={{ display: "None", float: "left" }}>Please select either Delivery Date or Load Date</span>
                                </td>



                                <td >
                                    <DatePicker className='form-control' id='Deliverytodate' dateFormat="MM/dd/yyyy" onChange={(date) => { SetDeliveryTodate(date); }} selected={Deliverytodate} minDate={Deliveryfromdate} placeholderText="MM/DD/YYYY" />


                                    <span className="help-block text-danger" id="Invalid_Deliverytodate" style={{ display: "None", float: "left" }}>Please select Delivery Date (To) </span>
                                    <span className="help-block text-danger" id="Invalid_Deliverytodate_from" style={{ display: "None", float: "left" }}>Delivery Date (To) should be greater than or equal to Delivery Date (From) </span>
                                </td>

                                <td >
                                    <DatePicker className='form-control' id='LoadDateFromDate' dateFormat="MM/dd/yyyy" onChange={(date) => { SetLoadDateFromDate(date); }} selected={LoadDateFromDate} minDate={startDate} placeholderText="MM/DD/YYYY" />
                                    <span className="help-block text-danger" id="Invalid_LoadDateFromDate" style={{ display: "None", float: "left" }}>Please select Load Date (From)  </span>
                                    <span className="help-block text-danger" id="Invalid_EitherLoadDate" style={{ display: "None", float: "left" }}>Please select either Delivery Date or Load Date  </span>
                                </td>
                                <td >
                                    <DatePicker className='form-control' id='LoadDateToDate' dateFormat="MM/dd/yyyy" onChange={(date) => { SetLoadDateToDate(date); }} selected={LoadDateToDate} minDate={LoadDateFromDate} placeholderText="MM/DD/YYYY" />
                                    <span className="help-block text-danger" id="Invalid_LoadDateToDate" style={{ display: "None", float: "left" }}>Please select Load Date (To) </span>
                                    <span className="help-block text-danger" id="Invalid_LoadDateToDate_from" style={{ display: "None", float: "left" }}>Load Date (To) should be greater than or equal to Load Date (From) </span>
                                </td>



                                <td >
                                    <Form.Select aria-label="Default select example" id="ProtectPOET_Events" >
                                        <option selected value="">--Select--</option>
                                        <option value="Y">Y</option>
                                        <option value="N">N</option>

                                    </Form.Select>

                                    <span className="help-block text-danger" id="Invalid_ProtectPOET_Events" style={{ display: "None", float: "left" }}>Please select Protect POET Events </span>


                                </td>


                                <td>
                                    <Form.Select aria-label="Default select example" id='TargetOrderType'
                                        onChange={(e) => (handleAdvancedToggle(e))}
                                    >
                                        <option selected value="">--Select--</option>
                                        <option value="SO">Sales Order</option>
                                        <option value="STO">Stock Transfer</option>

                                    </Form.Select>
                                    <span className="help-block text-danger" id="Invalid_Advanced_TargetOrderType" style={{ display: "None", float: "left" }}> Please select Target Order Type </span>
                                    <span className="help-block text-danger" id="Invalid_TargetOrderType" style={{ display: "None", float: "left" }}> Please select Target Order Type</span>
                                </td>
                            </tr>

                        </tbody>
                    </Table>


                    <div className="row" style={{ marginTop: "1.5vw", display: "contents" }}>
                        <div className="col-md-7"></div>

                        <div className="col-md-2"></div>
                        <div className="col-md-3">
                            <Button variant="outline-light" style={{ marginRight: "1.2rem", backgroundColor: "#1C1B83", color: "white", float: "right" }} onClick={() => { ToggleAdvanced() }}>Advanced</Button>

                            {Advancedshow ? <ModalAdvancedcontent /> : null}
                            {AdvancedSTOshow ? <ModalAdvancedSTOcontent /> : null}

                        </div>
                    </div>
                    <div className="row" style={{ marginTop: "1vw" }}>
                        <div className="col-md-9"></div>

                        <div className="col-md-3">

                        </div>
                    </div>



                </div>


            </Card.Body>

            <Card>

                <Card.Body style={{ padding: "0vw" }}>
                    <div className='col-md-12 mt-2' style={{ color: "Black", textAlign: 'center' }}>
                    </div>




                    <form onSubmit={handleAddSublistmaterialFormSubmit}>
                        <div className="row" id='SubList' style={{ marginTop: "2vw" }} >
                            <div className="row" style={{ display: "contents" }}>

                                <Table responsive bordered hover className="square border border-dark">

                                    <thead>

                                        <tr style={{ backgroundColor: "#1C1B83", textAlign: 'center', fontWeight: "bold", color: "White" }}>
                                            <td colSpan="12">SUB LIST (STO or SO)</td>

                                        </tr>
                                        <tr>
                                            <th style={{ textAlign: 'center', width: "9rem", fontSize: 14, verticalAlign: "middle" }}>Material Code (From) </th>
                                            <th style={{ textAlign: 'center', width: "8rem", fontSize: 14, verticalAlign: "middle" }}>Material Description (From)</th>
                                            <th style={{ textAlign: 'center', width: "7rem", fontSize: 14, verticalAlign: "middle" }}>Unit of Measure (UOM) (From)</th>

                                            <th style={{ textAlign: 'center', width: "9rem", fontSize: 14, verticalAlign: "middle" }}>Material Code (To)</th>
                                            <th style={{ textAlign: 'center', width: "8rem", fontSize: 14, verticalAlign: "middle" }}>Material Description (To)</th>
                                            <th style={{ textAlign: 'center', width: "7rem", fontSize: 14, verticalAlign: "middle" }}>Unit of Measure (UOM) (To)</th>

                                            <th style={{ textAlign: 'center', width: "8rem", fontSize: 14, verticalAlign: "middle" }}>Reason Code </th>

                                            <th style={{ textAlign: 'center', width: "14rem", fontSize: 14, verticalAlign: "middle" }}>Actions</th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        {Sublistmaterial.map((material) =>

                                            <Fragment>

                                                {editSublistmaterialId === material.id ? <SublistEdit editSublistmaterialFormData={editSublistmaterialFormData}
                                                    handleEditSublistmaterialFormChange={handleEditSublistmaterialFormChange}
                                                    handelCancelSublistmaterialClick={handelCancelSublistmaterialClick}
                                                    handleEditSublistmaterialFormSubmit={handleEditSublistmaterialFormSubmit} />

                                                    : <SublistRead material={material}
                                                        handleEditSublistmaterialClick={handleEditSublistmaterialClick}
                                                        handelRemoveSublistmaterialClick={handelRemoveSublistmaterialClick} />
                                                }


                                            </Fragment>

                                        )}
                                        {
                                            showAddSublist &&
                                            <tr id='AddItemsSublist'>
                                                <td style={{ verticalAlign: "middle" }}><input type="text" style={{ width: "9rem" }}

                                                    id="TableMaterialCodeFromSublist"
                                                    name="MaterialCodeFrom"
                                                    required="required"
                                                    idx="restrictedDecimal"

                                                    onChange={(e) => { handleAddSublistmaterialFormChange(e); setMaterialDecription(e) }}

                                                />
                                                    <br />
                                                    <span className="help-block text-danger" id="Invalid_MaterialCodeFrom" style={{ display: "None", float: "left" }} >Please enter a 13 digit numeric Material Code (From)</span>
                                                    <span className="help-block text-danger" id="Invalid_Status_MaterialCodeFromSub" style={{ display: "None", float: "left" }}>The Material Code entered is invalid</span>
                                                    <span className="help-block text-danger" id="Inactive_Status_MaterialCodeFromSub" style={{ display: "None", float: "left" }}>The Material Code entered is inactive</span>

                                                </td>
                                                <td style={{ verticalAlign: "middle" }}><input type="text" style={{ width: "8rem" }}
                                                    id="TableMaterialDescriptionFromSublist"
                                                    name="MaterialDescriptionFrom"
                                                    required="required"

                                                    onChange={handleAddSublistmaterialFormChange}

                                                />
                                                    <br />
                                                    <span className="help-block text-danger" id="Invalid_MaterialDescriptionFrom" style={{ display: "None", float: "left" }}> Please enter Material Description</span>
                                                </td>
                                                <td style={{ verticalAlign: "middle" }}>

                                                    <select id="TableUnitOfMeasureFrom" name='UnitOfMeasureFrom' onChange={handleAddSublistmaterialFormChange} style={{ width: "7rem", height: "1.80rem" }}>
                                                        <option selected name="" value="" >--Select--</option>
                                                        <option name="CS" value="CS">CS</option>
                                                        <option name="EA" value="EA">EA</option>
                                                        <option name="DS" value="DS">DS</option>
                                                        <option name="PAC" value="PAC" >PAC</option>

                                                    </select>
                                                    <br />
                                                    <span className="help-block text-danger" id="Invalid_UnitOfMeasureFrom" style={{ display: "None" }} >Please select Unit of Measure (From)</span>

                                                </td>
                                                <td style={{ verticalAlign: "middle" }}><input type="text" style={{ width: "9rem" }}

                                                    id="TableMaterialCodeToSublist"
                                                    name="MaterialCodeTo"
                                                    required="required"
                                                    idx="restrictedDecimal"

                                                    onChange={(e) => { handleAddSublistmaterialFormChange(e); setMaterialDecription(e) }}
                                                />
                                                    <br />
                                                    <span className="help-block text-danger" id="Invalid_MaterialCodeTo" style={{ display: "None" }}  >Please enter a 13 digit numeric Material Code (To)</span>
                                                    <span className="help-block text-danger" id="Invalid_Status_MaterialCodeToSub" style={{ display: "None", float: "left" }}>The Material Code entered is invalid</span>
                                                    <span className="help-block text-danger" id="Inactive_Status_MaterialCodeToSub" style={{ display: "None", float: "left" }}>The Material Code entered is inactive</span>

                                                </td>
                                                <td style={{ verticalAlign: "middle" }}><input type="text" style={{ width: "8rem" }}
                                                    id="TableMaterialDescriptionToSublist"
                                                    name="MaterialDescriptionTo"
                                                    required="required"

                                                    onChange={handleAddSublistmaterialFormChange}

                                                />
                                                    <br />
                                                    <span className="help-block text-danger" id="Invalid_MaterialDescriptionTo" style={{ display: "None", float: "left" }}> Please enter Material Description (To)</span>
                                                </td>
                                                <td style={{ verticalAlign: "middle" }}>

                                                    <select id="TableUnitOfMeasureTo" name='UnitOfMeasureTo' onChange={handleAddSublistmaterialFormChange} style={{ width: "7rem", height: "1.80rem" }}>
                                                        <option selected name="" value="" >--Select--</option>
                                                        <option name="CS" value="CS">CS</option>
                                                        <option name="EA" value="EA">EA</option>
                                                        <option name="DS" value="DS">DS</option>
                                                        <option name="PAC" value="PAC">PAC</option>

                                                    </select>
                                                    <br />
                                                    <span className="help-block text-danger" id="Invalid_UnitOfMeasureTo" style={{ display: "None" }} >Please select Unit of Measure (To)</span>

                                                </td>


                                                <td style={{ verticalAlign: "middle" }}>

                                                    <select name='ReasonCode' id="ReasonCodeSublist" onChange={(e) => { handleAddSublistmaterialFormChange(e); }} style={{ width: "8rem", height: "1.80rem" }}>

                                                    </select>
                                                    <br />
                                                    <span className="help-block text-danger" id="Invalid_ReasonCodeSublist" style={{ display: "None" }}>Please select a Reason Code</span>

                                                </td>


                                                <td className='text-center'><Button variant="outline-light" onClick={handleAddSublistmaterialFormSubmit} style={{ width: "5rem", backgroundColor: "#009639", color: "white" }}>Add</Button></td>

                                            </tr>
                                        }

                                    </tbody>
                                </Table>

                            </div>
                            <div className="row" >
                                <div className="col-md-11" >

                                </div>
                                <div className="col-md-1" >

                                    <Button variant="outline-secondary" style={{ borderRadius: "2vw" }} onClick={() => { setShowAddSublist(!showAddSublist); getReasonCode() }}>+</Button>
                                    <span> Add </span>


                                </div>

                            </div>
                        </div>
                    </form>

                    <form onSubmit={handleAddAllocationlistmaterialFormSubmit}>
                        <div className="row" id='AllocationList' style={{ marginTop: "2vw" }}>
                            <div className="row" style={{ display: "contents" }}>

                                <Table responsive bordered hover className="square border border-dark">
                                    <thead>

                                        <tr style={{ backgroundColor: "#1C1B83", textAlign: 'center', fontWeight: "bold", color: "White" }}>


                                            <td colSpan="12">ALLOCATION LIST (STO or SO)</td>

                                        </tr>
                                        <tr>
                                            <th style={{ textAlign: 'center', width: "10rem", fontSize: 14, verticalAlign: "middle" }}>Material Code </th>
                                            <th style={{ textAlign: 'center', width: "10rem", fontSize: 14, verticalAlign: "middle" }}>Material Description</th>
                                            <th style={{ textAlign: 'center', width: "6rem", fontSize: 14, verticalAlign: "middle" }}>Unit of Measure (UOM)</th>
                                            <th style={{ textAlign: 'center', width: "5rem", fontSize: 14, verticalAlign: "middle" }}>Minimum Quantity</th>
                                            <th style={{ textAlign: 'center', width: "5rem", fontSize: 14, verticalAlign: "middle" }}>Maximum Quantity</th>
                                            <th style={{ textAlign: 'center', width: "10rem", fontSize: 14, verticalAlign: "middle" }}>Reason Code</th>

                                            <th style={{ textAlign: 'center', width: "14rem", fontSize: 14, verticalAlign: "middle" }}>Actions</th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        {Allocationlistmaterial.map((material) =>

                                            <Fragment>

                                                {editAllocationlistmaterialId === material.id ? <AllocationListEdit editAllocationlistmaterialFormData={editAllocationlistmaterialFormData}
                                                    handleEditAllocationlistmaterialFormChange={handleEditAllocationlistmaterialFormChange}
                                                    handelCancelAllocationlistmaterialClick={handelCancelAllocationlistmaterialClick}
                                                    handleEditAllocationlistmaterialFormSubmit={handleEditAllocationlistmaterialFormSubmit} />

                                                    : <AllocationListRead material={material}
                                                        handleEditAllocationlistmaterialClick={handleEditAllocationlistmaterialClick}
                                                        handelRemoveAllocationlistmaterialClick={handelRemoveAllocationlistmaterialClick} />
                                                }


                                            </Fragment>

                                        )}
                                        {
                                            showAddAllocationlist &&
                                            <tr id='AddItemsAllocationlist'>
                                                <td style={{ verticalAlign: "middle" }}>
                                                    <input type="text" style={{ width: "10rem" }}

                                                        id="TableMaterialCodeAllocationlist"
                                                        name="MaterialCode"
                                                        required="required"
                                                        idx="restrictedDecimal"

                                                        onChange={(e) => { handleAddAllocationlistmaterialFormChange(e); setMaterialDecription(e) }}
                                                    />
                                                    <br />
                                                    <span className="help-block text-danger" id="Invalid_MaterialCode" style={{ display: "None", float: "left" }}> Please enter a 13 digit numeric Material Code</span>
                                                    <span className="help-block text-danger" id="Invalid_Status_MaterialCodeAllocation" style={{ display: "None", float: "left" }}>The Material Code entered is invalid</span>
                                                    <span className="help-block text-danger" id="Inactive_Status_MaterialCodeAllocation" style={{ display: "None", float: "left" }}>The Material Code entered is inactive</span>

                                                </td>
                                                <td style={{ verticalAlign: "middle" }}><input type="text" style={{ width: "10rem" }}
                                                    id="TableMaterialDescriptionAllocationlist"
                                                    name="MaterialDescription"
                                                    required="required"

                                                    onChange={handleAddAllocationlistmaterialFormChange}

                                                />
                                                    <br />
                                                    <span className="help-block text-danger" id="Invalid_MaterialDescriptionAlloacation" style={{ display: "None", float: "left" }}> Please enter Material Description</span>
                                                </td>
                                                <td style={{ verticalAlign: "middle" }}>

                                                    <select id="TableUnitOfMeasure" name='UnitOfMeasure' onChange={handleAddAllocationlistmaterialFormChange} style={{ width: "10rem", height: "1.80rem" }}>
                                                        <option selected name="" value="" >--Select--</option>
                                                        <option name="CS" value="CS">CS</option>
                                                        <option name="EA" value="EA">EA</option>
                                                        <option name="DS" value="DS">DS</option>
                                                        <option name="PAC" value="PAC" >PAC</option>

                                                    </select>

                                                    <br />
                                                    <span className="help-block text-danger" id="Invalid_UnitOfMeasure" style={{ display: "None", float: "left" }}>Please select Unit of Measure</span>


                                                </td>
                                                <td style={{ verticalAlign: "middle" }}><input type="text" style={{ width: "5rem" }}

                                                    id="TableMinQtyCap"
                                                    name="MinQtyCap"
                                                    required="required"

                                                    idx="restrictedDecimal"

                                                    onChange={handleAddAllocationlistmaterialFormChange}

                                                />
                                                    <br />
                                                    <span className="help-block text-danger" id="Invalid_MinQtyCap" style={{ display: "None", float: "left" }}>Please enter Min Qty Cap</span>

                                                </td>
                                                <td style={{ verticalAlign: "middle" }}><input type="text" style={{ width: "5rem" }}

                                                    id="TableMaxQtyCap"
                                                    name="MaxQtyCap"
                                                    required="required"

                                                    idx="restrictedDecimal"
                                                    onChange={handleAddAllocationlistmaterialFormChange}

                                                />
                                                    <br />
                                                    <span className="help-block text-danger" id="Invalid_MaxQtyCap" style={{ display: "None", float: "left" }}>Please enter Max Qty Cap</span>

                                                </td>
                                                <td style={{ verticalAlign: "middle" }}>

                                                    <select name='ReasonCode' id="ReasonCodeAlloactionlist" onChange={(e) => { handleAddAllocationlistmaterialFormChange(e); }} style={{ width: "10rem", height: "1.80rem" }}>

                                                    </select>
                                                    <br />
                                                    <span className="help-block text-danger" id="Invalid_ReasonCodeAlloactionlist" style={{ display: "None" }}>Please select a Reason Code</span>


                                                </td>

                                                <td className='text-center'><Button variant="outline-light" onClick={handleAddAllocationlistmaterialFormSubmit} style={{ width: "5rem", backgroundColor: "#009639", color: "white" }}>Add</Button></td>

                                            </tr>
                                        }

                                    </tbody>
                                </Table>

                            </div>
                            <div className="row" >
                                <div className="col-md-11" >

                                </div>

                                <div className="col-md-1" >

                                    <Button variant="outline-secondary" style={{ borderRadius: "2vw" }} onClick={() => { setShowAddAllocationlist(!showAddAllocationlist); getReasonCode() }}>+</Button>
                                    <span> Add </span>


                                </div>

                            </div>

                        </div>
                    </form>

                    <form onSubmit={handleAddAddlistmaterialFormSubmit}>
                        <div className="row" id='AddList' style={{ marginTop: "2vw" }}>
                            <div className="row" style={{ display: "contents" }}>

                                <Table responsive bordered hover className="square border border-dark">
                                    <thead>


                                        <tr style={{ backgroundColor: "#1C1B83", textAlign: 'center', fontWeight: "bold", color: "White" }}>

                                            <td colSpan="12">ADD LIST (STO or SO)</td>

                                        </tr>
                                        <tr>
                                            <th style={{ textAlign: 'center', width: "10rem", fontSize: 14, verticalAlign: "middle" }}>Material Code</th>
                                            <th style={{ textAlign: 'center', width: "10rem", fontSize: 14, verticalAlign: "middle" }}>Material Description</th>
                                            <th style={{ textAlign: 'center', width: "6rem", fontSize: 14, verticalAlign: "middle" }}>Unit of Measure (UOM)</th>
                                            <th style={{ textAlign: 'center', width: "5rem", fontSize: 14, verticalAlign: "middle" }}>Add Value</th>
                                            <th style={{ textAlign: 'center', width: "5rem", fontSize: 14, verticalAlign: "middle" }}>Add Value Quantity or Percentage (Q/P)</th>
                                            <th style={{ textAlign: 'center', width: "10rem", fontSize: 14, verticalAlign: "middle" }}>Reason Code</th>

                                            <th style={{ textAlign: 'center', width: "14rem", fontSize: 14, verticalAlign: "middle" }}>Actions</th>

                                        </tr>

                                    </thead>
                                    <tbody>
                                        {Addlistmaterial.map((material) =>

                                            <Fragment>

                                                {editAddlistmaterialId === material.id ? <AddListEdit editAddlistmaterialFormData={editAddlistmaterialFormData}
                                                    handleEditAddlistmaterialFormChange={handleEditAddlistmaterialFormChange}
                                                    handelCancelAddlistmaterialClick={handelCancelAddlistmaterialClick}
                                                    handleEditAddlistmaterialFormSubmit={handleEditAddlistmaterialFormSubmit} />

                                                    : <AddListRead material={material}
                                                        handleEditAddlistmaterialClick={handleEditAddlistmaterialClick}
                                                        handelRemoveAddlistmaterialClick={handelRemoveAddlistmaterialClick} />
                                                }


                                            </Fragment>

                                        )}
                                        {
                                            showAddAddlist &&
                                            <tr id='AddItemsAddlist'>
                                                <td style={{ verticalAlign: "middle" }}><input type="text" style={{ width: "10rem" }}

                                                    id="TableMaterialCodeAddlist"
                                                    name="MaterialCode"
                                                    required="required"
                                                    idx="restrictedDecimal"

                                                    onChange={(e) => { handleAddAddlistmaterialFormChange(e); setMaterialDecription(e) }}


                                                />
                                                    <br />
                                                    <span className="help-block text-danger" id="Invalid_MaterialCodeAdd" style={{ display: "None", float: "left" }}> Please enter a 13 digit numeric Material Code</span>
                                                    <span className="help-block text-danger" id="Invalid_Status_MaterialCodeAdd" style={{ display: "None", float: "left" }}>The Material Code entered is invalid</span>
                                                    <span className="help-block text-danger" id="Inactive_Status_MaterialCodeAdd" style={{ display: "None", float: "left" }}>The Material Code entered is inactive</span>

                                                </td>
                                                <td style={{ verticalAlign: "middle" }}><input type="text" style={{ width: "10rem" }}
                                                    id="TableMaterialDescriptionAddlist"
                                                    name="MaterialDescription"
                                                    required="required"

                                                    onChange={handleAddAddlistmaterialFormChange}

                                                />
                                                    <br />
                                                    <span className="help-block text-danger" id="Invalid_MaterialDescriptionAdd" style={{ display: "None", float: "left" }}> Please enter Material Description</span>
                                                </td>
                                                <td style={{ verticalAlign: "middle" }}>

                                                    <select name='UnitOfMeasure' id="TableUnitOfMeasureAdd" onChange={handleAddAddlistmaterialFormChange} style={{ width: "6rem", height: "1.80rem" }}>
                                                        <option selected name="" value="" >--Select--</option>
                                                        <option name="CS" value="CS">CS</option>
                                                        <option name="EA" value="EA">EA</option>
                                                        <option name="DS" value="DS">DS</option>
                                                        <option name="PAC" value="PAC" >PAC</option>

                                                    </select>

                                                    <br />
                                                    <span className="help-block text-danger" id="Invalid_UnitOfMeasureAdd" style={{ display: "None", float: "left" }}>Please select Unit of Measure</span>


                                                </td>
                                                <td style={{ verticalAlign: "middle" }}><input type="text" style={{ width: "5rem" }}

                                                    id="TableAddValue"
                                                    name="AddValue"
                                                    required="required"
                                                    idx="restrictedDecimal"
                                                    onChange={handleAddAddlistmaterialFormChange}

                                                />
                                                    <br />
                                                    <span className="help-block text-danger" id="Invalid_AddValue" style={{ display: "None", float: "left" }}>Please enter Add Value</span>


                                                </td>
                                                <td style={{ verticalAlign: "middle" }}><input type="text" style={{ width: "5rem" }}

                                                    id="TableAddValueQty"
                                                    name="AddValueQty"
                                                    required="required"

                                                    onChange={handleAddAddlistmaterialFormChange}

                                                />
                                                    <br />
                                                    <span className="help-block text-danger" id="Invalid_AddValueQty" style={{ display: "None" }}>Please enter Add Value Quantity or Percentage (Q/P)</span>

                                                </td>
                                                <td style={{ verticalAlign: "middle" }}>

                                                    <select name='ReasonCode' id="ReasonCodeAddlist" onChange={(e) => { handleAddAddlistmaterialFormChange(e); }} style={{ width: "10rem", height: "1.80rem" }}>

                                                    </select>
                                                    <br />
                                                    <span className="help-block text-danger" id="Invalid_ReasonCodeAddlist" style={{ display: "None" }}>Please select a Reason Code</span>


                                                </td>


                                                <td className='text-center'><Button variant="outline-light" onClick={handleAddAddlistmaterialFormSubmit} style={{ width: "5rem", backgroundColor: "#009639", color: "white" }}>Add</Button></td>

                                            </tr>
                                        }

                                    </tbody>
                                </Table>

                            </div>
                            <div className="row" >
                                <div className="col-md-11" >

                                </div>
                                <div className="col-md-1" >

                                    <Button variant="outline-secondary" style={{ borderRadius: "2vw" }} onClick={() => { setShowAddAddlist(!showAddAddlist); getReasonCode() }}>+</Button>
                                    <span> Add </span>


                                </div>

                            </div>


                        </div>
                    </form>

                    <form onSubmit={handleAddCutlistmaterialFormSubmit}>
                        <div className="row" id='CutList' style={{ marginTop: "2vw" }}>
                            <div className="row" style={{ display: "contents" }}>

                                <Table responsive bordered hover className="square border border-dark">
                                    <thead>


                                        <tr style={{ backgroundColor: "#1C1B83", textAlign: 'center', fontWeight: "bold", color: "White" }}>


                                            <td colSpan="12">CUT LIST (STO or SO)</td>

                                        </tr>
                                        <tr>
                                            <th style={{ textAlign: 'center', width: "10rem", fontSize: 14, verticalAlign: "middle" }}>Material Code </th>
                                            <th style={{ textAlign: 'center', width: "10rem", fontSize: 14, verticalAlign: "middle" }}>Material Description</th>
                                            <th style={{ textAlign: 'center', width: "6rem", fontSize: 14, verticalAlign: "middle" }}>Unit of Measure (UOM)</th>
                                            <th style={{ textAlign: 'center', width: "5rem", fontSize: 14, verticalAlign: "middle" }}>Cut Value</th>
                                            <th style={{ textAlign: 'center', width: "5rem", fontSize: 14, verticalAlign: "middle" }}>Cut Value Quantity or Percentage (Q/P)</th>
                                            <th style={{ textAlign: 'center', width: "10rem", fontSize: 14, verticalAlign: "middle" }}>Reason Code</th>

                                            <th style={{ textAlign: 'center', width: "14rem", fontSize: 14, verticalAlign: "middle" }}>Actions</th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        {Cutlistmaterial.map((material) =>

                                            <Fragment>

                                                {editCutlistmaterialId === material.id ? <CutListEdit editCutlistmaterialFormData={editCutlistmaterialFormData}
                                                    handleEditCutlistmaterialFormChange={handleEditCutlistmaterialFormChange}
                                                    handelCancelCutlistmaterialClick={handelCancelCutlistmaterialClick}
                                                    handleEditCutlistmaterialFormSubmit={handleEditCutlistmaterialFormSubmit} />

                                                    : <CutListRead material={material}
                                                        handleEditCutlistmaterialClick={handleEditCutlistmaterialClick}
                                                        handelRemoveCutlistmaterialClick={handelRemoveCutlistmaterialClick} />
                                                }


                                            </Fragment>

                                        )}
                                        {
                                            showAddCutlist &&
                                            <tr id='AddItemsCutlist'>
                                                <td style={{ verticalAlign: "middle" }}><input type="text" style={{ width: "10rem" }}
                                                    id="TableMaterialCodeCutlist"
                                                    name="MaterialCode"
                                                    required="required"
                                                    idx="restrictedDecimal"
                                                    onChange={(e) => { handleAddCutlistmaterialFormChange(e); setMaterialDecription(e) }}

                                                />
                                                    <br />
                                                    <span className="help-block text-danger" id="Invalid_MaterialCodeCut" style={{ display: "None", float: "left" }}> Please enter a 13 digit numeric Material Code</span>
                                                    <span className="help-block text-danger" id="Invalid_Status_MaterialCodeCut" style={{ display: "None", float: "left" }}>The Material Code entered is invalid</span>
                                                    <span className="help-block text-danger" id="Inactive_Status_MaterialCodeCut" style={{ display: "None", float: "left" }}>The Material Code entered is inactive</span>

                                                </td>

                                                <td style={{ verticalAlign: "middle" }}><input type="text" style={{ width: "10rem" }}
                                                    id="TableMaterialDescriptionCutlist"
                                                    name="MaterialDescription"
                                                    required="required"

                                                    onChange={handleAddCutlistmaterialFormChange}

                                                />
                                                    <br />
                                                    <span className="help-block text-danger" id="Invalid_MaterialDescriptionCut" style={{ display: "None", float: "left" }}> Please enter Material Description</span>
                                                </td>

                                                <td style={{ verticalAlign: "middle" }}>
                                                    <select name='UnitOfMeasure' id="TableUnitOfMeasureCut" onChange={handleAddCutlistmaterialFormChange} style={{ width: "6rem", height: "1.80rem" }}>
                                                        <option selected name="" value="" >--Select--</option>
                                                        <option name="CS" value="CS">CS</option>
                                                        <option name="EA" value="EA">EA</option>
                                                        <option name="DS" value="DS">DS</option>
                                                        <option name="PAC" value="PAC" >PAC</option>

                                                    </select>
                                                    <br />
                                                    <span className="help-block text-danger" id="Invalid_UnitOfMeasureCut" style={{ display: "None", float: "left" }}>Please select Unit of Measure</span>


                                                </td>
                                                <td style={{ verticalAlign: "middle" }}>
                                                    <input type="text" style={{ width: "5rem" }}

                                                        id="TableCutValue"

                                                        name="CutValue"
                                                        required="required"

                                                        idx="restrictedDecimal"
                                                        onChange={handleAddCutlistmaterialFormChange}

                                                    />
                                                    <br />
                                                    <span className="help-block text-danger" id="Invalid_CutValue" style={{ display: "None", float: "left" }}>Please enter Cut Value</span>

                                                </td>
                                                <td style={{ verticalAlign: "middle" }}><input type="text" style={{ width: "5rem" }}

                                                    id="TableCutValueQty"
                                                    name="CutValueQty"
                                                    required="required"


                                                    onChange={handleAddCutlistmaterialFormChange}

                                                />
                                                    <br />
                                                    <span className="help-block text-danger" id="Invalid_CutValueQty" style={{ display: "None" }}>Please enter Cut Value Quantity or Percentage (Q/P)</span>


                                                </td>
                                                <td style={{ verticalAlign: "middle" }}>

                                                    <select name='ReasonCode' id="ReasonCodeCutlist" onChange={(e) => { handleAddCutlistmaterialFormChange(e); }} style={{ width: "10rem", height: "1.80rem" }}>

                                                    </select>
                                                    <br />
                                                    <span className="help-block text-danger" id="Invalid_ReasonCodeCutlist" style={{ display: "None" }}>Please select a Reason Code</span>

                                                </td>

                                                <td className='text-center'><Button variant="outline-light" onClick={handleAddCutlistmaterialFormSubmit} style={{ width: "5rem", backgroundColor: "#009639", color: "white" }}>Add</Button></td>

                                            </tr>
                                        }

                                    </tbody>
                                </Table>

                            </div>
                            <div className="row" >
                                <div className="col-md-11" >

                                </div>
                                <div className="col-md-1" >

                                    <Button variant="outline-secondary" style={{ borderRadius: "2vw" }} onClick={() => { setShowAddCutlist(!showAddCutlist); getReasonCode() }}>+</Button>
                                    <span> Add </span>


                                </div>

                            </div>
                        </div>
                    </form>


                    <div className="row" style={{ marginTop: "2vw" }} >
                        <div className="col-md-9" >

                        </div>
                        <div className="col-md-3" >

                            <Button variant="outline-light" style={{ backgroundColor: "#009639", color: "white", marginLeft: "5vw", fontWeight: "bold", fontSize: 14 }} onClick={handleConfirmation}>Submit ICR Request(s)</Button>

                            <Modal
                                show={show}
                                onHide={handleClose}
                                backdrop="static"
                                keyboard={false}
                            >
                                <Modal.Header closeButton >
                                    <Modal.Title >  </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>

                                </Modal.Body>

                                {!isShowConfirmPopUp && <Modal.Footer>
                                    <Button variant="outline-light" style={{ backgroundColor: "rgb(0, 150, 57)", color: "white" }} onClick={handleClose}>
                                        Close
                                    </Button>

                                </Modal.Footer>}
                                {isShowConfirmPopUp && <Modal.Footer>
                                    <Button variant="outline-light" style={{ backgroundColor: "rgb(0, 150, 57)", color: "white" }} onClick={handleOk}>
                                        Yes
                                    </Button>
                                    <Button variant="outline-light" style={{ backgroundColor: "rgb(0, 150, 57)", color: "white" }} onClick={handleClose}>
                                        No
                                    </Button>
                                </Modal.Footer>}
                            </Modal>
                        </div>

                    </div>

                </Card.Body>
            </Card>
        </div>
    );

}
export default ICRCreation;