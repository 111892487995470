import React from 'react';
import Button from 'react-bootstrap/Button';

const AllocationlistRead = ({ material, handleEditAllocationlistmaterialClick, handelRemoveAllocationlistmaterialClick }) => {

    return (


        <tr>
            <td style={{ backgroundColor: "#e9ecef", verticalAlign: "middle" }}>{material.MaterialCode}</td>
            <td style={{ backgroundColor: "#e9ecef", verticalAlign: "middle" }}>{material.MaterialDescription}</td>
            <td style={{ backgroundColor: "#e9ecef", verticalAlign: "middle" }}>{material.UnitOfMeasure}</td>
            <td style={{ backgroundColor: "#e9ecef", verticalAlign: "middle" }}>{material.MinQtyCap}</td>
            <td style={{ backgroundColor: "#e9ecef", verticalAlign: "middle" }}>{material.MaxQtyCap}</td>
            <td style={{ backgroundColor: "#e9ecef", verticalAlign: "middle" }}>{material.ReasonCodeDescription}</td>
            <td className='text-center'>
                <Button variant="outline-light" onClick={(event) => handleEditAllocationlistmaterialClick(event, material)} style={{ width: "5rem", backgroundColor: "#009639", color: "white" }}>Edit</Button>
                <Button variant="outline-light" onClick={() => handelRemoveAllocationlistmaterialClick(material.id)} style={{ marginLeft: "1vw", width: "7rem", backgroundColor: "#FF0000", color: "white" }} >Remove</Button>
            </td>


        </tr>
    )

}
export default AllocationlistRead;