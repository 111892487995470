import React, { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { Outlet } from "react-router-dom";

export const RequiredAuth = () => {
  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
         window.location.origin
      );
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
   
  }, [oktaAuth, !!authState, authState?.isAuthenticated]);

  if (!authState || !authState?.isAuthenticated) {
    return <div>Loading...</div>;
  } else{
     sessionStorage.setItem("gpid", authState.idToken.claims.gpid);
     sessionStorage.setItem("name", authState.idToken.claims.name);
     sessionStorage.setItem("emailid", authState.idToken.claims.email);
     sessionStorage.setItem("role", authState.accessToken.claims.pepappsupplychainroles);
     sessionStorage.setItem("siteaccess", authState.accessToken.claims.pepappsupplychainsiteid);
      
    }


  return <Outlet />;
};