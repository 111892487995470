import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';

import JQuery, { event } from 'jquery';
import UtilBaseUrl from '../../ConfigUtility';

const getToken = () => {
    let tokenStr = localStorage.getItem("okta-token-storage");

    if (!tokenStr) {
        return;
    }
    else {
        const tokenObj = JSON.parse(tokenStr);

        return tokenObj?.accessToken?.accessToken;
    }
}

const SublistEdit = ({ editSublistmaterialFormData, handleEditSublistmaterialFormChange, handelCancelSublistmaterialClick, handleEditSublistmaterialFormSubmit }) => {

    const hostUrl = UtilBaseUrl.URL;
    const token = getToken();
    const headers = {
        "accept": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${token}`
    }




    let reasonCodeResponse = [], reasonCodeOptions = '';
    // page Load//
    useEffect(() => {


        JQuery.ajax({
            url: hostUrl + "/ordermodification/reason-code",

            type: 'POST',
            dataType: 'json',
            CORS: true,
            contentType: 'application/json',
            secure: true,
            headers: headers,
            crossDomain: true,
            success: function (response) {
                reasonCodeOptions = '<option value="" name ="" selected>--Select--</option>\n';
                reasonCodeResponse = response["response"];
                for (let i = 0; i < reasonCodeResponse.length; i++) {
                    reasonCodeOptions += '<option value="' + reasonCodeResponse[i]["description"] + '" name="' + reasonCodeResponse[i]["description"] + '">' + reasonCodeResponse[i]["description"] + '</option>\n';
                }

                JQuery("#ReasonCodeSublist").html(reasonCodeOptions);
                JQuery("#ReasonCodeSublist").val(editSublistmaterialFormData.ReasonCode);



            },
            error: function (jqHXR, textStatus, errorThrown) {


            }

        });

    }, []);

    // material Decription //
    const setMaterialDecription = (event) => {

        let materialcode = event.target.value;

        if (materialcode.length === 13) {

            checkValidationMaterial(event);
            ValidMaterial(event);

        }
        else {

            if (event.target.id === "TableMaterialCodeFromSublist") {
                JQuery("#TableMaterialCodeFromSublist").val(materialcode);

                JQuery("#Invalid_Status_MaterialCodeFromSub").hide();
                JQuery("#Inactive_Status_MaterialCodeFromSub").hide();

                JQuery("#TableMaterialCodeFromSublist").removeAttr("style");
                JQuery("#TableMaterialCodeFromSublist").css({ "width": "9rem" });



            }

            if (event.target.id === "TableMaterialCodeToSublist") {

                JQuery("#TableMaterialCodeToSublist").val(materialcode);
                JQuery("#Invalid_Status_MaterialCodeToSub").hide();
                JQuery("#Inactive_Status_MaterialCodeToSub").hide();

                JQuery("#TableMaterialCodeToSublist").removeAttr("style");
                JQuery("#TableMaterialCodeToSublist").css({ "width": "9rem" });


            }

        }

    }
    // Check validtion  material  //
    const checkValidationMaterial = (event) => {

        let materialcode = event.target.value;

        const token = getToken();
        const headers = {
            "accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }

        JQuery.ajax({
            url: hostUrl + "/orderModification/materialValidation",

            type: 'POST',
            dataType: 'json',
            data: JSON.stringify({
                "materialNumber": materialcode
            }),
            CORS: true,
            contentType: 'application/json',
            secure: true,
            headers: headers,
            crossDomain: true,
            success: (res) => {



                let MaterialCodeMessage = res.errormessage;
                if (MaterialCodeMessage === "Material Exists") {


                    if (event.target.id === "TableMaterialCodeFromSublist") {

                        JQuery("#Invalid_Status_MaterialCodeFromSub").hide();
                        JQuery("#Inactive_Status_MaterialCodeFromSub").hide();
                        JQuery("#TableMaterialCodeFromSublist").removeAttr("style");
                        JQuery("#TableMaterialCodeFromSublist").css({ "width": "9rem" });

                    }

                    if (event.target.id === "TableMaterialCodeToSublist") {

                        JQuery("#Invalid_Status_MaterialCodeToSub").hide();
                        JQuery("#Inactive_Status_MaterialCodeToSub").hide();

                        JQuery("#TableMaterialCodeToSublist").removeAttr("style");
                        JQuery("#TableMaterialCodeToSublist").css({ "width": "9rem" });

                    }
                    ValidMaterial();


                }
                else if (MaterialCodeMessage === "Material is not active") {

                    if (event.target.id === "TableMaterialCodeFromSublist") {


                        JQuery("#Invalid_Status_MaterialCodeFromSub").hide();
                        JQuery("#Inactive_Status_MaterialCodeFromSub").show();

                        JQuery("#TableMaterialCodeFromSublist").css("border", "1px solid red");

                        JQuery("#TableMaterialDescriptionFromSublist").val(res.response.materialDesciption);
                        JQuery("#TableUnitOfMeasureFrom").val(res.response.umo);

                    }

                    if (event.target.id === "TableMaterialCodeToSublist") {


                        JQuery("#Invalid_Status_MaterialCodeToSub").hide();
                        JQuery("#Inactive_Status_MaterialCodeToSub").show();

                        JQuery("#TableMaterialCodeToSublist").css("border", "1px solid red");

                        JQuery("#TableMaterialDescriptionToSublist").val(res.response.materialDesciption);
                        JQuery("#TableUnitOfMeasureTo").val(res.response.umo);

                    }
                }
                else {

                    if (event.target.id === "TableMaterialCodeFromSublist") {


                        JQuery("#Invalid_Status_MaterialCodeFromSub").show();
                        JQuery("#Inactive_Status_MaterialCodeFromSub").hide();

                        JQuery("#TableMaterialCodeFromSublist").css("border", "1px solid red");

                        JQuery("#TableMaterialDescriptionFromSublist").val(res.response.materialDesciption);
                        JQuery("#TableUnitOfMeasureFrom").val(res.response.umo);

                    }

                    if (event.target.id === "TableMaterialCodeToSublist") {


                        JQuery("#Invalid_Status_MaterialCodeToSub").show();
                        JQuery("#Inactive_Status_MaterialCodeToSub").hide();

                        JQuery("#TableMaterialCodeToSublist").css("border", "1px solid red");

                        JQuery("#TableMaterialDescriptionToSublist").val(res.response.materialDesciption);
                        JQuery("#TableUnitOfMeasureTo").val(res.response.umo);

                    }

                }

            },
            error: function (jqHXR, textStatus, errorThrown) {


            }

        });

    }

    // valid material//
    const ValidMaterial = (event) => {

        let materialcode = event.target.value;

        const token = getToken();
        const headers = {
            "accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }

        JQuery.ajax({
            url: hostUrl + "/ordermodification/materialDescription",

            type: 'POST',
            dataType: 'json',
            data: JSON.stringify({
                "materialNumber": materialcode
            }),
            CORS: true,
            contentType: 'application/json',
            secure: true,
            headers: headers,
            crossDomain: true,
            success: (res) => {



                ResponseSetMaterial(res.response, event.target.id, materialcode);

            },
            error: function (jqHXR, textStatus, errorThrown) {


            }

        });
    }

    const ResponseSetMaterial = (Response, ID, materialcode) => {


        if (ID === "TableMaterialCodeFromSublist") {

            JQuery("#TableMaterialDescriptionFromSublist").val(Response.materialDesciption);
            JQuery("#TableUnitOfMeasureFrom").val(Response.umo);

        }

        if (ID === "TableMaterialCodeToSublist") {

            JQuery("#TableMaterialDescriptionToSublist").val(Response.materialDesciption);
            JQuery("#TableUnitOfMeasureTo").val(Response.umo);

        }

    }


    return (


        <tr id='AddItems'>
            <td style={{ verticalAlign: "middle" }}><input type="text" style={{ width: "9rem" }}

                id="TableMaterialCodeFromSublist"
                name="MaterialCodeFrom"
                required="required"
                idx="restrictedDecimal"
                value={editSublistmaterialFormData.MaterialCodeFrom}

                onChange={(e) => { handleEditSublistmaterialFormChange(e); setMaterialDecription(e) }}

            />
                <br />
                <span className="help-block text-danger" id="Invalid_MaterialCodeFrom" style={{ display: "None", float: "left" }} >Please enter a 13 digit numeric Material Code (From)</span>
                <span className="help-block text-danger" id="Invalid_Status_MaterialCodeFromSub" style={{ display: "None", float: "left" }}>The Material Code entered is invalid</span>
                <span className="help-block text-danger" id="Inactive_Status_MaterialCodeFromSub" style={{ display: "None", float: "left" }}>The Material Code entered is inactive</span>

            </td>
            <td style={{ verticalAlign: "middle" }}><input type="text" style={{ width: "8rem" }}
                id="TableMaterialDescriptionFromSublist"
                name="MaterialDescriptionFrom"
                required="required"

                value={editSublistmaterialFormData.MaterialDescriptionFrom}
                onChange={handleEditSublistmaterialFormChange}

            />
                <br />
                <span class="help-block text-danger" id="Invalid_MaterialDescriptionFrom" style={{ display: "None", float: "left" }}> Please enter Material Description (From)</span>
            </td>
            <td style={{ verticalAlign: "middle" }}>

                <select id="TableUnitOfMeasureFrom" name='UnitOfMeasureFrom' value={editSublistmaterialFormData.UnitOfMeasureFrom}
                    onChange={handleEditSublistmaterialFormChange} style={{ width: "7rem", height: "1.80rem" }}>
                    <option selected name="" value="" >--Select--</option>
                    <option name="CS" value="CS">CS</option>
                    <option name="EA" value="EA">EA</option>
                    <option name="DS" value="DS">DS</option>
                    <option name="PAC" value="PAC" >PAC</option>

                </select>
                <br />
                <span class="help-block text-danger" id="Invalid_UnitOfMeasureFrom" style={{ display: "None" }} >Please select Unit Of Measure (From)</span>

            </td>
            <td style={{ verticalAlign: "middle" }}><input type="text" style={{ width: "9rem" }}

                id="TableMaterialCodeToSublist"
                name="MaterialCodeTo"
                required="required"
                idx="restrictedDecimal"
                value={editSublistmaterialFormData.MaterialCodeTo}

                onChange={(e) => { handleEditSublistmaterialFormChange(e); setMaterialDecription(e) }}

            />
                <br />
                <span className="help-block text-danger" id="Invalid_MaterialCodeTo" style={{ display: "None" }}  >Please enter a 13 digit numeric Material Code (To)</span>
                <span className="help-block text-danger" id="Invalid_Status_MaterialCodeToSub" style={{ display: "None", float: "left" }}>The Material Code entered is invalid</span>
                <span className="help-block text-danger" id="Inactive_Status_MaterialCodeToSub" style={{ display: "None", float: "left" }}>The Material Code entered is inactive</span>

            </td>
            <td style={{ verticalAlign: "middle" }}><input type="text" style={{ width: "8rem" }}
                id="TableMaterialDescriptionToSublist"
                name="MaterialDescriptionTo"
                required="required"

                value={editSublistmaterialFormData.MaterialDescriptionTo}
                onChange={handleEditSublistmaterialFormChange}

            />
                <br />
                <span class="help-block text-danger" id="Invalid_MaterialDescriptionTo" style={{ display: "None", float: "left" }}> Please enter Material Description (To)</span>
            </td>
            <td style={{ verticalAlign: "middle" }}>

                <select id="TableUnitOfMeasureTo" name='UnitOfMeasureTo' value={editSublistmaterialFormData.UnitOfMeasureTo}
                    onChange={handleEditSublistmaterialFormChange} style={{ width: "7rem", height: "1.80rem" }}>
                    <option selected name="" value="" >--Select--</option>
                    <option name="CS" value="CS">CS</option>
                    <option name="EA" value="EA">EA</option>
                    <option name="DS" value="DS">DS</option>
                    <option name="PAC" value="PAC">PAC</option>

                </select>
                <br />
                <span class="help-block text-danger" id="Invalid_UnitOfMeasureTo" style={{ display: "None" }} >Please select Unit Of Measure (To)</span>

            </td>

            <td style={{ verticalAlign: "middle" }}>

                <select name='ReasonCode' id="ReasonCodeSublist"
                    onChange={handleEditSublistmaterialFormChange} value={editSublistmaterialFormData.ReasonCode}
                    style={{ width: "8rem", height: "1.80rem" }}>


                </select>
                <br />
                <span class="help-block text-danger" id="Invalid_ReasonCodeSublist" style={{ display: "None" }}>Please select a Reason Code</span>
            </td>

            <td className='text-center'>
                <Button variant="outline-light" onClick={handleEditSublistmaterialFormSubmit} style={{ width: "5rem", backgroundColor: "#009639", color: "white" }}>Save</Button>
                <Button variant="outline-light" onClick={() => handelCancelSublistmaterialClick()} style={{ marginLeft: "1vw", width: "7rem", backgroundColor: "#FF0000", color: "white" }}>Cancel</Button>
            </td>

        </tr>
    )

}
export default SublistEdit;