import React from 'react';


const ReadOnlyRowsDeliveryDate = ({ material }) => {

    return (

        <tr>
            <td style={{ backgroundColor: "#e9ecef", verticalAlign: "middle" }}>{material.materialNumber}</td>
            <td style={{ backgroundColor: "#e9ecef", verticalAlign: "middle" }}>{material.upc}</td>
            <td style={{ backgroundColor: "#e9ecef", verticalAlign: "middle" }}>{material.materialDescription}</td>
            <td style={{ backgroundColor: "#e9ecef", verticalAlign: "middle" }}>{material.uom}</td>
            <td style={{ backgroundColor: "#e9ecef", verticalAlign: "middle" }}>{material.orderQuantity}</td>

        </tr>

    )

}
export default ReadOnlyRowsDeliveryDate;