import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import DatePicker from 'react-datepicker';
import Form from 'react-bootstrap/Form';
import { CDBBox } from 'cdbreact';
import Button from 'react-bootstrap/Button';
import JQuery from 'jquery';
import { useNavigate } from 'react-router-dom';
import ConfigUtility from '../ConfigUtility';
import * as Constants from '../Common Files/Constants.js';
import { Modal, Spinner } from 'react-bootstrap';


const getToken = () => {
  let tokenStr = localStorage.getItem("okta-token-storage");
  if (!tokenStr) {
    return;
  }
  else {
    const tokenObj = JSON.parse(tokenStr);
    return tokenObj?.accessToken?.accessToken;
  }
}

function TrackerIncompletion() {

  // Initial declaration


  const hostUrl = ConfigUtility.URL;
  let siteCodeResponse = [], siteCodeOptions = '', incompletionResponses = [], incompletionFilterResponse = [], distinctRequests;
  let temp_emailid = sessionStorage.getItem("emailid");
  let temp_role = sessionStorage.getItem("role");
  let recordsPerPage = 10, paginationBasic;
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);

  // Filter's Datepicker 
  const [OrderDeliverytodate, SetOrderDeliveryTodate] = useState('');
  const [OrderDeliveryfromdate, SetOrderDeliveryFromdate] = useState('');

  //GET SITE ID FOR FILTER (VIA POST)
  const getFilterSiteID = () => {

    const token = getToken();
    const headers = {
      "accept": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Authorization": `Bearer ${token}`
    }

    JQuery.ajax({
      url: hostUrl + "/status/getSiteApprovalList",
      type: 'POST',
      dataType: 'json',
      CORS: true,
      data: JSON.stringify({

        "role": temp_role,
        "emailId": temp_emailid,
        "type": "F"
      }),
      contentType: 'application/json',
      headers: headers,
      crossDomain: true,
      success: function (response) {
        siteCodeOptions = '<option selected>Select Site ID </option>\n';
        siteCodeResponse = response["response"];
        for (let i = 0; i < siteCodeResponse.length; i++) {
          siteCodeOptions += '<option value="' + siteCodeResponse[i]["siteName"] + '">' + siteCodeResponse[i]["siteId"] + '</option>\n';
        }
        JQuery("#sites").html(siteCodeOptions);

      },
      error: function (xhr, textStatus) {
      }
    });

  };

  //GET REQUEST ID FOR FILTER(VIA POST)
  const getFilterRequestID = () => {

    const token = getToken();
    const headers = {
      "accept": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Authorization": `Bearer ${token}`
    }

    JQuery.ajax({
      url: hostUrl + "/status/getRequestIDListforIncompletion",
      type: 'POST',
      dataType: 'json',
      CORS: true,
      data: JSON.stringify({

        "role": temp_role,
        "emailId": temp_emailid,
        "type": "F"
      }),
      contentType: 'application/json',
      headers: headers,
      crossDomain: true,
      success: function (response) {
        siteCodeOptions = '<option selected>Select Request ID </option>\n';
        siteCodeResponse = response["response"];
        for (let i = 0; i < siteCodeResponse.length; i++) {
          siteCodeOptions += '<option value="' + siteCodeResponse[i]["requestID"] + '">' + siteCodeResponse[i]["requestName"] + '</option>\n';
        }
        JQuery("#requestid").html(siteCodeOptions);

      },
      error: function (xhr, textStatus) {
      }
    });

  };

  //GET DATA FOR INCOMPLETION APPROVAL(VIA POST)
  const getIncompletionApproval = () => {

    const token = getToken();
    const headers = {
      "accept": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Authorization": `Bearer ${token}`
    }
    setIsLoading(true);
    JQuery.ajax({
      url: hostUrl + "/status/getIncompletionRecords",
      type: 'POST',
      dataType: 'json',
      CORS: true,
      data: JSON.stringify({

        "role": temp_role,
        "emailId": temp_emailid,
        "type": "F"
      }),
      contentType: 'application/json',
      headers: headers,
      crossDomain: true,
      success: function (response) {
        setIsLoading(false);
        let incompletionDataElements = '';
        incompletionResponses = response.response;
        for (let i = 0; i < incompletionResponses.length; i++) {
          let formattedDeliveryDateFrom = incompletionResponses[i].deliveryDateFrom;
          formattedDeliveryDateFrom = formattedDeliveryDateFrom.split("-")[1] + "/" + formattedDeliveryDateFrom.split("-")[2] + "/" + formattedDeliveryDateFrom.split("-")[0];
          let formattedDeliveryDateTo = incompletionResponses[i].deliveryDateTo;
          formattedDeliveryDateTo = formattedDeliveryDateTo.split("-")[1] + "/" + formattedDeliveryDateTo.split("-")[2] + "/" + formattedDeliveryDateTo.split("-")[0];
          incompletionDataElements +=
            `<tr style="display: none">
            <td style="vertical-align: middle">`+ incompletionResponses[i].requestId + `</td>
            <td style="vertical-align: middle">`+ incompletionResponses[i].icrId + `</td>
            <td style="vertical-align: middle">`+ incompletionResponses[i].site + `</td>
            <td style="vertical-align: middle">`+ formattedDeliveryDateFrom + `</td>
            <td style="vertical-align: middle">`+ formattedDeliveryDateTo + `</td>
            <td style="vertical-align: middle">`+ incompletionResponses[i].sapMaterial + `</td>
            <td style="vertical-align: middle">`+ incompletionResponses[i].channel + `</td>
            <td style="vertical-align: middle">`+ incompletionResponses[i].action + `</td>
            <td style="vertical-align: middle">`+ incompletionResponses[i].instock + `</td>
            <td style="vertical-align: middle">`+ incompletionResponses[i].substitutionStock + `</td>
            <td style="vertical-align: middle">`+ incompletionResponses[i].poetEvent + `</td>
            <td style="vertical-align: middle">`+ incompletionResponses[i].sapDescription + `</td>
            <td style="vertical-align: middle">`+ incompletionResponses[i].pwmDescription + `</td>
          `;
          if (incompletionResponses[i].approvalStatus === "0") {
            incompletionDataElements +=
              `  <td class="submitapproval" style="vertical-align: middle" >
                <select name="Approve Action"  data-sno="`+ incompletionResponses[i].sno + `" style=" width:10rem;text-align: center; margin-left: 1vw;"> 
                  <option value="Please Select">Please Select</option>
                  <option value="Take no Action">Take No Action</option>
                  <option value="Escalate To CSR">Escalate to CSR</option>
                  <option value="`+ incompletionResponses[i].action + `">` + incompletionResponses[i].action + `</option>                                                                               
                </select>
              </td>
            </tr>
            `;
          }
          else {
            incompletionDataElements +=
              ` <td style="vertical-align: middle">` + incompletionResponses[i].updatedAction + `</td>
            </tr>
            `;
          }
        }
        incompletionResponses = JSON.parse(JSON.stringify(incompletionResponses));
        JQuery('#TrackerData tbody').empty();
        JQuery('#TrackerData tbody').html(incompletionDataElements);
        JQuery('#TrackerData tbody tr').slice(0, recordsPerPage).show();
        paginateTracker(recordsPerPage, incompletionResponses.length);
      },
      error: (err) => {
        console.log("Error", err);
        setIsLoading(false);
        setShow(true);
        setTimeout(function () {
          JQuery('.modal-title').html("<strong class='text-danger'><i class='fa fa-exclamation-circle'></i> Error !!! </strong>");
          JQuery(".modal-body").html("<p>Something went wrong. please try again later.</p>");
        }, 0);
      }
    });


  };

  //SUBMIT INCOMPLETION APPROVAL
  const postIncompletionApproval = () => {

    const token = getToken();
    const headers = {
      "accept": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Authorization": `Bearer ${token}`
    }


    let postActions = { "approvalTypes": [] }, counter = 0, postRequestIds = [];

    for (let i = 0; i < incompletionFilterResponse.length; i++) {
      let postAction = {};
      if (incompletionFilterResponse[i].approvalStatus === '0') {
        postAction["sno"] = parseInt(incompletionFilterResponse[i].sno);
        postAction["updatedAction"] = JQuery('[data-sno="' + incompletionFilterResponse[i].sno + '"]').find(":selected").val();
        postActions.approvalTypes[counter++] = postAction;
        postRequestIds.push(incompletionFilterResponse[i].requestId);
      }
    }

    distinctRequests = postRequestIds.filter(function (item, i, postRequestIds) {
      return i === postRequestIds.indexOf(item);
    });
    postActions = JSON.parse(JSON.stringify(postActions));
    JQuery.ajax({
      url: hostUrl + "/status/saveIncompletionsApprovalDetails",
      type: 'POST',
      dataType: 'text',
      CORS: true,
      data: JSON.stringify(postActions),
      contentType: 'application/json',
      headers: headers,
      crossDomain: true,
      success: function (data, textStatus, xhr) {

      },
      error: function (xhr, textStatus) {
      }
    });

  };

  // Filtering the tracker table
  const filterTracker = () => {
    // Gather data for filteration
    let deliveryDateFrom = JQuery('#DeliveryDateFrom').val();
    let deliveryDateTo = JQuery('#DeliveryDateTo').val();
    let selectedSites = JQuery('#sites').find(":selected").val();
    let selectedRequestedId = JQuery('#requestid').find(":selected").val();
    filterSiteidDeliveryData(deliveryDateFrom, deliveryDateTo, selectedSites, selectedRequestedId);
  };

  //FILTER ON THE BASIS OF SITE AND REQUEST ID AND DELIVERY DATE
  const filterSiteidDeliveryData = (deliveryDateFrom, deliveryDateTo, selectedSites, selectedRequestedId) => {
    JQuery("#submitfilter").html("<i class='fa fa-refresh fa-spin'></i> Submitting")
    const token = getToken();
    const headers = {
      "accept": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Authorization": `Bearer ${token}`
    }
    let filters = {
      "role": temp_role,
      "emailId": temp_emailid,
      "type": "F"
    };
    filters["site"] = (selectedSites !== "Select Site ID") ? selectedSites : "";
    filters["requestId"] = (selectedRequestedId !== "Select Request ID") ? selectedRequestedId : "";
    filters["deliveryDateFrom"] = (deliveryDateFrom !== "") ? deliveryDateFrom.split("/")[2] + "-" + deliveryDateFrom.split("/")[0] + "-" + deliveryDateFrom.split("/")[1] : "";
    filters["deliveryDateTo"] = (deliveryDateTo !== "") ? deliveryDateTo.split("/")[2] + "-" + deliveryDateTo.split("/")[0] + "-" + deliveryDateTo.split("/")[1] : "";
    filters = JSON.parse(JSON.stringify(filters));
    setIsLoading(true);
    JQuery.ajax({
      url: hostUrl + "/approvalMatrix/getIncompletionsbySiteandDeliveryDate",
      type: 'POST',
      dataType: 'json',
      CORS: true,
      data: JSON.stringify(filters),
      contentType: 'application/json',
      headers: headers,
      crossDomain: true,
      success: function (response) {
        setIsLoading(false);
        let incompletionFilterDataElements = '';
        incompletionFilterResponse = response.response;
        if (incompletionFilterResponse.length === 0 && response.status === 'Fail') {
          incompletionFilterDataElements += "";
        }
        else {
          for (let i = 0; i < incompletionFilterResponse.length; i++) {
            let formattedDeliveryDateFrom = incompletionFilterResponse[i].deliveryDateFrom;
            formattedDeliveryDateFrom = formattedDeliveryDateFrom.split("-")[1] + "/" + formattedDeliveryDateFrom.split("-")[2] + "/" + formattedDeliveryDateFrom.split("-")[0];
            let formattedDeliveryDateTo = incompletionFilterResponse[i].deliveryDateTo;
            formattedDeliveryDateTo = formattedDeliveryDateTo.split("-")[1] + "/" + formattedDeliveryDateTo.split("-")[2] + "/" + formattedDeliveryDateTo.split("-")[0];
            incompletionFilterResponse[i].requestId = incompletionFilterResponse[i].requestIdByFilter
            incompletionFilterDataElements +=
              `<tr style="display: none;vertical-align: middle">
                <td style="vertical-align: middle">`+ incompletionFilterResponse[i].requestId + `</td>
                <td style="vertical-align: middle">`+ incompletionFilterResponse[i].icrIdByFilter + `</td>
                <td style="vertical-align: middle">`+ incompletionFilterResponse[i].site + `</td>
                <td style="vertical-align: middle">`+ formattedDeliveryDateFrom + `</td>
                <td style="vertical-align: middle">`+ formattedDeliveryDateTo + `</td>
                <td style="vertical-align: middle">`+ incompletionFilterResponse[i].sapMaterial + `</td>
                <td style="vertical-align: middle">`+ incompletionFilterResponse[i].channelByFilter + `</td>
                <td style="vertical-align: middle">`+ incompletionFilterResponse[i].action + `</td>
                <td style="vertical-align: middle">`+ incompletionFilterResponse[i].inStock + `</td>
                <td style="vertical-align: middle">`+ incompletionFilterResponse[i].substitutionStock + `</td>
                <td style="vertical-align: middle">`+ incompletionFilterResponse[i].poetEvent + `</td>
                <td style="vertical-align: middle">`+ incompletionFilterResponse[i].sapDescription + `</td>
                <td style="vertical-align: middle">`+ incompletionFilterResponse[i].pwmDescription + `</td>
              `;
            if (incompletionFilterResponse[i].approvalStatus === "0") {
              incompletionFilterDataElements +=
                `  <td class="submitapproval"  style="vertical-align: middle">
                <select name="Approve Action"  data-sno="`+ incompletionFilterResponse[i].sno + `" style="width: 11rem; text-align: center; margin-left: 1vw;"> 
                  <option value="Please Select">Please Select</option>
                  <option value="Take no Action">Take No Action</option>
                  <option value="Escalate To CSR">Escalate to CSR</option>
                  <option value="`+ incompletionFilterResponse[i].action + `">` + incompletionFilterResponse[i].action + `</option>                                                                               
                </select>
              </td>
            </tr>
            `;
            }
            else {
              incompletionFilterDataElements +=
                ` <td style="vertical-align: middle">` + incompletionFilterResponse[i].updatedAction + `</td>
            </tr>
            `;
            }
          }

        }
        incompletionFilterResponse = JSON.parse(JSON.stringify(incompletionFilterResponse));
        JQuery('#TrackerData tbody').empty();
        JQuery('#TrackerData tbody').html(incompletionFilterDataElements);
        JQuery('#TrackerData tbody tr').slice(0, recordsPerPage).show();
        paginateTracker(recordsPerPage, incompletionFilterResponse.length);
        JQuery("#submitfilter").html("Submit")
      },
      error: (err) => {
        console.log("Error", err);
        setIsLoading(false);
        setShow(true);
        setTimeout(function () {
          JQuery('.modal-title').html("<strong class='text-danger'><i class='fa fa-exclamation-circle'></i> Error !!! </strong>");
          JQuery(".modal-body").html("<p>Something went wrong. please try again later.</p>");
        }, 0);
      }
    });
  };

  // Validating action submission
  const submitvalidation = () => {
    let countOfLeftActions = 0, countTotalActions = 0;
    JQuery("#SubmitIncompletion").html("<i class='fa fa-refresh fa-spin'></i> Submitting Approval");
    JQuery('.submitapproval').each(function () {
      countTotalActions++;
      let approveaction = JQuery(this).find(":selected").val();
      (approveaction === "Please Select") ? countOfLeftActions++ : countOfLeftActions = countOfLeftActions;
    });
    if (countOfLeftActions > 0) {

      //Show alert
      JQuery('#AlertOnAction').show();
      JQuery('#AlertOnActionLabel').removeClass('text-primary')
        .removeClass('text-danger')
        .removeClass('text-success')
        .addClass('text-danger');

      JQuery('#AlertOnActionLabel').html("<strong><i class='fa fa-times-circle'></i> Error !!! </strong>");
      JQuery('#AlertOnAction .modal-body').css('text-align', 'left').html("Please select the Appropriate Action for all rows before submitting for approval.");


      JQuery('#AlertOnAction .modal-footer button').removeClass('btn-primary')
        .removeClass('btn-danger')
        .removeClass('btn-success')
        .addClass('btn-danger');
      JQuery('.container-fluid').css("opacity", "0.5");
    }
    else if (countTotalActions <= 0) {
      JQuery('#AlertOnAction').show();
      JQuery('#AlertOnActionLabel').removeClass('text-primary')
        .removeClass('text-danger')
        .removeClass('text-success')
        .addClass('text-danger');

      JQuery('#AlertOnActionLabel').html("<strong><i class='fa fa-exclamation-triangle'></i> Warning !!! </strong>");
      JQuery('#AlertOnAction .modal-body').css('text-align', 'left').html("No action is pending at your end.");


      JQuery('#AlertOnAction .modal-footer button').removeClass('btn-primary')
        .removeClass('btn-danger')
        .removeClass('btn-success')
        .addClass('btn-danger');
      JQuery('.container-fluid').css("opacity", "0.5");

    }
    else {
      //CALLING THE API FUNCTION TO SUBMIT DATA
      postIncompletionApproval();

      //Show alert
      setTimeout(function () {
        JQuery('#AlertOnAction').show();
        JQuery('#AlertOnActionLabel').removeClass('text-primary')
          .removeClass('text-danger')
          .removeClass('text-success')
          .addClass('text-success');
        JQuery('#AlertOnActionLabel').html("<strong><i class='fa fa-check-circle'></i> Successfully Submitted </strong>");
        let successMsg = "Request has been submitted successfully. SAP updates in progress. Notification will be sent on completion.<br><br> Your Request ID is ";
        for (let i = 0; i < distinctRequests.length; i++) {
          (i === distinctRequests.length - 1) ? (successMsg = successMsg + "#" + distinctRequests[i]) : (successMsg = successMsg + "#" + distinctRequests[i] + ", ");
        }
        JQuery('#AlertOnAction .modal-body').css('text-align', 'left').html(successMsg);
        JQuery('#AlertOnAction .modal-footer button').removeClass('btn-primary')
          .removeClass('btn-danger')
          .removeClass('btn-success')
          .addClass('btn-success');
        JQuery('.container-fluid').css("opacity", "0.5");
        JQuery("#close").click(function () {
          window.location.reload();
        });
      }, 800);
    }
    JQuery("#SubmitIncompletion").html("Submit Approval");
  };

  // Pagination for the tracker table
  const paginateTracker = (pageLimit, totalTrackerRecords) => {

    let items = '',

      totalpageindex = (totalTrackerRecords % pageLimit === 0) ? parseInt(totalTrackerRecords / pageLimit) : parseInt(totalTrackerRecords / pageLimit + 1),

      prevIndex = 1,

      nextIndex = (totalpageindex > 1) ? (prevIndex + 1) : prevIndex,

      navSideLimit = 2, navMidLimit = 3;

    items += `<li class="page-item"><a class="page-link page-prev" tabindex="` + prevIndex + `" rel="` + prevIndex + `" type="button"><i class="fa fa-arrow-left"></i> Prev </a></li>`;

    for (let number = 1; number <= totalpageindex; number++) {

      items += `<li class="page-item"><a class="page-link page-index" tabindex="` + number + `" key="` + number + `" rel="` + number + `">`

        + number +

        `</a></li>`;

      if (totalpageindex > 7 && (number === navSideLimit || number === (totalpageindex - navSideLimit))) {

        items += `<li class="page-item"><button class="page-link" disabled> ... </button></li>`;

      }

    }

    items += `<li class="page-item"><a class="page-link page-next" tabindex="` + nextIndex + `" rel="` + nextIndex + `"type="button"> Next <i class="fa fa-arrow-right"></i></a></li>`;
    paginationBasic = `<nav id="paginationNav" aria-label="..."><ul class="pagination">` + items + `</ul></nav>`;
    setTimeout(function () {
      JQuery('#TabPagination').empty();
      JQuery('#TabPagination').html(paginationBasic.toString());
      JQuery('#paginationNav a').eq(0).addClass('bg-primary text-white');
      JQuery('#paginationNav .page-index').slice(navSideLimit, (totalpageindex - navSideLimit)).hide();
      JQuery('#paginationNav .page-index').slice(navSideLimit, (navSideLimit + navMidLimit)).show();
      JQuery('#paginationNav a').click(function (event) {

        event.preventDefault();

        JQuery('#paginationNav a').removeClass('bg-primary text-white');

        JQuery('#TrackerData tbody tr').slice(0, pageLimit).show();
        let currentPage = parseInt(JQuery(this).attr('rel')),

          startRecordIndex = ((currentPage - 1) * pageLimit),

          endRecordIndex = startRecordIndex + pageLimit;

        prevIndex = (currentPage === 1) ? currentPage : (currentPage - 1);

        nextIndex = (currentPage === totalpageindex) ? currentPage : (currentPage + 1);
        JQuery('#paginationNav .page-prev').attr({ tabindex: prevIndex, rel: prevIndex });

        JQuery('#paginationNav .page-next').attr({ tabindex: nextIndex, rel: nextIndex });

        JQuery('#paginationNav .page-index').slice(navSideLimit, (totalpageindex - navSideLimit)).hide();

        if (currentPage > navSideLimit && currentPage < (totalpageindex - navSideLimit)) {

          if ((currentPage + navMidLimit) <= (totalpageindex - navSideLimit)) {

            JQuery('#paginationNav .page-index').slice((currentPage - 1), ((currentPage - 1) + navMidLimit)).show();

          } else {
            JQuery('#paginationNav .page-index').slice(((totalpageindex - navSideLimit) - navMidLimit), (totalpageindex - navSideLimit)).show();

          }

        } else if (currentPage <= navSideLimit && currentPage < (totalpageindex - navSideLimit)) {

          JQuery('#paginationNav .page-index').slice(navSideLimit, (navSideLimit + navMidLimit)).show();

        } else {

          JQuery('#paginationNav .page-index').slice(((totalpageindex - navSideLimit) - navMidLimit), (totalpageindex - navSideLimit)).show();
        }
        JQuery('#TrackerData tbody tr').slice(0, totalTrackerRecords).hide();

        JQuery('#TrackerData tbody tr').slice(startRecordIndex, endRecordIndex).show();
        JQuery('#paginationNav a[key=' + currentPage + ']').addClass('bg-primary text-white');

      });

    }, 0);

  };

  // Onload effects, only once
  useEffect(() => {
    getFilterSiteID();
    getFilterRequestID();
    getIncompletionApproval();
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    if (temp_role === Constants.roleCommercial || temp_role === Constants.roleundefined) {
      navigate('/accessdenied');
    }
  }, []);

  const handleClose = () => {

    setShow(false);

  }
  return (
    <>
      <div className='order-font container-fluid'>


        <Card.Header style={{ backgroundColor: "#1C1B83" }}>
          <div className='row'>
            <div className='col-md-2' style={{ backgroundColor: "white" }}>
              <img src={process.env.PUBLIC_URL + "/PepsicoLogo.png"} width="140" alt="logo" style={{}} />
            </div>
            <div className='col-md-6 ' style={{ color: "white", backgroundColor: "#1C1B83" }}>
              <h5 style={{ marginTop: ".5rem", textAlign: "left" }}><b>Pick Sequence Incompletion-Resolution Approvals</b></h5>
            </div>
            <div className='col-md-2'>
              <a href='/orders/home'>
                <i class="fa fa-home" aria-hidden="true" style={{ color: 'white', marginLeft: '9vw', fontSize: '2.3rem', marginTop: ".3rem" }}></i>
              </a>
            </div>
            <div className='col-md-2 ' style={{ color: "white", backgroundColor: "white" }}>
              <img src={process.env.PUBLIC_URL + "/LogoImages.png"} width="140" alt="logo" style={{ width: "11rem" }} />
            </div>

          </div>

        </Card.Header>

        <div className='row mt-6' >
          <div className='col-md-1'></div>
          <div className='col-md-10'>

          </div>
          <div className='col-md-1'></div>
        </div>
        <div className='row mt-2' style={{ fontSize: ".9rem" }}>
          <div className='col-md-2 col-sm-2 '>
            <CDBBox className='square border border-primary' style={{ backgroundColor: 'rgb(28, 27, 131)', borderBlock: '#0099e6' }}>
              <div className='row mt-4'>
                <div className='col-md-12' style={{ color: 'white' }}>
                  <h5>Filters</h5>
                </div>
              </div>
              <div className='row  mt-2'>
                <div className="col-md-12" style={{ textAlign: 'left', marginLeft: '1vw' }}>
                  <Form.Label style={{ color: "white" }}>Request ID</Form.Label>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-10' style={{ marginLeft: "1.4vw" }}>

                  <select className="form-select" id="requestid" style={{ textAlign: "center", fontSize: ".9rem" }} >
                  </select>
                </div>
              </div>
              <div className='row mt-3'>
                <div className="col-md-12" style={{ textAlign: 'left', marginLeft: '1vw' }}>
                  <Form.Label style={{ color: "white" }}>Delivery Date From</Form.Label>
                </div>
              </div>
              <div className="row ">
                <div className="col-md-10" style={{ color: "white", marginLeft: "1.4vw" }}>
                  <DatePicker className='form-control' id='DeliveryDateFrom' dateFormat="MM/dd/yyyy" onChange={SetOrderDeliveryFromdate} selected={OrderDeliveryfromdate} placeholderText="MM/DD/YYYY" />
                </div>
              </div>

              <div className='row mt-3'>
                <div className="col-md-12" style={{ textAlign: 'left', marginLeft: '1vw' }}>
                  <Form.Label style={{ color: "white" }}>Delivery Date To</Form.Label>
                </div>
              </div>
              <div className="row ">
                <div className="col-md-10" style={{ color: "white", marginLeft: "1.4vw" }}>
                  <DatePicker className='form-control' id='DeliveryDateTo' dateFormat="MM/dd/yyyy" onChange={SetOrderDeliveryTodate} selected={OrderDeliverytodate} placeholderText="MM/DD/YYYY" />
                </div>
              </div>
              <div className='row  mt-2'>
                <div className="col-md-12" style={{ textAlign: 'left', marginLeft: '1vw' }}>
                  <Form.Label style={{ color: "white" }}>Site ID</Form.Label>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-10' style={{ marginLeft: "1.4vw" }}>

                  <select className="form-select" id="sites" style={{ textAlign: "center", fontSize: ".9rem" }} >
                  </select>
                </div>
              </div>
              <div className='row mt-6 '>
                <div className='col-md-12' style={{ justifyContent: "center" }}>
                  <Button variant="outline-secondary" className="btn" id="submitfilter" style={{ backgroundColor: "rgb(0, 150, 57)", marginTop: "2rem", color: "white" }} onClick={filterTracker}>
                    Submit
                  </Button>
                </div>
              </div>
              <div className='row mt-4'>

              </div>
              <div className='row mt-4'>

              </div>
            </CDBBox>
            <div className='row mt-3'>
              <div className='col-md-12'>

                <a href='/orders/archivedata'>
                  <Button variant="outline-light" class="rgb(0, 150, 57)" style={{ backgroundColor: "rgb(0, 150, 57)", color: "white", marginTop: "0.5rem", fontSize: ".9rem" }}>
                    Link For Archive Data
                  </Button>
                </a>
              </div>
            </div>
          </div>

          {isLoading ? <div className={"loaderWrapper"}>
            <Spinner animation="border" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> : <div className='col-md-10 col-sm-10 ' style={{ marginLeft: "-.7rem" }}>
            <Table responsive striped bordered hover border-primary className='text-sm-small' id="TrackerData">
              <thead className='align-middle'>
                {/* //TABLE HEADER */}
                <tr style={{ backgroundColor: "#1C1B83", color: "white" }}>
                  <th style={{ color: "white" }}>Request ID</th>
                  <th style={{ color: "white" }}>SAP ICR ID</th>
                  <th style={{ color: "white" }}>Site ID</th>
                  <th style={{ color: "white" }}>Delivery Date From</th>
                  <th style={{ color: "white" }}>Delivery Date To</th>
                  <th style={{ color: "white" }}>SAP Material</th>
                  <th style={{ color: "white" }}>Channel</th>
                  <th style={{ color: "white" }}>Action</th>
                  <th style={{ color: "white" }}>Stock in ICS</th>
                  <th style={{ color: "white" }}>Substitution Stock in ICS</th>
                  <th style={{ color: "white" }}>Poet</th>
                  <th style={{ color: "white" }}>SAP Description</th>
                  <th style={{ color: "white" }}>PWM First Description</th>
                  <th style={{ color: "white" }}>Approved Action</th>
                </tr>
              </thead>
              <tbody>
                {/* VALUE OF THE TABLE TO BE FETCHED */}

              </tbody>
            </Table>
            <div>
              <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton >
                  <Modal.Title >  </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                </Modal.Body>
                <Modal.Footer>

                  <Button variant="outline-light" style={{ color: "White", backgroundColor: "#009639" }} onClick={handleClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
          }

          <div className='row mt-3'>
            <div className='col-md-2'>

            </div>
            <div className='col-md-7' id='TabPagination'>
              {paginationBasic}
            </div>
            <div className='col-md-3'>
              <Button variant="outline-light" type="button" id='SubmitIncompletion' style={{ backgroundColor: '#009639', color: 'white', float: 'right' }} onClick={submitvalidation}>
                Submit Approval
              </Button>
              <br /><br />
              <span className='help-block text-danger text-right' id='ValidationAction' style={{ display: 'None', textAlign: 'right' }}>
                <i className='fa fa-spinner fa-spin'></i> Please select a appropriate reason for all the approved action...
              </span>
            </div>
          </div>
        </div>

      </div>
      <div className='modal' id='AlertOnAction' tabindex='-1' aria-labelledby='AlertOnActionLabel' aria-hidden='true'>
        <div className='modal-dialog modal-dialog-top'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h4 className='modal-title' id='AlertOnActionLabel'>...</h4>
              <button variant="outline-light" type='button' rel='CloseAlertOnAction' className='CloseAlertOnAction btn-close' data-bs-dismiss='modal' aria-label='Close' onClick={function (event) { JQuery('.' + event.target.getAttribute('rel')).parents('.modal').fadeOut(); JQuery('.container-fluid').css("opacity", "1.0"); }}>
              </button>
            </div>
            <input type='hidden' id='distinctRequestStorage' />
            <div className='modal-body' id='AlertOnActionBody'>
              ...
            </div>
            <div className='modal-footer'>
              <button variant="outline-light" type='button' id="close" rel='CloseAlertOnAction' className='CloseAlertOnAction btn' style={{ backgroundColor: "green", border: "green" }} data-bs-dismiss='modal' onClick={function (event) { JQuery('.' + event.target.getAttribute('rel')).parents('.modal').fadeOut(); JQuery('.container-fluid').css("opacity", "1.0"); }}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
export default TrackerIncompletion;