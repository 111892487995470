import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';

import JQuery, { event } from 'jquery';
import UtilBaseUrl from '../../ConfigUtility';


const getToken = () => {
    let tokenStr = localStorage.getItem("okta-token-storage");

    if (!tokenStr) {
        return;
    }
    else {
        const tokenObj = JSON.parse(tokenStr);

        return tokenObj?.accessToken?.accessToken;
    }
}

const CutlistEdit = ({ editCutlistmaterialFormData, handleEditCutlistmaterialFormChange,
    handelCancelCutlistmaterialClick, handleEditCutlistmaterialFormSubmit }) => {

    const hostUrl = UtilBaseUrl.URL;

    const token = getToken();
    const headers = {
        "accept": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${token}`
    }


    let reasonCodeResponse = [], reasonCodeOptions = '';
    // page Load//

    useEffect(() => {


        JQuery.ajax({
            url: hostUrl + "/ordermodification/reason-code",
            type: 'POST',
            dataType: 'json',
            CORS: true,
            contentType: 'application/json',
            secure: true,
            headers: headers,
            crossDomain: true,
            success: function (response) {
                reasonCodeOptions = '<option value="" name ="" selected>--Select--</option>\n';
                reasonCodeResponse = response["response"];
                for (let i = 0; i < reasonCodeResponse.length; i++) {
                    reasonCodeOptions += '<option value="' + reasonCodeResponse[i]["description"] + '" name="' + reasonCodeResponse[i]["description"] + '">' + reasonCodeResponse[i]["description"] + '</option>\n';
                }


                JQuery("#ReasonCodeCutlist").html(reasonCodeOptions);
                JQuery('#ReasonCodeCutlist').val(editCutlistmaterialFormData.ReasonCode);




            },
            error: function (jqHXR, textStatus, errorThrown) {


            }

        });


    }, []);

    // material Decription //
    const setMaterialDecription = (event) => {


        let materialcode = event.target.value;
        if (materialcode.length === 13) {

            checkValidationMaterial(event);
            ValidMaterial(event);

        }

        else {


            if (event.target.id === "TableMaterialCodeCutlist") {

                JQuery("#TableMaterialCodeCutlist").val(materialcode);
                JQuery("#Invalid_Status_MaterialCodeCut").hide();
                JQuery("#Inactive_Status_MaterialCodeCut").hide();

                JQuery("#TableMaterialCodeCutlist").removeAttr("style");
                JQuery("#TableMaterialCodeCutlist").css({ "width": "10rem" });

            }
        }

    }


    // Check validtion  material  //
    const checkValidationMaterial = (event) => {
        let materialcode = event.target.value;

        const token = getToken();
        const headers = {
            "accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }

        JQuery.ajax({
            url: hostUrl + "/orderModification/materialValidation",

            type: 'POST',
            dataType: 'json',
            data: JSON.stringify({
                "materialNumber": materialcode
            }),
            CORS: true,
            contentType: 'application/json',
            secure: true,
            headers: headers,
            crossDomain: true,
            success: (res) => {


                let MaterialCodeMessage = res.errormessage;

                if (MaterialCodeMessage === "Material Exists") {

                    if (event.target.id === "TableMaterialCodeCutlist") {

                        JQuery("#Invalid_Status_MaterialCodeCut").hide();
                        JQuery("#Inactive_Status_MaterialCodeCut").hide();
                        JQuery("#TableMaterialCodeCutlist").removeAttr("style");
                        JQuery("#TableMaterialCodeCutlist").css({ "width": "10rem" });


                    }

                }
                else if (MaterialCodeMessage === "Material is not active") {


                    if (event.target.id === "TableMaterialCodeCutlist") {

                        JQuery("#Invalid_Status_MaterialCodeCut").hide();
                        JQuery("#Inactive_Status_MaterialCodeCut").show();
                        JQuery("#TableMaterialCodeCutlist").css("border", "1px solid red");

                        JQuery("#TableMaterialDescriptionCutlist").val(res.response.materialDesciption);
                        JQuery("#TableUnitOfMeasureCut").val(res.response.umo);
                    }

                }
                else {

                    if (event.target.id === "TableMaterialCodeCutlist") {

                        JQuery("#Invalid_Status_MaterialCodeCut").show();
                        JQuery("#Inactive_Status_MaterialCodeCut").hide();
                        JQuery("#TableMaterialCodeCutlist").css("border", "1px solid red");

                        JQuery("#TableMaterialDescriptionCutlist").val(res.response.materialDesciption);
                        JQuery("#TableUnitOfMeasureCut").val(res.response.umo);
                    }

                }

            },
            error: function (jqHXR, textStatus, errorThrown) {


            }

        });

    }

    // valid material//
    const ValidMaterial = (event) => {

        let materialcode = event.target.value;

        const token = getToken();
        const headers = {
            "accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }

        JQuery.ajax({
            url: hostUrl + "/ordermodification/materialDescription",

            type: 'POST',
            dataType: 'json',
            data: JSON.stringify({
                "materialNumber": materialcode
            }),
            CORS: true,
            contentType: 'application/json',
            secure: true,
            headers: headers,
            crossDomain: true,
            success: (res) => {

                ResponseSetMaterial(res.response, event.target.id, materialcode);

            },
            error: function (jqHXR, textStatus, errorThrown) {


            }

        });
    }

    const ResponseSetMaterial = (Response, ID, materialcode) => {


        if (ID === "TableMaterialCodeCutlist") {

            JQuery("#TableMaterialDescriptionCutlist").val(Response.materialDesciption);
            JQuery("#TableUnitOfMeasureCut").val(Response.umo);

        }

    }

    return (


        <tr id='AddItems'>
            <td style={{ verticalAlign: "middle" }}><input type="text" style={{ width: "10rem" }}

                id="TableMaterialCodeCutlist"
                name="MaterialCode"
                required="required"
                idx="restrictedDecimal"
                value={editCutlistmaterialFormData.MaterialCode}

                onChange={(e) => { handleEditCutlistmaterialFormChange(e); setMaterialDecription(e) }}

            />
                <br />
                <span className="help-block text-danger" id="Invalid_MaterialCodeCut" style={{ display: "None", float: "left" }}> Please enter a 13 digit numeric Material Code</span>
                <span className="help-block text-danger" id="Invalid_Status_MaterialCodeCut" style={{ display: "None", float: "left" }}>The Material Code entered is invalid</span>
                <span className="help-block text-danger" id="Inactive_Status_MaterialCodeCut" style={{ display: "None", float: "left" }}>The Material Code entered is inactive</span>

            </td>
            <td style={{ verticalAlign: "middle" }}>
                <input type="text" style={{ width: "10rem" }}
                    id="TableMaterialDescriptionCutlist"
                    name="MaterialDescription"
                    required="required"
                    value={editCutlistmaterialFormData.MaterialDescription}
                    onChange={handleEditCutlistmaterialFormChange}

                />
                <br />
                <span class="help-block text-danger" id="Invalid_MaterialDescriptionCut" style={{ display: "None", float: "left" }}> Please enter Material Description</span>
            </td>
            <td style={{ verticalAlign: "middle" }}>

                <select name='UnitOfMeasure' id="TableUnitOfMeasureCut"
                    value={editCutlistmaterialFormData.UnitOfMeasure}
                    onChange={handleEditCutlistmaterialFormChange} style={{ width: "10rem", height: "1.80rem" }}>
                    <option selected name="" value="" >--Select--</option>
                    <option name="CS" value="CS">CS</option>
                    <option name="EA" value="EA">EA</option>
                    <option name="DS" value="DS">DS</option>
                    <option name="PAC" value="PAC" >PAC</option>

                </select>
                <br />
                <span class="help-block text-danger" id="Invalid_UnitOfMeasureCut" style={{ display: "None", float: "left" }}>Please select Unit of Measure</span>

            </td>
            <td style={{ verticalAlign: "middle" }}><input type="text" style={{ width: "5rem" }}

                id="TableCutValue"
                name="CutValue"
                required="required"
                idx="restrictedDecimal"
                value={editCutlistmaterialFormData.CutValue}
                onChange={handleEditCutlistmaterialFormChange}

            />
                <br />
                <span class="help-block text-danger" id="Invalid_CutValue" style={{ display: "None", float: "left" }}>Please enter Cut Value</span>

            </td>
            <td style={{ verticalAlign: "middle" }}><input type="text" style={{ width: "5rem" }}

                id="TableCutValueQty"
                name="CutValueQty"
                required="required"

                value={editCutlistmaterialFormData.CutValueQty}
                onChange={handleEditCutlistmaterialFormChange}

            />
                <br />
                <span class="help-block text-danger" id="Invalid_CutValueQty" style={{ display: "None" }}>Please enter Cut Value Quantity or Percentage (Q/P)</span>


            </td>
            <td style={{ verticalAlign: "middle" }}>
                <select name='ReasonCode' id='ReasonCodeCutlist' value={editCutlistmaterialFormData.ReasonCode}
                    onChange={handleEditCutlistmaterialFormChange} style={{ width: "10rem", height: "1.80rem" }}>

                </select>
                <br />
                <span class="help-block text-danger" id="Invalid_ReasonCodeCutlist" style={{ display: "None" }}>Please select a Reason Code</span>

            </td>

            <td className='text-center'>
                <Button variant="outline-light" onClick={handleEditCutlistmaterialFormSubmit} style={{ width: "5rem", backgroundColor: "#009639", color: "white" }}>Save</Button>
                <Button variant="outline-light" onClick={() => handelCancelCutlistmaterialClick()} style={{ marginLeft: "1vw", width: "7rem", backgroundColor: "#FF0000", color: "white" }}>Cancel</Button>
            </td>

        </tr>
    )

}
export default CutlistEdit;