import React from 'react';
import Button from 'react-bootstrap/Button';


const ReadOnlyRows = ({ material, handleEditClick }) => {


    return (

        <tr >
            <td style={{ backgroundColor: "#e9ecef", verticalAlign: "middle" }}>{material.materialNumber}</td>
            <td style={{ backgroundColor: "#e9ecef", verticalAlign: "middle" }}>{material.upc}</td>
            <td style={{ backgroundColor: "#e9ecef", verticalAlign: "middle" }}>{material.materialDescription}</td>
            <td style={{ backgroundColor: "#e9ecef", verticalAlign: "middle" }}>{material.uom}</td>
            <td style={{ verticalAlign: "middle" }}>{material.orderQuantity}</td>
            <td style={{ verticalAlign: "middle" }} >
                <select class="form-select" name="ReasonCode" onfocus='this.size=10;' disabled>
                    <option value={(material.reasonCode === "") ? "Please Select" : material.reasonCode} >
                        {(material.reasonCode === "") ? "Please Select" : material.reasonCodeDesc}
                    </option>
                </select>
            </td>
            <td>
                <Button variant="outline-light" onClick={(event) => handleEditClick(event, material)} style={{ backgroundColor: "#009639", color: "white" }} >Edit</Button>
            </td>

        </tr>
    )

}
export default ReadOnlyRows;