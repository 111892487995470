import React from 'react';

import TrafficLight from 'react-trafficlight';
import Button from 'react-bootstrap/Button';

import UtilBaseUrl from '../ConfigUtility';

import * as Constants  from '../Common Files/Constants.js';

let temp_emailid = sessionStorage.getItem("emailid");
let temp_gpid = sessionStorage.getItem("gpid");
let temp_role = sessionStorage.getItem("role");

const ReadOnlyRowsDashboard = ({ dashboardObj, handleMonitoring, handleRefreshing }) => {

    const hostUrl = UtilBaseUrl.URL;


    // traffic light for dashboard data value//
    function ArrayTrafficLight(objectvalue) {


        if (objectvalue === Constants.ArrayTrafficLightGreen) {

            return (
                <div>

                    <TrafficLight Size="25" Horizontal={true} GreenOn />
                </div>

            );
        }
        else if (objectvalue === Constants.ArrayTrafficLightYellow) {

            return (
                <div>

                    <TrafficLight Size="25" Horizontal={true} YellowOn />
                </div>

            );

        }
        else if (objectvalue === Constants.ArrayTrafficLightRed) {

            return (
                <div>

                    <TrafficLight Size="25" Horizontal={true} RedOn />
                </div>

            );

        }
        else {

            return (
                <div>

                    <TrafficLight Size="25" Horizontal={true} />
                </div>

            );

        }

    }

    // traffic light for status
    function ArrayStatusTrafficLight(objectvalue) {


        if (objectvalue === Constants.ArrayStatusTrafficLightGreen) {

            return (
                <div>

                    <TrafficLight Size="25" Horizontal={true} GreenOn />
                </div>

            );
        }
        else if (objectvalue === Constants.ArrayStatusTrafficLightYellow) {

            return (
                <div>

                    <TrafficLight Size="25" Horizontal={true} YellowOn />
                </div>

            );

        }
        else if (objectvalue === Constants.ArrayStatusTrafficLightRed) {

            return (
                <div>

                    <TrafficLight Size="25" Horizontal={true} RedOn />
                </div>

            );

        }
        else {

            return (
                <div>

                    <TrafficLight Size="25" Horizontal={true} />
                </div>

            );

        }

    }



    let formatteddate, monitoringStartSplitDesc, monitoringStartSplittime;

    // date format //

    function dateDisplay(value) {
        let Monitoringstart = dashboardObj.monitoringStartedAt;

        monitoringStartSplitDesc = Monitoringstart.split(":")[0] + ":";
        monitoringStartSplittime = Monitoringstart.split(":")[1] + ":" + Monitoringstart.split(":")[2] + ":" + Monitoringstart.split(":")[3];


        let storeDate = (dashboardObj.deliveryDate), monthDisplay;

        let monthSplit = storeDate.split("-")[1]

        if (monthSplit === "01") {
            monthDisplay = "Jan";
        }
        else if (monthSplit === "02") {
            monthDisplay = "Feb";
        }
        else if (monthSplit === "03") {
            monthDisplay = "Mar";
        }
        else if (monthSplit === "04") {
            monthDisplay = "Apr";
        }
        else if (monthSplit === "05") {
            monthDisplay = "May";
        }
        else if (monthSplit === "06") {
            monthDisplay = "Jun";
        }
        else if (monthSplit === "07") {
            monthDisplay = "Jul";
        }
        else if (monthSplit === "08") {
            monthDisplay = "Aug";
        }
        else if (monthSplit === "09") {
            monthDisplay = "Sept";
        }
        else if (monthSplit === "10") {
            monthDisplay = "Oct";
        }
        else if (monthSplit === "11") {
            monthDisplay = "Nov";
        }
        else {
            monthDisplay = "Dec";
        }

        formatteddate = monthDisplay + " " + storeDate.split("-")[2] + "," + storeDate.split("-")[0];


    }

    // Monitoring status //

    function MonitoringDisplayStatus(monitoringStatus, site, lmsSiteId, countryCode, deliveryDate, sNo) {

        if (monitoringStatus === Constants.MonitoringStatus0) {

            return (
                <div>

                    <Button title='Begins 1-hour monitoring for data flow at this site' id={sNo + "_Monitoring"} variant="outline-light"
                        style={{ backgroundColor: "#BBDF9E", color: "black", fontSize: 10 }}
                        onClick={() => handleMonitoring(site, lmsSiteId, countryCode, deliveryDate, sNo, true)}>Begin Monitoring
                    </Button>
                    <Button id={sNo + "_Refresh"} variant="outline-light"
                        style={{ backgroundColor: "#BBDF9E", color: "black", fontSize: 10, display: "none" }}
                        onClick={() => handleRefreshing(site, lmsSiteId, countryCode, deliveryDate, sNo, true)}>
                        <span><i class='fa fa-refresh' ></i></span>
                    </Button>
                </div>

            );

        }
        else if (monitoringStatus === Constants.MonitoringStatus1) {

            return (
                <div>
                    <Button id={sNo + "_Monitoring"} variant="outline-light"
                        style={{ backgroundColor: "gray", color: "black", fontSize: 10 }}
                        disabled>Monitoring Started
                    </Button>
                    <Button title='Resets 1-hour monitoring timer for this site (status column will also be reset)' id={sNo + "_Refresh"} variant="outline-light"
                        style={{ backgroundColor: "#BBDF9E", color: "black", fontSize: 10 }}
                        onClick={() => handleRefreshing(site, lmsSiteId, countryCode, deliveryDate, sNo, true)}>
                        <span><i class='fa fa-refresh' ></i></span>
                    </Button>

                </div>

            );
        }
        else if (monitoringStatus === Constants.MonitoringStatus2) {

            return (
                <div>
                    <Button id={sNo + "_Monitoring"} variant="outline-light"
                        style={{ backgroundColor: "gray", color: "black", fontSize: 10 }}
                        disabled>Monitoring Ended
                    </Button>
                    <Button title='Resets 1-hour monitoring timer for this site (status column will also be reset)' id={sNo + "_Refresh"} variant="outline-light"
                        style={{ backgroundColor: "#BBDF9E", color: "black", fontSize: 10 }}
                        onClick={() => handleRefreshing(site, lmsSiteId, countryCode, deliveryDate, sNo, true)}>
                        <span><i class='fa fa-refresh' ></i></span>
                    </Button>

                </div>

            );
        }

    }

    return (


        <tr key={dashboardObj.sNo} style={{ verticalAlign: "middle", fontSize: 12 }} >
            <td>

                <div className="row " >
                    <div className="col-md-12" >
                        <span style={{ fontWeight: 'bold' }}>{dashboardObj.site}</span>
                        <br />
                        <span>{dateDisplay(dashboardObj.deliveryDate)}({formatteddate})</span>

                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-12" >
                        {MonitoringDisplayStatus(dashboardObj.monitoringStatus, dashboardObj.site, dashboardObj.lmsSiteId, dashboardObj.countryCode, dashboardObj.deliveryDate, dashboardObj.sNo)}
                    </div>
                </div>

            </td>
            {dashboardObj.ordersCreatedInSAP !== "N/A" || dashboardObj.ordersReleasedInSAP !== "N/A" || dashboardObj.tripsInSAP !== "N/A" ?
                <td >
                    <div className="row " >
                        <div className="col-md-12" >
                            {dashboardObj.ordersCreatedInSAP}
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-12" >
                            {dashboardObj.ordersReleasedInSAP}
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-12">
                            {dashboardObj.tripsInSAP}
                        </div>
                    </div>

                </td>
                :
                <td>

                    <div className="row ">
                        <div className="col-md-12">
                            {"N/A"}
                        </div>
                    </div>

                </td>}
            {dashboardObj.ordersReleasedInETMS !== "N/A" || dashboardObj.tripsInETMS !== "N/A" ?
                <td>
                    <div className="row ">
                        <div className="col-md-12" >
                            {dashboardObj.ordersReleasedInETMS}
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-12" >
                            {dashboardObj.tripsInETMS}
                        </div>
                    </div>
                    <div className="row mt-2">

                    </div>

                    <div className="row mt-2 " >
                        {ArrayTrafficLight(dashboardObj.etmsFlag)}


                    </div>
                </td>
                :
                <td>

                    <div className="row ">
                        <div className="col-md-12">
                            {"N/A"}
                        </div>
                    </div>

                </td>}
            {dashboardObj.ordersReleasedInPWM !== "N/A" || dashboardObj.tripsinPWM !== "N/A" ?
                <td>
                    <div className="row ">
                        <div className="col-md-12">
                            {dashboardObj.ordersReleasedInPWM}
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-12">
                            {dashboardObj.tripsinPWM}
                        </div>
                    </div>
                    <div className="row mt-2">

                    </div>
                    <div className="row mt-2 ">

                        {ArrayTrafficLight(dashboardObj.pwmFlag)}

                    </div>
                </td>
                :
                <td>

                    <div className="row ">
                        <div className="col-md-12">
                            {"N/A"}
                        </div>
                    </div>

                </td>}
            {dashboardObj.ordersReleasedInIPM !== "N/A" || dashboardObj.tripsInIPM !== "N/A" ?
                <td>

                    <div className="row ">
                        <div className="col-md-12">
                            {dashboardObj.ordersReleasedInIPM}
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-12">
                            {dashboardObj.tripsInIPM}
                        </div>
                    </div>

                    <div className="row mt-2">

                    </div>
                    <div className="row mt-2 ">

                        {ArrayTrafficLight(dashboardObj.ipmFlag)}

                    </div>


                </td>
                :
                <td>

                    <div className="row ">
                        <div className="col-md-12">
                            {"N/A"}
                        </div>
                    </div>

                </td>
            }
            {dashboardObj.ordersReleasedInICS !== "N/A" || dashboardObj.tripsInICS !== "N/A" ?

                <td >
                    <div className="row ">
                        <div className="col-md-12">
                            {dashboardObj.ordersReleasedInICS}
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-12">
                            {dashboardObj.tripsInICS}
                        </div>
                    </div>
                    <div className="row mt-2">

                    </div>
                    <div className="row mt-2">


                        {ArrayTrafficLight(dashboardObj.icsFlag)}
                    </div>

                </td>
                : <td>

                    <div className="row ">
                        <div className="col-md-12">
                            {"N/A"}
                        </div>
                    </div>

                </td>
            }


            {dashboardObj.monitoringStartedAt === "Monitoring has not started" ?
                <td>

                    <div className="row ">
                        <div className="col-md-12" id={dashboardObj.sNo + "_Status_Monitoting_id"} >
                            {dashboardObj.monitoringStartedAt}
                        </div>
                    </div>

                    <div className="row ">
                        <div className="col-md-12">
                            {dashboardObj.timeElapsed}
                        </div>
                    </div>
                    <div className="row mt-2">

                    </div>
                    <div className="row mt-2">
                        {ArrayStatusTrafficLight(dashboardObj.status)}
                    </div>
                </td>
                :
                <td>

                    <div className="row ">
                        <div className="col-md-12" id={dashboardObj.sNo + "_Status_Monitoting_id"} >
                            {monitoringStartSplitDesc}
                        </div>
                    </div>
                    <div className="row " id={dashboardObj.sNo + "_Status_Monitoting_id2"}>
                        <div className="col-md-12"  >
                            {monitoringStartSplittime}
                        </div>
                    </div>

                    <div className="row ">
                        <div className="col-md-12" id={dashboardObj.sNo + "_Status_Monitoting_id3"}>
                            {dashboardObj.timeElapsed}
                        </div>
                    </div>
                    <div className="row mt-2">

                    </div>
                    <div className="row mt-2">
                        {ArrayStatusTrafficLight(dashboardObj.status)}
                    </div>
                </td>

            }
        </tr>


    )

}
export default ReadOnlyRowsDashboard;