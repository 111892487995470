import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import ConfigUtility from '../ConfigUtility';
import * as Constants from '../Common Files/Constants.js';


function LandingPage() {


 
  let temp_role = sessionStorage.getItem("role");

  return (
    <>
      <div className="order-font container-fluid">

        <Card.Header style={{ backgroundColor: "#1C1B83" }}>
          <div className='row'>
            <div className='col-md-2' style={{ backgroundColor: "white" }}>
              <img src={process.env.PUBLIC_URL + "/PepsicoLogo.png"} width="140" alt="logo" style={{}} />
            </div>
            <div className='col-md-8 ' style={{ color: "white", backgroundColor: "#1C1B83" }}>
              <h5 style={{ marginTop: ".5rem" }}><b>Digital Factory Hub: PFNA Order Processing</b></h5>
            </div>
            <div className='col-md-2 ' style={{ color: "white", backgroundColor: "white" }}>
              <img src={process.env.PUBLIC_URL + "/LogoImages.png"} width="140" alt="logo" style={{ width: "11rem" }} />
            </div>
          </div>

        </Card.Header>

        <Card.Body style={{ backgroundColor: "white" }}>
          <div className='row'>

            <div className='col-md-12'>
              <h5 style={{ marginTop: "0.5rem" }}><b>Welcome to Digital Factory PFNA Order Processing Hub!</b></h5>
            </div>
            <div className='col-md-12'>
              <div className='row'>
                <div className='col-md-12' style={{ textAlign: 'initial' }}>

                </div>
              </div>
              <div className='row mt-3' style={{ textAlign: 'center' }}>
              </div>
              <div className='row mt-2'>
                <div className='col-md-4 sm-4 lg-4 mt-2'>

                  {temp_role === Constants.roleAdmin || temp_role === Constants.roleCSR || temp_role === Constants.roleSeniorCSR || temp_role === Constants.roleCommercial || temp_role === Constants.roleSite ?
                    <a href='/orders/ordermodification'>
                      <Button style={{ backgroundColor: '#1C1B83', color: 'white', height: '3.8rem', width: '19rem' }}><img src={process.env.PUBLIC_URL + "/Clipboard.png"} style={{ background: 'white', float: 'left', marginTop: "0.15rem", height: '2.7rem', width: '2.5rem' }} />
                        <b>Order Modifications Webform</b>
                      </Button>
                    </a>
                    :

                    <Button style={{ backgroundColor: '#1C1B83', color: 'white', height: '3.8rem', width: '19rem' }} disabled><img src={process.env.PUBLIC_URL + "/Clipboard.png"} style={{ background: 'white', float: 'left', marginTop: "0.15rem", height: '2.7rem', width: '2.5rem' }} />
                      <b>Order Modifications Webform</b>
                    </Button>
                  }


                </div>
                <div className='col-md-4 sm-4 lg-4 mt-2'>
                  {temp_role === Constants.roleAdmin || temp_role === Constants.roleCSR || temp_role === Constants.roleSeniorCSR ||  temp_role === Constants.roleSite ?
                  
                    <a href='/orders/icrcreationform'>
                      <Button style={{ backgroundColor: '#1C1B83', color: 'white', height: '3.8rem', width: '19rem' }}><img src={process.env.PUBLIC_URL + "/Playbook.png"} style={{ background: 'white', float: 'left', marginTop: "0.15rem", height: '2.7rem', width: '2.5rem' }} />
                        <b>Item Conversion Rule (ICR) Creation Webform</b>
                      </Button>
                    </a>
                    :
                    <Button style={{ backgroundColor: '#1C1B83', color: 'white', height: '3.8rem', width: '19rem' }} disabled><img src={process.env.PUBLIC_URL + "/Playbook.png"} style={{ background: 'white', float: 'left', marginTop: "0.15rem", height: '2.7rem', width: '2.5rem' }} />
                      <b>Item Conversion Rule (ICR) Creation Webform</b>
                    </Button>
                  }
                </div>
                <div className='col-md-4 sm-4 lg-4 mt-2'>
                  {temp_role === Constants.roleAdmin || temp_role === Constants.roleCSR || temp_role === Constants.roleSeniorCSR ||  temp_role === Constants.roleSite ?
                  
                    <a href='/orders/trackerincompletion'>
                      <Button style={{ backgroundColor: '#1C1B83', color: 'white', height: '3.8rem', width: '19rem' }}><img src={process.env.PUBLIC_URL + "/warning.png"} style={{ background: 'white', float: 'left', marginTop: "0.15rem", height: '2.7rem', width: '2.7rem' }} />
                        <b>Incompletion Resolution Approval</b>
                      </Button>
                    </a>
                    :
                    <Button style={{ backgroundColor: '#1C1B83', color: 'white', height: '3.8rem', width: '19rem' }} disabled><img src={process.env.PUBLIC_URL + "/warning.png"} style={{ background: 'white', float: 'left', marginTop: "0.15rem", height: '2.7rem', width: '2.7rem' }} />
                      <b>Incompletion Resolution Approval</b>
                    </Button>

                  }

                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-md-4 sm-4 lg-4 mt-2'>
                  {temp_role === Constants.roleAdmin || temp_role === Constants.roleCSR || temp_role === Constants.roleSeniorCSR || temp_role === Constants.roleCommercial || temp_role === Constants.roleSite ?
                  
                    <a href='/orders/requesttracker'>

                      <Button style={{ backgroundColor: '#1C1B83', color: 'white', height: '3.8rem', width: '19rem' }}><img src={process.env.PUBLIC_URL + "/Checkbox.png"} style={{ background: 'white', float: 'left', marginTop: "0.15rem", height: '2.7rem', width: '2.5rem' }} />
                        <b>Request Tracker and Approval Dashboard</b>
                      </Button>
                    </a>
                    :
                    <Button style={{ backgroundColor: '#1C1B83', color: 'white', height: '3.8rem', width: '19rem' }} disabled><img src={process.env.PUBLIC_URL + "/Checkbox.png"} style={{ background: 'white', float: 'left', marginTop: "0.15rem", height: '2.7rem', width: '2.5rem' }} />
                      <b> Request Tracker and Approval Dashboard</b>
                    </Button>

                  }

                </div>
                <div className='col-md-4 sm-4 lg-4 mt-2'>
                  {temp_role === Constants.roleAdmin || temp_role === Constants.roleCSR || temp_role === Constants.roleSeniorCSR  ?
                    <a href='/orders/orderflowstatusdashboard'>
                      <Button style={{ backgroundColor: '#1C1B83', color: 'white', height: '3.8rem', width: '19rem' }}><img src={process.env.PUBLIC_URL + "/Flow.png"} style={{ background: 'white', float: 'left', marginTop: "0.15rem", height: '2.7rem', width: '2.5rem' }} />
                        <b>Order Flow Status Dashboard</b>
                      </Button>
                    </a>
                    :
                    <Button style={{ backgroundColor: '#1C1B83', color: 'white', height: '3.8rem', width: '19rem' }} disabled><img src={process.env.PUBLIC_URL + "/Flow.png"} style={{ background: 'white', float: 'left', marginTop: "0.15rem", height: '2.7rem', width: '2.5rem' }} />
                      <b>Order Flow Status Dashboard</b>
                    </Button>
                  }
                </div>
                <div className='col-md-4 sm-4 lg-4 mt-2'>
                  <div className='row' style={{ justifyContent: "center" }}>
                    <a target="_blank" href={ConfigUtility.requestaccess}>
                      <Button style={{ backgroundColor: '#1C1B83', color: 'white', width: '19rem', fontSize: '.73rem' }}><b>Request Access to Order Processing Hub</b></Button>
                    </a>
                  </div>
                  <div className='row' style={{ justifyContent: "center" }} >
                    <a target="_blank" href={ConfigUtility.traininghub}>
                      <Button style={{ backgroundColor: '#1C1B83', color: 'white', width: '19rem', fontSize: '.73rem', marginTop: '.1em' }}><b>Order Processing Hub Trainings and FAQs</b></Button>
                    </a>
                  </div>
                </div>
              </div>
              <div class='row mt-3'>
                <div className='col-md-12'>
                  <img src={process.env.PUBLIC_URL + "/LandingPage.png"} alt="logo" style={{ width: "70vw" }} />
                </div>
              </div>
            </div>

          </div>
        </Card.Body>

      </div >
    </>
  );
}

export default LandingPage;