import React, { useState, Component, useEffect } from 'react';
import { Navigate } from "react-router-dom";
import makeAnimated from "react-select/animated";
import SelectAll from '../Common Files/SelectAll';
import "../Styles.css";
import { components } from "react-select";
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import JQuery from 'jquery';
import ReactPaginate from 'react-paginate';


import ReadOnlyRowsRequestTracker from "./ReadonlyRowsRequestTracker.js";

import UtilBaseUrl from '../ConfigUtility';
import * as Constants from '../Common Files/Constants.js';

import _ from 'lodash';
import { Spinner } from 'react-bootstrap';



// multiple selection checkbox//

const Option = props => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const allOption = {
    label: "Select all",
    value: "*"
};

// select all values checked//
const ValueContainer = ({ children, ...props }) => {
    const currentValues = props.getValue();
    let toBeRendered = children;
    if (currentValues.some(val => val.value === allOption.value)) {
        toBeRendered = [[children[0][0]], children[1]];
    }

    return (
        <components.ValueContainer {...props}>
            {toBeRendered}
        </components.ValueContainer>
    );
};


const MultiValue = props => {
    let labelToBeDisplayed = `${props.data.label} `;
    if (props.data.value === allOption.value) {
        labelToBeDisplayed = "All Selected";
    }
    return (
        <components.MultiValue {...props}>
            <span>{labelToBeDisplayed}</span>
        </components.MultiValue>
    );
};

const animatedComponents = makeAnimated();

const pageSize = 5;


const getToken = () => {
    let tokenStr = localStorage.getItem("okta-token-storage");

    if (!tokenStr) {
        return;
    }
    else {
        const tokenObj = JSON.parse(tokenStr);

        return tokenObj?.accessToken?.accessToken;
    }
}

const removeDuplicates = (objArray) => {
    let jsonObject = objArray.map(JSON.stringify);
    let uniqueSet = new Set(jsonObject);
    let uniqueArray = Array.from(uniqueSet).map(JSON.parse);

    return uniqueArray;
}

let temp_emailid = null;
let temp_gpid = null;
let temp_role = null;

const hostUrl = UtilBaseUrl.URL;
const denyResons = ["Late request", "Release/Lock has been done", "Not enough material/resources", "Missing Information", "Others"]
export default class OrderProcessingRequestTracker extends Component {

    constructor(props) {


        super(props);

        this.state = {

            optionSelectedRequestId: null,
            optionSelectedSiteIdSiteName: null,
            optionSelectedRequestType: null,
            optionSelectedStatus: null,
            selectedDenyReason: null,
            selectedDenyRequestID: null,
            AccessDenied: false,

            SelectedRequestID: [],
            SelectedSiteIdSiteName: [],
            SelectedRequestType: [],
            SelectedStatus: [],

            show: false,

            pageCount: 0,
            currentPage: 0,


            temp__requestid: [],
            temp__siteidsitename: [],
            temp__requesttype: [],
            temp__status: [],
            temp__RequestTrackerData: [],
            temp__RequestTrackerDataTotal: []




        };


    }


    // page load//
    componentDidMount() {

        temp_emailid = sessionStorage.getItem("emailid");
        temp_gpid = sessionStorage.getItem("gpid");
        temp_role = sessionStorage.getItem("role");

        if (temp_role === Constants.roleundefined) {

            this.setState({ AccessDenied: true });

        }


        const token = getToken();

        const headers = {
            "accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }

        // Get RequesiID//
        JQuery.ajax({
            url: hostUrl + "/Request-Approval-Tracker/getRequestIdApproved",

            type: 'POST',
            dataType: 'json',
            data: JSON.stringify({

                "role": temp_role,
                "emailId": temp_emailid,
                "type": "F"
            }),
            CORS: true,
            contentType: 'application/json',
            secure: true,
            headers: headers,
            crossDomain: true,
            success: (res) => {
                let temp_requestid2 = [];

                for (let i = 0; i < res.response.length; i++) {
                    temp_requestid2 = res.response.reduce((values, value) => {

                        values.push({
                            id: parseInt(value.RequestID),
                            label: value.Value,
                            value: parseInt(value.Value),

                        });

                        return values;
                    }, []);
                }
                this.setState({ temp__requestid: temp_requestid2 });
            },
            error: function (jqHXR, textStatus, errorThrown) {


            }
        });



        // Get Request Type//
        JQuery.ajax({
            url: hostUrl + "/approvalMatrix/getRequestTypeApprovalSP",

            type: 'POST',
            dataType: 'json',
            data: JSON.stringify({

                "role": temp_role,
                "emailId": temp_emailid,
                "type": "F"
            }),
            CORS: true,
            contentType: 'application/json',
            secure: true,
            headers: headers,
            crossDomain: true,
            success: (res) => {
                let temp_requesttype2 = [];

                for (let i = 0; i < res.response.length; i++) {
                    temp_requesttype2 = res.response.reduce((values, value) => {

                        values.push({

                            label: value.columnname,
                            value: value.value,

                        });

                        return values;
                    }, []);
                }
                this.setState({ temp__requesttype: temp_requesttype2 });
            },
            error: function (jqHXR, textStatus, errorThrown) {


            }

        });

        // Get Request Status//
        JQuery.ajax({
            url: hostUrl + "/approvalMatrix/getRequestApprovalStatus",

            type: 'POST',
            dataType: 'json',
            data: JSON.stringify({

                "role": temp_role,
                "emailId": temp_emailid,
                "type": "F"
            }),
            CORS: true,
            contentType: 'application/json',
            secure: true,
            headers: headers,
            crossDomain: true,
            success: (res) => {
                let temp_status2 = [];

                for (let i = 0; i < res.response.length; i++) {
                    temp_status2 = res.response.reduce((values, value) => {

                        values.push({

                            label: value.columnname,
                            value: value.value,

                        });

                        return values;
                    }, []);
                }
                this.setState({ temp__status: temp_status2 });
            },
            error: function (jqHXR, textStatus, errorThrown) {


            }

        });


        this.setState({ isLoading: true });

        // Get Site ID//
        JQuery.ajax({
            url: hostUrl + "/Request-Approval-Tracker/getSiteValues",

            type: 'POST',
            dataType: 'json',
            data: JSON.stringify({

                "role": temp_role,
                "emailId": temp_emailid,
                "type": "F"
            }),
            CORS: true,
            contentType: 'application/json',
            secure: true,
            headers: headers,
            crossDomain: true,
            success: (SiteIdres) => {
                let temp_siteidsitename2 = [];
                let siteDetailsObj = {};
                for (let i = 0; i < SiteIdres.response.length; i++) {
                    temp_siteidsitename2 = SiteIdres.response.reduce((values, value) => {
                        siteDetailsObj[value.siteId] = value.siteName;
                        values.push({
                            id: value.siteId,
                            label: value.siteName,
                            value: value.siteId,

                        });

                        return values;
                    }, []);
                }
                console.log("siteDetailsObj:::", siteDetailsObj)
                this.setState({ temp__siteidsitename: temp_siteidsitename2 });
                this.setState({ siteDetails: siteDetailsObj });

                // Get Tracker Records//
                JQuery.ajax({
                    url: hostUrl + "/approvalMatrix/getApprovalTrackerRecords",

                    type: 'POST',
                    dataType: 'json',
                    data: JSON.stringify({
                        "role": temp_role,
                        "emailId": temp_emailid,
                        "type": "F"

                    }),
                    CORS: true,
                    contentType: 'application/json',
                    secure: true,
                    headers: headers,
                    crossDomain: true,
                    success: (res) => {
                        let temp_data2 = [];
                        this.setState({ isLoading: false });
                        for (let i = 0; i < res.response.length; i++) {

                            temp_data2 = res.response.reduce((values, value) => {

                                values.push({

                                    RequestID: parseInt(value.Request_Id),
                                    RequestorNameEmailGPID: value.Requestor_Email,
                                    SiteIDSiteName: this.state.siteDetails[value.SiteId],
                                    SiteId: value.SiteId,
                                    RequestType: value.Request_Type,
                                    RequestDateTime: value.Request_Date_Time,
                                    ApprovalRequired: value.Approval_Required,
                                    ApprovalSentTimeEmail: value.Approval_Sent_Date_Time,
                                    ReminderSentTimeEmail: value.Reminder_Sent_Date_Time,
                                    EscalationSentTimeEmail: value.Escalation_Sent_Date_Time,
                                    EnableButtons: value.Enable_Buttons,
                                    Status: value.Status,
                                    RequestDetails: value.Request_Details
                                });

                                return values;
                            }, []);
                        }
                        this.setState({ temp__RequestTrackerDataTotal: temp_data2 });
                        let valx = 0;

                        this.setState({ currentPage: valx });


                        let value = temp_data2 ? Math.ceil(temp_data2.length / pageSize) : 0;

                        let pages = _.range(1, value + 1);
                        let dtPagination = _(temp_data2).slice(0).take(pageSize).value();

                        this.setState({ pageCount: value });
                        this.setState({ temp__RequestTrackerData: dtPagination });


                    },
                    error: (err) => {
                        console.log("Error", err);
                        this.setState({ isLoading: false });
                        this.setState({ show: true });
                        setTimeout(function () {
                            JQuery('.modal-title').html("<strong class='text-danger'><i class='fa fa-exclamation-circle'></i> Error !!! </strong>");
                            JQuery(".modal-body").html("<p>Something went wrong. please try again later.</p>");
                        }, 0);
                    }

                });

            },
            error: function (jqHXR, textStatus, errorThrown) {


            }

        });



    }

    // multiple selection for Request ID//

    handleChangerequestid = selected => {
        this.setState({
            optionSelectedRequestId: selected
        });
    };
    // multiple selection for Site ID//
    handleChangeSiteIdSiteName = selected => {
        this.setState({
            optionSelectedSiteIdSiteName: selected
        });
    };

    // multiple selection for Request Type//
    handleChangeRequestType = selected => {
        this.setState({
            optionSelectedRequestType: selected
        });
    };

    // multiple selection for Status//
    handleChangeStatus = selected => {
        this.setState({
            optionSelectedStatus: selected
        });
    };


    handleClose = () => {

        this.setState({ show: false });

    }
    closeDenyReasons = () => {
        this.setState({ showDenyReasons: false });
        this.setState({ selectedDenyReason: null });
        this.setState({ selectedDenyRequestID: null });
    }

    handleDenyReasonChange = selected => {
        console.log("this.selected::::", selected)
        console.log("this.selected.value::::", selected.value)
        this.setState({ selectedDenyReason: selected.value });
        console.log("this.selectedDenyReason::::", this.state.selectedDenyReason)
    };

    //button approve and deny//

    handleApproveChange = (RequestId, flag) => {

        const token = getToken();
        const headers = {
            "accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }

        this.setState({ show: true });

        JQuery.ajax({
            url: hostUrl + "/approvalMatrix/saveApproval",

            type: 'POST',
            dataType: 'json',
            data: JSON.stringify({

                "role": temp_role,
                "emailId": temp_emailid,
                "requestId": RequestId,
                "action": "Approved"
            }),
            CORS: true,
            contentType: 'application/json',
            secure: true,
            headers: headers,
            crossDomain: true,
            success: function (data, textStatus, xhr) {


                JQuery('.modal-title').html("<strong class='text-success'><i class='fa fa-check-circle'></i> Successfully Approved </strong>");
                JQuery(".modal-body").html("<p>Request Tracker data has been approved</p>");



                JQuery('#' + RequestId + '_Approve').prop('disabled', true).css({ "background-color": "gray", "border": '3px solid' });

                JQuery('#' + RequestId + '_Deny').prop('disabled', true).css({ "background-color": "gray" });


            },
            error: function (jqHXR, textStatus, errorThrown) {

                JQuery('.modal-title').html("<strong class='text-danger'><i class='fa fa-exclamation-circle'></i> Error !!! </strong>");
                JQuery(".modal-body").text("The request has not been submitted. Check the input data and try again.");


            }

        });

    }
    handleDenyConfirmationModal = (RequestId, flag) => {
        this.setState({ selectedDenyRequestID: RequestId });
        this.setState({ showDenyReasons: true });
    }
    submitDenyReason = (RequestId, reason) => {
        console.log("RequestId::::", RequestId)
        console.log("Reason::::", reason);
        const token = getToken();
        const headers = {
            "accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
        this.setState({ show: true });
        JQuery.ajax({
            url: hostUrl + "/approvalMatrix/saveApproval",
            type: 'POST',
            dataType: 'json',
            data: JSON.stringify({
                "role": temp_role,
                "emailId": temp_emailid,
                "requestId": RequestId,
                "action": "Denied",
                "reason": reason
            }),
            CORS: true,
            contentType: 'application/json',
            secure: true,
            headers: headers,
            crossDomain: true,
            success: function (data, textStatus, xhr) {

                this.closeDenyReasons();
                JQuery('.modal-title').html("<strong class='text-danger'><i class='fa fa-check-circle'></i> Denied </strong>");
                JQuery(".modal-body").html("<p>Request Tracker data has been denied</p>");


                JQuery('#' + RequestId + '_Approve').prop('disabled', true).css({ "background-color": "gray" });
                JQuery('#' + RequestId + '_Deny').prop('disabled', true).css({ "background-color": "gray", "border": '3px solid' });
            },
            error: function (jqHXR, textStatus, errorThrown) {

                this.closeDenyReasons();
                JQuery('.modal-title').html("<strong class='text-danger'><i class='fa fa-exclamation-circle'></i> Error !!! </strong>");
                JQuery(".modal-body").text("The request has not been submitted. Check the input data and try again.");


            }

        });
    }


    // submit filter and filterise tracker records//

    handleFilterSubmit = (event) => {

        JQuery("#filtersubmitbutton").html("<i class='fa fa-refresh fa-spin' ></i> Submitting");

        const token = getToken();
        const headers = {
            "accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }


        let temp1 = [];

        this.setState({ SelectedRequestID: [] });

        if (this.state.optionSelectedRequestId !== null) {

            for (let i = 0; i < this.state.optionSelectedRequestId.length; i++) {

                if (this.state.optionSelectedRequestId[i].value !== "*") {

                    temp1.push(this.state.optionSelectedRequestId[i].value);
                }

            }
        }

        this.setState({ SelectedRequestID: temp1 });

        let temp2 = [];

        this.setState({ SelectedSiteIdSiteName: [] });

        if (this.state.optionSelectedSiteIdSiteName !== null) {

            for (let i = 0; i < this.state.optionSelectedSiteIdSiteName.length; i++) {

                if (this.state.optionSelectedSiteIdSiteName[i].value !== "*") {

                    temp2.push(this.state.optionSelectedSiteIdSiteName[i].value);
                }

            }
        }

        this.setState({ SelectedSiteIdSiteName: temp2 });

        let temp3 = [];

        this.setState({ SelectedRequestType: [] });

        if (this.state.optionSelectedRequestType !== null) {

            for (let i = 0; i < this.state.optionSelectedRequestType.length; i++) {

                if (this.state.optionSelectedRequestType[i].value !== "*") {

                    temp3.push(this.state.optionSelectedRequestType[i].value);
                }

            }
        }

        this.setState({ SelectedRequestType: temp3 });

        let temp4 = [];

        this.setState({ SelectedStatus: [] });

        if (this.state.optionSelectedStatus !== null) {

            for (let i = 0; i < this.state.optionSelectedStatus.length; i++) {

                if (this.state.optionSelectedStatus[i].value !== "*") {

                    temp4.push(this.state.optionSelectedStatus[i].value);
                }

            }
        }

        this.setState({ SelectedStatus: temp4 });

        let result_temp1 = '';
        let result_temp2 = '';
        let result_temp3 = '';
        let result_temp4 = '';

        for (let i = 0; i < temp1.length; i++) {
            result_temp1 = result_temp1.concat(temp1[i]);
            if (i < temp1.length - 1) {
                result_temp1 = result_temp1.concat(",");
            }
        }
        for (let i = 0; i < temp2.length; i++) {
            result_temp2 = result_temp2.concat(temp2[i]);
            if (i < temp2.length - 1) {
                result_temp2 = result_temp2.concat(",");
            }
        }
        for (let i = 0; i < temp3.length; i++) {
            result_temp3 = result_temp3.concat(temp3[i]);
            if (i < temp3.length - 1) {
                result_temp3 = result_temp3.concat(",");
            }
        }
        for (let i = 0; i < temp4.length; i++) {
            result_temp4 = result_temp4.concat(temp4[i]);
            if (i < temp4.length - 1) {
                result_temp4 = result_temp4.concat(",");
            }
        }

        this.setState({ isLoading: true });
        JQuery.ajax({
            url: hostUrl + "/approvalMatrix/getApprovalTrackerRecordsByFilters",

            type: 'POST',
            dataType: 'json',
            data: JSON.stringify({


                "role": temp_role,
                "emailId": temp_emailid,
                "type": "F",
                "requestId": result_temp1,
                "site": result_temp2,
                "requestType": result_temp3,
                "requestStatus": result_temp4

            }),
            CORS: true,
            contentType: 'application/json',
            secure: true,
            headers: headers,
            crossDomain: true,
            success: (res) => {
                let temp_data2 = [];
                this.setState({ isLoading: false });
                for (let i = 0; i < res.response.length; i++) {
                    temp_data2 = res.response.reduce((values, value) => {

                        values.push({

                            RequestID: parseInt(value.Request_Id),
                            RequestorNameEmailGPID: value.Requestor_Email,
                            SiteIDSiteName: this.state.siteDetails[value.SiteId],
                            RequestType: value.Request_Type,
                            RequestDateTime: value.Request_Date_Time,
                            ApprovalRequired: value.Approval_Required,
                            ApprovalSentTimeEmail: value.Approval_Sent_Date_Time,
                            ReminderSentTimeEmail: value.Reminder_Sent_Date_Time,
                            EscalationSentTimeEmail: value.Escalation_Sent_Date_Time,
                            EnableButtons: value.Enable_Buttons,
                            Status: value.Status,
                            RequestDetails: value.Request_Details
                        });

                        return values;
                    }, []);
                }



                this.setState({ temp__RequestTrackerDataTotal: temp_data2 });

                let valx = 0;
                this.setState({ currentPage: valx });

                let value = temp_data2 ? Math.ceil(temp_data2.length / pageSize) : 0;

                let pages = _.range(1, value + 1);
                let dtPagination = _(temp_data2).slice(0).take(pageSize).value();

                this.setState({ pageCount: value });

                this.setState({ temp__RequestTrackerData: dtPagination });

                JQuery("#filtersubmitbutton").html("Submit");


            },
            error: (err) => {
                console.log("Error", err);
                JQuery("#filtersubmitbutton").html("Submit");
                this.setState({ isLoading: false });
                this.setState({ show: true });
                setTimeout(function () {
                    JQuery('.modal-title').html("<strong class='text-danger'><i class='fa fa-exclamation-circle'></i> Error !!! </strong>");
                    JQuery(".modal-body").html("<p>Something went wrong. please try again later.</p>");
                }, 0);
            }

        });



        event.preventDefault();



    }

    // Page Load Pagination for Records of data//

    handleChangePagination = (TotalRequestTrackerdata, pageNumber) => {


        let newCurrentpage = pageNumber.selected + 1;


        this.setState({ temp__RequestTrackerDataTotal: TotalRequestTrackerdata });

        this.setState({ currentPage: pageNumber.selected });

        let value = TotalRequestTrackerdata ? Math.ceil(TotalRequestTrackerdata.length / pageSize) : 0;
        this.setState({ pageCount: value });

        const startIndex = (newCurrentpage - 1) * pageSize;

        const PaginationPost = _(TotalRequestTrackerdata).slice(startIndex).take(pageSize).value();

        this.setState({ temp__RequestTrackerData: PaginationPost });


    }

    render() {


        return (

            <>
                <div>
                    {this.state.AccessDenied === true && (
                        <Navigate to="/accessdenied" replace={true} />
                    )}
                </div>

                <div className="order-font container-fluid">


                    <Card.Header style={{ backgroundColor: "#1C1B83" }}>

                        <div className='row'>

                            <div className='col-md-2' style={{ backgroundColor: "white" }}>

                                <img src={process.env.PUBLIC_URL + "/PepsicoLogo.png"} width="140" alt="logo" style={{}} />

                            </div>

                            <div className='col-md-6 ' style={{ color: "white", backgroundColor: "#1C1B83" }}>

                                <h5 style={{ marginTop: ".5rem", textAlign: "left" }}><b>Order Processing Automation Request Tracker</b></h5>

                            </div>

                            <div className='col-md-2'>

                                <a href='/orders/home'>

                                    <i class="fa fa-home" aria-hidden="true" style={{ color: 'white', marginLeft: '9vw', fontSize: '2.3rem', marginTop: ".3rem" }}></i>

                                </a>

                            </div>

                            <div className='col-md-2 ' style={{ color: "white", backgroundColor: "white" }}>

                                <img src={process.env.PUBLIC_URL + "/LogoImages.png"} width="140" alt="logo" style={{ width: "11rem" }} />

                            </div>




                        </div>




                    </Card.Header>


                    <Card.Body >
                        <div className='row mt-2'>

                            <div className='col-md-2'>
                                <div style={{ backgroundColor: "#1C1B83", height: "auto" }}>
                                    <div class="row">

                                    </div>
                                    <div class="row mt-4">
                                        <h5 className="text-white" style={{ textAlign: 'center' }}>Filters</h5>
                                    </div>

                                    <div className="row" style={{ marginTop: "0.5vw" }}>
                                        <div className="col-md-10" style={{ textAlign: 'left', marginLeft: "0.5rem" }} >

                                            <Form.Label style={{ color: "white" }}>Request ID</Form.Label>
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-md-10" style={{ marginLeft: "1.4vw" }}>
                                            <div className="row" style={{ textAlign: 'left' }} >
                                                <span
                                                    className="d-inline-block"
                                                    data-toggle="popover"
                                                    data-trigger="focus"
                                                    data-content="Please selecet account(s)"
                                                >
                                                    <SelectAll

                                                        options={this.state.temp__requestid}

                                                        isMulti
                                                        closeMenuOnSelect={false}
                                                        hideSelectedOptions={false}
                                                        components={{
                                                            Option,
                                                            MultiValue,
                                                            ValueContainer,
                                                            animatedComponents
                                                        }}
                                                        onChange={this.handleChangerequestid}
                                                        allowSelectAll={true}
                                                        value={this.state.optionSelectedRequestId}


                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: "0.5vw" }}>
                                        <div className="col-md-10" style={{ textAlign: 'left', marginLeft: "0.5rem" }}>

                                            <Form.Label style={{ color: "white" }}>Site ID</Form.Label>
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-md-10" style={{ marginLeft: "1.4vw", textAlign: 'left' }}>

                                            <div className="row">
                                                <span
                                                    className="d-inline-block"
                                                    data-toggle="popover"
                                                    data-trigger="focus"
                                                    data-content="Please selecet account(s)"
                                                >
                                                    <SelectAll

                                                        options={this.state.temp__siteidsitename}

                                                        isMulti
                                                        closeMenuOnSelect={false}
                                                        hideSelectedOptions={false}
                                                        components={{
                                                            Option,
                                                            MultiValue,
                                                            ValueContainer,
                                                            animatedComponents
                                                        }}
                                                        onChange={this.handleChangeSiteIdSiteName}
                                                        allowSelectAll={true}
                                                        value={this.state.optionSelectedSiteIdSiteName}


                                                    />
                                                </span>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: "0.5vw" }}>
                                        <div className="col-md-10" style={{ textAlign: 'left', marginLeft: "0.5rem" }}>

                                            <Form.Label style={{ color: "white" }}>Request Type</Form.Label>
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-md-10" style={{ marginLeft: "1.4vw", textAlign: 'left' }}>
                                            <div className="row">
                                                <span
                                                    className="d-inline-block"
                                                    data-toggle="popover"
                                                    data-trigger="focus"
                                                    data-content="Please selecet account(s)"
                                                >
                                                    <SelectAll

                                                        options={this.state.temp__requesttype}

                                                        isMulti
                                                        closeMenuOnSelect={false}
                                                        hideSelectedOptions={false}
                                                        components={{
                                                            Option,
                                                            MultiValue,
                                                            ValueContainer,
                                                            animatedComponents
                                                        }}
                                                        onChange={this.handleChangeRequestType}
                                                        allowSelectAll={true}
                                                        value={this.state.optionSelectedRequestType}


                                                    />
                                                </span>
                                            </div>


                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: "0.5vw" }}>
                                        <div className="col-md-10" style={{ textAlign: 'left', marginLeft: "0.5rem" }}>

                                            <Form.Label style={{ color: "white" }}>Status</Form.Label>
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-md-10" style={{ marginLeft: "1.4vw", textAlign: 'left' }}>
                                            <div className="row">
                                                <span
                                                    className="d-inline-block"
                                                    data-toggle="popover"
                                                    data-trigger="focus"
                                                    data-content="Please selecet account(s)"
                                                >
                                                    <SelectAll

                                                        options={this.state.temp__status}

                                                        isMulti
                                                        closeMenuOnSelect={false}
                                                        hideSelectedOptions={false}
                                                        components={{
                                                            Option,
                                                            MultiValue,
                                                            ValueContainer,
                                                            animatedComponents
                                                        }}
                                                        onChange={this.handleChangeStatus}
                                                        allowSelectAll={true}
                                                        value={this.state.optionSelectedStatus}


                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12 justify-content-center">

                                            <Button variant="outline-light" id="filtersubmitbutton" style={{ marginTop: "1.5rem", backgroundColor: "#009639", borderColor: "#1C1B83", color: "white" }} onClick={this.handleFilterSubmit}>Submit</Button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-10">

                                            <Form.Label style={{ color: "white" }}></Form.Label>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='row' style={{ marginTop: "1.5vw" }}>

                                        <div className='col-md-12'>
                                            <a href='/orders/archiverequesttracker'>

                                                <Button variant="outline-light" class="rgb(0, 150, 57)" style={{ backgroundColor: "rgb(0, 150, 57)", color: "white", marginTop: "0.5rem", fontSize: ".9rem" }}>

                                                    Link For Archive Data

                                                </Button>

                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.state.isLoading ? <div className={"loaderWrapper"}>
                                <Spinner animation="border" variant="primary">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div> : <div className='col-md-10' style={{ marginLeft: "-.8rem" }} >

                                <div className='row' >
                                    <Table responsive striped bordered hover id="RequestTrackerData">
                                        <thead className="text-white" style={{ backgroundColor: "#1C1B83" }}>
                                            <tr>
                                                <td style={{ textAlign: 'center', verticalAlign: "middle" }}>Request ID</td>

                                                <td style={{ textAlign: 'center', verticalAlign: "middle" }}>Requester Email</td>

                                                <td style={{ textAlign: 'center', verticalAlign: "middle" }}>Site ID</td>

                                                <td style={{ textAlign: 'center', verticalAlign: "middle" }}>Request Type</td>

                                                <td style={{ textAlign: 'center', verticalAlign: "middle" }}>Request Date/Time</td>

                                                <td style={{ textAlign: 'center', verticalAlign: "middle" }}>Approvers Email</td>

                                                <td style={{ textAlign: 'center', verticalAlign: "middle" }}>Approval Sent Time</td>

                                                <td style={{ textAlign: 'center', verticalAlign: "middle" }}>Reminder Sent Time</td>

                                                <td style={{ textAlign: 'center', verticalAlign: "middle" }}>Escalation Sent Time</td>

                                                <td style={{ textAlign: 'center', verticalAlign: "middle" }}>Status</td>

                                                <td style={{ textAlign: 'center', verticalAlign: "middle" }}>Request Details</td>

                                                <td style={{ textAlign: 'center', verticalAlign: "middle" }}>Actions</td>
                                            </tr>

                                        </thead>
                                        <tbody>

                                            {

                                                this.state.temp__RequestTrackerData.map((RequestTrackerObj) =>



                                                    <ReadOnlyRowsRequestTracker RequestTrackerObj={RequestTrackerObj}
                                                        handleApproveChange={this.handleApproveChange}
                                                        handelDenyChange={this.handleDenyConfirmationModal} />

                                                )}

                                        </tbody>
                                    </Table>

                                </div>

                                <div className='row' style={{ marginTop: "1.5vw", marginLeft: ".1rem" }}>
                                    <ReactPaginate
                                        previousLabel={"<< Prev"}
                                        nextLabel={"Next >>"}
                                        breakLabel={"..."}
                                        forcePage={this.state.currentPage}
                                        pageCount={this.state.pageCount}
                                        marginPagesDisplayed={3}
                                        pageRangeDisplayed={3}
                                        onPageChange={(data) => this.handleChangePagination(this.state.temp__RequestTrackerDataTotal, data)}
                                        containerClassName={'pagination'}
                                        pageClassName={'page-item'}
                                        pageLinkClassName={'page-link'}
                                        previousClassName={'page-item'}
                                        previousLinkClassName={'page-link'}
                                        nextClassName={'page-item'}
                                        nextLinkClassName={'page-link'}
                                        breakClassName={'page-item'}
                                        breakLinkClassName={'page-link'}
                                        activeClassName={'active'}

                                    />

                                </div>
                                <div>
                                    <Modal
                                        show={this.state.show}
                                        onHide={this.handleClose}
                                        backdrop="static"
                                        keyboard={false}
                                    >
                                        <Modal.Header closeButton >
                                            <Modal.Title >  </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                        </Modal.Body>
                                        <Modal.Footer>

                                            <Button variant="outline-light" style={{ color: "White", backgroundColor: "#009639" }} onClick={this.handleClose}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                                <div>
                                    <Modal
                                        show={this.state.showDenyReasons}
                                        onHide={this.closeDenyReasons}
                                        backdrop="static"
                                        keyboard={false}
                                    >
                                        <Modal.Header closeButton >
                                            <Modal.Title > Select a reason for a rejection </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Dropdown options={denyResons} onChange={this.handleDenyReasonChange} value={this.state.selectedDenyReason} placeholder="Select an option" />
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="outline-light" style={{ color: "White", backgroundColor: "#009639" }} onClick={() => this.submitDenyReason(this.state.selectedDenyRequestID, this.state.selectedDenyReason)} disabled={this.state.selectedDenyReason == null ? true : false}>
                                                Submit
                                            </Button>
                                            <Button variant="outline-light" style={{ color: "White", backgroundColor: "#009639" }} onClick={this.closeDenyReasons}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            </div>}
                        </div>
                    </Card.Body>

                </div>
            </>
        )
    }

}