import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';

import JQuery, { event } from 'jquery';
import UtilBaseUrl from '../../ConfigUtility';

const getToken = () => {
    let tokenStr = localStorage.getItem("okta-token-storage");

    if (!tokenStr) {
        return;
    }
    else {
        const tokenObj = JSON.parse(tokenStr);

        return tokenObj?.accessToken?.accessToken;
    }
}


const AllocationlistEdit = ({ editAllocationlistmaterialFormData, handleEditAllocationlistmaterialFormChange,
    handelCancelAllocationlistmaterialClick, handleEditAllocationlistmaterialFormSubmit }) => {

    const hostUrl = UtilBaseUrl.URL;

    const token = getToken();
    const headers = {
        "accept": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${token}`
    }



    let reasonCodeResponse = [], reasonCodeOptions = '';

    // page Load//
    useEffect(() => {


        JQuery.ajax({
            url: hostUrl + "/ordermodification/reason-code",
            type: 'POST',
            dataType: 'json',
            CORS: true,
            contentType: 'application/json',
            secure: true,
            headers: headers,
            crossDomain: true,
            success: function (response) {
                reasonCodeOptions = '<option value="" name ="" selected>--Select--</option>\n';
                reasonCodeResponse = response["response"];
                for (let i = 0; i < reasonCodeResponse.length; i++) {
                    reasonCodeOptions += '<option value="' + reasonCodeResponse[i]["description"] + '" name="' + reasonCodeResponse[i]["description"] + '">' + reasonCodeResponse[i]["description"] + '</option>\n';
                }

                JQuery("#ReasonCodeAlloactionlist").html(reasonCodeOptions);
                JQuery("#ReasonCodeAlloactionlist").val(editAllocationlistmaterialFormData.ReasonCode);


            },
            error: function (jqHXR, textStatus, errorThrown) {


            }

        });

    }, []);

    // material Decription //
    const setMaterialDecription = (event) => {

        let materialcode = event.target.value;
        if (materialcode.length === 13) {

            checkValidationMaterial(event);
            ValidMaterial(event);

        }
        else {

            if (event.target.id === "TableMaterialCodeAllocationlist") {

                JQuery("#TableMaterialCodeAllocationlist").val(materialcode);
                JQuery("#Invalid_Status_MaterialCodeAllocation").hide();
                JQuery("#Inactive_Status_MaterialCodeAllocation").hide();

                JQuery("#TableMaterialCodeAllocationlist").removeAttr("style");
                JQuery("#TableMaterialCodeAllocationlist").css({ "width": "10rem" });

            }
        }



    }
    // Check validtion  material  //

    const checkValidationMaterial = (event) => {
        const token = getToken();
        const headers = {
            "accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }

        let materialcode = event.target.value;
        JQuery.ajax({
            url: hostUrl + "/orderModification/materialValidation",

            type: 'POST',
            dataType: 'json',
            data: JSON.stringify({
                "materialNumber": materialcode
            }),
            CORS: true,
            contentType: 'application/json',
            secure: true,
            headers: headers,
            crossDomain: true,
            success: (res) => {




                let MaterialCodeMessage = res.errormessage;
                if (MaterialCodeMessage === "Material Exists") {

                    if (event.target.id === "TableMaterialCodeAllocationlist") {

                        JQuery("#Invalid_Status_MaterialCodeAllocation").hide();
                        JQuery("#Inactive_Status_MaterialCodeAllocation").hide();
                        JQuery("#TableMaterialCodeAllocationlist").removeAttr("style");
                        JQuery("#TableMaterialCodeAllocationlist").css({ "width": "10rem" });


                    }

                }
                else if (MaterialCodeMessage === "Material is not active") {


                    if (event.target.id === "TableMaterialCodeAllocationlist") {

                        JQuery("#Invalid_Status_MaterialCodeAllocation").hide();
                        JQuery("#Inactive_Status_MaterialCodeAllocation").show();

                        JQuery("#TableMaterialCodeAllocationlist").css("border", "1px solid red");

                        JQuery("#TableMaterialDescriptionAllocationlist").val(res.response.materialDesciption);
                        JQuery("#TableUnitOfMeasure").val(res.response.umo);
                    }
                }
                else {

                    if (event.target.id === "TableMaterialCodeAllocationlist") {

                        JQuery("#Invalid_Status_MaterialCodeAllocation").show();
                        JQuery("#Inactive_Status_MaterialCodeAllocation").hide();
                        JQuery("#TableMaterialCodeAllocationlist").css("border", "1px solid red");

                        JQuery("#TableMaterialDescriptionAllocationlist").val(res.response.materialDesciption);
                        JQuery("#TableUnitOfMeasure").val(res.response.umo);
                    }

                }

            },
            error: function (jqHXR, textStatus, errorThrown) {


            }

        });

    }

    // vaild material//
    const ValidMaterial = (event) => {
        let materialcode = event.target.value;

        const token = getToken();
        const headers = {
            "accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }

        JQuery.ajax({
            url: hostUrl + "/ordermodification/materialDescription",
            type: 'POST',
            dataType: 'json',
            data: JSON.stringify({
                "materialNumber": materialcode
            }),
            CORS: true,
            contentType: 'application/json',
            secure: true,
            headers: headers,
            crossDomain: true,
            success: (res) => {



                ResponseSetMaterial(res.response, event.target.id, materialcode);

            },
            error: function (jqHXR, textStatus, errorThrown) {


            }

        });

    }

    const ResponseSetMaterial = (Response, ID, materialcode) => {


        if (ID === "TableMaterialCodeAllocationlist") {

            JQuery("#TableMaterialDescriptionAllocationlist").val(Response.materialDesciption);
            JQuery("#TableUnitOfMeasure").val(Response.umo);

        }

    }

    return (


        <tr id='AddItems'>
            <td style={{ verticalAlign: "middle" }}><input type="text" style={{ width: "10rem" }}

                id="TableMaterialCodeAllocationlist"
                name="MaterialCode"
                required="required"
                idx="restrictedDecimal"
                value={editAllocationlistmaterialFormData.MaterialCode}

                onChange={(e) => { handleEditAllocationlistmaterialFormChange(e); setMaterialDecription(e) }}
            />
                <br />
                <span className="help-block text-danger" id="Invalid_MaterialCode" style={{ display: "None", float: "left" }}> Please enter a 13 digit numeric Material Code</span>
                <span className="help-block text-danger" id="Invalid_Status_MaterialCodeAllocation" style={{ display: "None", float: "left" }}>The Material Code entered is invalid</span>
                <span className="help-block text-danger" id="Inactive_Status_MaterialCodeAllocation" style={{ display: "None", float: "left" }}>The Material Code entered is inactive</span>

            </td>
            <td style={{ verticalAlign: "middle" }}><input type="text" style={{ width: "10rem" }}
                id="TableMaterialDescriptionAllocationlist"
                name="MaterialDescription"
                required="required"
                value={editAllocationlistmaterialFormData.MaterialDescription}
                onChange={handleEditAllocationlistmaterialFormChange}

            />
                <br />
                <span class="help-block text-danger" id="Invalid_MaterialDescriptionAlloacation" style={{ display: "None", float: "left" }}> Please enter Material Description</span>
            </td>
            <td style={{ verticalAlign: "middle" }}>

                <select id="TableUnitOfMeasure" name='UnitOfMeasure' value={editAllocationlistmaterialFormData.UnitOfMeasure}
                    onChange={handleEditAllocationlistmaterialFormChange} style={{ width: "10rem", height: "1.80rem" }}>
                    <option selected name="" value="" >--Select--</option>
                    <option name="CS" value="CS">CS</option>
                    <option name="EA" value="EA">EA</option>
                    <option name="DS" value="DS">DS</option>
                    <option name="PAC" value="PAC" >PAC</option>

                </select>
                <br />
                <span class="help-block text-danger" id="Invalid_UnitOfMeasure" style={{ display: "None", float: "left" }}>Please select Unit Of Measure</span>

            </td>
            <td style={{ verticalAlign: "middle" }}><input type="text" style={{ width: "5rem" }}
                id="TableMinQtyCap"
                name="MinQtyCap"
                required="required"

                idx="restrictedDecimal"
                value={editAllocationlistmaterialFormData.MinQtyCap}
                onChange={handleEditAllocationlistmaterialFormChange}

            />
                <br />
                <span class="help-block text-danger" id="Invalid_MinQtyCap" style={{ display: "None", float: "left" }}>Please enter Min Qty Cap</span>


            </td>
            <td style={{ verticalAlign: "middle" }}><input type="text" style={{ width: "5rem" }}
                id="TableMaxQtyCap"
                name="MaxQtyCap"
                required="required"

                idx="restrictedDecimal"
                value={editAllocationlistmaterialFormData.MaxQtyCap}
                onChange={handleEditAllocationlistmaterialFormChange}

            />
                <br />
                <span class="help-block text-danger" id="Invalid_MaxQtyCap" style={{ display: "None", float: "left" }}>Please enter Max Qty Cap</span>

            </td>
            <td style={{ verticalAlign: "middle" }}>

                <select name='ReasonCode' id='ReasonCodeAlloactionlist' value={editAllocationlistmaterialFormData.ReasonCode}
                    onChange={handleEditAllocationlistmaterialFormChange} style={{ width: "10rem", height: "1.80rem" }}>


                </select>
                <br />
                <span class="help-block text-danger" id="Invalid_ReasonCodeAlloactionlist" style={{ display: "None" }}>Please select a Reason Code</span>

            </td>

            <td className='text-center'>
                <Button variant="outline-light" onClick={handleEditAllocationlistmaterialFormSubmit} style={{ backgroundColor: "#009639", color: "white", width: "5rem" }}>Save</Button>
                <Button variant="outline-light" onClick={() => handelCancelAllocationlistmaterialClick()} style={{ backgroundColor: "#FF0000", color: "white", marginLeft: "1vw", width: "7rem" }}>Cancel</Button>
            </td>

        </tr>
    )

}
export default AllocationlistEdit;