import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import UtilBaseUrl from '../ConfigUtility';

import JQuery from 'jquery';

import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';


import ReactPaginate from 'react-paginate';
import _ from 'lodash';

import * as Constants  from '../Common Files/Constants.js';

const getToken = () => {
    let tokenStr = localStorage.getItem("okta-token-storage");
  
    if (!tokenStr) {
        return;
    }
    else {
        const tokenObj = JSON.parse(tokenStr);
  
        return tokenObj?.accessToken?.accessToken;
    }
}

const pageSize = 10;

let temp_emailid = sessionStorage.getItem("emailid");
let temp_gpid = sessionStorage.getItem("gpid");
let temp_role = sessionStorage.getItem("role");



const ReadOnlyRowsRequestTracker = ({ RequestTrackerObj, handleApproveChange, handelDenyChange }) => {

    const hostUrl = UtilBaseUrl.URL;

    const [RequestDetailsdataOM, SetRequestDetailsdataOM] = useState([]);
    const [RequestDetailsTotaldataOM, SetRequestDetailsTotaldataOM] = useState([]);
    const [requestDescriptionOM, SetrequestDescriptionOM] = useState();

    const [RequestDetailsdataOMAdv, SetRequestDetailsdataOMAdv] = useState([]);
    const [RequestDetailsTotaldataOMAdv, SetRequestDetailsTotaldataOMAdv] = useState([]);
    const [requestDescriptionOMAdv, SetrequestDescriptionOAdv] = useState();

    const [RequestDetailsdataICR, SetRequestDetailsdataICR] = useState([]);
    const [RequestDetailsTotaldataICR, SetRequestDetailsTotaldataICR] = useState([]);
    const [requestDescriptionICR, SetrequestDescriptionICR] = useState();
    const [icrIdICR, SeticrIdICR] = useState();


    const [RequestDetailsdataICRAdv, SetRequestDetailsdataICRAdv] = useState([]);
    const [RequestDetailsTotaldataICRAdv, SetRequestDetailsTotaldataICRAdv] = useState([]);
    const [requestDescriptionICRAdv, SetrequestDescriptionICRAdv] = useState();

    const [RequestDetailsTotaldataSOSTOICRAdv, SetRequestDetailsTotaldataSOSTOICRAdv] = useState([]);


    const [pageCount, SetpageCount] = useState();
    const [currentPage, SetcurrentPage] = useState();



    


    //* RequestDetails*//

    const RequestDetails = (RequestID, RequestType,siteId,siteName) => {



        if (RequestType === "Order Modification") {


            const token = getToken();
            const headers = {
                "accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }

            JQuery.ajax({
                url: hostUrl + "/approvalMatrix/getRequestDetails",
                type: 'POST',
                dataType: 'json',
                data: JSON.stringify({

                    "requestId": RequestID,

                }),
                CORS: true,
                contentType: 'application/json',
                secure: true,
                headers: headers,
                crossDomain: true,
                success: (res) => {

                    if (res.response.length > 0) {

                        if (res.response[0] !== null) {
                            SetrequestDescriptionOM((res.response[0].Request_Description).replace(siteId, siteName));

                        }


                    }


                    let temp_data2 = [];

                    if (res.response[0] !== null) {

                        for (let i = 0; i < res.response.length; i++) {
                            temp_data2 = res.response.reduce((values, value) => {

                                values.push({
                                    requestDescription: value.Request_Description,
                                    materialId: value.Material_Id,
                                    updatedMaterailId: value.updated_material_id,
                                    oldQuantity: value.Old_Quantity,
                                    updatedQuantity: value.Updated_Quantity,
                                    upc: value.UPC,
                                    materialDescription: value.Material_Description,
                                    deliveryDate: value.Delivery_Date,
                                    newDeliveryDate: value.New_Delivery_Date,
                                    modificationAction: value.Modification_Action,
                                    Sno: value.Sno,
                                    ReasonCode: value.Reason_Code
                                });

                                return values;
                            }, []);
                        }
                    }


                    SetRequestDetailsTotaldataOM(temp_data2);
                    let value = temp_data2 ? Math.ceil(temp_data2.length / pageSize) : 0;

                    let pages = _.range(1, value + 1);
                    let dtPagination = _(temp_data2).slice(0).take(pageSize).value();

                    SetpageCount(value);
                    SetRequestDetailsdataOM(dtPagination);

                    ToggleTruefalse();

                },
                error: function (jqHXR, textStatus, errorThrown) {


                }

            });
        }

        else if (RequestType === "Order Modification-Advanced Functionality") {
            const token = getToken();
            const headers = {
                "accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }

            JQuery.ajax({
                url: hostUrl + "/orderModification/getRequestDetailsForOrderModAdv",
                type: 'POST',
                dataType: 'json',
                data: JSON.stringify({

                    "requestId": RequestID,

                }),
                CORS: true,
                contentType: 'application/json',
                secure: true,
                headers: headers,
                crossDomain: true,
                success: (res) => {


                    if (res.response.length > 0) {

                        if (res.response[0] !== null) {
                            SetrequestDescriptionOAdv((res.response[0].requestDescription).replace(siteId, siteName));

                        }

                    }

                    SetRequestDetailsTotaldataOMAdv(res.response[0]);
                    ToggleTruefalse();


                },
                error: function (jqHXR, textStatus, errorThrown) {
                   

                }

            });
        }

        else if (RequestType === "ICR") {

            const token = getToken();
            const headers = {
                "accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }

            JQuery.ajax({
                url: hostUrl + "/approvalMatrix/getRequestDetailsIcr",

                type: 'POST',
                dataType: 'json',
                data: JSON.stringify({

                    "requestId": RequestID,
 

                }),
                CORS: true,
                contentType: 'application/json',
                secure: true,
                headers: headers,
                crossDomain: true,
                success: (res) => {


                    if (res.response.length > 0) {

                        if (res.response[0] !== null) {
                            SetrequestDescriptionICR((res.response[0].requestDescription).replace(siteId, siteName));
                            SeticrIdICR(res.response[0].icrId);

                        }

                    }


                    let temp_data2 = [];

                    if (res.response[0] !== null) {

                        for (let i = 0; i < res.response.length; i++) {
                            temp_data2 = res.response.reduce((values, value) => {

                                values.push({

                                    requestDescription: value.requestDescription,
                                    activity: value.activity,
                                    materialCode: value.materialCode,
                                    materialCodeTo: value.materialCodeTo,
                                    uomTo: value.uomTo,
                                    uom: value.uom,
                                    addCutValue: value.addCutValue,
                                    addCutValueQorP: value.addCutValueQorP,
                                    reasonCode: value.reasonCode,
                                    minQtyCap: value.minQtyCap,
                                    maxQtyCap: value.maxQtyCap,
                                    sno: value.sno

                                });

                                return values;
                            }, []);
                        }
                    }

                    SetRequestDetailsTotaldataICR(temp_data2);

                    let value = temp_data2 ? Math.ceil(temp_data2.length / pageSize) : 0;

                    let pages = _.range(1, value + 1);
                    let dtPagination = _(temp_data2).slice(0).take(pageSize).value();

                    SetpageCount(value);
                    SetRequestDetailsdataICR(dtPagination);

                    ToggleTruefalse();

                },
                error: function (jqHXR, textStatus, errorThrown) {
                  

                }

            });

        }

        else if (RequestType === "ICR-Advanced Functionality") {

            const token = getToken();
            const headers = {
                "accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }


            JQuery.ajax({
                url: hostUrl +"/icrForm/getRequestSOSTODetailsForICRAdvFunctionality",

                type: 'POST',
                dataType: 'json',
                data: JSON.stringify({

                    "requestId": RequestID,

                }),
                CORS: true,
                contentType: 'application/json',
                secure: true,
                headers: headers,
                crossDomain: true,
                success: (res) => {

  

                    SetRequestDetailsTotaldataSOSTOICRAdv(res.response[0]);

                },
                error: function (jqHXR, textStatus, errorThrown) {
                  

                }

            });

            JQuery.ajax({
                url: hostUrl + "/icrForm/getRequestListDetailsForICRAdvFunctionality",

                type: 'POST',
                dataType: 'json',
                data: JSON.stringify({

                    "requestId": RequestID,


                }),
                CORS: true,
                contentType: 'application/json',
                secure: true,
                headers: headers,
                crossDomain: true,
                success: (res) => {



                    if (res.response.length > 0) {

                        if (res.response[0] !== null) {
                            SetrequestDescriptionICRAdv((res.response[0].requestDescription).replace(siteId, siteName));

                        }

                    }
                    let temp_data2 = [];

                    if (res.response[0] !== null) {

                        for (let i = 0; i < res.response.length; i++) {
                            temp_data2 = res.response.reduce((values, value) => {

                                values.push({

                                    requestDescription: value.requestDescription,
                                    activity: value.activity,
                                    materialCode: value.materialCode,
                                    materialCodeTo: value.materialCodeTo,
                                    uomTo: value.uomTo,
                                    uom: value.uom,
                                    addCutValue: value.addCutValue,
                                    addCutValueQorP: value.addCutValueQorP,
                                    reasonCode: value.reasonCode,
                                    minQtyCap: value.minQtyCap,
                                    maxQtyCap: value.maxQtyCap,
                                    sno: value.sno

                                });

                                return values;
                            }, []);
                        }
                    }

                    SetRequestDetailsTotaldataICRAdv(temp_data2);

                    let value = temp_data2 ? Math.ceil(temp_data2.length / pageSize) : 0;

                    let pages = _.range(1, value + 1);
                    let dtPagination = _(temp_data2).slice(0).take(pageSize).value();

                    SetpageCount(value);
                    SetRequestDetailsdataICRAdv(dtPagination);

                    ToggleTruefalse();

                },
                error: function (jqHXR, textStatus, errorThrown) {
                

                }

            });

        }


    }

    const [showmodal, Setshowmodal] = useState(false);

    const [show, Setshow] = useState(false);

    const handleClose = () => Setshow(false);
    const handleShow = () => Setshow(true);

    const ToggleTruefalse = () => {


        Setshowmodal(handleShow);

    }

    //* modals*//
    const Modalcontent = () => {
        return (
            <div >
                <Modal show={show} onHide={handleClose} size="lg"  >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Request ID: {RequestTrackerObj.RequestID}

                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ height: "auto" }}>
                    

    
                        {RequestTrackerObj.RequestType === "Order Modification" ?


                            <p><span style={{ fontWeight: 'bold' }}>Request Details: </span><span>{requestDescriptionOM} </span></p>

                            : RequestTrackerObj.RequestType === "Order Modification-Advanced Functionality" ?

                                <p><span style={{ fontWeight: 'bold' }}>Request Details: </span><span>{requestDescriptionOMAdv} </span></p>


                                : RequestTrackerObj.RequestType === "ICR" ?

                                    <>
                                        <p><span style={{ fontWeight: 'bold' }}>Request Details: </span><span>{requestDescriptionICR} </span></p>
                                        <p><span style={{ fontWeight: 'bold' }}>ICR ID: </span><span>{icrIdICR}</span></p>
                                    </>
                                    : null

                        }


                        {RequestTrackerObj.RequestType === "Order Modification" ?

                            <Table responsive striped bordered hover style={{ marginTop: "1.5vw" }}>
                                <thead >
                                    <tr>
                                        <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Sno</td>
                                        <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Material ID</td>
                                        <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Updated Material ID</td>
                                        <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Old Quantity</td>
                                        <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Updated Quantity</td>
                                        <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>UPC</td>
                                        <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Material Description</td>
                                        <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Delivery Date</td>
                                        <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>New Delivery Date</td>
                                        <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Reason Code</td>
                                        <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Modification Action</td>


                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        RequestDetailsdataOM.map((Obj) =>

                                            <tr className='text-center'>
                                                <td >{Obj.Sno}</td>
                                                <td >{Obj.materialId}</td>
                                                <td >{Obj.updatedMaterailId}</td>
                                                <td >{Obj.oldQuantity}</td>
                                                <td >{Obj.updatedQuantity}</td>
                                                <td >{Obj.upc}</td>
                                                <td >{Obj.materialDescription}</td>
                                                <td >{Obj.deliveryDate}</td>
                                                <td >{Obj.newDeliveryDate}</td>
                                                <td >{Obj.ReasonCode}</td>
                                                <td >{Obj.modificationAction}</td>


                                            </tr>


                                        )}
                                </tbody>
                            </Table>

                            : RequestTrackerObj.RequestType === "Order Modification-Advanced Functionality" ?

                                <>
                                    {DetailsForOrderModification_Adv(RequestDetailsTotaldataOMAdv)}

                                </>
                                : RequestTrackerObj.RequestType === "ICR" ?
                                    <Table responsive striped bordered hover style={{ marginTop: "1.5vw" }}>
                                        <thead >
                                            <tr>
                                                <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Sno</td>
                                                <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Activity</td>
                                                <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Material Code</td>
                                                <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Material Code To</td>
                                                <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>UOM</td>
                                                <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>UOM To</td>
                                                <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Add/Cut Value</td>
                                                <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Add/Cut Value QorP</td>
                                                <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Reason Code</td>
                                                <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Min Qty Cap</td>
                                                <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Max Qty Cap</td>


                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                RequestDetailsdataICR.map((Obj) =>

                                                    <tr className='text-center'>
                                                        <td >{Obj.sno}</td>
                                                        <td >{Obj.activity}</td>
                                                        <td >{Obj.materialCode}</td>
                                                        <td >{Obj.materialCodeTo}</td>
                                                        <td >{Obj.uom}</td>
                                                        <td >{Obj.uomTo}</td>
                                                        <td >{Obj.addCutValue}</td>
                                                        <td >{Obj.addCutValueQorP}</td>
                                                        <td >{Obj.reasonCode}</td>
                                                        <td >{Obj.minQtyCap}</td>
                                                        <td >{Obj.maxQtyCap}</td>
                                                    </tr>


                                                )}
                                        </tbody>
                                    </Table>

                                    : RequestTrackerObj.RequestType === "ICR-Advanced Functionality" ?

                                        <>
                                            {DetailsForICR_Adv(RequestDetailsdataICRAdv, RequestDetailsTotaldataSOSTOICRAdv)}

                                        </>
                                        : null

                        }
                        <div className='row' style={{ marginTop: "1.5vw" }}>

                            {RequestTrackerObj.RequestType === "Order Modification" ?
                                <ReactPaginate
                                    previousLabel={"<< Prev"}
                                    nextLabel={"Next >>"}
                                    breakLabel={"..."}

                                    pageCount={pageCount}
                                    forcePage={currentPage}

                                    marginPagesDisplayed={3}
                                    pageRangeDisplayed={3}
                                    onPageChange={(data) => handleChangePagination(RequestDetailsTotaldataOM, RequestTrackerObj.RequestType, data)}
                                    containerClassName={'pagination'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    breakClassName={'page-item'}
                                    breakLinkClassName={'page-link'}
                                    activeClassName={'active'}

                                />
                                : RequestTrackerObj.RequestType === "ICR" ?
                                    <ReactPaginate
                                        previousLabel={"<< Prev"}
                                        nextLabel={"Next >>"}
                                        breakLabel={"..."}

                                        pageCount={pageCount}
                                        forcePage={currentPage}

                                        marginPagesDisplayed={3}
                                        pageRangeDisplayed={3}
                                        onPageChange={(data) => handleChangePagination(RequestDetailsTotaldataICR, RequestTrackerObj.RequestType, data)}
                                        containerClassName={'pagination'}
                                        pageClassName={'page-item'}
                                        pageLinkClassName={'page-link'}
                                        previousClassName={'page-item'}
                                        previousLinkClassName={'page-link'}
                                        nextClassName={'page-item'}
                                        nextLinkClassName={'page-link'}
                                        breakClassName={'page-item'}
                                        breakLinkClassName={'page-link'}
                                        activeClassName={'active'}

                                    />
                                    : RequestTrackerObj.RequestType === "ICR-Advanced Functionality" ?
                                        <ReactPaginate
                                            previousLabel={"<< Prev"}
                                            nextLabel={"Next >>"}
                                            breakLabel={"..."}

                                            pageCount={pageCount}
                                            forcePage={currentPage}

                                            marginPagesDisplayed={3}
                                            pageRangeDisplayed={3}
                                            onPageChange={(data) => handleChangePagination(RequestDetailsTotaldataICRAdv, RequestTrackerObj.RequestType, data)}
                                            containerClassName={'pagination'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            breakClassName={'page-item'}
                                            breakLinkClassName={'page-link'}
                                            activeClassName={'active'}

                                        />
                                        : null
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-light" style={{ color: "White", backgroundColor: "#009639" }} onClick={handleClose}>Cancel</Button>
                    </Modal.Footer>
                </Modal>







            </div>
        )
    }

    //* pagination*//
    const handleChangePagination = (TotalRequestTrackerdata, RequestType, pagenumber) => {

        let newCurrentpage = pagenumber.selected + 1;

        if (RequestType === "Order Modification") {


            SetRequestDetailsTotaldataOM(TotalRequestTrackerdata);
            let value = TotalRequestTrackerdata ? Math.ceil(TotalRequestTrackerdata.length / pageSize) : 0;

            SetpageCount(value);

            SetcurrentPage(pagenumber.selected);

            const startIndex = (newCurrentpage - 1) * pageSize;

            const PaginationPost = _(TotalRequestTrackerdata).slice(startIndex).take(pageSize).value();

            SetRequestDetailsdataOM(PaginationPost);

            ToggleTruefalse();

        }

        else if (RequestType === "ICR") {

            SetRequestDetailsTotaldataICR(TotalRequestTrackerdata);

            let value = TotalRequestTrackerdata ? Math.ceil(TotalRequestTrackerdata.length / pageSize) : 0;

            SetpageCount(value);

            SetcurrentPage(pagenumber.selected);

            const startIndex = (newCurrentpage - 1) * pageSize;

            const PaginationPost = _(TotalRequestTrackerdata).slice(startIndex).take(pageSize).value();

            SetRequestDetailsdataICR(PaginationPost);

            ToggleTruefalse();


        }
        else if (RequestType === "ICR-Advanced Functionality") {

            SetRequestDetailsTotaldataICRAdv(TotalRequestTrackerdata);

            let value = TotalRequestTrackerdata ? Math.ceil(TotalRequestTrackerdata.length / pageSize) : 0;

            SetpageCount(value);

            SetcurrentPage(pagenumber.selected);

            const startIndex = (newCurrentpage - 1) * pageSize;

            const PaginationPost = _(TotalRequestTrackerdata).slice(startIndex).take(pageSize).value();

            SetRequestDetailsdataICRAdv(PaginationPost);

            ToggleTruefalse();


        }
    }

    //* approve/deny buttons *//
    function EnableButtons(value) {

        if (value === Constants.EnableBothApprove_Deny) {

            return (
                <div >
                    <div className="row justify-content-center" style={{ margin: "auto" }}><Button id={RequestTrackerObj.RequestID + "_Approve"} variant="outline-light" style={{ backgroundColor: "#009639", color: "white" }} onClick={() => handleApproveChange(RequestTrackerObj.RequestID, true)}>Approve</Button></div>

                    <div className="row justify-content-center" style={{ margin: "auto" }}><Button id={RequestTrackerObj.RequestID + "_Deny"} variant="outline-light" style={{ marginTop: "0.5vw", backgroundColor: "#FF0000", color: "white" }} onClick={() => handelDenyChange(RequestTrackerObj.RequestID, false)}>Deny</Button></div>

                </div>
            )

        }
        else if (value === Constants.Approve) {
            return (
                <div>
                    <div className="row justify-content-center" style={{ margin: "auto" }} ><Button id={RequestTrackerObj.RequestID + "_Approve"} variant="outline-light" style={{ backgroundColor: "gray", borderColor: "black", border: '3px solid', color: "black" }} disabled>Approve</Button></div>
                    <div className="row justify-content-center" style={{ margin: "auto" }}><Button id={RequestTrackerObj.RequestID + "_Deny"} variant="outline-light" style={{ marginTop: "0.5vw", backgroundColor: "gray", color: "black" }} disabled>Deny</Button></div>

                </div>
            )
        }
        else if (value === Constants.Deny) {
            return (

                <>
                    <div className="row justify-content-center" style={{ margin: "auto" }}><Button id={RequestTrackerObj.RequestID + "_Approve"} variant="outline-light" style={{ backgroundColor: "gray", color: "black" }} disabled>Approve</Button></div>
                    <div className="row justify-content-center" style={{ margin: "auto" }}><Button id={RequestTrackerObj.RequestID + "_Deny"} variant="outline-light" style={{ marginTop: "0.5vw", backgroundColor: "gray", borderColor: "black", border: '3px solid', color: "black" }} disabled>Deny</Button></div>
                </>

            )
        }

        else if (value === Constants.DisableBothApprove_Deny) {

            return (
                <div>
                    <div className="row justify-content-center" style={{ margin: "auto" }}> <Button id={RequestTrackerObj.RequestID + "_Approve"} variant="outline-light" style={{ backgroundColor: "gray", color: "black" }} disabled>Approve</Button></div>
                    <div className="row justify-content-center" style={{ margin: "auto" }}>  <Button id={RequestTrackerObj.RequestID + "_Deny"} variant="outline-light" style={{ marginTop: "0.5vw", backgroundColor: "gray", color: "black" }} disabled>Deny</Button></div>
                </div>
            )

        }

    }

    //* ClickForDetails*//

    function ClickForDetails(RequestType,siteId,siteName) {

        if (RequestType === "Order Modification") {

            return (
                <div>
                    
                    <a href="#" style={{ color: "#3364FF", fontWeight: 'bold', fontSize: 14 }} onClick={() => { RequestDetails(RequestTrackerObj.RequestID, RequestType,siteId,siteName); }}>Click For Details</a>

                    
                    {show ? <Modalcontent /> : null}
                </div>
            )
        }
        else if (RequestType === "Order Modification-Advanced Functionality") {

            return (
                <div>
                    <a href="#" style={{ color: "#3364FF", fontWeight: 'bold', fontSize: 14 }} onClick={() => { RequestDetails(RequestTrackerObj.RequestID, RequestType,siteId,siteName); }}>Click For Details</a>
                    {show ? <Modalcontent /> : null}
                </div>
            )

        }
        else if (RequestType === "ICR") {

            return (
                <div>
                    <a href="#" style={{ color: "#3364FF", fontWeight: 'bold', fontSize: 14 }} onClick={() => { RequestDetails(RequestTrackerObj.RequestID, RequestType,siteId,siteName); }}>Click For Details</a>
                    {show ? <Modalcontent /> : null}
                </div>
            )

        }

        else if (RequestType === "ICR-Advanced Functionality") {

            return (
                <div>
                    <a href="#" style={{ color: "#3364FF", fontWeight: 'bold', fontSize: 14 }} onClick={() => { RequestDetails(RequestTrackerObj.RequestID, RequestType,siteId,siteName); }}>Click For Details</a>
                    {show ? <Modalcontent /> : null}
                </div>
            )

        }

        else if (RequestType === "Incompletions") {

            return (
                <div>
                    <a href='/orders/trackerincompletion' target="_blank" style={{ color: "#3364FF", fontWeight: 'bold', fontSize: 14 }}>Click For Details</a>

                </div>
            )
        }
    }

    // For Order Modification Advanced Pop up//

    function DetailsForOrderModification_Adv(RequestDetailsdataOMAdv) {


        if (RequestDetailsdataOMAdv.massModifyType === "Delivery Date Change") {

            return (
                <>

                    <div className='row' style={{ marginTop: "0.5vw" }}>
                        <div className='col-md-3'>
                            <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Site Id:</Form.Label>

                        </div>
                        <div className='col-md-5'>

                            {RequestDetailsdataOMAdv.siteIdAdv}
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: "0.5vw" }}>
                        <div className='col-md-3'>
                            <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Delivery Date From:</Form.Label>

                        </div>
                        <div className='col-md-5'>

                            {RequestDetailsdataOMAdv.deliveryDateFromAdv}
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: "0.5vw" }}>
                        <div className='col-md-3'>
                            <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Delivery Date To:</Form.Label>

                        </div>
                        <div className='col-md-5'>

                            {RequestDetailsdataOMAdv.deliveryDateToAdv}
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: "2vw" }}>
                        <div className='col-md-3'>
                            <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Additional Comments:</Form.Label>

                        </div>
                        <div className='col-md-5'>

                            <textarea style={{ height: "8rem", width: "19rem" }} className="form-control" >{RequestDetailsdataOMAdv.additionalCommentsAdv}</textarea>

                        </div>
                    </div>
                </>
            )
        }
        else if (RequestDetailsdataOMAdv.massModifyType === "Load Date Change") {

            return (
                <>
                    <div className='row' style={{ marginTop: "0.5vw" }}>
                        <div className='col-md-3'>
                            <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Site Id:</Form.Label>

                        </div>
                        <div className='col-md-5'>

                            {RequestDetailsdataOMAdv.siteIdAdv}
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: "0.5vw" }}>
                        <div className='col-md-3'>
                            <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Load Date From:</Form.Label>

                        </div>
                        <div className='col-md-5'>

                            {RequestDetailsdataOMAdv.loadDateFromAdv}
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: "0.5vw" }}>
                        <div className='col-md-3'>
                            <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Load Date To:</Form.Label>

                        </div>
                        <div className='col-md-5'>

                            {RequestDetailsdataOMAdv.loadDateToAdv}
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: "2vw" }}>
                        <div className='col-md-3'>
                            <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Additional Comments:</Form.Label>

                        </div>
                        <div className='col-md-5'>

                            <textarea style={{ height: "8rem", width: "19rem" }} className="form-control" >{RequestDetailsdataOMAdv.additionalCommentsAdv}</textarea>

                        </div>
                    </div>
                </>
            )

        }
        else if (RequestDetailsdataOMAdv.massModifyType === "Route Change") {

            return (
                <>
                    <div className='row' style={{ marginTop: "0.5vw" }}>
                        <div className='col-md-3'>
                            <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Site Id:</Form.Label>

                        </div>
                        <div className='col-md-5'>

                            {RequestDetailsdataOMAdv.siteIdAdv}
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: "0.5vw" }}>
                        <div className='col-md-3'>
                            <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Current Route:</Form.Label>

                        </div>
                        <div className='col-md-5'>

                            {RequestDetailsdataOMAdv.currentRouteAdv}
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: "0.5vw" }}>
                        <div className='col-md-3'>
                            <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Requested Route:</Form.Label>

                        </div>
                        <div className='col-md-5'>

                            {RequestDetailsdataOMAdv.requestedRouteAdv}
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: "2vw" }}>
                        <div className='col-md-3'>
                            <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Additional Comments:</Form.Label>

                        </div>
                        <div className='col-md-5'>

                            <textarea style={{ height: "8rem", width: "19rem" }} className="form-control" >{RequestDetailsdataOMAdv.additionalCommentsAdv}</textarea>

                        </div>
                    </div>
                </>
            )

        }

        else {

            return (
                <>
                    <div className='row' style={{ marginTop: "0.5vw" }}>
                        <div className='col-md-3'>
                            <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Site Id:</Form.Label>

                        </div>
                        <div className='col-md-5'>

                            {RequestDetailsdataOMAdv.siteIdAdv}
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: "0.5vw" }}>
                        <div className='col-md-3'>
                            <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Delivery Date :</Form.Label>

                        </div>
                        <div className='col-md-5'>

                            {RequestDetailsdataOMAdv.deliveryDateAdv}
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: "0.5vw" }}>
                        <div className='col-md-3'>
                            <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Load Date:</Form.Label>

                        </div>
                        <div className='col-md-5'>

                            {RequestDetailsdataOMAdv.loadDateAdv}
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: "2vw" }}>
                        <div className='col-md-3'>
                            <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Additional Comments:</Form.Label>

                        </div>
                        <div className='col-md-5'>

                            <textarea style={{ height: "8rem", width: "19rem" }} className="form-control" >{RequestDetailsdataOMAdv.additionalCommentsAdv}</textarea>

                        </div>
                    </div>
                </>
            )
        }

    }

    
    // For ICR Advanced Pop up//
    function DetailsForICR_Adv(RequestDetailsdataICRAdv, RequestDetailsdataSOSTOICRAdv) {

        if (RequestDetailsdataSOSTOICRAdv.targetOrderTypeSOS === "SO") {
            return (
                <>
                    <div className='row' style={{ marginTop: "0.5vw" }}>
                        <div className='col-md-2'>
                            <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Request Details:</Form.Label>

                        </div>
                        <div className='col-md-10'>

                            {RequestDetailsdataSOSTOICRAdv.requestDescriptionSOS}
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: "0.5vw" }}>
                        <div className='col-md-3'>
                            <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Trade Segment:</Form.Label>

                        </div>
                        <div className='col-md-9'>

                            {RequestDetailsdataSOSTOICRAdv.tradeSegmentSOS}
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: "0.5vw" }}>
                        <div className='col-md-3'>
                            <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Customer (Exclude):</Form.Label>

                        </div>
                        <div className='col-md-9'>

                            {RequestDetailsdataSOSTOICRAdv.customersExcludeSOS}
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: "0.5vw" }}>
                        <div className='col-md-3'>
                            <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Customer (Include):</Form.Label>

                        </div>
                        <div className='col-md-9'>

                            {RequestDetailsdataSOSTOICRAdv.customersIncludeSOS}
                        </div>
                    </div>

                    <div className='row' style={{ marginTop: "0.5vw" }}>
                        <div className='col-md-3'>
                            <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Hierarchy:</Form.Label>

                        </div>
                        <div className='col-md-9'>

                            {RequestDetailsdataSOSTOICRAdv.hierarchySOS}
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: "0.5vw" }}>
                        <div className='col-md-3'>
                            <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Sold-to-Party:</Form.Label>

                        </div>
                        <div className='col-md-9'>

                            {RequestDetailsdataSOSTOICRAdv.soldToPartySOS}
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: "0.5vw" }}>
                        <div className='col-md-3'>
                            <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Order Reporting:</Form.Label>

                        </div>
                        <div className='col-md-9'>

                            {RequestDetailsdataSOSTOICRAdv.orderReportingSOS}
                        </div>
                    </div>

                    <div className='row' style={{ marginTop: "2vw" }}>
                        <div className='col-md-3'>
                            <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Comments:</Form.Label>

                        </div>
                        <div className='col-md-9'>

                            <textarea style={{ height: "8rem", width: "19rem" }} className="form-control" >{RequestDetailsdataSOSTOICRAdv.commentsSOS}</textarea>

                        </div>
                    </div>

                    <div className='row' style={{ marginTop: "2vw" }}>
                        <Table responsive striped bordered hover style={{ marginTop: "1.5vw" }}>
                            <thead >
                                <tr>
                                    <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Sno</td>
                                    <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Activity</td>
                                    <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Material Code</td>
                                    <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Material Code To</td>
                                    <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>UOM</td>
                                    <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>UOM To</td>
                                    <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Add/Cut Value</td>
                                    <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Add/Cut Value QorP</td>
                                    <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Reason Code</td>
                                    <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Min Qty Cap</td>
                                    <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Max Qty Cap</td>


                                </tr>
                            </thead>
                            <tbody>

                                {
                                    RequestDetailsdataICRAdv.map((Obj) =>

                                        <tr className='text-center'>
                                            <td >{Obj.sno}</td>
                                            <td >{Obj.activity}</td>
                                            <td >{Obj.materialCode}</td>
                                            <td >{Obj.materialCodeTo}</td>
                                            <td >{Obj.uom}</td>
                                            <td >{Obj.uomTo}</td>
                                            <td >{Obj.addCutValue}</td>
                                            <td >{Obj.addCutValueQorP}</td>
                                            <td >{Obj.reasonCode}</td>
                                            <td >{Obj.minQtyCap}</td>
                                            <td >{Obj.maxQtyCap}</td>
                                        </tr>


                                    )}
                            </tbody>
                        </Table>
                    </div>
                </>
            )

        }
        else if (RequestDetailsdataSOSTOICRAdv.targetOrderTypeSOS === "STO") {
            return (

                <>
                      <div className='row' style={{ marginTop: "0.5vw" }}>
                        <div className='col-md-2'>
                            <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Request Details:</Form.Label>

                        </div>
                        <div className='col-md-10'>

                            {RequestDetailsdataSOSTOICRAdv.requestDescriptionSOS}
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: "0.5vw" }}>
                        <div className='col-md-3'>
                            <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Dest Location(Include):</Form.Label>

                        </div>
                        <div className='col-md-9'>

                            {RequestDetailsdataSOSTOICRAdv.destLocationIncludeSOS}
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: "0.5vw" }}>
                        <div className='col-md-3'>
                            <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Dest Location(Exclude):</Form.Label>

                        </div>
                        <div className='col-md-9'>

                            {RequestDetailsdataSOSTOICRAdv.destLocationExcludeSOS}
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: "0.5vw" }}>
                        <div className='col-md-3'>
                            <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Purchase Group:</Form.Label>

                        </div>
                        <div className='col-md-9'>

                            {RequestDetailsdataSOSTOICRAdv.purchaseGroupSOS}
                        </div>
                    </div>

                    <div className='row' style={{ marginTop: "0.5vw" }}>
                        <div className='col-md-3'>
                            <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>STO #:</Form.Label>

                        </div>
                        <div className='col-md-9'>

                            {RequestDetailsdataSOSTOICRAdv.stoSOS}
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: "0.5vw" }}>
                        <div className='col-md-3'>
                            <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>STO Type:</Form.Label>

                        </div>
                        <div className='col-md-9'>

                            {RequestDetailsdataSOSTOICRAdv.stoTypeSOS}
                        </div>
                    </div>

                    <div className='row' style={{ marginTop: "2vw" }}>
                        <div className='col-md-3'>
                            <Form.Label style={{ fontWeight: "bold", fontSize: 14 }}>Comments:</Form.Label>

                        </div>
                        <div className='col-md-9'>

                            <textarea style={{ height: "8rem", width: "19rem" }} className="form-control" >{RequestDetailsdataSOSTOICRAdv.commentsSOS}</textarea>

                        </div>
                    </div>

                    <div className='row' style={{ marginTop: "2vw" }}>
                        <Table responsive striped bordered hover style={{ marginTop: "1.5vw" }}>
                            <thead >
                                <tr>
                                    <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Sno</td>
                                    <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Activity</td>
                                    <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Material Code</td>
                                    <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Material Code To</td>
                                    <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>UOM</td>
                                    <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>UOM To</td>
                                    <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Add/Cut Value</td>
                                    <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Add/Cut Value QorP</td>
                                    <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Reason Code</td>
                                    <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Min Qty Cap</td>
                                    <td style={{ textAlign: 'center', fontWeight: "bold", verticalAlign: "middle" }}>Max Qty Cap</td>


                                </tr>
                            </thead>
                            <tbody>

                                {
                                    RequestDetailsdataICRAdv.map((Obj) =>

                                        <tr className='text-center'>
                                            <td >{Obj.sno}</td>
                                            <td >{Obj.activity}</td>
                                            <td >{Obj.materialCode}</td>
                                            <td >{Obj.materialCodeTo}</td>
                                            <td >{Obj.uom}</td>
                                            <td >{Obj.uomTo}</td>
                                            <td >{Obj.addCutValue}</td>
                                            <td >{Obj.addCutValueQorP}</td>
                                            <td >{Obj.reasonCode}</td>
                                            <td >{Obj.minQtyCap}</td>
                                            <td >{Obj.maxQtyCap}</td>
                                        </tr>


                                    )}
                            </tbody>
                        </Table>
                    </div>
                </>
            )
        }

    }


    return (
        <tr style={{ fontSize: 13 }}>
            <td style={{ verticalAlign: "middle" }}>{RequestTrackerObj.RequestID}</td>
            <td style={{ verticalAlign: "middle" }}>{RequestTrackerObj.RequestorNameEmailGPID}</td>
            <td style={{ verticalAlign: "middle" }}>{RequestTrackerObj.SiteIDSiteName}</td>
            <td style={{ verticalAlign: "middle" }}>{RequestTrackerObj.RequestType}</td>
            <td style={{ verticalAlign: "middle" }}>{RequestTrackerObj.RequestDateTime}</td>
            <td style={{ verticalAlign: "middle" }}>{RequestTrackerObj.ApprovalRequired}</td>
            <td style={{ verticalAlign: "middle" }}>{RequestTrackerObj.ApprovalSentTimeEmail}</td>
            <td style={{ verticalAlign: "middle" }}>{RequestTrackerObj.ReminderSentTimeEmail}</td>
            <td style={{ verticalAlign: "middle" }}>{RequestTrackerObj.EscalationSentTimeEmail}</td>
            <td style={{ verticalAlign: "middle" }}>{RequestTrackerObj.Status}</td>
            <td style={{ verticalAlign: "middle" }} className='text-center'>
    

                {ClickForDetails(RequestTrackerObj.RequestType,RequestTrackerObj.SiteId,RequestTrackerObj.SiteIDSiteName)}

            </td>

            <td className="justify-content-center" style={{ verticalAlign: "middle" }}>


                {EnableButtons(RequestTrackerObj.EnableButtons)}
            </td>
        </tr>
    )

}
export default ReadOnlyRowsRequestTracker;