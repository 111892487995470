import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import JQuery from 'jquery';
import ConfigUtility from '../../ConfigUtility';
const EditableRows = ({ editFormData, handleEditFormChange, handleEditFormSubmit, handelCancelClick }) => {






    let reasonCodeResponse = [], reasonCodeOptions = '';
    const hostUrl = ConfigUtility.URL;

    const getToken = () => {
        let tokenStr = localStorage.getItem("okta-token-storage");
        if (!tokenStr) {
            return;
        }
        else {
            const tokenObj = JSON.parse(tokenStr);
            return tokenObj?.accessToken?.accessToken;
        }
    }
    useEffect(() => {
        const token = getToken();
        const headers = {
            "accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        };

        JQuery.ajax({
            url: hostUrl + "/ordermodification/reason-code",
            type: 'POST',
            dataType: 'json',
            CORS: true,
            contentType: 'application/json',
            secure: true,
            headers: headers,
            crossDomain: true,
            success: function (response) {
                reasonCodeOptions = '<option value="" name ="" selected>--Select--</option>\n';
                reasonCodeResponse = response["response"];
                for (let i = 0; i < reasonCodeResponse.length; i++) {
                    reasonCodeOptions += '<option value="' + reasonCodeResponse[i]["id"] + '" description="' + reasonCodeResponse[i]["description"] + '">' + reasonCodeResponse[i]["description"] + '</option>\n';
                }


                JQuery("#ReasonCodeAlloactionlist").html(reasonCodeOptions);
                JQuery("#ReasonCodeAlloactionlist").val(editFormData.reasoncodedescription);


            },
            error: function (jqHXR, textStatus, errorThrown) {


            }

        });


    }, []);
    return (

        <tr>
            <td style={{ verticalAlign: "middle" }}>
                <input type="text"
                    name="materialNumber"
                    required="required"
                    placeholder='Enter Material Number'
                    value={editFormData.materialNumber}
                    onChange={handleEditFormChange}
                    disabled
                />
            </td>
            <td style={{ verticalAlign: "middle" }}>
                <input type="text"
                    name="upc"
                    required="required"
                    placeholder='Enter UPC'
                    value={editFormData.upc}
                    onChange={handleEditFormChange}
                    disabled
                />
            </td>
            <td style={{ verticalAlign: "middle" }}>
                <input type="text"
                    name="materialDescription"
                    required="required"
                    placeholder='Enter Description '
                    value={editFormData.materialDescription}
                    onChange={handleEditFormChange}
                    disabled
                />
            </td>
            <td style={{ verticalAlign: "middle" }}>
                <input type="text"
                    name="uom"
                    required="required"
                    placeholder='Enter Unit Of Measure'
                    value={editFormData.uom}
                    onChange={handleEditFormChange}
                    disabled
                    style={{ width: "5rem" }}
                />
            </td>
            <td style={{ verticalAlign: "middle" }}>
                <input type="text"
                    name="orderQuantity"
                    required="required"
                    idx="restrictedDecimal"
                    placeholder='Enter Current Order Quantity'
                    value={editFormData.orderQuantity}
                    onChange={handleEditFormChange}
                    style={{ width: "5rem" }}
                />
            </td>
            <td style={{ verticalAlign: "middle" }}>
                <select name='ReasonCode' id='ReasonCodeAlloactionlist' value={editFormData.reasoncodedescription}
                    onChange={handleEditFormChange} style={{ width: "10rem", height: "1.80rem" }}>

                </select>
                <br></br>
                <span className="help-block text-danger" name="Invalid_ReasonDB" style={{ display: "None" }}>Please select a Reason Code </span>
            </td>
            <td>
                <Button variant="outline-light" type="button" onClick={handleEditFormSubmit} style={{ backgroundColor: "#009639", color: "white" }} >Save</Button>
                &nbsp;&nbsp;
                <Button variant="outline-light" type="button" onClick={handelCancelClick} style={{ backgroundColor: "#FF0000", color: "white" }} >Cancel</Button>
            </td>
        </tr>
    )

}
export default EditableRows;