import React, { useState, useEffect, Fragment } from 'react';

import { nanoid } from 'nanoid';
import JQuery, { data } from 'jquery';
import ReadOnlyRowsForAdd from "./AddRemove/ReadOnlyRowsForAdd"
import ReadOnlyRows from "./ItemQuantity/ReadOnlyRows"
import ReadProductRows from "./ProductCode/ReadProductRows"
import ReadOnlyRowsDeliveryDate from './DeliveryDate/ReadOnlyRowsDeliveryDate'
import EditableRows from "./ItemQuantity/EditableRows"
import EditableRowsForProduct from "./ProductCode/EdittableRowsForProduct"
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { get } from '@okta/okta-auth-js';
import { EXCEL_FILE_BASE64 } from './ExportOMTemplate';
import FileSaver from 'file-saver';

import ConfigUtility from '../ConfigUtility';
import * as Constants from '../Common Files/Constants.js';
import moment from 'moment';
import 'moment-timezone';

const getToken = () => {
    let tokenStr = localStorage.getItem("okta-token-storage");
    if (!tokenStr) {
        return;
    }
    else {
        const tokenObj = JSON.parse(tokenStr);
        return tokenObj?.accessToken?.accessToken;
    }
}


const OrderModification = () => {
    const [isReasonCodesFlag, setReasonCodeFlag] = useState(false);

    let reasonCodeResponse = [], validOrder = false, validmaterial = false, orderModificationResponses = [], orderModificationDetails = [], postOrderDetailsActions = [], postOrderDetailsElementsCounter = 0, ordernumber, numberTypeOrder, CustomerName, Siteid, siteName, DeliveryDate, selectedFile, uploadFileEncode, MaterialNumbrMessage, FileType, ErrorMessageOrder;
    let temp_emailid = sessionStorage.getItem("emailid");
    let temp_gpid = sessionStorage.getItem("gpid");
    let temp_role = sessionStorage.getItem("role");
    let temp_siteaccess = sessionStorage.getItem("siteaccess");
    const [selectedRequestType, setSelectedRequestType] = useState("");
    const [isShowConfirmPopUp, setShowConfirmPopUp] = useState(false);
    sessionStorage.setItem("windowload", false);

    const hostUrl = ConfigUtility.URL;
    const [materials, Setmaterials] = useState([]);
    const [oldMaterials, Setoldmaterials] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [deliveryDate, setDeliveryDate] = useState(new Date());
    const [reasonCodeOptions, setreasoncodeoptions] = useState(null);
    const [showAdd, SetshowAdd] = useState(false);
    //DATEPICKER
    const [OrderDeliverytodate, SetOrderDeliveryTodate] = useState('');
    const [OrderDeliveryFromdate, SetOrderDeliveryFromdate] = useState('');
    const [LoadTodate, SetLoadTodate] = useState('');
    const [LoadFromdate, SetLoadFromdate] = useState('');
    const [OrderDeliveryDate, SetOrderDeliveryDate] = useState('');
    const [OrderLoadDate, SetOrderLoadDate] = useState('');


    const [addFormData, SetaddFormData] = useState({

        materialNumber: '',
        upc: '',
        materialDescription: '',
        uom: '',
        orderQuantity: '',
        reasonCode: '',
        resonCodeDesc: '',


    });

    const handleAddFormChange = (event) => {
        event.preventDefault();
        JQuery('input[idx= "restrictedDecimal"]').keyup(function (e) {
            if (/\D/g.test(this.value)) {
                // Filter non-digits from input value.
                this.value = this.value.replace(/\D/g, '');
            }
        });

        const fieldName = event.target.getAttribute('alias');
        const fieldvalue = event.target.value;
        if (fieldName === "materialNumber") {
            valiateMaterialNumber();
            handleMaterialDescription(event);
        }
        const newFormData = { ...addFormData };
        newFormData[fieldName] = fieldvalue;
        newFormData['resonCodeDesc'] = (fieldName === "reasonCode") ? JQuery(event.target).find(':selected').html() : newFormData['resonCodeDesc'];
        SetaddFormData(newFormData);
    }

    const handleAddSubmit = () => {

        let addedMaterial = {};
        const materialNumber = JQuery("#TableMaterialNumber").val();
        validmaterial = (JQuery("#TableMaterialNumber").attr("validation") === "true");
        if (validmaterial) {
            let newId = nanoid();
            const newObject = {

                id: newId,
                materialNumber: (materialNumber === "") ? materialNumber.toString() : (parseInt(materialNumber)).toString(),
                upc: addFormData.upc,
                materialDescription: addFormData.materialDescription,
                uom: addFormData.uom,
                reasonCode: addFormData.reasonCode,
                reasonCodeDesc: addFormData.resonCodeDesc,
                orderQuantity: (addFormData.orderQuantity === "") ? addFormData.orderQuantity.toString() : (parseInt(addFormData.orderQuantity)).toString(),

            }

            const newObjects = [...materials, newObject];

            addedMaterial["materialId"] = "";
            addedMaterial["oldQuantity"] = "";
            addedMaterial["updatedQuantity"] = Number(newObject.orderQuantity);
            addedMaterial["upc"] = newObject.upc.toString();
            addedMaterial["modificationDescription"] = newObject.materialDescription.toString();
            addedMaterial["uom"] = newObject.uom.toString();
            addedMaterial["deliveryDate"] = "";
            addedMaterial["deliveryDateNew"] = "";
            addedMaterial["modificationAction"] = "Add";
            addedMaterial["dateTime"] = "";
            addedMaterial["updatedMaterialId"] = newObject.materialNumber.toString();
            addedMaterial["reasonCode"] = newObject.reasonCodeDesc.toString();
            consolidatedPostOrderDetails(addedMaterial);
            JQuery('#AddRemoveTable').find('tr').each(function () {
                JQuery(this).attr('index', JQuery(this).index());
            });
            Setmaterials(newObjects);
            JQuery('#AddRemoveTable').find('select[name="ReasonCode"]').each(function () {

            });
            JQuery("#AddOrder").html("Add").css({ width: "3.8rem" });
            JQuery('#AddItems').find('input').val('');
            JQuery("#Invalid_MaterialNumberTable").hide();
            JQuery("#InvalidMaterial_ReasonCode").hide();
            JQuery("#Invalid_MaterialNumberTableDB").hide();
            JQuery("#Invactive_MaterialNumberTableDB").hide();
            JQuery("#TableMaterialNumber").removeAttr("style");
            JQuery('#AddItems').find('select[name="ReasonCode"]').css("width", "15rem");
            JQuery('#AddItems').find('select[name="ReasonCode"]').css("border", "");

        }
        else {

            JQuery("#Invalid_MaterialNumberTable").hide();
            if (MaterialNumbrMessage === "Material is not active") {
                JQuery("#Invactive_MaterialNumberTableDB").show();
                JQuery("#Invalid_MaterialNumberTableDB").hide();
                JQuery("#TableMaterialNumber").css("border", "1px solid red");
                JQuery("#AddOrder").html("Add").css({ width: "3.8rem" });
                JQuery('#AddItems').find('select[name="ReasonCode"]').css("width", "15rem");
                JQuery("#InvalidMaterial_ReasonCode").hide();
                JQuery('#AddItems').find('select[name="ReasonCode"]').css("border", "");


            }
            else {
                JQuery("#Invalid_MaterialNumberTableDB").show();
                JQuery("#Invactive_MaterialNumberTableDB").hide();
                JQuery("#TableMaterialNumber").css("border", "1px solid red");
                JQuery("#AddOrder").html("Add").css({ width: "3.8rem" });
                JQuery('#AddItems').find('select[name="ReasonCode"]').css("width", "15rem");
                JQuery("#InvalidMaterial_ReasonCode").hide();
                JQuery('#AddItems').find('select[name="ReasonCode"]').css("border", "");

            }
        }
    }

    //For Submitting data for Add Functionality
    const handleAddFormSubmit = (event) => {

        event.preventDefault();


        const materialnumber_regex = /^[0-9]{13}$/;
        const materialNumber = JQuery("#TableMaterialNumber").val();
        JQuery("#AddOrder").html("<i class='fa fa-refresh fa-spin' ></i> Adding").css({ width: "6.8rem" });
        JQuery('#AddItems').find('select[name="ReasonCode"]').css("width", "15rem");
        if (materialnumber_regex.test(materialNumber) && JQuery('#AddItems').find('select[name="ReasonCode"] :selected').val() !== 'Select') {

            handleAddSubmit();

        }
        else if (materialnumber_regex.test(materialNumber) && JQuery('#AddItems').find('select[name="ReasonCode"] :selected').val() === 'Select') {

            JQuery("#Invalid_MaterialNumberTable").hide();
            JQuery("#TableMaterialNumber").removeAttr("style");
            JQuery("#InvalidMaterial_ReasonCode").show();
            JQuery('#AddItems').find('select[name="ReasonCode"]').css("border", "1px solid red");
            JQuery("#AddOrder").html("Add").css({ width: "3.8rem" });
            JQuery('#AddItems').find('select[name="ReasonCode"]').css("width", "15rem");
        }
        else if (!materialnumber_regex.test(materialNumber) && JQuery('#AddItems').find('select[name="ReasonCode"] :selected').val() !== 'Select') {
            JQuery("#Invalid_MaterialNumberTable").show();
            JQuery("#TableMaterialNumber").css("border", "1px solid red");
            JQuery("#InvalidMaterial_ReasonCode").hide();
            JQuery('#AddItems').find('select[name="ReasonCode"]').css("border", "");
            JQuery("#AddOrder").html("Add").css({ width: "3.8rem" });
            JQuery('#AddItems').find('select[name="ReasonCode"]').css("width", "15rem");

        }
        else {
            JQuery("#Invalid_MaterialNumberTable").show();
            JQuery("#Invalid_MaterialNumberTableDB").hide();
            JQuery("#Invactive_MaterialNumberTableDB").hide();
            JQuery("#TableMaterialNumber").css("border", "1px solid red");
            JQuery("#InvalidMaterial_ReasonCode").show();
            JQuery('#AddItems').find('select[name="ReasonCode"]').css("border", "1px solid red");
            JQuery("#AddOrder").html("Add").css({ width: "3.8rem" });
            JQuery('#AddItems').find('select[name="ReasonCode"]').css("width", "15rem");
        }
    }

    //To get Material Description
    const handleMaterialDescription = (event) => {
        const token = getToken();
        const headers = {
            "accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
        const materialnumber_regex = /^[0-9]{13}$/;
        let materialnumberAdd = JQuery("#TableMaterialNumber").val();
        let materialNumberValiid = {};
        materialNumberValiid["materialNumber"] = materialnumberAdd;
        if (JQuery(event.target).attr('id') === "TableMaterialNumber" && materialnumber_regex.test(materialnumberAdd)) {
            JQuery.ajax({
                url: hostUrl + "/ordermodification/materialDescription",
                type: 'POST',
                dataType: 'json',
                data: JSON.stringify({
                    "materialNumber": materialnumberAdd
                }),
                CORS: true,
                contentType: 'application/json',
                secure: true,
                headers: headers,
                crossDomain: true,
                success: function (response) {
                    JQuery(event.target).parents('tr').find('input[name="materialDescription"]').val(response.response.materialDesciption);
                    JQuery(event.target).parents('tr').find('select[name="uom"] option[name="' + response.response.umo + '"]').prop('selected', true);
                    JQuery(event.target).parents('tr').find('input[name="upc"]').val(response.response.upc);
                    const newFormData = { ...addFormData };
                    newFormData['materialDescription'] = response.response.materialDesciption;
                    newFormData['uom'] = response.response.umo;
                    newFormData['upc'] = response.response.upc;
                    newFormData['orderQuantity'] = "";
                    SetaddFormData(newFormData);
                },
                error: function (textStatus) {

                }
            });
        }
        if (JQuery(event.target).attr('id') === "TableMaterialNumber" && !materialnumber_regex.test(materialnumberAdd)) {
            JQuery(event.target).parents('tr').find('input[name="materialDescription"]').val("");
            JQuery(event.target).parents('tr').find('select[name="uom"] option[name="Please Select"]').prop('selected', true);
            JQuery(event.target).parents('tr').find('input[name="upc"]').val("");
            const newFormData = { ...addFormData };
            newFormData['materialDescription'] = "";
            newFormData['uom'] = "";
            newFormData['upc'] = "";
            SetaddFormData(newFormData);
        }

    }



    const [editMaterialId, setEditMaterialId] = useState(null);

    const [editFormData, SeteditFormData] = useState({

        materialNumber: '',
        upc: '',
        materialDescription: '',
        uom: '',
        orderQuantity: '',
        updatedMaterialNumber: '',
        reasonCode: '',
        resonCodeDesc: '',


    });

    const handleEditClick = (event, material) => {
        event.preventDefault();
        setEditMaterialId(material.id);
        const formvalues = {

            materialNumber: material.materialNumber,
            upc: material.upc,
            materialDescription: material.materialDescription,
            uom: material.uom,
            orderQuantity: material.orderQuantity,
            updatedMaterialNumber: material.updatedMaterialNumber,
            reasonCode: material.reasonCode,
            resonCodeDesc: material.reasonCodeDesc
        }

        SeteditFormData(formvalues);
    }


    const handleEditFormChange = (event) => {
        event.preventDefault();

        const fieldName = event.target.getAttribute('name');
        const fieldvalue = event.target.value;
        JQuery('input[idx= "restrictedDecimal"]').keyup(function (e) {
            if (/\D/g.test(this.value)) {
                this.value = this.value.replace(/\D/g, '');
            }
        });
        const newFormData = { ...editFormData };
        newFormData[fieldName] = fieldvalue;

        SeteditFormData(newFormData);

    }

    //To Edit Data for Item Quantity Channge and Product Code Change Request
    const handleEditFormSubmit = (event) => {

        let updatedMaterialnumber_regex = /^[0-9]{13}$/;
        let UpdatedmaterialNumber = JQuery("#updatedMaterialNumber").val();
        let materialValidationFlag = false;
        let editReasonCode = JQuery(event.target).parents('tr').find('select[name="ReasonCode"] :selected').val();
        let editReasonCodeDesc = JQuery(event.target).parents('tr').find('select[name="ReasonCode"] :selected').html();
        (JQuery('#OrderRequest').find(":selected").val() === "Product Code Change Request") ? materialValidationFlag = updatedMaterialnumber_regex.test(UpdatedmaterialNumber) && (JQuery(event.target).parents('tr').find('select[name="ReasonCode"] :selected').val() !== "") : materialValidationFlag = true;
        if (JQuery('#OrderRequest').find(":selected").val() === "Product Code Change Request") {
            materialValidationFlag = updatedMaterialnumber_regex.test(UpdatedmaterialNumber) && (JQuery(event.target).parents('tr').find('select[name="ReasonCode"] :selected').val() !== "");
        }
        else if (JQuery('#OrderRequest').find(":selected").val() === "Item Quantity Change") {
            materialValidationFlag = (JQuery(event.target).parents('tr').find('select[name="ReasonCode"] :selected').val() !== "");
        }
        else {
            materialValidationFlag = true;
        }

        if (materialValidationFlag) {
            event.preventDefault();
            let editMaterial = {};
            const EditObject = {

                id: editMaterialId,
                materialNumber: editFormData.materialNumber,
                upc: editFormData.upc,
                materialDescription: editFormData.materialDescription,
                uom: editFormData.uom,
                orderQuantity: (editFormData.orderQuantity === "") ? editFormData.orderQuantity.toString() : (parseInt(editFormData.orderQuantity)).toString(),
                updatedMaterialNumber: (editFormData.updatedMaterialNumber === "") ? editFormData.updatedMaterialNumber.toString() : (parseInt(editFormData.updatedMaterialNumber)).toString(),
                reasonCode: editReasonCode,
                reasonCodeDesc: editReasonCodeDesc,

            };

            const newObjects = [...materials];
            const index = materials.findIndex((material) => material.id === editMaterialId);
            editMaterial["materialId"] = EditObject.materialNumber;
            (JQuery('#OrderRequest').find(":selected").val() === "Product Code Change Request") ? editMaterial["oldQuantity"] = "" : editMaterial["oldQuantity"] = Number(oldMaterials[index].orderQuantity);
            (JQuery('#OrderRequest').find(":selected").val() === "Product Code Change Request") ? editMaterial["updatedQuantity"] = "" : editMaterial["updatedQuantity"] = Number(EditObject.orderQuantity);
            editMaterial["upc"] = "";
            editMaterial["modificationDescription"] = "";
            editMaterial["uom"] = "";
            editMaterial["deliveryDate"] = "";
            editMaterial["deliveryDateNew"] = "";
            (JQuery('#OrderRequest').find(":selected").val() === "Product Code Change Request") ? editMaterial["modificationAction"] = "Product Code Change" : editMaterial["modificationAction"] = "Update Qty";
            editMaterial["dateTime"] = "";
            (JQuery('#OrderRequest').find(":selected").val() === "Product Code Change Request") ? editMaterial["updatedMaterialId"] = EditObject.updatedMaterialNumber : editMaterial["updatedMaterialId"] = "";
            editMaterial["reasonCode"] = EditObject.reasonCodeDesc;
            consolidatedPostOrderDetails(editMaterial);

            newObjects[index] = EditObject;
            Setmaterials(newObjects);
            setEditMaterialId(null);

            JQuery("#Invalid_UpdatedMaterialNumberTable").hide();
            JQuery("#updatedMaterialNumber").removeAttr("style");
            JQuery(event.target).parents('tr').find('span[name="Invalid_ReasonDB"]').hide();
            JQuery(event.target).parents('tr').find('select[name="ReasonCode"]').removeAttr("style");

        }
        else {
            (JQuery("#updatedMaterialNumber").val().length >= 0 && JQuery("#updatedMaterialNumber").val().length !== 13) ? JQuery("#Invalid_UpdatedMaterialNumberTable").show() : JQuery("#Invalid_UpdatedMaterialNumberTable").hide();
            (JQuery("#updatedMaterialNumber").val().length >= 0 && JQuery("#updatedMaterialNumber").val().length !== 13) ? JQuery("#updatedMaterialNumber").css("border", "1px solid red") : JQuery("#updatedMaterialNumber").removeAttr("style");
            (JQuery(event.target).parents('tr').find('select[name="ReasonCode"] :selected').val() === "") ? JQuery(event.target).parents('tr').find('span[name="Invalid_ReasonDB"]').show() : JQuery(event.target).parents('tr').find('span[name="Invalid_ReasonDB"]').hide();
            (JQuery(event.target).parents('tr').find('select[name="ReasonCode"] :selected').val() === "") ? JQuery(event.target).parents('tr').find('select[name="ReasonCode"]').css("border", "1px solid red") : JQuery(event.target).parents('tr').find('select[name="ReasonCode"]').removeAttr("style");

        }
    }

    const handelCancelClick = () => {


        setEditMaterialId(null);

    }

    //Remove Functionality for Add or Remove Items
    const handelRemoveClick = (materialId, reasonCodeOptions, event) => {

        let newObjects = [...materials];
        let removeMaterials = {};
        let removeReasoncode = JQuery(event.target).parents('tr').find('select[name="ReasonCode"] :selected');
        const index = materials.findIndex((material) => material.id === materialId);
        const old_index = oldMaterials.findIndex((material) => material.id === materialId);
        newObjects.splice(index, 1);
        removeMaterials["materialId"] = materials[index].materialNumber;
        removeMaterials["oldQuantity"] = Number(materials[index].orderQuantity);
        removeMaterials["updatedQuantity"] = "";
        removeMaterials["upc"] = materials[index].upc;
        removeMaterials["modificationDescription"] = materials[index].materialDescription;
        removeMaterials["uom"] = materials[index].uom;
        removeMaterials["deliveryDate"] = "";
        removeMaterials["deliveryDateNew"] = "";
        removeMaterials["modificationAction"] = "Remove";
        removeMaterials["dateTime"] = "";
        removeMaterials["updatedMaterialId"] = "";
        removeMaterials["reasonCode"] = removeReasoncode.html();
        if (removeReasoncode.val() === "Select") {
            removeReasoncode.parents('table').find('select[name="ReasonCode"]').css("border", "");
            removeReasoncode.parents('table').find('select[name="ReasonCode"]').siblings('span[name="Invalid_ReasonCode"]').hide();
            removeReasoncode.parent().css("border", "1px solid red");
            removeReasoncode.parent().siblings('span[name="Invalid_ReasonCode"]').show();

        }
        else {
            removeReasoncode.parents('table').find('select[name="ReasonCode"]').css("border", "");
            removeReasoncode.parents('table').find('select[name="ReasonCode"]').siblings('span[name="Invalid_ReasonCode"]').hide();
            if (old_index >= 0) {
                consolidatedPostOrderDetails(removeMaterials);
                Setmaterials(newObjects);
            }
            else {
                postOrderDetailsActions = JSON.parse(JQuery("#quicksaver").val());
                postOrderDetailsElementsCounter = parseInt(JQuery("#quicksaver2").val());
                for (let i = 0; i < postOrderDetailsActions.typeModifications.length; i++) {
                    if (postOrderDetailsActions.typeModifications[i].updatedMaterialId === removeMaterials.materialId &&
                        postOrderDetailsActions.typeModifications[i].upc === removeMaterials.upc &&
                        postOrderDetailsActions.typeModifications[i].uom === removeMaterials.uom &&
                        postOrderDetailsActions.typeModifications[i].materialDescription === removeMaterials.materialDescription &&
                        postOrderDetailsActions.typeModifications[i].updatedQuantity === removeMaterials.oldQuantity
                    ) {
                        postOrderDetailsActions.typeModifications.splice(i, 1);
                        postOrderDetailsElementsCounter--;
                    }


                }
                JQuery("#quicksaver").val(JSON.stringify(postOrderDetailsActions));
                JQuery("#quicksaver2").val(postOrderDetailsElementsCounter.toString());
                Setmaterials(newObjects);

            }
            JQuery('#AddRemoveTable').find('select[name="ReasonCode"]').empty();
            JQuery('#AddRemoveTable').find('select[name="ReasonCode"]').html('<option value="Select" selected="">Loading...</option>');
            setTimeout(() => {
                JQuery('#AddRemoveTable').find('select[name="ReasonCode"]').each(function () {
                    JQuery(this).empty().html(reasonCodeOptions);
                    if (JQuery(this).attr('data-getrc') !== '') {
                        JQuery(this).find('option[value="' + JQuery(this).attr('data-getrc') + '"]').prop('selected', true);

                    }
                });
            }, 700);
        }

    }

    //onchange states
    const [excelFile, setExcelFile] = useState(null);
    const [typeError, setTypeError] = useState(null);

    // UPLOAD ONCHANGE FUNCTIONALITY
    const handleFile = (e) => {
        e.preventDefault();
        let filetypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', ' application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf', 'text/plain'];
        selectedFile = e.target.files[0];
        FileType = "." + selectedFile.name.split(".")[1];
        if (selectedFile) {
            if (selectedFile && filetypes.includes(selectedFile.type)) {
                setTypeError(null);
                JQuery('#Invalid_File').hide();
                JQuery('#Valid_File').html("<i class='fa fa-check-circle'></i> " + selectedFile.name).show();
                convertBase64(selectedFile);

            }
            else {

                JQuery('#Valid_File').hide();
                JQuery('#Invalid_File').show();
                setExcelFile(null);
            }
        }
    }

    // BASE 64 CONVERSION
    const convertBase64 = (file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            let uploadFile = reader.result;
            uploadFileEncode = uploadFile.split(",")[1];
        };
        reader.onerror = function (error) {

        };
    };

    //UPLOAD ONCLICK FUNCTIONALITY
    const uploadClick = (event) => {

        JQuery(event.target).siblings('input[type="file"]').click();


    }

    //DOWNLOAD FUNCTIONALITY
    const handledownload = () => {

        let sliceSize = 1024;
        let byteCharecters = atob(EXCEL_FILE_BASE64);
        let bytesLength = byteCharecters.length;
        let slicesCount = Math.ceil(bytesLength / sliceSize);
        let byteArrays = new Array(slicesCount);
        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            let begin = sliceIndex * sliceSize;
            let end = Math.min(begin + sliceSize, bytesLength);
            let bytes = new Array(end - begin);
            for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharecters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        let blob = new Blob(byteArrays, { type: "application/vnd.ms-excel" });
        FileSaver.saveAs(new Blob([blob], {}), "Mass_Order_Modification_Template.xlsx");
    };


    //FOR  REASON CODE API
    const getReasonCode = (siteType) => {
        // if (!isReasonCodesFlag) {
        let reasonCodeOptions;
        const token = getToken();
        const headers = {
            "accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        };
        if (siteType === "IMO") {
            siteType = "DC";
        }
        JQuery.ajax({
            url: hostUrl + "/ordermodification/reason-code?type=" + siteType,
            type: 'POST',
            dataType: 'json',
            CORS: true,
            contentType: 'application/json',
            secure: true,
            headers: headers,
            crossDomain: true,
            success: function (response) {
                reasonCodeOptions = '<option value="Select" selected>Select Reason Code</option>\n';
                JQuery(this).html(reasonCodeOptions);


                reasonCodeResponse = response["response"];
                if (reasonCodeResponse.length > 0) {
                    setReasonCodeFlag(true)
                }
                for (let i = 0; i < reasonCodeResponse.length; i++) {
                    if (reasonCodeResponse[i]["description"] === "" || reasonCodeResponse[i]["description"] === null) {
                        reasonCodeOptions += '<option value="' + reasonCodeResponse[i]["id"] + '">' + "No Description Available" + '</option>\n';
                    } else {
                        if (reasonCodeResponse[i]["id"] === "G2" && siteType === "PLANT") {
                            reasonCodeOptions += '<option selected="selected" value="' + reasonCodeResponse[i]["id"] + '">' + reasonCodeResponse[i]["description"] + '</option>\n';
                        } else if (reasonCodeResponse[i]["id"] === "08" && siteType === "DC") {
                            reasonCodeOptions += '<option selected="selected" value="' + reasonCodeResponse[i]["id"] + '">' + reasonCodeResponse[i]["description"] + '</option>\n';
                        } else {
                            reasonCodeOptions += '<option value="' + reasonCodeResponse[i]["id"] + '">' + reasonCodeResponse[i]["description"] + '</option>\n';
                        }
                    }
                }
                setreasoncodeoptions(reasonCodeOptions);
                console.log("siteType::::", siteType)
                JQuery('select[name="ReasonCode"]').each(function () {
                    if (JQuery(this).find(":selected").val() === undefined || JQuery(this).find(":selected").val() === "Select") {
                        JQuery(this).html(reasonCodeOptions);
                    } else {
                        let targetedSelect = JQuery(this).find(':selected').val();
                        JQuery(this).val(targetedSelect);
                    }

                });


            },
            error: function (jqHXR, textStatus, errorThrown) {

            }
        });
        // }
    };


    //FOR MATERIAL NUMBER VALIDATION API
    const valiateMaterialNumber = () => {

        const token = getToken();
        const headers = {
            "accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
        const materialnumber_regex = /^[0-9]{13}$/;
        let materialnumberAdd = JQuery("#TableMaterialNumber").val();
        if (materialnumber_regex.test(materialnumberAdd)) {
            let materialNumberValid = {};
            materialNumberValid["materialNumber"] = materialnumberAdd;
            JQuery.ajax({
                url: hostUrl + "/orderModification/materialValidation",
                type: 'POST',
                dataType: 'json',
                CORS: true,
                data: JSON.stringify(materialNumberValid),
                contentType: 'application/json',
                headers: headers,
                crossDomain: true,
                success: function (response) {
                    MaterialNumbrMessage = response.errormessage;
                    if (MaterialNumbrMessage === "Material Exists") {
                        validmaterial = true;
                        JQuery("#TableMaterialNumber").attr("validation", "true")
                        JQuery("#Invactive_MaterialNumberTableDB").hide();
                        JQuery("#Invalid_MaterialNumberTableDB").hide();
                        JQuery("#Invalid_MaterialNumberTable").hide();
                        JQuery("#TableMaterialNumber").css("border", "");

                    }
                    else if (MaterialNumbrMessage === "Material is not active") {
                        validmaterial = false;
                        JQuery("#TableMaterialNumber").attr("validation", "false");
                        JQuery("#Invactive_MaterialNumberTableDB").show();
                        JQuery("#Invalid_MaterialNumberTableDB").hide();
                        JQuery("#Invalid_MaterialNumberTable").hide();
                        JQuery("#TableMaterialNumber").css("border", "1px solid red");
                    }
                    else {
                        validmaterial = false;
                        JQuery("#TableMaterialNumber").attr("validation", "false")
                        JQuery("#Invactive_MaterialNumberTableDB").hide();
                        JQuery("#Invalid_MaterialNumberTableDB").show();
                        JQuery("#Invalid_MaterialNumberTable").hide();
                        JQuery("#TableMaterialNumber").css("border", "1px solid red");
                    }

                },
                error: function (textStatus) {
                }
            });
        }
        else {
            JQuery("#Invactive_MaterialNumberTableDB").hide();
            JQuery("#Invalid_MaterialNumberTableDB").hide();
            JQuery("#Invalid_MaterialNumberTable").hide();
            JQuery("#TableMaterialNumber").css("border", "");
        }
    };

    //FOR ORDER NUMBER VALIDATION API
    const validateOrderNumber = () => {

        const token = getToken();
        const headers = {
            "accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        };
        ordernumber = JQuery('#OrderNumber').val();
        numberTypeOrder = JQuery('input[name="inlineRadioOptions"]:checked').val();
        let orderNumberValiid = {};
        let OrderTypeDetails, legacynumber;
        orderNumberValiid["orderNumber"] = ordernumber;
        orderNumberValiid["numberType"] = numberTypeOrder;
        orderNumberValiid["siteAccessList"] = temp_role === Constants.roleSite ? temp_siteaccess : "";


        JQuery.ajax({
            url: hostUrl + "/ordermodification/getOrderDetails",
            type: 'POST',
            dataType: 'json',
            CORS: true,
            data: JSON.stringify(orderNumberValiid),
            contentType: 'application/json',
            headers: headers,
            crossDomain: true,
            success: function (response) {
                ErrorMessageOrder = (response.errormessage).toUpperCase();
                if ((response.status).toUpperCase() === "SUCCESS") {
                    orderModificationResponses = response.response.materials;
                    orderModificationDetails = response.response;
                    Siteid = orderModificationDetails.siteID;
                    getReasonCode(orderModificationDetails.siteType);
                    siteName = orderModificationDetails.siteID + " - " + orderModificationDetails.siteName;
                    CustomerName = orderModificationDetails.customerName;
                    DeliveryDate = orderModificationDetails.deliveryDate;
                    OrderTypeDetails = orderModificationDetails.orderType;
                    legacynumber = orderModificationDetails.legacyNumber;
                    JQuery("#DeliveryDate").attr("rel", DeliveryDate);
                    DeliveryDate = DeliveryDate.split('-')[2] + '-' + DeliveryDate.split('-')[1] + '-' + DeliveryDate.split('-')[0];
                    DeliveryDate = new Date(DeliveryDate.toString().replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"));
                    setDeliveryDate(DeliveryDate);
                    DeliveryDate = orderModificationDetails.deliveryDate;
                    DeliveryDate = DeliveryDate.split('-')[1] + '/' + DeliveryDate.split('-')[2] + '/' + DeliveryDate.split('-')[0];
                    JQuery("#DeliveryDateDisabled").val(DeliveryDate).attr("disabled", true)
                    JQuery("#plant").val(Siteid);
                    JQuery("#siteName").val(siteName);
                    JQuery('#Details_Customername').val(CustomerName);
                    for (let i = 0; i < orderModificationResponses.length; i++) {
                        orderModificationResponses[i]["id"] = i + 1;
                        orderModificationResponses[i]["reasonCode"] = "";
                        orderModificationResponses[i]["resonCodeDesc"] = "";

                    }
                    validOrder = true;
                    Setmaterials(orderModificationResponses);
                    Setoldmaterials(orderModificationResponses);
                    DeliveryDate = DeliveryDate.split("/")[2] + "-" + DeliveryDate.split("/")[0] + "-" + DeliveryDate.split("/")[1];
                    postOrderDetailsActions = [];
                    postOrderDetailsActions = {

                        "requestorGpid": temp_gpid,
                        "requestType": "Order Modification",
                        "component": "WebApp",
                        "requesterName": "",
                        "requesterEmailId": JQuery(".RequesterEmail").val().toString(),
                        "orderId": JQuery('#OrderNumber').val().toString(),
                        "plantId": JQuery("#plant").val().toString(),
                        "deliveryDate": DeliveryDate.toString(),
                        "orderNumberType": JQuery('input[name="inlineRadioOptions"]:checked').val().toString(),
                        "customerName": JQuery('#Details_Customername').val().toString(),
                        "action": JQuery("#OrderRequest").val().toString(),
                        "requesterGroup": JQuery("#Requestor").val().toString(),
                        "orderType": OrderTypeDetails,
                        "legacyNumber": legacynumber,
                        "typeModifications": [
                        ]
                    };

                    JQuery("#quicksaver").val(JSON.stringify(postOrderDetailsActions));
                    JQuery("#quicksaver2").val(0);
                }
                else if ((response.status).toUpperCase() === "FAIL" && temp_role === Constants.roleSite && response.errormessage.toUpperCase() === "ORDER PRESENT BUT USER DONT HAVE THE ACCESS TO MODIFY IT") {
                    JQuery("#Invalid_OrderNumberSite").show();

                }
                else {
                    validOrder = false;
                }
                fetchOrderDetails();
                JQuery("#visibleOrderDetails").html(" Submit");
            },
            error: function (textStatus, response) {

                JQuery("#visibleOrderDetails").html(" Submit");
            }
        });

    };

    const fetchOrderDetails = () => {

        if (validOrder) {
            let orderno = JQuery('#OrderNumber').val(),
                orderreq = JQuery('#OrderRequest').find(":selected").val();

            JQuery("#Invalid_OrderNumberDB").hide();
            JQuery("#Invalid_OrderNumberSite").hide()
            JQuery('#Details_OrderNumber').val(orderno);
            JQuery('#Orderdetails').fadeIn();
            JQuery('#AddRemoveTable').hide();
            JQuery('#ItemQuantityTable').hide();
            JQuery('#DeliveryDateTable').hide();
            JQuery('#deleteDateTable').hide();

            JQuery('#ProductCodeChangeTable').hide();
            setSelectedRequestType("");
            if (orderreq === 'Add or Remove Items') {
                JQuery("#DeliveryDate").hide();
                JQuery("#DeliveryDateDisabled").show();
                JQuery('#AddRemoveTable').fadeIn();

            }
            else if (orderreq === 'Item Quantity Change') {
                JQuery("#DeliveryDate").hide();
                JQuery("#DeliveryDateDisabled").show();
                JQuery('#ItemQuantityTable').fadeIn();

            }
            else if (orderreq === 'Delivery Date Change Request') {
                JQuery("#DeliveryDate").show();
                JQuery("#DeliveryDateDisabled").hide();
                JQuery('#DeliveryDateTable').fadeIn();

            }
            else if (orderreq === 'Product Code Change Request') {
                JQuery("#DeliveryDate").hide();
                JQuery("#DeliveryDateDisabled").show();
                JQuery('#ProductCodeChangeTable').fadeIn();


            } else if (orderreq === 'Delete or Cancel Order') {
                JQuery("#DeliveryDate").hide();
                JQuery("#DeliveryDateDisabled").show();
                JQuery('#deleteDateTable').fadeIn();
                setSelectedRequestType("Delete or Cancel Order");
            } else {

                JQuery('#AddRemoveTable').hide();
                JQuery('#ItemQuantityTable').hide();
                JQuery('#DeliveryDateTable').hide();
                JQuery('#ProductCodeChangeTable').hide();
            }
            JQuery("#Invalid_Email").hide();
            JQuery("#Detailsemail").removeAttr("style");
            JQuery("#Invalid_OrderNumber").hide();
            JQuery("#OrderNumber").removeAttr("style");
            JQuery("#InvalidTypeRequest").hide();
            JQuery("#OrderRequest").removeAttr("style");
            JQuery("#visibleOrderDetails").html("Submit").removeAttr('readonly');

            // getReasonCode();
        }
        else {
            JQuery("#Invalid_Email").hide();
            JQuery("#Detailsemail").removeAttr("style");
            JQuery("#Invalid_OrderNumber").hide();
            JQuery("#InvalidTypeRequest").hide();
            (ErrorMessageOrder === "ORDER DOES NOT EXIST") ? JQuery("#Invalid_OrderNumberDB").show() : JQuery("#Invalid_OrderNumberDB").hide();
            (ErrorMessageOrder === "ORDER PRESENT BUT USER DONT HAVE THE ACCESS TO MODIFY IT") ? JQuery("#Invalid_OrderNumberSite").show() : JQuery("#Invalid_OrderNumberSite").hide();
            JQuery("#Orderdetails").hide();
            JQuery("#visibleOrderDetails").html("Submit").removeAttr('readonly');
        }


    }

    //ON SUBMIT FOR FETCHING ORDER DETAILS API
    const showOrderDetails = () => {
        JQuery("#visibleOrderDetails").html("<i class='fa fa-refresh fa-spin' ></i> Submitting");

        let numberTypeOrder = JQuery('input[name="inlineRadioOptions"]:checked').val();
        let requestTypeOrder = JQuery('#RequestTypeDropdown').find(":selected").val();
        let orderreq = JQuery('#OrderRequest').find(":selected").val();
        var email_regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (numberTypeOrder === 'legacy') {
            var ordernumber_regex = /^[0-9]{1,12}$/;

        }
        else {
            ordernumber_regex = /^[0-9]{10}$/;
        }

        if (orderreq === 'Mass Order Modification') {
            JQuery("#Orderdetails").hide();
            JQuery('#MassOrder').show();
            if (requestTypeOrder === 'Please Select' && !email_regex.test(JQuery("#DetailsMassemail").val())) {
                JQuery('#Invalid_Requestype').show();
                JQuery("#RequestTypeDropdown").css("border", "1px solid red");
                JQuery("#Invalid_MassEmail").show();
                JQuery("#DetailsMassemail").css("border", "1px solid red");
                JQuery('#MassOrder').hide();
                JQuery("#visibleOrderDetails").html("Submit");
            }
            else if (requestTypeOrder !== 'Please Select' && !email_regex.test(JQuery("#DetailsMassemail").val())) {
                JQuery('#Invalid_Requestype').hide();
                JQuery("#RequestTypeDropdown").removeAttr("style");
                JQuery("#Invalid_MassEmail").show();
                JQuery("#DetailsMassemail").css("border", "1px solid red");
                JQuery('#MassOrder').hide();
                JQuery("#visibleOrderDetails").html("Submit");
            }
            else if (requestTypeOrder === 'Please Select' && email_regex.test(JQuery("#DetailsMassemail").val())) {
                JQuery('#Invalid_Requestype').show();
                JQuery("#RequestTypeDropdown").css("border", "1px solid red");
                JQuery("#Invalid_MassEmail").hide();
                JQuery("#DetailsMassemail").removeAttr("style");
                JQuery('#MassOrder').hide();
                JQuery("#visibleOrderDetails").html("Submit");
            }
            else {
                JQuery("#Invalid_MassEmail").hide();
                JQuery("#DetailsMassemail").removeAttr("style");
                JQuery('#Invalid_Requestype').hide();
                JQuery("#RequestTypeDropdown").removeAttr("style");
                JQuery('#MassOrder').show();
                JQuery("#visibleOrderDetails").html("Submit");

            }
            if (requestTypeOrder === "Delivery Date Change") {
                JQuery('#Modify_DeliveryModifyType').val(requestTypeOrder);
                JQuery('#ModifyDeliveryDate').show();
                JQuery('#ModifyLoadDateChange').hide();
                JQuery('#ModifyRouteChange').hide();
                JQuery('#ModifyQuantityChange').hide();
                JQuery("#visibleOrderDetails").html("Submit");
            }
            else if (requestTypeOrder === "Load Date Change") {
                JQuery('#Modify_LoadModifyType').val(requestTypeOrder);
                JQuery('#ModifyDeliveryDate').hide();
                JQuery('#ModifyLoadDateChange').show();
                JQuery('#ModifyRouteChange').hide();
                JQuery('#ModifyQuantityChange').hide();
                JQuery("#visibleOrderDetails").html("Submit");
            }
            else if (requestTypeOrder === 'Route Change') {
                JQuery('#Modify_RouteType').val(requestTypeOrder);
                JQuery('#ModifyDeliveryDate').hide();
                JQuery('#ModifyLoadDateChange').hide();
                JQuery('#ModifyRouteChange').show();
                JQuery('#ModifyQuantityChange').hide();
                JQuery("#visibleOrderDetails").html("Submit");
            }

            else if (requestTypeOrder === "Quantity Change") {
                JQuery('#Modify_QuantityModifyType').val(requestTypeOrder);
                JQuery('#ModifyDeliveryDate').hide();
                JQuery('#ModifyLoadDateChange').hide();
                JQuery('#ModifyRouteChange').hide();
                JQuery('#ModifyQuantityChange').show();
                JQuery("#visibleOrderDetails").html("Submit");
            }
            else {
                JQuery('#ModifyDeliveryDate').hide();
                JQuery('#ModifyLoadDateChange').hide();
                JQuery('#ModifyRouteChange').hide();
                JQuery('#ModifyQuantityChange').hide();
                JQuery("#visibleOrderDetails").html("Submit");


            }


        }

        else {
            JQuery('#MassOrder').hide();
            if (email_regex.test(JQuery("#Detailsemail").val()) && ordernumber_regex.test(JQuery("#OrderNumber").val())) {
                validateOrderNumber();
            }
            else if (!email_regex.test(JQuery("#Detailsemail").val()) && ordernumber_regex.test(JQuery("#OrderNumber").val())) {

                JQuery("#Invalid_Email").show();
                JQuery("#Detailsemail").css("border", "1px solid red");
                JQuery("#Invalid_OrderNumber").hide();
                JQuery("#OrderNumber").removeAttr("style");
                JQuery("#InvalidTypeRequest").hide();
                JQuery("#OrderRequest").removeAttr("style");
                JQuery("#Orderdetails").hide();
                JQuery("#Invalid_OrderNumberDB").hide();
                JQuery("#Invalid_OrderNumberSite").hide();
                JQuery("#visibleOrderDetails").html("Submit").removeAttr('readonly');
            }
            else if (email_regex.test(JQuery("#Detailsemail").val()) && !ordernumber_regex.test(JQuery("#OrderNumber").val())) {


                JQuery("#Invalid_OrderNumber").show();
                JQuery("#OrderNumber").css("border", "1px solid red");
                JQuery("#InvalidTypeRequest").hide();
                JQuery("#OrderRequest").removeAttr("style");
                JQuery("#Invalid_OrderNumberDB").hide();
                JQuery("#Invalid_OrderNumberSite").hide();
                JQuery("#Invalid_Email").hide();
                JQuery("#Detailsemail").removeAttr("style");
                JQuery("#Orderdetails").hide();
                JQuery("#visibleOrderDetails").html("Submit").removeAttr('readonly');

            }


            else {
                JQuery("#Invalid_Email").show();
                JQuery("#Detailsemail").css("border", "1px solid red");
                JQuery("#Invalid_OrderNumber").show();
                JQuery("#Invalid_OrderNumberDB").hide();
                JQuery("#Invalid_OrderNumberSite").hide()
                JQuery("#OrderNumber").css("border", "1px solid red");
                JQuery("#Orderdetails").hide();
                JQuery("#visibleOrderDetails").html("Submit").removeAttr('readonly');
            }
            // getReasonCode();
        }
    }


    //SAVE ORDER MODIFICATION API
    const postSubmitOrderDetails = (orderRequests) => {
        const token = getToken();
        const headers = {
            "accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }

        if (orderRequests.typeModifications.length === 0 && orderRequests.action !== "Delete or Cancel Order") {
            setTimeout(function () {
                JQuery('.modal-title').html("<strong class='text-danger'><i class='fa fa-exclamation-circle'></i> Error !!! </strong>");
                JQuery(".modal-body").html("<p>Request has not been submitted. Please perform some actions.</p>");
            }, 0);
        }
        else {
            JQuery.ajax({
                url: hostUrl + "/icrForm/sendModificationTypeDetails",
                type: 'POST',
                dataType: 'json',
                CORS: true,
                data: JSON.stringify(orderRequests),
                contentType: 'application/json',
                headers: headers,
                crossDomain: true,

                success: function (response) {
                    if (response.responsecode === 200) {
                        JQuery('.modal-title').html("<strong class='text-success'><i class='fa fa-check-circle'></i> Successfully Submitted </strong>");
                        JQuery(".modal-body").html("<p>Request has been submitted successfully.</p> \n <p>Your Request ID is #" + response.response[1] + "</p>");
                        postOrderDetailsActions = JSON.parse(JQuery("#quicksaver").val());
                        postOrderDetailsElementsCounter = 0;
                        postOrderDetailsActions.typeModifications = [];
                        JQuery("#quicksaver").val(JSON.stringify(postOrderDetailsActions));
                        JQuery("#quicksaver2").val(postOrderDetailsElementsCounter.toString());
                        sessionStorage.setItem("windowload", true);
                    }
                    else {
                        setShow(true);
                        setTimeout(function () {
                            JQuery('.modal-title').html("<strong class='text-danger'><i class='fa fa-exclamation-circle'></i> Error !!! </strong>");
                            JQuery(".modal-body").html("<p>Request has not been submitted.</p>");
                            sessionStorage.setItem("windowload", true);
                        }, 0);
                    }
                },
                error: function (xhr, textStatus) {
                    JQuery('.modal-title').html("<strong class='text-danger'><i class='fa fa-exclamation-circle'></i> Error !!! </strong>");
                    JQuery(".modal-body").html("<p>Request has not been submitted. Check the input data and try again.</p>");
                    sessionStorage.setItem("windowload", false);
                }
            });
        }
    };

    const consolidatedPostOrderDetails = (element) => {
        let elementPlace, flag = true;
        postOrderDetailsActions = JSON.parse(JQuery("#quicksaver").val());
        postOrderDetailsElementsCounter = parseInt(JQuery("#quicksaver2").val());
        if (postOrderDetailsActions.typeModifications.length > 0) {
            for (let i = 0; i < postOrderDetailsActions.typeModifications.length; i++) {

                if (postOrderDetailsActions.typeModifications[i].materialId === element.materialId && element.materialId !== "") {
                    elementPlace = i;
                    flag = false;
                }
            }
            if (flag) {
                elementPlace = postOrderDetailsElementsCounter;
                postOrderDetailsElementsCounter++;
            }
        }
        else {
            elementPlace = 0;
            postOrderDetailsElementsCounter++;
        }
        postOrderDetailsActions.typeModifications[elementPlace] = element;
        JQuery("#quicksaver").val(JSON.stringify(postOrderDetailsActions));
        JQuery("#quicksaver2").val(postOrderDetailsElementsCounter.toString());
    };



    //IMPLEMENTATION OF MAGIC FORMULA IN MATERIAL NUMBER
    JQuery("body").on('mouseup', function () {
        let tablematerialnumber = JQuery("#TableMaterialNumber").val();
        if (tablematerialnumber?.length === 8) {
            let appliedMagicFormula = (parseInt(tablematerialnumber) + Math.pow(10, 9)) * Math.pow(10, 3);
            JQuery("#TableMaterialNumber").val(appliedMagicFormula);
        }

    });

    //FOR THE HELP POPUP
    const popover = (
        <Popover id="popover-basic">

            <Popover.Body style={{ fontSize: ".7rem" }}>
                <b>You can reach out to your CSR over email with your request. For escalations, you can email - flnacsrnetworkleadmanagers@pepsico.com</b>
            </Popover.Body>
        </Popover>
    );



    //FOR MODAL
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShowConfirmPopUp(false);

        setShow(false);

        let temp_windowload = sessionStorage.getItem("windowload");


        if (temp_windowload === 'true') {
            window.location.reload(true);
        }

    }
    const handleOk = () => {
        setShowConfirmPopUp(false);
        setShow(false);
        handleShow();

    }

    //POST SUBMIT SENDING DETAILS VIA API FOR DELIVERY DATE CHANGE REQUEST
    const handleShow = () => {


        let orderRequests = JSON.parse(JQuery("#quicksaver").val());
        let newDeliveryDate = JQuery("#DeliveryDate").val();
        newDeliveryDate = newDeliveryDate.split('/')[2] + '-' + newDeliveryDate.split('/')[0] + '-' + newDeliveryDate.split('/')[1];

        if (JQuery('#OrderRequest').find(":selected").val() === "Delivery Date Change Request") {
            if (JQuery("#DeliveryDate").attr("rel") !== newDeliveryDate) {
                let deliveryDateMaterial = {};
                deliveryDateMaterial["materialId"] = "";
                deliveryDateMaterial["oldQuantity"] = "";
                deliveryDateMaterial["updatedQuantity"] = "";
                deliveryDateMaterial["upc"] = "";
                deliveryDateMaterial["modificationDescription"] = "";
                deliveryDateMaterial["uom"] = "";
                deliveryDateMaterial["deliveryDate"] = JQuery("#DeliveryDate").attr("rel");
                deliveryDateMaterial["deliveryDateNew"] = newDeliveryDate;
                deliveryDateMaterial["modificationAction"] = "Date Change";
                deliveryDateMaterial["dateTime"] = "";
                deliveryDateMaterial["updatedMaterialId"] = "";
                deliveryDateMaterial["reasonCode"] = "";
                consolidatedPostOrderDetails(deliveryDateMaterial);
            }

            orderRequests = JSON.parse(JQuery("#quicksaver").val());
            postSubmitOrderDetails(orderRequests);

        }
        else if (JQuery('#OrderRequest').find(":selected").val() === "Delete or Cancel Order") {
            let deliveryDateMaterial = {};
            deliveryDateMaterial["materialId"] = "";
            deliveryDateMaterial["oldQuantity"] = "";
            deliveryDateMaterial["updatedQuantity"] = "";
            deliveryDateMaterial["upc"] = "";
            deliveryDateMaterial["modificationDescription"] = "";
            deliveryDateMaterial["uom"] = "";
            deliveryDateMaterial["deliveryDate"] = "";
            deliveryDateMaterial["deliveryDateNew"] = "";
            deliveryDateMaterial["modificationAction"] = "Order Delete";
            deliveryDateMaterial["dateTime"] = "";
            deliveryDateMaterial["updatedMaterialId"] = "";
            deliveryDateMaterial["reasonCode"] = "";
            consolidatedPostOrderDetails(deliveryDateMaterial);

            orderRequests = JSON.parse(JQuery("#quicksaver").val());
            postSubmitOrderDetails(orderRequests);

        }
        else {
            orderRequests = JSON.parse(JQuery("#quicksaver").val());
            postSubmitOrderDetails(orderRequests);
        }

        setTimeout(setShow(true), 1500);

    };

    //CHECK Confirmation validation
    const handleConfirmation = () => {
        //Check Date deliveryDate-1,2
        const TimeZone = 'America/Chicago';
        let twoDaysBeforeDeliveryDate = moment(deliveryDate).subtract(2, 'day').format('DD/MM/YYYY')
        var compareDate = moment(moment.tz(moment(), TimeZone).format('DD/MM/YYYY'), "DD/MM/YYYY");
        var startDate = moment(twoDaysBeforeDeliveryDate, "DD/MM/YYYY");
        var endDate = moment(deliveryDate, "DD/MM/YYYY");
        const isValidTime = compareDate.isBetween(startDate, endDate);
        //Get CST Time in hours
        const CurrentTime = parseInt(moment.tz(moment(), TimeZone).format('HH'));
        console.log("Current CST Time:::", moment.tz(moment(), TimeZone).format('DD/MM/YYYY HH:mm'))

        //Check Final validation 
        if (!isValidTime) {
            handleShow();
        } else {
            if (CurrentTime < 15) {
                handleShow();
            } else {
                setShowConfirmPopUp(true);
                setTimeout(setShow(true), 1500);
                setTimeout(function () {
                    if (CurrentTime === 15) {
                        JQuery('.modal-title').html("<strong class='text-warning'><i class='fas fa-exclamation-triangle' style='color:#ffc107;'></i> Warning</strong>");
                        JQuery(".modal-body").html("<p> Please consider DCs release time & Tcomm to make sure all the requests are processed in time.</p>");
                    } else if (CurrentTime >= 16) {
                        JQuery('.modal-title').html("<strong class='text-warning'><i class='fas fa-exclamation-triangle' style='color:#ffc107;'></i> Warning</strong>");
                        JQuery(".modal-body").html("<p> Please consider DCs release time & Tcomm to make sure all the requests are processed in time.</p>");
                    } else {
                        setShowConfirmPopUp(false);
                        setShow(false);
                        handleShow();
                    }
                }, 0);
            }
        }
    }
    //POPUP CONFIRMATION FOR THE DELETE ORDER 
    const deletePopUp = () => {
        console.log("selectedRequestType:::", selectedRequestType)
        setShowConfirmPopUp(true);
        setTimeout(setShow(true), 1500);
        setTimeout(function () {
            JQuery('.modal-title').html("<strong class='text-warning'><i class='fas fa-exclamation-triangle' style='color:#ffc107;'></i> Warning</strong>");
            JQuery(".modal-body").html("<p>You are creating a request for Order deletion/cancellation. Are you sure you want to proceed?</p>");
        }, 0);
    }
    //TO HIDE REQUEST DETAILS ONCHANGE FUNCTION 
    const hideRequestDetail = () => {
        JQuery('#MassOrder').hide();
    };

    //MASS MODIFY ON CHANGE LOAD
    const masModify = () => {

        let orderreq = JQuery('#OrderRequest').find(":selected").val();
        if (orderreq === "Mass Order Modification") {
            JQuery('#HideNumberType').hide();
            JQuery('#OrderNumberHide').hide();
            JQuery('#ShowRequestType').show();
            JQuery('#HideMandatory').hide();
            JQuery("#Orderdetails").hide();

        }
        else {
            JQuery('#HideNumberType').show();
            JQuery('#OrderNumberHide').show();
            JQuery('#ShowRequestType').hide();
            JQuery('#HideMandatory').show();
            JQuery("#Orderdetails").hide();
            JQuery('#MassOrder').hide();
        }
    };

    // MASS MODIFY SUBMIT
    const massSubmit = (event) => {
        JQuery(event.target).html("Submitting").attr('disabled', 'disabled');
        let delsite, loadsite, routeSite, qtySite;
        let deliverySiteId = JQuery("#Modify_DeliverySiteId").val();
        let loadSiteId = JQuery("#Modify_LoadSiteId").val();
        let routeSiteId = JQuery("#Modify_RouteSiteId").val();
        let qtySiteId = JQuery("#Modify_QuantitySiteId").val();


        if (temp_role === Constants.roleSite) {

            delsite = temp_siteaccess.includes(deliverySiteId);
            loadsite = temp_siteaccess.includes(loadSiteId);
            routeSite = temp_siteaccess.includes(routeSiteId);
            qtySite = temp_siteaccess.includes(qtySiteId);
        }
        else {
            delsite = true;
            loadsite = true;
            routeSite = true;
            qtySite = true;
        }


        //Deivery date change
        if (JQuery('#RequestTypeDropdown').find(":selected").val() === "Delivery Date Change" && (JQuery("#Modify_DeliverySiteId").val().length !== 4 || JQuery("#Modify_CommentsDeliveryDate").val().length === 5001 || delsite === false || delsite === false && JQuery("#Modify_DeliverySiteId").val().length !== 4)) {

            (JQuery("#Modify_DeliverySiteId").val().length !== 4) ? JQuery("#InvalidCount_DeliverySiteId").show() : JQuery("#InvalidCount_DeliverySiteId").hide();
            (JQuery("#Modify_DeliverySiteId").val().length !== 4) ? JQuery("#Modify_DeliverySiteId").css("border", "1px solid red") : JQuery("#Modify_DeliverySiteId").removeAttr("style");
            (JQuery("#Modify_CommentsDeliveryDate").val().length === 5001) ? JQuery("#Invalid_CountDelivery").show() : JQuery("#Invalid_CountDelivery").hide();
            (JQuery("#Modify_CommentsDeliveryDate").val().length === 5001) ? JQuery("#Modify_CommentsDeliveryDate").css("border", "1px solid red") : JQuery("#Modify_CommentsDeliveryDate").removeAttr("style");
            (delsite === false && JQuery("#Modify_DeliverySiteId").val().length === 4) ? JQuery("#InvalidCount_DeliverySiteAccess").show() : JQuery("#InvalidCount_DeliverySiteAccess").hide();
            (delsite === false && JQuery("#Modify_DeliverySiteId").val().length === 4) ? JQuery("#Modify_DeliverySiteId").css("border", "1px solid red") : JQuery("#Modify_DeliverySiteId").removeAttr("style");

            JQuery("#MassModifySubmit").html("Submit").removeAttr('disabled');

        }
        //Load date change
        else if (JQuery('#RequestTypeDropdown').find(":selected").val() === "Load Date Change" && (JQuery("#Modify_LoadSiteId").val().length !== 4 || JQuery("#Modify_CommentsLoadDate").val().length === 5001 || loadsite === false)) {


            (JQuery("#Modify_LoadSiteId").val().length !== 4) ? JQuery("#InvalidCount_LoadSiteId").show() : JQuery("#InvalidCount_LoadSiteId").hide();
            (JQuery("#Modify_LoadSiteId").val().length !== 4) ? JQuery("#Modify_LoadSiteId").css("border", "1px solid red") : JQuery("#Invalid_CountRoute").removeAttr("style");
            (JQuery("#Modify_CommentsLoadDate").val().length === 5001) ? JQuery("#Invalid_CountLoad").show() : JQuery("#Invalid_CountLoad").hide();
            (JQuery("#Modify_CommentsLoadDate").val().length === 5001) ? JQuery("#Modify_CommentsLoadDate").css("border", "1px solid red") : JQuery("#Modify_CommentsLoadDate").removeAttr("style");
            (loadsite === false && JQuery("#Modify_LoadSiteId").val().length === 4) ? JQuery("#InvalidCount_LoadSiteAccess").show() : JQuery("#InvalidCount_LoadSiteAccess").hide();
            (loadsite === false && JQuery("#Modify_LoadSiteId").val().length === 4) ? JQuery("#Modify_LoadSiteId").css("border", "1px solid red") : JQuery("#Modify_LoadSiteId").removeAttr("style");
            JQuery("#MassModifySubmit").html("Submit").removeAttr('disabled');
        }
        //Route change
        else if (JQuery('#RequestTypeDropdown').find(":selected").val() === "Route Change" && (JQuery("#Modify_RouteSiteId").val().length !== 4 || (JQuery("#Modify_CurrentRoute").val().length > 0 && JQuery("#Modify_CurrentRoute").val().length !== 5) || (JQuery("#Modify_RequestedRoute").val().length > 0 && JQuery("#Modify_RequestedRoute").val().length !== 5) || JQuery("#Modify_CommentsRoute").val().length === 5001 || routeSite === false)) {


            (JQuery("#Modify_RouteSiteId").val().length !== 4) ? JQuery("#InvalidCount_RouteSiteId").show() : JQuery("#InvalidCount_RouteSiteId").hide();
            (JQuery("#Modify_RouteSiteId").val().length !== 4) ? JQuery("#Modify_RouteSiteId").css("border", "1px solid red") : JQuery("#Modify_RouteSiteId").removeAttr("style");
            (JQuery("#Modify_CurrentRoute").val().length !== 5 && JQuery("#Modify_CurrentRoute").val().length > 0) ? JQuery("#InvalidCount_CurrentRoute").show() : JQuery("#InvalidCount_CurrentRoute").hide();
            (JQuery("#Modify_CurrentRoute").val().length !== 5 && JQuery("#Modify_CurrentRoute").val().length > 0) ? JQuery("#Modify_CurrentRoute").css("border", "1px solid red") : JQuery("#Modify_CurrentRoute").removeAttr("style");
            (JQuery("#Modify_RequestedRoute").val().length !== 5 && JQuery("#Modify_RequestedRoute").val().length > 0) ? JQuery("#InvalidCount_RequestedRoute").show() : JQuery("#InvalidCount_RequestedRoute").hide();
            (JQuery("#Modify_RequestedRoute").val().length !== 5 && JQuery("#Modify_RequestedRoute").val().length > 0) ? JQuery("#Modify_RequestedRoute").css("border", "1px solid red") : JQuery("#Modify_RequestedRoute").removeAttr("style");
            (JQuery("#Modify_CommentsRoute").val().length === 5001) ? JQuery("#Invalid_CountRoute").show() : JQuery("#Invalid_CountRoute").hide();
            (JQuery("#Modify_CommentsRoute").val().length === 5001) ? JQuery("#Modify_CommentsRoute").css("border", "1px solid red") : JQuery("#Modify_CommentsRoute").removeAttr("style");
            JQuery("#MassModifySubmit").html("Submit").removeAttr('disabled');
            (routeSite === false && JQuery("#Modify_RouteSiteId").val().length === 4) ? JQuery("#Invalid_RouteSiteAccess").show() : JQuery("#Invalid_RouteSiteAccess").hide();
            (routeSite === false && JQuery("#Modify_RouteSiteId").val().length === 4) ? JQuery("#Modify_RouteSiteId").css("border", "1px solid red") : JQuery("#Modify_RouteSiteId").removeAttr("style");
        }
        //Quantity change
        else if (JQuery('#RequestTypeDropdown').find(":selected").val() === "Quantity Change" && (JQuery("#Modify_QuantitySiteId").val().length !== 4) || JQuery("#Modify_CommentsQuantity").val().length === 5001 || qtySite === false) {


            (JQuery("#Modify_QuantitySiteId").val().length !== 4) ? JQuery("#InvalidCount_QuantitySiteId").show() : JQuery("#InvalidCount_QuantitySiteId").hide();
            (JQuery("#Modify_QuantitySiteId").val().length !== 4) ? JQuery("#Modify_QuantitySiteId").css("border", "1px solid red") : JQuery("#Modify_QuantitySiteId").removeAttr("style");
            (JQuery("#Modify_CommentsQuantity").val().length === 5001) ? JQuery("#Invalid_CountQuantity").show() : JQuery("#Invalid_CountQuantity").hide();
            (JQuery("#Modify_CommentsQuantity").val().length === 5001) ? JQuery("#Modify_CommentsQuantity").css("border", "1px solid red") : JQuery("#Modify_CommentsQuantity").removeAttr("style");
            JQuery("#MassModifySubmit").html("Submit").removeAttr('disabled');
            (qtySite === false && JQuery("#Modify_QuantitySiteId").val().length === 4) ? JQuery("#Invalid_QuantitySiteAccess").show() : JQuery("#Invalid_QuantitySiteAccess").hide();
            (qtySite === false && JQuery("#Modify_QuantitySiteId").val().length === 4) ? JQuery("#Modify_QuantitySiteId").css("border", "1px solid red") : JQuery("#Modify_QuantitySiteId").removeAttr("style");
        }

        else {
            JQuery(event.target).html("Submitting").attr('disabled', 'disabled');

            //DELIVERY DATE
            JQuery("#InvalidCount_DeliverySiteAccess").hide()
            JQuery("#InvalidCount_DeliverySiteId").hide();
            JQuery("#Modify_DeliverySiteId").removeAttr("style");
            JQuery("#Invalid_CountDelivery").hide();
            JQuery("#Modify_CommentsDeliveryDate").removeAttr("style");
            JQuery("#Count_DeliverySiteId").hide();

            //LOAD DATE
            JQuery("#InvalidCount_LoadSiteAccess").hide()
            JQuery("#InvalidCount_LoadSiteId").hide();
            JQuery("#Modify_LoadSiteId").removeAttr("style");
            JQuery("#Invalid_CountLoad").hide();
            JQuery("#Modify_CommentsLoadDate").removeAttr("style");

            //ROUTE DATA
            JQuery("#Invalid_RouteSiteAccess").hide();
            JQuery("#InvalidCount_RouteSiteId").hide();
            JQuery("#Modify_RouteSiteId").removeAttr("style");
            JQuery("#Invalid_CountRoute").hide();
            JQuery("#Modify_CommentsRoute").removeAttr("style");
            JQuery("#InvalidCount_CurrentRoute").hide();
            JQuery("#Modify_CurrentRoute").removeAttr("style");
            JQuery("#InvalidCount_RequestedRoute").hide();
            JQuery("#Modify_RequestedRoute").removeAttr("style");


            //QUANTITY CHANGE
            JQuery("#Invalid_QuantitySiteAccess").hide()
            JQuery("#InvalidCount_QuantitySiteId").hide();
            JQuery("#Modify_QuantitySiteId").removeAttr("style");
            JQuery("#Invalid_CountQuantity").hide();
            JQuery("#Modify_CommentsQuantity").removeAttr("style");



            const token = getToken();
            const headers = {
                "accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }

            let massModifySubmit = {}, requestTypeMass;
            requestTypeMass = JQuery('#RequestTypeDropdown').find(":selected").val();

            if (requestTypeMass === "Delivery Date Change") {

                let deliveryDateFrom = JQuery('#DeliveryDateFrom').val();
                let finalDeliveryDateFrom = deliveryDateFrom === "" ? "" : deliveryDateFrom.split("/")[2] + "-" + deliveryDateFrom.split("/")[0] + "-" + deliveryDateFrom.split("/")[1];
                let deliveryDateTo = JQuery('#DeliveryDateTo').val();
                let finalDeliveryDateTo = deliveryDateTo === "" ? "" : deliveryDateTo.split("/")[2] + "-" + deliveryDateTo.split("/")[0] + "-" + deliveryDateTo.split("/")[1];

                massModifySubmit = {
                    "requestorGpid": temp_gpid,
                    "requestType": "Order Modification-Advanced Functionality",
                    "component": "WebApp",
                    "requesterEmailId": temp_emailid,
                    "requesterGroup": JQuery('#Requestor').find(":selected").val(),
                    "massModifyType": requestTypeMass,
                    "siteId": JQuery('#Modify_DeliverySiteId').val(),
                    "deliveryDateFrom": finalDeliveryDateFrom,
                    "deliveryDateTo": finalDeliveryDateTo,
                    "loadDateFrom": "",
                    "loadDateTo": "",
                    "currentRoute": "",
                    "requestedRoute": "",
                    "deliveryDate": "",
                    "loadDate": "",
                    "additionalComments": JQuery('#Modify_CommentsDeliveryDate').val(),
                    "encodedFile": uploadFileEncode === undefined ? "" : uploadFileEncode,
                    "encodedFileType": FileType === undefined ? "" : FileType,
                    "siteAccessList": temp_role === Constants.roleSite ? temp_siteaccess : ""

                }

            }
            else if (requestTypeMass === "Load Date Change") {
                let loadDateFrom = JQuery('#Modify_LoadDateFrom').val();
                let finalLoadDateFrom = loadDateFrom === "" ? "" : loadDateFrom.split("/")[2] + "-" + loadDateFrom.split("/")[0] + "-" + loadDateFrom.split("/")[1]
                let loadDateTo = JQuery('#Modify_LoadDateTo').val();
                let finalLoadDateTo = loadDateTo === "" ? "" : loadDateTo.split("/")[2] + "-" + loadDateTo.split("/")[0] + "-" + loadDateTo.split("/")[1]
                let role = temp_role;
                massModifySubmit = {
                    "requestorGpid": temp_gpid,
                    "requestType": "Order Modification-Advanced Functionality",
                    "component": "WebApp",
                    "requesterEmailId": temp_emailid,
                    "requesterGroup": JQuery('#Requestor').find(":selected").val(),
                    "massModifyType": requestTypeMass,
                    "siteId": JQuery('#Modify_LoadSiteId').val(),
                    "deliveryDateFrom": "",
                    "deliveryDateTo": "",
                    "loadDateFrom": finalLoadDateFrom,
                    "loadDateTo": finalLoadDateTo,
                    "currentRoute": "",
                    "requestedRoute": "",
                    "deliveryDate": "",
                    "loadDate": "",
                    "additionalComments": JQuery('#Modify_CommentsLoadDate').val(),
                    "encodedFile": uploadFileEncode === undefined ? "" : uploadFileEncode,
                    "encodedFileType": FileType === undefined ? "" : FileType,
                    "siteAccessList": temp_role === Constants.roleSite ? temp_siteaccess : ""
                }

            }
            else if (requestTypeMass === "Route Change") {
                let role = temp_role;
                massModifySubmit = {
                    "requestorGpid": temp_gpid,
                    "requestType": "Order Modification-Advanced Functionality",
                    "component": "WebApp",
                    "requesterEmailId": temp_emailid,
                    "requesterGroup": JQuery('#Requestor').find(":selected").val(),
                    "massModifyType": requestTypeMass,
                    "siteId": JQuery('#Modify_RouteSiteId').val(),
                    "deliveryDateFrom": "",
                    "deliveryDateTo": "",
                    "loadDateFrom": "",
                    "loadDateTo": "",
                    "currentRoute": JQuery('#Modify_CurrentRoute').val(),
                    "requestedRoute": JQuery('#Modify_RequestedRoute').val(),
                    "deliveryDate": "",
                    "loadDate": "",
                    "additionalComments": JQuery('#Modify_CommentsRoute').val(),
                    "encodedFile": uploadFileEncode === undefined ? "" : uploadFileEncode,
                    "encodedFileType": FileType === undefined ? "" : FileType,
                    "siteAccessList": temp_role === Constants.roleSite ? temp_siteaccess : ""
                }

            }
            else {
                let loadDateQuantity = JQuery('#LoadDateQty').val();
                let finalLoadDateQuantity = loadDateQuantity === "" ? "" : loadDateQuantity.split("/")[2] + "-" + loadDateQuantity.split("/")[0] + "-" + loadDateQuantity.split("/")[1]
                let deliveryDateQuantity = JQuery('#DeliveryDateQty').val();
                let finalDeliveryDateQuantity = deliveryDateQuantity === "" ? "" : deliveryDateQuantity.split("/")[2] + "-" + deliveryDateQuantity.split("/")[0] + "-" + deliveryDateQuantity.split("/")[1]
                let role = temp_role;
                massModifySubmit = {
                    "requestorGpid": temp_gpid,
                    "requestType": "Order Modification-Advanced Functionality",
                    "component": "WebApp",
                    "requesterEmailId": temp_emailid,
                    "requesterGroup": JQuery('#Requestor').find(":selected").val(),
                    "massModifyType": requestTypeMass,
                    "siteId": JQuery('#Modify_QuantitySiteId').val(),
                    "deliveryDateFrom": "",
                    "deliveryDateTo": "",
                    "loadDateFrom": "",
                    "loadDateTo": "",
                    "currentRoute": "",
                    "requestedRoute": "",
                    "deliveryDate": finalDeliveryDateQuantity,
                    "loadDate": finalLoadDateQuantity,
                    "additionalComments": JQuery('#Modify_CommentsQuantity').val(),
                    "encodedFile": uploadFileEncode === undefined ? "" : uploadFileEncode,
                    "encodedFileType": FileType === undefined ? "" : FileType,
                    "siteAccessList": temp_role === Constants.roleSite ? temp_siteaccess : ""
                }
            }

            JQuery.ajax({
                url: hostUrl + "/ordermodification/saveOrderModification",
                type: 'POST',
                dataType: 'json',
                CORS: true,
                data: JSON.stringify(massModifySubmit),
                contentType: 'application/json',
                headers: headers,
                crossDomain: true,
                success: function (response) {
                    JQuery("#MassModifySubmit").html("Submit").removeAttr('disabled');
                    if (response.responsecode === 200) {
                        setShow(true);
                        setTimeout(function () {
                            JQuery('.modal-title').html("<strong class='text-success'><i class='fa fa-check-circle'></i> Successfully Submitted </strong>");
                            JQuery(".modal-body").html("<p>Request has been submitted successfully.</p> \n <p>Your Request ID is #" + response.response[1] + "</p>");
                            sessionStorage.setItem("windowload", true);
                        }, 0);
                    }
                    else {
                        setShow(true);
                        setTimeout(function () {
                            JQuery('.modal-title').html("<strong class='text-danger'><i class='fa fa-exclamation-circle'></i> Error !!! </strong>");
                            JQuery(".modal-body").html("<p>Request has not been submitted.</p>");
                            sessionStorage.setItem("windowload", true);
                        }, 0);

                    }



                },
                error: function (xhr, textStatus) {
                    JQuery("#MassModifySubmit").html("Submit").removeAttr('disabled');
                    setShow(true);
                    setTimeout(function () {
                        JQuery('.modal-title').html("<strong class='text-danger'><i class='fa fa-exclamation-circle'></i> Error !!! </strong>");
                        JQuery(".modal-body").html("<p>Request has not been submitted. Check the input data and try again.</p>");
                        sessionStorage.setItem("windowload", true);
                    }, 0);
                }
            });
        }
    };

    // NUMERIC SITE ID INPUT
    const numericSiteId = () => {
        JQuery('input[idx= "restrictedSiteId"]').keyup(function (e) {
            if (/\D/g.test(this.value)) {
                // Filter non-digits from input value.
                this.value = this.value.replace(/\D/g, '');
            }
        });

    }

    //FOR GETTING EMAILID FROM LOCAL STORAGE
    useEffect(() => {
        setTimeout(function () { JQuery(".RequesterEmail").val(temp_emailid); }, 0);
    }, []);

    // ACESS DENIED FUNCTIONALITY ON THE BASIS OF ROLE
    const navigate = useNavigate();
    useEffect(() => {
        if (temp_role === Constants.roleundefined) {
            navigate('/accessdenied');
        }
    }, []);

    return (
        <>
            <div className="order-font container-fluid" style={{ backgroundColor: "white" }}>
                <Card.Header style={{ backgroundColor: "#1C1B83" }}>
                    <div className='row'>
                        <div className='col-md-2' style={{ backgroundColor: "white" }}>
                            <img src={process.env.PUBLIC_URL + "/PepsicoLogo.png"} width="140" alt="logo" style={{}} />
                        </div>
                        <div className='col-md-6 ' style={{ color: "white", backgroundColor: "#1C1B83" }}>
                            <h5 style={{ marginTop: ".5rem", textAlign: "left" }}><b>Order Modification Request Form</b></h5>
                        </div>
                        <div className='col-md-2'>
                            <a href='/orders/home'>
                                <i class="fa fa-home" aria-hidden="true" style={{ color: 'white', marginLeft: '9vw', fontSize: '2.3rem', marginTop: ".3rem" }}></i>
                            </a>
                        </div>
                        <div className='col-md-2 ' style={{ color: "white", backgroundColor: "white" }}>
                            <img src={process.env.PUBLIC_URL + "/LogoImages.png"} width="140" alt="logo" style={{ width: "11rem" }} />
                        </div>

                    </div>

                </Card.Header>
                <Card className='customCard'>
                    <Card.Body style={{ backgroundColor: "white" }} >
                        <div className='row'>

                            <div className='col-md-11'  >

                            </div>
                            <div className='col-md-1 mt-3'>
                                <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
                                    <img src={process.env.PUBLIC_URL + "/customer-service.png"} alt="img" style={{ width: "2.1rem", marginLeft: "2.5rem", marginTop: "-1rem" }} />
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className='row mt-1'>
                            <div className='col-md-3' style={{ background: "#1C1B83" }}>
                                <Form.Label style={{ color: "white", marginTop: ".5rem" }}>Type of Request</Form.Label>
                            </div>
                            <div className='col-md-3'>

                                <Form.Select aria-label="Default select example" id='OrderRequest' onChange={masModify}>
                                    <option value="Add or Remove Items">Add or Remove Items</option>
                                    <option value="Item Quantity Change">Item Quantity Change</option>
                                    <option value="Delivery Date Change Request">Delivery Date Change Request</option>
                                    <option value="Product Code Change Request">Product Code Change Request</option>
                                    <option value="Mass Order Modification">Mass Order Modification</option>
                                    <option value="Delete or Cancel Order">Delete or Cancel Order</option>
                                </Form.Select>
                                <span className="help-block text-danger" id="InvalidTypeRequest" style={{ display: "None", float: "left" }}  >Please select a type of request</span>
                            </div>
                            <div className='col-md-3' style={{ background: "#1C1B83" }}>
                                <Form.Label style={{ color: "white", marginTop: ".5rem" }}>Requester</Form.Label>
                            </div>
                            <div className='col-md-3'>
                                <Form.Select aria-label="Default select example" id="Requestor">
                                    <option value="Sales">Sales</option>
                                    <option value="WS">Winston Salem</option>
                                    <option value="Site">Site</option>
                                </Form.Select>
                                <span className="help-block text-danger" id="InvalidRequestor" style={{ display: "None", float: "left" }} >Please select a Requestor</span>
                            </div>
                        </div>
                        <div className='row mt-5'></div>
                        <div className='row mt-2'></div>
                        <div className='row' id="HideNumberType">
                            <div className='col-md-3' style={{ background: "#1C1B83", height: "2.5rem" }}>
                                <Form.Label style={{ color: "white", marginTop: ".5rem" }}>Number Type</Form.Label>
                            </div>
                            <div className='col-md-6' style={{ textAlign: "initial" }} >
                                <div class="form-check form-check-inline" id="NumberType">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="orderNumber" />
                                    <label class="form-check-label" for="inlineRadio1">SAP Order Number</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="legacy" />
                                    <label class="form-check-label" for="inlineRadio2">Document number</label>
                                </div>

                            </div>

                        </div>
                        <div className='row mt-5'></div>
                        <div className='row' id="HideMandatory">


                        </div>
                        <div className='row' id='OrderNumberHide'>
                            <div className='col-md-3' style={{ background: "#1C1B83", height: "2.5rem" }}>
                                <Form.Label style={{ color: "white", marginTop: ".5rem" }}>
                                    Order Number<img src={process.env.PUBLIC_URL + "/redasterisk1.png"} alt="img" style={{ width: "0.6rem" }} />
                                </Form.Label>

                            </div>
                            <div className='col-md-3'>
                                <Form.Control type="text" id='OrderNumber' placeholder="Enter Number here after selecting the Number Type" />
                                <span className="help-block text-danger" id="Invalid_OrderNumber" style={{ display: "None", float: "left" }}  >The order number entered should be 10 digit numeric number for SAP and upto 12 digits numeric number for Legacy Number Type </span>
                                <span className="help-block text-danger" id="Invalid_OrderNumberDB" style={{ display: "None", float: "left" }}  >The order number entered is invalid</span>
                                <span className="help-block text-danger" id="Invalid_OrderNumberSite" style={{ display: "None", float: "left" }}  >User is not authorized to submit request for this Site ID</span>
                            </div>

                            <div className='col-md-3' style={{ background: "#1C1B83", height: "2.5rem" }}>
                                <Form.Label style={{ color: "white", marginTop: ".5rem" }}>
                                    Requester Email<img src={process.env.PUBLIC_URL + "/redasterisk1.png"} style={{ width: "0.6rem" }} />
                                </Form.Label>
                            </div>

                            <div className='col-md-3'>
                                <Form.Control type="email" id='Detailsemail' className='RequesterEmail' placeholder="Enter Email Here" required />
                                <span className="help-block text-danger" id="Invalid_Email" style={{ display: "None", float: "left" }}>Please enter a valid Email ID</span>
                            </div>

                        </div>
                        <div className='row' id="ShowRequestType" style={{ display: 'None' }}>
                            <div className='col-md-3' style={{ background: "#1C1B83", height: "2.5rem" }}>
                                <Form.Label style={{ color: "white", marginTop: ".5rem" }} >
                                    Mass Modification Request Type
                                </Form.Label>
                            </div>
                            <div className='col-md-3' style={{ textAlign: "initial" }}>
                                <Form.Select aria-label="Default select example" id="RequestTypeDropdown" name="" onChange={hideRequestDetail}>
                                    <option value="Please Select">--Please Select--</option>
                                    <option value="Delivery Date Change">Delivery Date Change</option>
                                    <option value="Load Date Change">Load Date Change</option>
                                    <option value="Route Change">Route Change</option>
                                    <option value="Quantity Change">Quantity Change</option>
                                </Form.Select>
                                <span className="help-block text-danger" id="Invalid_Requestype" style={{ display: "None", float: "left" }}>Please Select a Request Type</span>
                            </div>

                            <div className='col-md-3' style={{ background: "#1C1B83", height: "2.5rem" }}>
                                <Form.Label style={{ color: "white", marginTop: ".5rem" }}>
                                    Requester Email<img src={process.env.PUBLIC_URL + "/redasterisk1.png"} alt="img" style={{ width: "0.6rem" }} />
                                </Form.Label>
                            </div>

                            <div className='col-md-3'>
                                <Form.Control type="email" id='DetailsMassemail' className='RequesterEmail' placeholder="Enter Email Here" required />
                                <span className="help-block text-danger" id="Invalid_MassEmail" style={{ display: "None", float: "left" }}>Please enter a valid Email ID</span>
                            </div>

                        </div>
                        <div className='row'>
                            <div className='col-md-10 sm-10'>

                            </div>
                            <div className='col-md-2 sm-2 mt-3'>
                                <Button variant="outline-light" id="visibleOrderDetails" style={{ backgroundColor: "#009639", color: "white" }} onClick={showOrderDetails}>Submit</Button>
                            </div>
                        </div>

                    </Card.Body>
                </Card>

                {/* OTHER TYPE REQUEST */}
                <Card id='Orderdetails' style={{ display: "None", marginTop: "1rem" }}>
                    <Card.Header style={{ backgroundColor: "#1C1B83", height: "2.8rem" }}>
                        <div className='row'>

                            <div className='col-md-12' style={{ color: "white", textAlign: "center" }}>
                                <h5><b>Order Details</b></h5>
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body style={{ backgroundColor: "white" }} >
                        <div className='row' style={{ color: "white", marginTop: ".5rem" }}>

                        </div>

                        <div className='row'>
                            <div className='col-md-3' style={{ background: "#1C1B83" }}>
                                <Form.Label style={{ color: "white", marginTop: ".5rem" }}>Order Number</Form.Label>
                            </div>
                            <div className='col-md-3'>
                                <Form.Control type="text" id='Details_OrderNumber' placeholder="" disabled />
                            </div>
                            <div className='col-md-3' style={{ background: "#1C1B83" }}>
                                <Form.Label style={{ color: "white", marginTop: ".5rem" }}>Customer Name</Form.Label>
                            </div>
                            <div className='col-md-3'  >
                                <Form.Control type="text" id='Details_Customername' placeholder="Name" disabled />
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-md-3' style={{ background: "#1C1B83", height: "2.5rem" }}>
                                <Form.Label style={{ color: "white", marginTop: ".5rem" }}>Plant/Site</Form.Label>
                            </div>
                            <div className='col-md-3'>
                                <Form.Control type="text" id='plant' placeholder="" disabled hidden/>
                                <Form.Control type="text" id='siteName' placeholder="" disabled />
                            </div>
                            <div className='col-md-3' style={{ background: "#1C1B83", height: "2.5rem" }}>
                                <Form.Label style={{ color: "white", marginTop: ".5rem" }}>Delivery Date</Form.Label>
                            </div>
                            <div className='col-md-3'>
                                <Form.Control type="text" id='DeliveryDateDisabled' style={{ display: "none" }} />
                                <DatePicker className='form-control' rel="" id='DeliveryDate' style={{ display: "none" }} selected={deliveryDate} minDate={startDate} onChange={(date) => setDeliveryDate(date)} />
                            </div>
                        </div>

                        <div className='row mt-4'>
                            <input type='hidden' id='quicksaver'></input>
                            <input type='hidden' id='quicksaver2'></input>

                        </div>
                        <form >
                            {/* Code for Add or Remove Items */}
                            <div id="AddRemoveTable" style={{ display: "None" }}>
                                <div className="row" >

                                    <Table responsive bordered hover style={{ marginLeft: "-0.75rem", width: "101%", marginTop: ".5rem" }} >
                                        <colgroup>

                                            <col span={1} style={{}}></col>
                                            <col span={1} style={{}}></col>
                                            <col span={1} style={{}}></col>
                                            <col span={1} style={{}}></col>
                                            <col span={1} style={{}}></col>
                                            <col span={1} style={{ width: "19%" }}></col>
                                            <col span={1} style={{}}></col>

                                        </colgroup>
                                        <thead className="text-white" style={{ backgroundColor: "#1C1B83" }}>
                                            <tr>
                                                <td style={{ verticalAlign: "middle" }}>Material Number</td>
                                                <td style={{ verticalAlign: "middle" }}>UPC</td>
                                                <td style={{ verticalAlign: "middle" }}>Description</td>
                                                <td style={{ verticalAlign: "middle" }}>Unit of Measure</td>
                                                <td style={{ verticalAlign: "middle" }}>Order Quantity</td>
                                                <td style={{ verticalAlign: "middle" }}>Reason Code 3</td>
                                                <td style={{ verticalAlign: "middle" }}>Actions</td>
                                            </tr>

                                        </thead>
                                        <tbody>
                                            {materials.map((material) =>

                                                <ReadOnlyRowsForAdd material={material} reasonCodeOptions={reasonCodeOptions}
                                                    handelRemoveClick={handelRemoveClick} />
                                            )}
                                            {
                                                showAdd &&
                                                <tr id='AddItems'>
                                                    <td style={{ verticalAlign: "middle" }}><input type="text"
                                                        id="TableMaterialNumber"
                                                        idx="restrictedDecimal"
                                                        name="materialNumber"
                                                        alias="materialNumber"
                                                        required="required"
                                                        placeholder='Enter Material Number'
                                                        onChange={handleAddFormChange}
                                                        validation=""

                                                    />
                                                        <br></br>
                                                        <span className="help-block text-danger" id="Invalid_MaterialNumberTable" style={{ display: "None", float: "left" }}>Please enter a 13 digit numeric Material Number</span>
                                                        <span className="help-block text-danger" id="Invactive_MaterialNumberTableDB" style={{ display: "None", float: "left" }}>The material number entered is inactive</span>
                                                        <span className="help-block text-danger" id="Invalid_MaterialNumberTableDB" style={{ display: "None", float: "left" }}>The material number entered is invalid</span>
                                                    </td>
                                                    <td style={{ verticalAlign: "middle" }}> <input type="text" style={{ width: "6rem" }}
                                                        name="upc"
                                                        alias="upc"
                                                        required="required"
                                                        placeholder='Enter UPC'
                                                        onChange={handleAddFormChange}

                                                    /></td>
                                                    <td style={{ verticalAlign: "middle" }}><input type="text"
                                                        name="materialDescription"
                                                        alias="materialDescription"
                                                        required="required"
                                                        placeholder='Enter Description '
                                                        onChange={handleAddFormChange}

                                                    /></td>
                                                    <td style={{ verticalAlign: "middle" }}>

                                                        <select name='uom' alias='uom' onChange={handleAddFormChange} style={{ width: "10vw", textAlign: "center", height: "1.80rem" }}>
                                                            <option name="Please Select">Please Select</option>
                                                            <option name="CS">CS</option>
                                                            <option name="EA">EA</option>
                                                            <option name="DS">DS</option>
                                                            <option name="PAC">PAC</option>

                                                        </select>


                                                    </td>

                                                    <td style={{ verticalAlign: "middle" }}><input type="text"
                                                        name="orderQuantity"
                                                        alias="orderQuantity"
                                                        idx="restrictedDecimal"
                                                        required="required"
                                                        placeholder='Enter Order Quantity'
                                                        onChange={handleAddFormChange}

                                                    />

                                                    </td>
                                                    <td style={{ verticalAlign: "middle" }}>
                                                        <select name="ReasonCode" alias="reasonCode" onChange={handleAddFormChange} data-getrc="" data-getrcd="" style={{ width: "15rem", height: "1.80rem" }}>

                                                        </select>
                                                        <span className="help-block text-danger" id="InvalidMaterial_ReasonCode" style={{ display: "None" }}>Please select a reason code</span>
                                                    </td>
                                                    <td><Button variant="outline-light" id="AddOrder" onClick={(event) => { handleAddFormSubmit(event); }} style={{ backgroundColor: "#009639", color: "white" }} >Add</Button></td>

                                                </tr>
                                            }

                                        </tbody>
                                    </Table>

                                </div>
                                <div className="row" >
                                    <div className="col-md-9" >

                                    </div>
                                    <div className="col-md-3" >

                                        <Button variant="outline-secondary" style={{ borderRadius: "2vw" }} onClick={() => { SetshowAdd(!showAdd); setTimeout(function () { JQuery('#AddItems').find('select[name="ReasonCode"]').empty().html(reasonCodeOptions); }, 0); }}>+</Button>
                                        <span> Add New Material</span>


                                    </div>

                                </div>


                            </div>


                            {/* Code For Item Quantity Change */}

                            <form onSubmit={handleEditFormSubmit}>
                                <div className="row" id='ItemQuantityTable' style={{ display: "None" }}>

                                    <Table responsive bordered hover style={{ marginLeft: "-0.75rem", width: "101%", marginTop: ".5rem" }}>
                                        <thead className="text-white" style={{ backgroundColor: "#1C1B83" }}>
                                            <tr>
                                                <td style={{ verticalAlign: "middle" }}>Material Number</td>
                                                <td style={{ verticalAlign: "middle" }}>UPC</td>
                                                <td style={{ verticalAlign: "middle" }}>Description</td>
                                                <td style={{ width: "5rem", verticalAlign: "middle" }}>Unit of Measure</td>
                                                <td style={{ width: "5rem", verticalAlign: "middle" }}>Order Quantity</td>
                                                <td style={{ verticalAlign: "middle" }}>Reason Code 1</td>
                                                <td style={{ verticalAlign: "middle" }}>Actions</td>
                                            </tr>

                                        </thead>
                                        <tbody>
                                            {materials.map((material) =>
                                                <Fragment>

                                                    {editMaterialId === material.id ? <EditableRows editFormData={editFormData}
                                                        handleEditFormChange={handleEditFormChange} handleEditFormSubmit={handleEditFormSubmit} handelCancelClick={handelCancelClick} />

                                                        : <ReadOnlyRows
                                                            material={material}
                                                            handleEditClick={handleEditClick} />
                                                    }


                                                </Fragment>

                                            )}
                                        </tbody>
                                    </Table>

                                </div>
                            </form>
                            {/* Code For Delivery Date Change Request */}

                            <div className="row" id='DeliveryDateTable' style={{ display: "None" }}>
                                <Table responsive bordered hover style={{ marginLeft: "-0.75rem", width: "101%", marginTop: ".5rem" }} >
                                    <thead className="text-white" style={{ backgroundColor: "#1C1B83" }}>
                                        <tr>
                                            <td style={{ verticalAlign: "middle" }}>Material Number </td>
                                            <td style={{ verticalAlign: "middle" }}>UPC</td>
                                            <td style={{ verticalAlign: "middle" }}>Description</td>
                                            <td style={{ verticalAlign: "middle" }}>Unit of Measure</td>
                                            <td style={{ verticalAlign: "middle" }}>Order Quantity</td>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        {materials.map((material) =>
                                            <Fragment>
                                                {
                                                    <ReadOnlyRowsDeliveryDate material={material} />
                                                }
                                            </Fragment>

                                        )}
                                    </tbody>
                                </Table>
                            </div>


                            {/* Code For Product Code Change Request */}

                            <form onSubmit={handleEditFormSubmit}>
                                <div className="row " id="ProductCodeChangeTable" style={{ display: "None" }}>

                                    <Table responsive bordered hove style={{ marginLeft: "-0.75rem", width: "101%", marginTop: ".5rem" }}  >
                                        <thead className="text-white" style={{ backgroundColor: "#1C1B83" }}>
                                            <tr>
                                                <td style={{ verticalAlign: "middle" }}>Material Number </td>
                                                <td style={{ verticalAlign: "middle" }}>Updated Material Number</td>
                                                <td style={{ verticalAlign: "middle" }}>Description</td>
                                                <td style={{ width: "5rem", verticalAlign: "middle" }}>Unit of Measure</td>
                                                <td style={{ width: "5rem", verticalAlign: "middle" }}>Order Quantity</td>
                                                <td style={{ verticalAlign: "middle" }}>Reason Code 2</td>
                                                <td style={{ verticalAlign: "middle" }}>Actions</td>
                                            </tr>

                                        </thead>
                                        <tbody>
                                            {materials.map((material) =>
                                                <Fragment>

                                                    {editMaterialId === material.id ? <EditableRowsForProduct editFormData={editFormData}
                                                        handleEditFormChange={handleEditFormChange} handleEditFormSubmit={handleEditFormSubmit} handelCancelClick={handelCancelClick} />

                                                        : <ReadProductRows
                                                            material={material}
                                                            handleEditClick={handleEditClick} />
                                                    }


                                                </Fragment>

                                            )}
                                        </tbody>
                                    </Table>



                                </div>
                            </form>

                            {/* Code For delete or cancel Change Request */}

                            <div className="row" id='deleteDateTable' style={{ display: "None" }}>
                                <Table responsive bordered hover style={{ marginLeft: "-0.75rem", width: "101%", marginTop: ".5rem" }} >
                                    <thead className="text-white" style={{ backgroundColor: "#1C1B83" }}>
                                        <tr>
                                            <td style={{ verticalAlign: "middle" }}>Material Number </td>
                                            <td style={{ verticalAlign: "middle" }}>UPC</td>
                                            <td style={{ verticalAlign: "middle" }}>Description</td>
                                            <td style={{ verticalAlign: "middle" }}>Unit of Measure</td>
                                            <td style={{ verticalAlign: "middle" }}>Order Quantity</td>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        {materials.map((material) =>
                                            <Fragment>
                                                {
                                                    <ReadOnlyRowsDeliveryDate material={material} />
                                                }
                                            </Fragment>

                                        )}
                                    </tbody>
                                </Table>
                            </div>
                            {/* CODE FOR SUBMIT BUTTON*/}

                            <div className='row'>
                                <div className='col-md-10 sm-10'>
                                </div>
                                <div className='col-md-2 sm-2 mt-3'>
                                    {selectedRequestType === "Delete or Cancel Order" ? <Button variant="outline-light" onClick={deletePopUp} style={{ backgroundColor: "#009639", color: "white" }} >
                                        Submit
                                    </Button>
                                        :
                                        <Button variant="outline-light" onClick={handleConfirmation} style={{ backgroundColor: "#009639", color: "white" }} >
                                            Submit
                                        </Button>}
                                </div>
                            </div>

                            {/* CODE FOR MODAL */}

                            <Modal
                                show={show}
                                onHide={handleClose}
                                backdrop="static"
                                keyboard={false}
                            >
                                <Modal.Header closeButton >
                                    <Modal.Title name="modal-title">  </Modal.Title>
                                </Modal.Header>
                                <Modal.Body name='modal-title'>

                                </Modal.Body>
                                {!isShowConfirmPopUp && <Modal.Footer>
                                    <Button variant="outline-light" style={{ backgroundColor: "rgb(0, 150, 57)", color: "white" }} onClick={handleClose}>
                                        Close
                                    </Button>

                                </Modal.Footer>}
                                {isShowConfirmPopUp && <Modal.Footer>
                                    <Button variant="outline-light" style={{ backgroundColor: "rgb(0, 150, 57)", color: "white" }} onClick={handleOk}>
                                        Yes
                                    </Button>
                                    <Button variant="outline-light" style={{ backgroundColor: "rgb(0, 150, 57)", color: "white" }} onClick={handleClose}>
                                        No
                                    </Button>
                                </Modal.Footer>}
                            </Modal>


                        </form>

                    </Card.Body>
                </Card>

                {/* MASS ORDER MODIFICATION */}
                <Card id='MassOrder' style={{ display: "None", marginTop: "1rem" }}>
                    <Card.Header style={{ backgroundColor: "#1C1B83", height: "2.8rem" }}>
                        <div className='row'>

                            <div className='col-md-12' style={{ color: "white", textAlign: "center" }}>
                                <h5><b>Request Details</b></h5>
                            </div>
                        </div>
                    </Card.Header>

                    {/* DELIVERY DATE CHANGE */}
                    <Card.Body id="ModifyDeliveryDate" style={{ display: 'None' }}>
                        <h6><b>Mass Order Modifications are handled by the MBS CSR team; fill out the form below and a CSR will handle your request</b></h6>
                        <div className='row mt-1'>
                            <div className='col-md-3' style={{ background: "#1C1B83", height: "2.5rem" }}>
                                <Form.Label style={{ color: "white", marginTop: ".5rem" }} >Site ID
                                    <img src={process.env.PUBLIC_URL + "/redasterisk1.png"} alt="img" style={{ width: "0.6rem" }} />
                                </Form.Label>

                            </div>
                            <div className='col-md-3'>
                                <Form.Control type="text" id='Modify_DeliverySiteId' idx="restrictedSiteId" placeholder="" onChange={numericSiteId} />
                                <span className="help-block text-danger" id="Invalid_DeliverySiteId" style={{ display: "None", float: "left", marginRight: "1.7rem" }}>Please enter Site Id</span>
                                <span className="help-block text-danger" id="InvalidCount_DeliverySiteAccess" style={{ display: "None", float: "left", marginRight: "1.7rem" }}>User is not authorized to submit request for this Site ID.</span>
                                <span className="help-block text-danger" id="InvalidCount_DeliverySiteId" style={{ display: "None", float: "left", marginRight: "1.7rem" }}>Please enter 4 digit numeric Site Id.</span>
                            </div>
                            <div className='col-md-3' style={{ background: "#1C1B83", height: "2.5rem" }}>
                                <Form.Label style={{ color: "white", marginTop: ".5rem" }}>Mass Modify Type</Form.Label>
                            </div>
                            <div className='col-md-3'  >
                                <Form.Control type="text" id='Modify_DeliveryModifyType' class='MassModifyType' placeholder="" disabled />
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-md-3' style={{ background: "#1C1B83", height: "2.5rem" }}>
                                <Form.Label style={{ color: "white", marginTop: ".5rem" }}>
                                    Delivery Date From
                                </Form.Label>
                            </div>
                            <div className='col-md-3'>
                                <DatePicker className='form-control' id='DeliveryDateFrom' dateFormat="MM/dd/yyyy" onChange={SetOrderDeliveryFromdate} selected={OrderDeliveryFromdate} placeholderText="MM/DD/YYYY" />
                                <span className="help-block text-danger" id="Invalid_DeliveryDateFrom" style={{ display: "None", float: "left", marginRight: "1.7rem" }}>Please select Delivery Date From</span>
                            </div>

                            <div className='col-md-3' style={{ background: "#1C1B83", height: "2.5rem" }}>
                                <Form.Label style={{ color: "white", marginTop: ".5rem" }}>
                                    Delivery Date To
                                </Form.Label>
                            </div>
                            <div className='col-md-3'>
                                <DatePicker className='form-control' id='DeliveryDateTo' dateFormat="MM/dd/yyyy" onChange={SetOrderDeliveryTodate} selected={OrderDeliverytodate} placeholderText="MM/DD/YYYY" />
                                <span className="help-block text-danger" id="Invalid_DeliveryDateTo" style={{ display: "None", float: "left", marginRight: "1.7rem" }}>Please select Delivery Date To</span>
                                <span className="help-block text-danger" id="Invalid_LessDeliveryDateTo" style={{ display: "None", float: "left", marginRight: "1.7rem" }}>Delivery Date To should be greater than or equal to Delivery Date From</span>
                            </div>

                        </div>
                        <div className='row mt-4'>
                            <div className='col-md-3' style={{ background: "#1C1B83", height: "2.5rem" }}>
                                <Form.Label style={{ color: "white", marginTop: ".5rem" }}>Additional Comments
                                </Form.Label>
                            </div>
                            <div className='col-md-9'>
                                < Form.Control type="text" id='Modify_CommentsDeliveryDate' placeholder="" />
                                <span className="help-block text-danger" id="Invalid_CountDelivery" style={{ display: "None", float: "left", marginRight: "1.7rem" }}>Maximun characters allowed is 5000</span>
                            </div>
                        </div>
                        <div className='row mt-4 MassModify'  >

                        </div>
                    </Card.Body>

                    {/* LOAD DATE CAHNGE */}
                    <Card.Body id="ModifyLoadDateChange" style={{ display: 'None' }}>
                        <h6><b>Mass Order Modifications are handled by the MBS CSR team; fill out the form below and a CSR will handle your request</b></h6>
                        <div className='row mt-1'>
                            <div className='col-md-3' style={{ background: "#1C1B83", height: "2.5rem" }}>
                                <Form.Label style={{ color: "white", marginTop: ".5rem" }}>
                                    Site ID<img src={process.env.PUBLIC_URL + "/redasterisk1.png"} alt="img" style={{ width: "0.6rem" }} />
                                </Form.Label>
                            </div>
                            <div className='col-md-3'>
                                <Form.Control type="text" idx="restrictedSiteId" id='Modify_LoadSiteId' placeholder="" onChange={numericSiteId} />
                                <span className="help-block text-danger" id="Invalid_LoadSiteId" style={{ display: "None", float: "left", marginRight: "1.7rem" }}>Please enter Site Id</span>
                                <span className="help-block text-danger" id="InvalidCount_LoadSiteAccess" style={{ display: "None", float: "left", marginRight: "1.7rem" }}>User is not authorized to submit request for this Site ID</span>
                                <span className="help-block text-danger" id="InvalidCount_LoadSiteId" style={{ display: "None", float: "left", marginRight: "1.7rem" }}>Please enter 4 digit numeric Site Id</span>
                            </div>
                            <div className='col-md-3' style={{ background: "#1C1B83", height: "2.5rem" }}>
                                <Form.Label style={{ color: "white", marginTop: ".5rem" }}>Mass Modify Type</Form.Label>
                            </div>
                            <div className='col-md-3'  >
                                <Form.Control type="text" id='Modify_LoadModifyType' class='MassModifyType' placeholder="" disabled />
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-md-3' style={{ background: "#1C1B83", height: "2.5rem" }}>
                                <Form.Label style={{ color: "white", marginTop: ".5rem" }}>
                                    Load Date From
                                </Form.Label>
                            </div>
                            <div className='col-md-3'>
                                <DatePicker className='form-control' id='Modify_LoadDateFrom' dateFormat="MM/dd/yyyy" onChange={SetLoadFromdate} selected={LoadFromdate} placeholderText="MM/DD/YYYY" ></DatePicker>
                                <span className="help-block text-danger" id="Invalid_LoadDateFrom" style={{ display: "None", float: "left", marginRight: "1.7rem" }}>Please select Load Date From</span>
                            </div>
                            <div className='col-md-3' style={{ background: "#1C1B83", height: "2.5rem" }}>
                                <Form.Label style={{ color: "white", marginTop: ".5rem" }}>
                                    Load Date To
                                </Form.Label>
                            </div>
                            <div className='col-md-3'>
                                <DatePicker className='form-control' id='Modify_LoadDateTo' dateFormat="MM/dd/yyyy" onChange={SetLoadTodate} selected={LoadTodate} placeholderText="MM/DD/YYYY" />
                                <span className="help-block text-danger" id="Invalid_LoadDateTo" style={{ display: "None", float: "left", marginRight: "1.7rem" }}>Please select Load Date To</span>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-md-3' style={{ background: "#1C1B83", height: "2.5rem" }}>
                                <Form.Label style={{ color: "white", marginTop: ".5rem" }}>
                                    Additional Comments
                                </Form.Label>
                            </div>
                            <div className='col-md-9'>
                                < Form.Control type="text" id='Modify_CommentsLoadDate' placeholder="" />
                                <span className="help-block text-danger" id="Invalid_CountLoad" style={{ display: "None", float: "left", marginRight: "1.7rem" }}>Maximun characters allowed is 5000</span>
                            </div>
                        </div>

                    </Card.Body>

                    {/* ROUTE CHANGE */}
                    <Card.Body id="ModifyRouteChange" style={{ display: 'None' }}>
                        <h6><b>Mass Order Modifications are handled by the MBS CSR team; fill out the form below and a CSR will handle your request </b></h6>
                        <div className='row mt-1'>
                            <div className='col-md-3' style={{ background: "#1C1B83", height: "2.5rem" }}>
                                <Form.Label style={{ color: "white", marginTop: ".5rem" }}>
                                    Site ID<img src={process.env.PUBLIC_URL + "/redasterisk1.png"} alt="img" style={{ width: "0.6rem" }} />
                                </Form.Label>
                            </div>
                            <div className='col-md-3'>
                                <Form.Control type="text" id='Modify_RouteSiteId' placeholder="" idx="restrictedSiteId" onChange={numericSiteId} />
                                <span className="help-block text-danger" id="InvalidCount_RouteSiteId" style={{ display: "None", float: "left", marginRight: "1.7rem" }}>Please enter 4 digit numeric Site Id</span>
                                <span className="help-block text-danger" id="Invalid_RouteSiteAccess" style={{ display: "None", float: "left", marginRight: "1.7rem" }}>User is not authorized to submit request for this Site ID</span>
                                <span className="help-block text-danger" id="Invalid_RouteSiteId" style={{ display: "None", float: "left", marginRight: "1.7rem" }}>Please enter Site Id</span>
                            </div>
                            <div className='col-md-3' style={{ background: "#1C1B83", height: "2.5rem" }}>
                                <Form.Label style={{ color: "white", marginTop: ".5rem" }}>Mass Modify Type</Form.Label>
                            </div>
                            <div className='col-md-3'  >
                                <Form.Control type="text" id='Modify_RouteType' class='MassModifyType' placeholder="" disabled />
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-md-3' style={{ background: "#1C1B83", height: "2.5rem" }}>
                                <Form.Label style={{ color: "white", marginTop: ".5rem" }}>
                                    Current Route
                                </Form.Label>
                            </div>
                            <div className='col-md-3'>
                                <Form.Control type="text" id='Modify_CurrentRoute' placeholder="" idx="restrictedSiteId" onChange={numericSiteId} />
                                <span className="help-block text-danger" id="InvalidCount_CurrentRoute" style={{ display: "None", float: "left", marginRight: "1.7rem" }}>Please enter 5 digit numeric Current  Route</span>
                            </div>
                            <div className='col-md-3' style={{ background: "#1C1B83", height: "2.5rem" }}>
                                <Form.Label style={{ color: "white", marginTop: ".5rem" }}>
                                    Requested Route
                                </Form.Label>
                            </div>
                            <div className='col-md-3'>
                                <Form.Control type="text" id='Modify_RequestedRoute' placeholder="" idx="restrictedSiteId" onChange={numericSiteId} />
                                <span className="help-block text-danger" id="InvalidCount_RequestedRoute" style={{ display: "None", float: "left", marginRight: "1.7rem" }}>Please enter 5 digit numeric Requested Route</span>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-md-3' style={{ background: "#1C1B83", height: "2.5rem" }}>
                                <Form.Label style={{ color: "white", marginTop: ".5rem" }}>
                                    Additional Comments
                                </Form.Label>
                            </div>
                            <div className='col-md-9'>
                                < Form.Control type="text" id='Modify_CommentsRoute' placeholder="" />
                                <span className="help-block text-danger" id="Invalid_CountRoute" style={{ display: "None", float: "left", marginRight: "1.7rem" }}>Maximun characters allowed is 5000</span>
                            </div>
                        </div>

                    </Card.Body>

                    {/* QUANTTY CHANGE */}
                    <Card.Body id="ModifyQuantityChange" style={{ display: 'None' }}>
                        <h6><b>Mass Order Modifications are handled by the MBS CSR team; fill out the form below and a CSR will handle your request</b></h6>
                        <div className='row mt-1'>
                            <div className='col-md-3' style={{ background: "#1C1B83", height: "2.5rem" }}>
                                <Form.Label style={{ color: "white", marginTop: ".5rem" }}>
                                    Site ID<img src={process.env.PUBLIC_URL + "/redasterisk1.png"} alt="img" style={{ width: "0.6rem" }} />
                                </Form.Label>
                            </div>
                            <div className='col-md-3'>
                                <Form.Control type="text" id='Modify_QuantitySiteId' placeholder="" idx="restrictedSiteId" onChange={numericSiteId} />
                                <span className="help-block text-danger" id="InvalidCount_QuantitySiteId" style={{ display: "None", float: "left", marginRight: "1.7rem" }}>Please enter 4 digit numeric Site Id</span>
                                <span className="help-block text-danger" id="Invalid_QuantitySiteAccess" style={{ display: "None", float: "left", marginRight: "1.7rem" }}>User is not authorized to submit request for this Site ID</span>
                                <span className="help-block text-danger" id="Invalid_QuantitySiteId" style={{ display: "None", float: "left", marginRight: "1.7rem" }}>Please enter Site Id</span>
                            </div>
                            <div className='col-md-3' style={{ background: "#1C1B83", height: "2.5rem" }}>
                                <Form.Label style={{ color: "white", marginTop: ".5rem" }}>Mass Modify Type</Form.Label>
                            </div>
                            <div className='col-md-3'  >
                                <Form.Control type="text" id='Modify_QuantityModifyType' class='MassModifyType' placeholder="" disabled />
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-md-3' style={{ background: "#1C1B83", height: "2.5rem" }}>
                                <Form.Label style={{ color: "white", marginTop: ".5rem" }}>
                                    Delivery Date
                                </Form.Label>
                            </div>
                            <div className='col-md-3'>
                                <DatePicker className='form-control' id='DeliveryDateQty' dateFormat="MM/dd/yyyy" onChange={SetOrderDeliveryDate} selected={OrderDeliveryDate} placeholderText="MM/DD/YYYY" />
                                <span className="help-block text-danger" id="Invalid_DeliveryDateQty" style={{ display: "None", float: "left", marginRight: "1.7rem" }}>Please enter either Delivery Date or Load Date</span>
                            </div>
                            <div className='col-md-3' style={{ background: "#1C1B83", height: "2.5rem" }}>
                                <Form.Label style={{ color: "white", marginTop: ".5rem" }}>
                                    Load Date
                                </Form.Label>
                            </div>
                            <div className='col-md-3'>
                                <DatePicker className='form-control' id='LoadDateQty' dateFormat="MM/dd/yyyy" onChange={SetOrderLoadDate} selected={OrderLoadDate} placeholderText="MM/DD/YYYY" />
                                <span className="help-block text-danger" id="Invalid_LoadDateQty" style={{ display: "None", float: "left", marginRight: "1.7rem" }}>Please enter either Delivery Date or Load Date</span>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-md-3' style={{ background: "#1C1B83", height: "2.5rem" }}>
                                <Form.Label style={{ color: "white", marginTop: ".5rem" }}>
                                    Additional Comments
                                </Form.Label>
                            </div>
                            <div className='col-md-9'>
                                < Form.Control type="text" id='Modify_CommentsQuantity' placeholder="" />
                                <span className="help-block text-danger" id="Invalid_CountQuantity" style={{ display: "None", float: "left", marginRight: "1.7rem" }}>Maximun characters allowed is 5000</span>
                            </div>
                        </div>

                    </Card.Body>



                    {/* CODE FOR SUBMIT BUTTON*/}
                    <div className='row mb-3'>
                        <div className='col-md-12'>
                            <span className="help-block text-success" id="Valid_File" style={{ float: 'left', display: 'None', marginLeft: '12.1rem' }} >
                                ...
                            </span>
                            <span className="col-md-4 help-block text-danger" id="Invalid_File" style={{ float: 'left', display: 'None' }}>
                                <i class="fa fa-times-circle"></i> Invalid File Type, File types other than .docx, .pdf, .xls, .txt are not supported, Please upload a valid file type
                            </span>
                        </div>
                        <div className='col-md-6 mt-3 '>
                            <input type='button' value='Download Template&#13;&#10;(For CSR Handling) ' className='btn-outline-pepsi p-1' style={{ float: 'left' }} id="" onClick={handledownload} />
                            <input type='file' name='fileSelector' className=' d-none' onChange={handleFile} />
                            <input type='button' className='btn-outline-pepsi p-1' value='Add Attachment&#13;&#10;(.docx,.pdf,.xlsx,.txt) ' style={{ marginLeft: '1.4rem', float: 'left' }} onClick={uploadClick} />

                        </div>
                        <div className='col-md-4 ' style={{ float: 'right' }}>



                        </div>
                        <div className='col-md-2 mt-4' >
                            <button type='button' className='btn btn-md' id='MassModifySubmit' style={{ backgroundColor: "#009639", color: "white" }} onClick={massSubmit}>
                                Submit
                            </button>
                        </div>
                    </div>
                </Card>

                {/* MODAL */}



            </div>

        </>
    );
}

export default OrderModification;

