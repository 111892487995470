import React from 'react';
import Button from 'react-bootstrap/Button';

const SublistRead = ({ material, handleEditSublistmaterialClick, handelRemoveSublistmaterialClick }) => {

    return (


        <tr>
            <td style={{ backgroundColor: "#e9ecef", verticalAlign: "middle" }}>{material.MaterialCodeFrom}</td>
            <td style={{ backgroundColor: "#e9ecef", verticalAlign: "middle" }}>{material.MaterialDescriptionFrom}</td>
            <td style={{ backgroundColor: "#e9ecef", verticalAlign: "middle" }}>{material.UnitOfMeasureFrom}</td>
            <td style={{ backgroundColor: "#e9ecef", verticalAlign: "middle" }}>{material.MaterialCodeTo}</td>
            <td style={{ backgroundColor: "#e9ecef", verticalAlign: "middle" }}>{material.MaterialDescriptionTo}</td>
            <td style={{ backgroundColor: "#e9ecef", verticalAlign: "middle" }}>{material.UnitOfMeasureTo}</td>

            <td style={{ backgroundColor: "#e9ecef", verticalAlign: "middle" }}>{material.ReasonCodeDescription}</td>
            <td className='text-center'>
                <Button variant="outline-light" onClick={(event) => handleEditSublistmaterialClick(event, material)} style={{ width: "5rem", backgroundColor: "#009639", color: "white" }}>Edit</Button>
                <Button variant="outline-light" onClick={() => handelRemoveSublistmaterialClick(material.id)} style={{ marginLeft: "1vw", width: "7rem", backgroundColor: "#FF0000", color: "white" }} >Remove</Button>
            </td>


        </tr>
    )

}
export default SublistRead;