import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import routes from './routes';
import { OktaAuth} from "@okta/okta-auth-js";

import { useNavigate } from 'react-router-dom';
import { Security } from "@okta/okta-react";
import { oktaConfig } from './okta/Okta.Config';


const oktaAuth = new OktaAuth(oktaConfig);


function App() {

  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth, originalUri) => {
    let actualpath = originalUri.substring(1, originalUri.length)
    let updatedpath = actualpath.substring(actualpath.indexOf('/') + 1, actualpath.length)
    navigate(updatedpath);
  };


  return (

    <div className="App">
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        {routes}
      </Security>
    </div>
  );

}

export default App;
