import React from 'react';
import Button from 'react-bootstrap/Button';

const CutlistRead = ({ material,handleEditCutlistmaterialClick,handelRemoveCutlistmaterialClick }) => {

    return (


        <tr>
            <td style={{ backgroundColor: "#e9ecef",verticalAlign:"middle"}}>{material.MaterialCode}</td>
            <td style={{ backgroundColor: "#e9ecef",verticalAlign:"middle"}}>{material.MaterialDescription}</td>
            <td style={{ backgroundColor: "#e9ecef" ,verticalAlign:"middle"}}>{material.UnitOfMeasure}</td>
            <td style={{ backgroundColor: "#e9ecef" ,verticalAlign:"middle"}}>{material.CutValue}</td>
            <td style={{ backgroundColor: "#e9ecef" ,verticalAlign:"middle"}}>{material.CutValueQty}</td>
            <td style={{ backgroundColor: "#e9ecef", verticalAlign:"middle" }}>{material.ReasonCodeDescription}</td>

            <td className='text-center'>
                <Button variant="outline-light" onClick={(event) => handleEditCutlistmaterialClick(event, material)} style={{ width:"5rem",backgroundColor: "#009639",color:"white"}}>Edit</Button>
                <Button variant="outline-light" onClick={() => handelRemoveCutlistmaterialClick(material.id)} style={{marginLeft: "1vw",width:"7rem",backgroundColor: "#FF0000",color:"white"}} >Remove</Button>
            </td>


        </tr>
    )

}
export default CutlistRead ;