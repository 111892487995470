//*constant values for Request tracker and Archive Request Tracker Enable button *//

export const Approve = "A"
export const Deny ="D"
export const DisableBothApprove_Deny ="N"

export const EnableBothApprove_Deny="B"



//*Constant values for Dashboard *//

//for traffic light data//
export const ArrayTrafficLightGreen="G"
export const ArrayTrafficLightYellow="Y"
export const ArrayTrafficLightRed="R"

//for traffic light status data//
export const ArrayStatusTrafficLightGreen="Green"
export const ArrayStatusTrafficLightYellow="Yellow"
export const ArrayStatusTrafficLightRed="Red"

//for moniroting status//
export const MonitoringStatus0="0"
export const MonitoringStatus1="1"
export const MonitoringStatus2="2"


//* User OKTA roles*//

export const roleAdmin= "Admin" 
export const roleCSR= "CSR"
export const roleSeniorCSR= "Senior CSR"
export const roleSite= "Site"
export const roleCommercial= "Commercial"
export const roleundefined= "undefined"





 