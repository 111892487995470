const ConfigUtility = {
    //API URL
    URL: 'https://apim-pfna.mypepsico.com/it/scas/v1/orders/',
    //FOR OKTA CLIENT ID
    clientId: `0oa1szpngkrAbigxK0h8`,
    issuer: `https://secure.pepsico.com/oauth2/aus1ljxpv3z9G8NGl0h8`,
    redirectUri: 'https://ordermgmtflna.mypepsico.com/orders',
    requestaccess : 'https://secure.mypepsico.com/associatesso/login?TYPE=33554433&REALMOID=06-0004f56a-b847-14db-880a-fa369c5140c7&GUID=&SMAUTHREASON=0&METHOD=GET&SMAGENTNAME=myidm-apache-peplxw00004-agent&TARGET=$SM$https%3a%2f%2fmyidm%2emypepsico%2ecom%2f',
    traininghub :'https://pepsico.sharepoint.com/teams/DigitalFactory823/Shared%20Documents/Forms/AllItems.aspx?ga=1&id=%2Fteams%2FDigitalFactory823%2FShared%20Documents%2FGeneral%2FTraining%20and%20FAQs&viewid=9bdc60f9%2De856%2D4d69%2D93ee%2D557728d69d1b',
   
    
    
};


export default ConfigUtility;