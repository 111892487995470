import React from 'react';
import Button from 'react-bootstrap/Button';

const AddlistRead = ({ material, handleEditAddlistmaterialClick, handelRemoveAddlistmaterialClick }) => {

    return (


        <tr>
            <td style={{ backgroundColor: "#e9ecef", verticalAlign: "middle" }}>{material.MaterialCode}</td>
            <td style={{ backgroundColor: "#e9ecef", verticalAlign: "middle" }}>{material.MaterialDescription}</td>
            <td style={{ backgroundColor: "#e9ecef", verticalAlign: "middle" }}>{material.UnitOfMeasure}</td>
            <td style={{ backgroundColor: "#e9ecef", verticalAlign: "middle" }}>{material.AddValue}</td>
            <td style={{ backgroundColor: "#e9ecef", verticalAlign: "middle" }}>{material.AddValueQty}</td>
            <td style={{ backgroundColor: "#e9ecef", verticalAlign: "middle" }}>{material.ReasonCodeDescription}</td>
            <td className='text-center'>
                <Button variant="outline-light" onClick={(event) => handleEditAddlistmaterialClick(event, material)} style={{ backgroundColor: "#009639", color: "white", width: "5rem" }}>Edit</Button>
                <Button variant="outline-light" onClick={() => handelRemoveAddlistmaterialClick(material.id)} style={{ backgroundColor: "#FF0000", color: "white", marginLeft: "1vw", width: "7rem" }} >Remove</Button>
            </td>


        </tr>
    )

}
export default AddlistRead;