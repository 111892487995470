

import ConfigUtility from '../ConfigUtility'



export const oktaConfig = {
  clientId: ConfigUtility.clientId,
  issuer: ConfigUtility.issuer,
  redirectUri: ConfigUtility.redirectUri,
  scopes: ["openid", "profile", "email","pepappsupplychainroles"],
  pkce: true,
  disableHttpsCheck: false,
};