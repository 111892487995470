import React from 'react';
import Card from 'react-bootstrap/Card';


function AccessDenied() {
  return (
    <>
      <div className="order-font container-fluid">

        <Card.Header style={{ backgroundColor: "#1C1B83" }}>
          <div className='row'>
            <div className='col-md-2' style={{ backgroundColor: "white" }}>
              <img src={process.env.PUBLIC_URL + "/PepsicoLogo.png"} width="140" alt="logo" style={{}} />
            </div>
            <div className='col-md-8 ' style={{ color: "white", backgroundColor: "#1C1B83" }}>
              <h5 style={{ marginTop: ".5rem" }}><b>Digital Factory Hub:PFNA Order Processing</b></h5>
            </div>
            <div className='col-md-2 ' style={{ color: "white", backgroundColor: "white" }}>
              <img src={process.env.PUBLIC_URL + "/LogoImages.png"} width="140" alt="logo" style={{ width: "11rem" }} />
            </div>
          </div>

        </Card.Header>

        <Card.Body>
          <h2 style={{ marginTop: "9rem" }}>You do not have access to the requested resource</h2>
        </Card.Body>

      </div >
    </>
  );
}

export default AccessDenied;