import React, { useState, Component } from 'react';

import { Navigate } from "react-router-dom";
import makeAnimated from "react-select/animated";

import SelectAll from '../Common Files/SelectAll';
import "../Styles.css";
import { components } from "react-select";


import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';


import JQuery, { event } from 'jquery';
import ReactPaginate from 'react-paginate';


import ReadOnlyRowsDashboard from './ReadonlyRowsDashboard';


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import Modal from 'react-bootstrap/Modal';


import _ from 'lodash';

import UtilBaseUrl from '../ConfigUtility';
import * as Constants from '../Common Files/Constants.js';
import { Spinner } from 'react-bootstrap';


const formatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: true,
  timeZone: 'America/Chicago',
};

let intlDateObj = new Intl.DateTimeFormat('en-US', formatOptions);




// multiple selection checkbox//

const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const allOption = {
  label: "Select all",
  value: "*"
};

// select all values checked//
const ValueContainer = ({ children, ...props }) => {
  const currentValues = props.getValue();
  let toBeRendered = children;
  if (currentValues.some(val => val.value === allOption.value)) {
    toBeRendered = [[children[0][0]], children[1]];
  }

  return (
    <components.ValueContainer {...props}>
      {toBeRendered}
    </components.ValueContainer>
  );
};


const MultiValue = props => {
  let labelToBeDisplayed = `${props.data.label} `;
  if (props.data.value === allOption.value) {
    labelToBeDisplayed = "All Selected";
  }
  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  );
};

const animatedComponents = makeAnimated();


const getToken = () => {
  let tokenStr = localStorage.getItem("okta-token-storage");

  if (!tokenStr) {
    return;
  }
  else {
    const tokenObj = JSON.parse(tokenStr);
    return tokenObj?.accessToken?.accessToken;
  }
}

const pageSize = 5;

let temp_emailid = null;
let temp_gpid = null;
let temp_role = null;

const hostUrl = UtilBaseUrl.URL;


class OrderFlowStatusDashboard extends Component {

  constructor(props) {

    super(props);

    this.state = {

      optionSelectedSites: null,
      optionSelectedStatus: null,

      dt: [],
      RequestDashboardDataTotal: [],

      Populateselectedsites: [],


      temp__sitesParam: [],
      temp__statusParam: [],


      datevalueFromdate: new Date(),
      datevalueTodate: new Date(),

      deliverydatevalue: "",

      AccessDenied: false,



      SystemClock: new Date(),
      LastRefresh: "12:00 AM",

      pageCount: 0,
      currentPage: 0,
      Digit: 0,

    };

    this.handleFilterSubmit = this.handleFilterSubmit.bind(this);


  }

  // submit filter //

  handleFilterSubmit = (event) => {

    const token = getToken();
    const headers = {
      "accept": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Authorization": `Bearer ${token}`
    }


    JQuery("#filtersubmitbutton").html("<i class='fa fa-refresh fa-spin' ></i> Submitting");


    let deliverydate = JQuery('#deliverydate').val();
    let formatteddeliverydate = "";

    if (deliverydate !== "") {

      let tempDate = deliverydate.split('/');
      formatteddeliverydate = [tempDate[2], tempDate[0], tempDate[1]].join('-');

    }


    let temp1 = [], temp2 = [];



    if (this.state.optionSelectedSites !== null) {

      for (let i = 0; i < this.state.optionSelectedSites.length; i++) {

        if (this.state.optionSelectedSites[i].value !== "*") {

          temp1.push(this.state.optionSelectedSites[i].value);
        }

      }
    }


    if (this.state.optionSelectedStatus !== null) {

      for (let i = 0; i < this.state.optionSelectedStatus.length; i++) {

        if (this.state.optionSelectedStatus[i].value !== "*") {

          temp2.push(this.state.optionSelectedStatus[i].value.split(" (")[1].split(")")[0]);
        }

      }
    }


    let result_temp1 = '';
    let result_temp2 = '';

    for (let i = 0; i < temp1.length; i++) {
      result_temp1 = result_temp1.concat(temp1[i]);
      if (i < temp1.length - 1) {
        result_temp1 = result_temp1.concat(",");
      }
    }
    for (let i = 0; i < temp2.length; i++) {
      result_temp2 = result_temp2.concat(temp2[i]);
      if (i < temp2.length - 1) {
        result_temp2 = result_temp2.concat(",");
      }
    }


    let filter_data = {
      "site": result_temp1,
      "deliveryDate": formatteddeliverydate,
      "requestStatus": result_temp2,

    }
    this.setState({ isLoading: true });
    JQuery.ajax({
      url: hostUrl + "/orderReleaseDashboard/fetchDashboardRecordsByFilter",

      type: 'POST',
      dataType: 'json',
      data: JSON.stringify(filter_data),
      CORS: true,
      contentType: 'application/json',
      secure: true,
      headers: headers,
      crossDomain: true,
      success: (res) => {
        this.setState({ isLoading: false });

        let temp = [];

        temp = res.response;

        this.setState({ RequestDashboardDataTotal: temp });

        let value = temp ? Math.ceil(temp.length / pageSize) : 0;

        let pages = _.range(1, value + 1);
        let dtPagination = _(temp).slice(0).take(pageSize).value();

        let valx = 0;

        this.setState({ currentPage: valx });

        this.setState({ pageCount: value });
        this.setState({ dt: dtPagination });
        this.setState(prevstate => ({ Digit: prevstate.Digit + 1 }));


        JQuery("#filtersubmitbutton").html("Submit");


      },
      error: (err) => {
        console.log("Error", err);
        JQuery("#filtersubmitbutton").html("Submit");
        this.setState({ isLoading: false });
        this.setState({ show: true });
        setTimeout(function () {
          JQuery('.modal-title').html("<strong class='text-danger'><i class='fa fa-exclamation-circle'></i> Error !!! </strong>");
          JQuery(".modal-body").html("<p>Something went wrong. please try again later.</p>");
        }, 0);
      }

    });


    event.preventDefault();
  }



  // multiple selection for Sites//
  handleChangeSites = selected => {
    this.setState({
      optionSelectedSites: selected
    });
  };

  // multiple selection for Status//

  handleChangeStatus = (selected) => {

    this.setState({
      optionSelectedStatus: selected
    });

  };

  // page load pagination for display data//

  handleChangePagination = (TotalRequestDasboarddata, pageNumber) => {


    let newCurrentpage = pageNumber.selected + 1;



    this.setState({ RequestDashboardDataTotal: TotalRequestDasboarddata });

    this.setState({ currentPage: pageNumber.selected });

    let value = TotalRequestDasboarddata ? Math.ceil(TotalRequestDasboarddata.length / pageSize) : 0;
    this.setState({ pageCount: value });

    const startIndex = (newCurrentpage - 1) * pageSize;
    const PaginationPost = _(TotalRequestDasboarddata).slice(startIndex).take(pageSize).value();



    this.setState({ dt: PaginationPost });
  }

  // page load table//

  PageLoadTable() {


    const token = getToken();
    const headers = {
      "accept": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Authorization": `Bearer ${token}`
    }
    this.setState({ isLoading: true });
    JQuery.ajax({
      url: hostUrl + "/orderReleaseDashboard/fetchDashboardRecords",
      type: 'POST',
      dataType: 'json',
      CORS: true,
      contentType: 'application/json',
      secure: true,
      headers: headers,
      crossDomain: true,
      success: (res) => {
        this.setState({ isLoading: false });
        let temp = [];

        temp = res.response;

        this.setState({ RequestDashboardDataTotal: temp });

        let value = temp ? Math.ceil(temp.length / pageSize) : 0;

        let pages = _.range(1, value + 1);
        let dtPagination = _(temp).slice(0).take(pageSize).value();

        let valx = 0;

        this.setState({ currentPage: valx });

        this.setState({ pageCount: value });
        this.setState({ dt: dtPagination });
        this.setState(prevstate => ({ Digit: prevstate.Digit + 1 }));


      },
      error: (err) => {
        console.log("Error", err);
        this.setState({ isLoading: false });
        this.setState({ show: true });
        setTimeout(function () {
          JQuery('.modal-title').html("<strong class='text-danger'><i class='fa fa-exclamation-circle'></i> Error !!! </strong>");
          JQuery(".modal-body").html("<p>Something went wrong. please try again later.</p>");
        }, 0);
      }
    });


  }

  // page load//
  componentDidMount() {

    temp_emailid = sessionStorage.getItem("emailid");
    temp_gpid = sessionStorage.getItem("gpid");
    temp_role = sessionStorage.getItem("role");

    if (temp_role === Constants.roleCommercial || temp_role === Constants.roleSite || temp_role === Constants.roleundefined) {

      this.setState({ AccessDenied: true });

    }



    this.ClockSystemTime();
    setInterval(this.ClockSystemTime, 1000);


    this.clockRefresh();
    this.PageLoadTable();

    const token = getToken();
    const headers = {
      "accept": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Authorization": `Bearer ${token}`
    }

    JQuery.ajax({
      url: hostUrl + "/orderReleaseDashboard/getSitesForDashboard",
      type: 'POST',
      dataType: 'json',
      CORS: true,
      contentType: 'application/json',
      secure: true,
      headers: headers,
      crossDomain: true,
      success: (res) => {

        let temp_Sites = [];

        for (let i = 0; i < res.response.length; i++) {
          temp_Sites = res.response.reduce((values, value) => {

            values.push({
              id: value.dashboardId,
              label: value.dashboardValue,
              value: value.dashboardId,

            });

            return values;
          }, []);
        }
        this.setState({ temp__sitesParam: temp_Sites });

      }
    });


    JQuery.ajax({
      url: hostUrl + "/orderReleaseDashboard/getStatusForDashboard",
      type: 'POST',
      dataType: 'json',
      CORS: true,
      contentType: 'application/json',
      secure: true,
      headers: headers,
      crossDomain: true,
      success: (res) => {

        let temp_Status = [];

        for (let i = 0; i < res.response.length; i++) {
          temp_Status = res.response.reduce((values, value) => {

            values.push({
              id: value.dashboardId,
              label: value.dashboardValue,
              value: value.dashboardValue

            });

            return values;
          }, []);
        }
        this.setState({ temp__statusParam: temp_Status });

      }
    });

  }

  // page load clock//
  ClockSystemTime() {
    var date = new Date(intlDateObj.format(new Date()))

    function addZero(x) {
      if (x < 10) {
        return x = '0' + x;
      } else {
        return x;
      }
    }

    function twelveHour(x) {
      if (x > 12) {
        return x = x - 12;
      } else if (x == 0) {
        return x = 12;
      } else {
        return x;
      }
    }



    let h = addZero(twelveHour(date.getHours()));
    let m = addZero(date.getMinutes());


    let timevalue;



    if (date.getHours() >= 12) {
      timevalue = (h + ':' + m + ' PM');
    }
    else if (date.getHours() < 12 || date.getHours() == 0) {
      timevalue = (h + ':' + m + ' AM');
    }
    JQuery("#systemclock").val(timevalue);



  }
  // page load refresh clock//
  clockRefresh() {
    var date = new Date(intlDateObj.format(new Date()))

    function addZero(x) {
      if (x < 10) {
        return x = '0' + x;
      } else {
        return x;
      }
    }

    function twelveHour(x) {
      if (x > 12) {
        return x = x - 12;
      } else if (x == 0) {
        return x = 12;
      } else {
        return x;
      }
    }



    let h = addZero(twelveHour(date.getHours()));
    let m = addZero(date.getMinutes());


    let timevalue;



    if (date.getHours() >= 12) {
      timevalue = (h + ':' + m + ' PM');
    }
    else if (date.getHours() < 12 || date.getHours() == 0) {
      timevalue = (h + ':' + m + ' AM');
    }

    JQuery("#LastRefresh").val(timevalue);


  }

  // refresh monitoring//
  handlePageRefreshing() {

    window.location.reload(true);

  }

  // call monitoring//
  handleMonitoring(Site, lmsSiteId, countryCode, Deliverydate, id, flag) {

    const token = getToken();
    const headers = {
      "accept": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Authorization": `Bearer ${token}`
    }

    let datapost = [{
      "date": Deliverydate,

      "locationNo": lmsSiteId,
      "countryCode": countryCode
    }]


    JQuery.ajax({
      url: hostUrl + "/orderReleaseDashboard/startMonitoring",

      type: 'POST',
      dataType: 'json',
      data: JSON.stringify(datapost),
      CORS: true,
      contentType: 'application/json',
      secure: true,
      headers: headers,
      crossDomain: true,
      success: (res) => {


        JQuery('#' + id + '_Monitoring').prop('disabled', true).css({ "background-color": "gray" });
        JQuery('#' + id + '_Monitoring').html("Monitoring Started");

        JQuery('#' + id + '_Refresh').show();

        JQuery('#' + id + '_Status_Monitoting_id').html("Monitoring has started");
        JQuery('#' + id + '_Status_Monitoting_id2').html("");
        JQuery('#' + id + '_Status_Monitoting_id3').html("");

      },
      error: function (jqHXR, textStatus, errorThrown) {


      }

    });



  }

  // call monitoring refresh button//
  handleRefreshing(Site, lmsSiteId, countryCode, Deliverydate, id, flag) {


    const token = getToken();
    const headers = {
      "accept": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Authorization": `Bearer ${token}`
    }

    JQuery.ajax({
      url: hostUrl + "/orderReleaseDashboard/refreshMointorning",

      type: 'POST',
      dataType: 'json',
      data: JSON.stringify([{

        "date": Deliverydate,

        "locationNo": lmsSiteId,
        "countryCode": countryCode

      }]),
      CORS: true,
      contentType: 'application/json',
      secure: true,
      headers: headers,
      crossDomain: true,
      success: (res) => {

        JQuery('#' + id + '_Monitoring').prop('disabled', true).css({ "background-color": "gray" });
        JQuery('#' + id + '_Monitoring').html("Monitoring Started");

        JQuery('#' + id + '_Refresh').show();

        JQuery('#' + id + '_Status_Monitoting_id').html("Monitoring has started");
        JQuery('#' + id + '_Status_Monitoting_id2').html("");
        JQuery('#' + id + '_Status_Monitoting_id3').html("");

      },
      error: function (jqHXR, textStatus, errorThrown) {


      }

    });


  }


  handleClose = () => {

    this.setState({ show: false });

  }
  render() {

    return (
      <>
        <div>
          {this.state.AccessDenied === true && (
            <Navigate to="/accessdenied" replace={true} />
          )}
        </div>

        <div className="order-font container-fluid">


          <Card.Header style={{ backgroundColor: "#1C1B83" }}>

            <div className='row'>

              <div className='col-md-2' style={{ backgroundColor: "white" }}>

                <img src={process.env.PUBLIC_URL + "/PepsicoLogo.png"} width="140" alt="logo" style={{}} />

              </div>

              <div className='col-md-6 ' style={{ color: "white", backgroundColor: "#1C1B83" }}>

                <h5 style={{ marginTop: ".5rem", textAlign: "left" }}><b>Supply Chain Monitoring: CSR Data Flow Dashboard</b></h5>

              </div>

              <div className='col-md-2'>

                <a href='/orders/home'>

                  <i class="fa fa-home" aria-hidden="true" style={{ color: 'white', marginLeft: '9vw', fontSize: '2.3rem', marginTop: ".3rem" }}></i>

                </a>

              </div>

              <div className='col-md-2 ' style={{ color: "white", backgroundColor: "white" }}>

                <img src={process.env.PUBLIC_URL + "/LogoImages.png"} width="140" alt="logo" style={{ width: "11rem" }} />

              </div>

            </div>


          </Card.Header>

          <Card.Body>


            <div className='row mt-2'>

              <div className='col-md-2' >

                <div className="row justify-content-center" style={{ marginTop: "0.5vw" }}>
                  <div className="col-md-10 ">

                    <Form.Label>System Clock</Form.Label>
                  </div>
                </div>
                <div className="row ">
                  <div className=" col-md-10" style={{ marginLeft: "1.4vw" }}>


                    <Form.Control type="text" id='systemclock' style={{ textAlign: "center" }} />
                  </div>
                </div>
                <div className="row justify-content-center" style={{ marginTop: "1vw" }}>
                  <div className="col-md-10">

                    <Form.Label>Last Refresh</Form.Label>&nbsp;

                    <Button title='Refreshes data for sites where monitoring has started' id="Page_Refresh" variant="outline-light" style={{ backgroundColor: "#1C1B83", fontSize: 10, color: "white" }}
                      onClick={() => this.handlePageRefreshing()}>

                      <span><i class='fa fa-refresh' ></i></span>
                    </Button>
                  </div>


                </div>
                <div className="row ">
                  <div className=" col-md-10" style={{ marginLeft: "1.4vw" }}>


                    <Form.Control type="text" id='LastRefresh' style={{ textAlign: "center" }} />
                  </div>
                </div>
                <div className="row" style={{ marginTop: "1vw" }}>

                </div>


                <div style={{ backgroundColor: "#1C1B83", height: "auto" }}>

                  <div class="row">

                  </div>
                  <div class="row mt-4">
                    <h5 className="text-white" style={{ textAlign: 'center' }}>Filters</h5>

                  </div>

                  <div className="row" style={{ marginTop: "0.5vw" }}>
                    <div className="col-md-10" style={{ textAlign: 'left', marginLeft: "0.5rem" }}>

                      <Form.Label style={{ color: "white" }}>Sites</Form.Label>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-10" style={{ marginLeft: "1.4vw", textAlign: 'left' }}>

                      <div className="row">

                        <span
                          className="d-inline-block"
                          data-toggle="popover"
                          data-trigger="focus"
                          data-content="Please select account(s)"
                        >

                          <SelectAll

                            options={this.state.temp__sitesParam}
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{
                              Option,
                              MultiValue,
                              ValueContainer,
                              animatedComponents
                            }}
                            onChange={this.handleChangeSites}
                            allowSelectAll={true}
                            value={this.state.optionSelectedSites}

                          />
                        </span>

                      </div>

                    </div>
                  </div>

                  <div className="row" style={{ marginTop: "0.5vw" }}>
                    <div className="col-md-10" style={{ textAlign: 'left', marginLeft: "0.5rem" }}>

                      <Form.Label style={{ color: "white" }}>Delivery Date</Form.Label>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-10" style={{ color: "white", marginLeft: "1.4vw" }}>

                      <DatePicker className='form-control' id='deliverydate' selected={this.state.deliverydatevalue} dateFormat="MM/dd/yyyy" onChange={(date) => this.setState({ deliverydatevalue: date })} placeholderText="MM/DD/YYYY" />


                    </div>
                  </div>
                  <div className="row" style={{ marginTop: "0.5vw" }}>
                    <div className="col-md-10" style={{ textAlign: 'left', marginLeft: "0.5rem" }}>

                      <Form.Label style={{ color: "white" }}>Status</Form.Label>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-10" style={{ marginLeft: "1.4vw", textAlign: 'left' }}>

                      <div className="row">

                        <span
                          className="d-inline-block"
                          data-toggle="popover"
                          data-trigger="focus"
                          data-content="Please select account(s)"
                        >
                          <SelectAll

                            options={this.state.temp__statusParam}
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{
                              Option,
                              MultiValue,
                              ValueContainer,
                              animatedComponents
                            }}
                            onChange={this.handleChangeStatus}
                            allowSelectAll={true}
                            value={this.state.optionSelectedStatus}

                          />
                        </span>

                      </div>

                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-12 justify-content-center">

                      <Button id="filtersubmitbutton" variant="outline-light" style={{ backgroundColor: "#009639", borderColor: "#1C1B83", color: "white", fontSize: 14 }} onClick={this.handleFilterSubmit}>Submit</Button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-10">

                      <Form.Label style={{ color: "white" }}></Form.Label>
                    </div>
                  </div>

                </div>
              </div>
              {this.state.isLoading ? <div className={"loaderWrapper"}>
                <Spinner animation="border" variant="primary">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div> : <div className='col-md-10'>

                <div className='row'>
                  <Table responsive striped bordered hover id="tab_DashboardData" style={{ marginLeft: "-.8rem" }}>

                    <thead className="text-white" style={{ backgroundColor: "#1C1B83" }}>
                      <tr style={{ verticalAlign: "middle" }}>
                        <th style={{ textAlign: 'center', width: "17rem" }}>Site <br />(Delivery Date)</th>

                        <th style={{ textAlign: 'center', width: "12rem" }}>SAP Data</th>
                        <th style={{ textAlign: 'center', width: "12rem" }}>ETMS Data</th>
                        <th style={{ textAlign: 'center', width: "12rem" }}>PWM Data</th>

                        <th style={{ textAlign: 'center', width: "12rem" }}>IPM Data</th>
                        <th style={{ textAlign: 'center', width: "12rem" }}>ICS Data</th>
                        <th style={{ textAlign: 'center', width: "13rem" }}>Status</th>
                      </tr>

                    </thead>
                    <tbody>
                      {


                        this.state.dt
                          .map((dashboardObj) =>

                            <ReadOnlyRowsDashboard dashboardObj={dashboardObj} handleMonitoring={this.handleMonitoring}

                              handleRefreshing={this.handleRefreshing} />

                          )}


                    </tbody>

                  </Table>
                </div>
                <div className='row' style={{ marginTop: "1.5vw" }}>
                  <ReactPaginate
                    previousLabel={"<< prev"}
                    nextLabel={"next >>"}
                    breakLabel={"..."}
                    forcePage={this.state.currentPage}
                    pageCount={this.state.pageCount}

                    marginPagesDisplayed={3}
                    pageRangeDisplayed={3}
                    onPageChange={(data) => this.handleChangePagination(this.state.RequestDashboardDataTotal, data)}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                    renderOnZeroPageCount={null}



                  />
                </div >
                <div>
                  <Modal
                    show={this.state.show}
                    onHide={this.handleClose}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton >
                      <Modal.Title >  </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                    </Modal.Body>
                    <Modal.Footer>

                      <Button variant="outline-light" style={{ color: "White", backgroundColor: "#009639" }} onClick={this.handleClose}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>}

            </div>


          </Card.Body>


        </div>
      </>

    );
  }
}


export default OrderFlowStatusDashboard;